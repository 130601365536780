import { ISchedule } from "../interface/scheduleDayRow";

export const getLastSchedule = (
  daySchedules: ISchedule[],
  currentScheduleIndex: number
) => {
  if (currentScheduleIndex > 0) {
    const prevSchedule = daySchedules[currentScheduleIndex - 1];

    if (prevSchedule.endTime !== "") {
      let endTimeHour = Number(prevSchedule.endTime.split(":")[0]);
      if (prevSchedule.endTime.split(" ")[1] === "PM") {
        if (endTimeHour !== 12) {
          endTimeHour += 12;
        }
      }

      const formattedEndTime = prevSchedule.endTime
        .replace(" AM", "")
        .replace(" PM", "");
      const convertedEndTime =
        endTimeHour + Number(formattedEndTime.split(":")[1]) / 60;
      return convertedEndTime;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
