// Constante para los tipos de movimientos
export const typeMovements = [
  { name: "Ingreso", value: "1" },
  { name: "Egreso", value: "2" },
];

// una funcion para elegir la clase de las celdas de la tabla
export const classCell = (movementType: string, style, isRounded = false) => {
  if (movementType === "Egreso") {
    return `${style.typeOfMovementOutcome} ${isRounded ? style.isRounded : ""}`;
  } else {
    return `${style.typeOfMovementIncome} ${isRounded ? style.isRounded : ""}`;
  }
};

export const MOVIEMIENTOS_DE_CAJA = "movimientos-de-caja";
