import { useEffect, useState } from "react";

const useValidation = (rucData, isRequired?: boolean) => {
  const [valueInput, setValueInput] = useState<string | null>(null);
  const [error, setError] = useState(null);

  //update the valueInput
  const changeValueInput = (e) => {
    const value = e.target.value;
    return setValueInput(value);
  };

  const changeInitValue = (value) => {
    return setValueInput(value);
  };

  const validateValueInput = () => {
    if (!isRequired) return;
    if (valueInput === "") setError("Este campo es de carácter obligatorio");
    else setError(null);
  };

  useEffect(
    () => validateValueInput(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valueInput]
  );

  useEffect(() => {
    if (rucData === "") changeInitValue("");
    if (rucData) changeInitValue(rucData);
  }, [rucData]);

  return {
    valueInput: valueInput,
    changeValueInput,
    changeInitValue,
    error: error,
  };
};

export default useValidation;
