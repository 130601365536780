// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.He6dIV0vQf4u7rivWMWF {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
}

.YS3kxXYxRGPtBx_ELsui {
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.233px;
  text-align: center;
}

.UcbGW7K1dES8YCobn_2N {
  width: 79px;
  height: 79px;
  margin: auto;
}

.w3cyqCjeqQ1kOfzAwkGD {
  display: grid;
  gap: 32px;
  justify-content: center;
  max-width: 400px;
}

.TzAuAxQ7gbzWN7y2Mky9 {
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/FormAuthorizeRuc/NewRucPage/components/headerModal/headerModal.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".titleModal {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  text-align: center;\n}\n\n.contentModal {\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  text-align: center;\n}\n\n.iconModal {\n  width: 79px;\n  height: 79px;\n  margin: auto;\n}\n\n.modal {\n  display: grid;\n  gap: 32px;\n  justify-content: center;\n  max-width: 400px;\n}\n\n.icon {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleModal": `He6dIV0vQf4u7rivWMWF`,
	"contentModal": `YS3kxXYxRGPtBx_ELsui`,
	"iconModal": `UcbGW7K1dES8YCobn_2N`,
	"modal": `w3cyqCjeqQ1kOfzAwkGD`,
	"icon": `TzAuAxQ7gbzWN7y2Mky9`
};
export default ___CSS_LOADER_EXPORT___;
