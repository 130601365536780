import React from "react";
//@ts-ignore
import style from "../searchCard.module.css";
import { TripleDotsIcon } from "@Models/icons";

const CardResult = ({ result, addCard, setTextValue, setResults, cards }) => {
  const [showActions, setShowActions] = React.useState(false);
  const bttnRef = React.useRef(null);

  const handleShowActions = () => {
    setShowActions(!showActions);
    function handleClickOutside(event) {
      if (bttnRef.current && !bttnRef.current.contains(event.target)) {
        setShowActions(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
  };
  const hasActions = result?.actions !== undefined;
  const hasSelected = cards?.find((c) => c.id === result.id);

  return (
    <li key={result.id}>
      <button onClick={() => addCard(result.id)} className={style.buttonClick}>
        <p>{result.name}</p>
      </button>
      {hasActions && !hasSelected ? (
        <button
          className={style.action}
          onClick={(e) => {
            e.stopPropagation();
            handleShowActions();
          }}
          ref={bttnRef}
        >
          {result.actions?.length !== 0 && (
            <img src={TripleDotsIcon} alt="Tres puntos" />
          )}
          {showActions && (
            <div>
              {result.actions?.map((a) => {
                return (
                  <button
                    key={a.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      setTextValue("");
                      setResults([]);
                      a.action(result.id);
                    }}
                  >
                    <p>{a.name}</p>
                  </button>
                );
              })}
            </div>
          )}
        </button>
      ) : (
        hasSelected && (
          <button onClick={() => addCard(result.id)} className={style.selected}>
            Seleccionado
          </button>
        )
      )}
    </li>
  );
};

export default CardResult;
