import { ApiResponse } from "@Services/constServices";
import { getAppointmentById_service } from "@Services/reservation/getAppointmentById.service";
import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";

export const getAppointmentById_adapter = async (id: number) => {
  const response = await getAppointmentById_service(id);
  if (!response.isSuccess) {
    return response;
  }

  const adaptItems = response.data.data.services.map((item) => ({
    id: item.reserveDetailId,
    service: item.priceServiceId,
    employee: item.employeeId,
    schedule: item.hourReserve,
  }));

  const adaptClient = {
    name: capitalizeFirstLetters(response.data.data.clientFirstName),
    surname: capitalizeFirstLetters(response.data.data.clientLastName),
    birthDate: response.data.data.clientBirthday || "Sin especificar",
    documentTypeId: response.data.data.clientDocumentTypeId || 2,
    document: response.data.data.clientDocument || "Sin especificar",
    category: response.data.data.clientCategoryName || "Sin especificar",
    email: response.data.data.clientEmail || "Sin especificar",
    phone: response.data.data.clientPhone || "Sin especificar",
    id: response.data.data.clientId,
  };

  const statusReserve = response.data.data.status;
  return {
    isSuccess: response.isSuccess,
    status: response.status,
    message: response.message,
    data: {
      services: adaptItems,
      client: adaptClient,
      statusReserve: statusReserve,
    },
  };
};
