import React from "react";
//@ts-ignore
import style from "./CircularRadioInput.module.css";

const CircularRadioInput = ({ size, checked, id, onClick }) => {
  return (
    <div
      className={style.container}
      onClick={onClick}
      style={{ width: size || "18px" }}
      id={id}
    >
      <div className={checked && style.buttonOn} onClick={onClick}></div>
    </div>
  );
};

export default CircularRadioInput;
