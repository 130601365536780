// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jwozPGQwqIftiaUvpkDA {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 24px;

  @media screen and (width < 550px) {
    display: flex;
    flex-direction: column;
  }

  & aside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border-right: 1px solid #392b75;

    @media screen and (width < 550px) {
      display: none;
    }

    & div {
      min-height: 480px;
      top: 0;
      position: sticky;
      display: flex;
      flex-direction: column;
      color: #45348e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.233px;
    }

    & a {
      padding: 12px;
      border-radius: 4px;
    }

    & a:hover {
      background-color: #947cf43d;
    }
  }
}

.Xubt6fKn_Hq2eTqJMa1A {
  font-weight: 900;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/AsideSectionMenu/style/AsideSectionMenu.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,SAAS;;EAET;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,+BAA+B;;IAE/B;MACE,aAAa;IACf;;IAEA;MACE,iBAAiB;MACjB,MAAM;MACN,gBAAgB;MAChB,aAAa;MACb,sBAAsB;MACtB,cAAc;MACd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB;MACnB,uBAAuB;IACzB;;IAEA;MACE,aAAa;MACb,kBAAkB;IACpB;;IAEA;MACE,2BAA2B;IAC7B;EACF;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".asideSectionMenu {\n  display: grid;\n  grid-template-columns: 150px auto;\n  gap: 24px;\n\n  @media screen and (width < 550px) {\n    display: flex;\n    flex-direction: column;\n  }\n\n  & aside {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    position: relative;\n    border-right: 1px solid #392b75;\n\n    @media screen and (width < 550px) {\n      display: none;\n    }\n\n    & div {\n      min-height: 480px;\n      top: 0;\n      position: sticky;\n      display: flex;\n      flex-direction: column;\n      color: #45348e;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: normal;\n      letter-spacing: 0.233px;\n    }\n\n    & a {\n      padding: 12px;\n      border-radius: 4px;\n    }\n\n    & a:hover {\n      background-color: #947cf43d;\n    }\n  }\n}\n\n.itemSelected {\n  font-weight: 900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asideSectionMenu": `jwozPGQwqIftiaUvpkDA`,
	"itemSelected": `Xubt6fKn_Hq2eTqJMa1A`
};
export default ___CSS_LOADER_EXPORT___;
