export const getInitials = (name: string) => {
  // Split the string into words
  const words = name.split(" ");

  // Get the first letter of the first word
  const firstLetter = words[0]?.charAt(0) || "";

  // Get the first letter of the last word
  const lastWord = words[words.length - 1];
  const lastLetter = lastWord !== words[0] ? lastWord?.charAt(0) || "" : "";

  // Concatenate the initials
  const initials = firstLetter + lastLetter;

  return initials.toUpperCase();
};
