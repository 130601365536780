import React, { useEffect, useState, useCallback, useMemo } from "react";
// @ts-ignore
import style from "./ModalSelectServices.module.css";
// @ts-ignore
import { ModalAcceptCancel, TextInput } from "@viuti/recursos";
import { useGetServices } from "../../../../../src/hooks/services/useGetServices";
import { Service } from "@Models/interfaces/services";
import { MagnifyingGlassIcon } from "@Models/icons";

const ModalSelectServices = ({
  servicesSelected = [],
  isVisible,
  handleSaveClick,
  handleCloseClick,
  message = "Selecciona los servicios que deseas agregar",
}: {
  servicesSelected?: Service[];
  isVisible: boolean;
  handleSaveClick: (selectedServices: Service[]) => void;
  handleCloseClick: () => void;
  message: string;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [search, setSearch] = useState<string>("");

  const { services } = useGetServices();

  useEffect(() => {
    setSelectedServices(servicesSelected);
  }, [servicesSelected, isVisible]);

  const handleSave = () => {
    handleSaveClick(selectedServices);
  };

  const handleCancel = () => {
    setSelectedServices(servicesSelected);
    handleCloseClick();
  };

  useEffect(() => {
    const hasChanges =
      JSON.stringify(selectedServices) !== JSON.stringify(servicesSelected);
    setIsButtonDisabled(!hasChanges);
  }, [selectedServices, servicesSelected]);

  const handleCheckboxChange = useCallback((service: Service) => {
    setSelectedServices((prev) => {
      const isSelected = prev.some((s) => s.id === service.id);
      if (isSelected) {
        return prev.filter((s) => s.id !== service.id);
      } else {
        return [...prev, service];
      }
    });
  }, []);

  const isServiceSelected = useCallback(
    (id: number) => selectedServices.some((service) => service.id === id),
    [selectedServices]
  );

  const filteredServices = useMemo(() => {
    return services.filter((service) =>
      service.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [services, search]);

  const handleSelectAll = useCallback(() => {
    if (selectedServices.length === filteredServices.length) {
      setSelectedServices([]);
    } else {
      setSelectedServices(filteredServices);
    }
  }, [filteredServices, selectedServices]);

  const searchProps = {
    value: search,
    placeholder: "Buscar servicios...",
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearch(e.target.value),
    leftIcon: MagnifyingGlassIcon,
  };

  return (
    <ModalAcceptCancel
      title={"Servicios"}
      visibility={{
        isVisible: isVisible,
      }}
      showButtons={{
        showButtonOne: true,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: handleCancel,
        isDisabled: false,
        isLoading: false,
      }}
      buttonTwo={{
        textButtonTwo: "Guardar",
        actionButtonTwo: handleSave,
        isDisabled: isButtonDisabled,
      }}
      actionButtonClose={handleCancel}
    >
      <div className={style.modal_select_services}>
        <p>{message}</p>
        <TextInput {...searchProps} />
        <button onClick={handleSelectAll} className={style.button_select_all}>
          {selectedServices.length === filteredServices.length
            ? "Deseleccionar todos"
            : "Seleccionar todos"}
        </button>
        {filteredServices.length === 0 && search !== "" && (
          <p className={style.noServicesMessage}>No se encontraron servicios</p>
        )}
        <div className={style.services}>
          {filteredServices.map((service: Service) => (
            <label key={service.id} className={style.service}>
              <input
                type="checkbox"
                id={String(service.id)}
                name={service.name}
                value={service.id}
                checked={isServiceSelected(service.id)}
                onChange={() => handleCheckboxChange(service)}
              />
              <span>{service.name}</span>
            </label>
          ))}
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default ModalSelectServices;
