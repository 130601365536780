import React, { useState } from "react";
import DragAndDropFile from "../../atoms/dragAndDropFile/DragAndDropFile";
import UploadedFileStatus from "@Atoms/dragAndDropFile/components/UploadedFileStatus/UploadedFileStatus";
import style from "./XmlFileReader.module.css";
const XmlFileReader = ({ onFileRead, label = "Cargar archivo XML" }) => {
  const [file, setFile] = useState(null);
  const [isUploadedFileOk, setIsUploadedFileOk] = useState(null);
  const [uploadedFileMessage, setUploadedFileMessage] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);

  const handleFileChange = (newFile) => {
    setFile(newFile);
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setXmlContent(content);
        onFileRead(content);
      };
      reader.readAsText(newFile);
    } else {
      setXmlContent(null);
      onFileRead(null);
    }
  };

  const handleStatusClose = () => {
    if (isUploadedFileOk) {
      setFile(null);
      setIsUploadedFileOk(null);
      setXmlContent(null);
      onFileRead(null);
    } else {
      setIsUploadedFileOk(null);
    }
  };

  return (
    <section className={style.file_uploader__container}>
      {label && <h2 className={style.newRucsPage__SectionRow__h2}>{label}</h2>}
      <DragAndDropFile
        accept={[".xml"]}
        file={file}
        setFile={handleFileChange}
        setIsUploadedFileOk={setIsUploadedFileOk}
        setUploadedFileMessage={setUploadedFileMessage}
      />
      {isUploadedFileOk !== null && (
        <UploadedFileStatus
          type={isUploadedFileOk ? "success" : "error"}
          message={uploadedFileMessage}
          setClose={handleStatusClose}
        />
      )}
    </section>
  );
};

export default XmlFileReader;
