// Customer.js
import React from "react";
import styles from "./Customer.module.css";
// @ts-ignore
import { Icon, Dropdown, TertiaryButton } from "@viuti/recursos";
import { PencilWithFrameIcon, WhatsAppIcon } from "@Models/icons";

export function Customer({
  name,
  surname,
  documentTypeId,
  document,
  birthDate,
  email,
  phone,
  category,
  deselectCustomer,
  isEdit,
  setEditCustomer,
  setCustomerSelected,
}) {
  const [showMore, setShowMore] = React.useState(false);
  let fields;
  const RUC = 1;
  if (documentTypeId === RUC) {
    fields = [
      { label: "Razón social", value: name },
      { label: "Número de RUC", value: document },
      { label: "Correo electrónico", value: email },
      { label: "Celular", value: phone },
      // { label: "Categoría", value: category },
    ];
  } else {
    fields = [
      { label: "Nombre(s)", value: name },
      { label: "Apellido(s)", value: surname },
      { label: "Número de documento", value: document },
      { label: "Fecha de nacimiento", value: birthDate },
      { label: "Correo electrónico", value: email },
      { label: "Celular", value: phone },
      // { label: "Categoría", value: category },
    ];
  }

  const handleWhatsAppClick = () => {
    if (phone === "Sin especificar") return;
    window.open(`https://api.whatsapp.com/send?phone=51${phone}`, "_blank");
  };

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Cliente</h2>
        {!isEdit && (
          <button
            onClick={deselectCustomer}
            className={styles.closeButton}
            aria-label="Cerrar"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L4 12M4 4L12 12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        {/* {isEdit && (
          <TertiaryButton
            buttonProps={{
              onClick: () => {
                setEditCustomer(true);
                setCustomerSelected(null);
              },
              disabled: false,
            }}
            icon={PencilWithFrameIcon}
            value="Cambiar"
          />
        )} */}
      </div>

      <div className={styles.content}>
        {fields.slice(0, 2).map((field, index) => (
          <article key={index} className={`${styles.fieldRow}`}>
            <span className={styles.label}>{field.label}</span>
            <strong className={styles.value}>{field.value}</strong>
          </article>
        ))}
        <Dropdown isOpen={showMore} transitionDurationInMs={200}>
          {fields.slice(2).map((field, index) => (
            <article key={index + 2} className={`${styles.fieldRow} document`}>
              <span className={styles.label}>{field.label}</span>
              <strong className={styles.value}>{field.value}</strong>
            </article>
          ))}
        </Dropdown>

        <button
          className={`${styles.showMoreButton} ${
            showMore ? styles.expanded : ""
          }`}
          onClick={toggleShowMore}
        >
          {showMore ? "Mostrar menos" : "Mostrar más"}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <button
          className={styles.whatsappButton}
          disabled={phone === "Sin especificar"}
          onClick={handleWhatsAppClick}
        >
          <Icon
            size={20}
            path={WhatsAppIcon}
            alt="whatsapp"
            className={styles.icon}
          />
          <span>Enviar mensaje</span>
        </button>
      </div>
    </section>
  );
}
