import { searchCustomers } from "@Services/clients/searchCustomers";
import { handleApiError } from "@Services/constServices";
import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";
import { formatDocument } from "@Utilities/formatDocument";
import { truncateString } from "@Utilities/truncateString";

export interface AdaptedSearchData {
  customerId: string;
  fullName: string;
  formattedDocument: string;
  email: string;
}
// Reveer estas funciones para hacerlas mas entendibles
const adaptedResponseCustomer = async (input: string, setStateResponse) => {
  try {
    const customersResponse = await searchCustomers(input);
    const adaptedCustomers = adaptToSearch(customersResponse.data);
    const adaptedResponse = { ...customersResponse, data: adaptedCustomers };
    return adaptedResponse;
  } catch (error) {
    setStateResponse({
      message:
        "Hubo un error al buscar los clientes. Por favor, intente nuevamente.",
      status: 400,
    });
    return { ...handleApiError(error), data: [] };
  }
};

const adaptToSearch = (customers): AdaptedSearchData[] => {
  const adaptedCustomers = customers.map((customer) => ({
    customerId: customer.customerId.toString(),
    fullName: `${capitalizeFirstLetters(
      customer.firstName
    )} ${capitalizeFirstLetters(customer.lastName)}`,
    formattedDocument: formatDocument(
      customer.documentType,
      customer.customerDocument
    ),
    email: customer.email,
  }));
  const firstFiveResults = adaptedCustomers.slice(0, 5);
  return firstFiveResults;
};

export const adaptedCustomerSearch = async (
  value,
  navigate,
  setStateResponse
) => {
  if (value.length === 0) {
    return [];
  } else {
    try {
      const adaptedResponse = await adaptedResponseCustomer(
        value,
        setStateResponse
      );
      const adaptedClients: AdaptedSearchData[] = adaptedResponse.data;
      return adaptedClients.map((client) => ({
        idClient: client.customerId,
        image: "",
        fullName: client.fullName,
        info: `${client.formattedDocument} ${truncateString(client.email, 20)}`,
        onClick: () => navigate(`/detalles/${client.customerId}`),
      }));
    } catch (error) {
      return [];
    }
  }
};

export const getClientsAdapter = async (
  initials,
  clickOnClient,
  setSearchInput,
  setInitialSelectCustomer
) => {
  try {
    const clients = await searchCustomers(initials);
    return {
      isSuccess: clients.status === 200,
      data: clients.data.map((client) => ({
        idClient: client.customerId,
        image: "",
        fullName:
          capitalizeFirstLetters(client.firstName) +
          " " +
          capitalizeFirstLetters(client.lastName),
        info: `${client.documentType} ${
          client.customerDocument || "Sin especificar"
        }`,
        onClick: () => {
          const objCustomer = {
            name: capitalizeFirstLetters(client.firstName) || "Sin especificar",
            surname:
              capitalizeFirstLetters(client.lastName) || "Sin especificar",
            documentTypeId: client.documentTypeId || 2,
            birthDate: client.birthdate || "Sin especificar",
            document: client.customerDocument || "Sin especificar",
            category: client?.category?.categoryName || "Sin especificar",
            email: client.email || "Sin especificar",
            phone: client.phone || "Sin especificar",
            id: client.customerId,
          };
          clickOnClient(objCustomer);
          setInitialSelectCustomer(objCustomer);
          setSearchInput("");
        },
      })),
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
    };
  }
};
