import React, { useEffect } from "react";

const useSelectBox = (boxes, saleBoxId, form, setForm, setInitialForm) => {
  useEffect(() => {
    if (boxes.length) {
      for (const box of boxes) {
        if (Number(box.value) === Number(saleBoxId)) {
          setForm({ ...form, idBox: box.value });
          setInitialForm({ ...form, idBox: box.value });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxes, saleBoxId]);
};

export default useSelectBox;
