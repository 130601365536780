// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TpGEzdDW91WVK0yCW921,
.fhTpl_heW5is7pQlb6dq {
  border: none;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.TpGEzdDW91WVK0yCW921 {
  background-color: #f0edfe;
}

.fhTpl_heW5is7pQlb6dq {
  background: #937cf44d;
}

.fhTpl_heW5is7pQlb6dq:hover {
  background-color: var(--bg-hover-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/buttons/buttonsWithIcons/buttonRoundedIcon/buttonRoundedIcon.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kDAAkD;AACpD","sourcesContent":[".bttnRoundedIcon,\n.bttnRoundedIconPrimary {\n  border: none;\n  border-radius: 50%;\n  width: 34px;\n  height: 34px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n}\n\n.bttnRoundedIcon {\n  background-color: #f0edfe;\n}\n\n.bttnRoundedIconPrimary {\n  background: #937cf44d;\n}\n\n.bttnRoundedIconPrimary:hover {\n  background-color: var(--bg-hover-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bttnRoundedIcon": `TpGEzdDW91WVK0yCW921`,
	"bttnRoundedIconPrimary": `fhTpl_heW5is7pQlb6dq`
};
export default ___CSS_LOADER_EXPORT___;
