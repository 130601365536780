import React, { useState } from "react";
import style from "./ShareSalesDocumentsReportModal.module.css";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";

import { ModalAcceptCancel } from "@Molecules/index";
import { getBase64downloadSaleDocument } from "@Adapters/saleboxes/getBase64downloadSaleDocument.adapter";

const ShareSalesDocumentsReportModal = ({
  isVisible,
  setIsVisible,
  filters,
  onNotification,
}) => {
  const [buttonStates, setButtonStates] = useState({
    csv: {
      isLoading: false,
      isDisabled: false,
    },
    excel: {
      isLoading: false,
      isDisabled: false,
    },
  });

  const [lastFetch, setLastFetch] = useState({
    status: null,
    message: null,
  });

  const onSubmit = async (type: 1 | 2) => {
    const buttonType = type === 1 ? "csv" : "excel";
    const fileExtension = type === 1 ? "csv" : "xlsx";
    const mimeType =
      type === 1
        ? "text/csv"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    setButtonStates((prev) => ({
      ...prev,
      [buttonType]: {
        isLoading: true,
        isDisabled: true,
      },
      [type === 1 ? "excel" : "csv"]: {
        ...prev[type === 1 ? "excel" : "csv"],
        isDisabled: true,
      },
    }));

    try {
      const response = await getBase64downloadSaleDocument(
        filters,
        onNotification,
        type
      );

      if (!response.isSuccessful) {
        setLastFetch({
          status: "error",
          message: "Error al descargar.",
        });
        return;
      }

      const binaryData = atob(response.data);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: mimeType });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Reporte-de-documentos-de-ventas.${fileExtension}`;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setLastFetch({
        status: "success",
        message: "Descarga exitosa.",
      });

      onNotification({
        message:
          "Descarga del reporte exitosa, revisa tu carpeta de descargas.",
        status: 200,
        toastTimeDuration: 6000,
      });
    } catch (error) {
      setLastFetch({
        status: "error",
        message: "Error al descargar.",
      });
      onNotification({
        message: "Error al descargar el reporte. Intenta nuevamente.",
        status: 500,
        toastTimeDuration: 4000,
      });
    } finally {
      setButtonStates((prev) => ({
        csv: {
          isLoading: false,
          isDisabled: false,
        },
        excel: {
          isLoading: false,
          isDisabled: false,
        },
      }));
    }
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setLastFetch({ status: null, message: null });
    setButtonStates({
      csv: {
        isLoading: false,
        isDisabled: false,
      },
      excel: {
        isLoading: false,
        isDisabled: false,
      },
    });
  };

  return (
    <ModalAcceptCancel
      title={"Exportar reporte"}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
      actionButtonClose={handleCloseModal}
    >
      <div className={style.body}>
        <p>
          Elija las opciones de reporte de documentos de ventas que prefiera.
        </p>
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn={
            buttonStates.csv.isLoading ? "Descargando..." : "Descargar CSV"
          }
          handleClick={() => onSubmit(1)}
          isDisabled={buttonStates.csv.isDisabled}
          isLoading={buttonStates.csv.isLoading}
        />
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn={
            buttonStates.excel.isLoading ? "Descargando..." : "Descargar Excel"
          }
          handleClick={() => onSubmit(2)}
          isDisabled={buttonStates.excel.isDisabled}
          isLoading={buttonStates.excel.isLoading}
        />
      </div>
    </ModalAcceptCancel>
  );
};

export default ShareSalesDocumentsReportModal;
