import React, { useEffect, useRef } from "react";
//@ts-ignore
import style from "./SearchWithColoredBorder.module.css";
import TextInput from "../textInput/TextInput";
import { MagnifyingGlassIcon } from "@Models/icons";
import { ISearchWithColoredBorder } from "./interface/SearchWithColoredBorder";
import useResultOverflow from "./hook/useResultOverflow";

const SearchWithColoredBorder = ({
  label = "",
  value,
  setValue,
  allData,
  onClickResult = (item) => {},
  showResults = false,
  placeholder,
  disabled = false,
  required = false,
  inputProps = {},
  clearInput = true,
  searchBy = ["name"],
  onKeyDown,
  inputMode = "search",
  inputRef,
  onClick,
  onFocus,
}: ISearchWithColoredBorder) => {
  const [showResultsState, setShowResultsState] = React.useState(showResults);
  const [searchResult, setSearchResult] = React.useState([]);
  const searchRef = useRef(null);
  const resultRef = useRef(null);

  const resultProps = useResultOverflow({
    searchElementRef: searchRef,
    resultElementRef: resultRef,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResultsState(false);
        if (clearInput) setValue("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clearInput]);

  useEffect(() => {
    if (showResultsState) {
      if (value === "") return setSearchResult([]);
      const result = allData?.filter((item) => {
        return searchBy.some((key) => {
          const itemValue = item[key];
          return itemValue?.toLowerCase()?.includes(value.toLowerCase());
        });
      });
      setSearchResult(result);
    }
  }, [value, allData, showResultsState, searchBy]);

  useEffect(() => {
    setShowResultsState(showResults);
  }, [showResults]);

  const handleFocus = () => {
    onFocus && onFocus();
    setShowResultsState(true);
  };

  const handleResultClick = (item) => {
    onClickResult(item);
    setShowResultsState(false);
  };

  return (
    <div className={style.container} ref={searchRef}>
      <TextInput
        label={label}
        placeholder={placeholder}
        leftIcon={MagnifyingGlassIcon}
        value={value}
        handleChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        inputProps={inputProps}
        required={required}
        onKeyDown={onKeyDown}
        inputMode={inputMode}
        inputRef={inputRef}
        onFocus={handleFocus}
      />
      {searchResult?.length > 0 && value !== "" && showResultsState && (
        <section
          className={`${style.results_container} ${
            label ? style.results_position_with_label : ""
          }`}
          ref={resultRef}
        >
          {searchResult.map((item, index) => {
            const uniqueKey = `${item.id}-${index}`;
            return (
              <button key={uniqueKey} onClick={() => handleResultClick(item)}>
                <article key={item.id}>{item.name}</article>
              </button>
            );
          })}
          {searchResult.length === 0 && value !== "" && (
            <article>No se encontraron resultados</article>
          )}
        </section>
      )}
    </div>
  );
};

export default SearchWithColoredBorder;
