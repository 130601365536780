import React from "react";
//@ts-ignore
import style from "./style/HeaderNavigation.module.css";
import Button from "@Atoms/buttons/buttonsWithoutIcons/button";
import { TimesIcon, ArrowLeftIcon } from "@Models/icons";
import { IHeaderNavegation } from "./interface/HeaderNavegation";

const HeaderNavegation = (props: IHeaderNavegation) => {
  const {
    title = "",
    context = null,
    previousAction,
    previousActionMovement,
    buttonProps,
    buttonTwoProps = false,
    otherComponent = null,
  } = props;

  const previousIcon =
    previousActionMovement === "close" ? TimesIcon : ArrowLeftIcon;

  return (
    <header className={style.header}>
      {/* <div className={style.header__action__container}>
        {previousAction && (
          
        )}
        {context ? (
          <span className={style.title__container}>
            <p className={style.title__context} title={context}>
              {context}
            </p>
            <h2 className={style.title__text} title={title}>
              {title}
            </h2>
          </span>
        ) : (
          
        )}
      </div> */}
      {previousAction && (
        <button onClick={previousAction} className={style.titleBackButton}>
          <img
            className={style.titleBackIcon}
            src={previousIcon}
            alt="volver"
          />
        </button>
      )}
      <h2>{title}</h2>
      <div className={style.buttonsContainer}>
        {buttonTwoProps?.isHidden
          ? null
          : buttonTwoProps && <Button {...buttonTwoProps} />}
        {buttonProps.isHidden ? null : <Button {...buttonProps} />}
        {otherComponent}
      </div>
    </header>
  );
};

export default HeaderNavegation;
