import {
  employeesBaseUrl,
  getData,
  servicesBaseUrl,
} from "@Services/constServices";

export const getServicesList_service = async () => {
  const url = `${employeesBaseUrl}/Services/ListServices`;
  const successMessage = "Servicios obtenidos correctamente";
  const errorMessage = "Error al obtener servicios";
  return getData(url, successMessage, errorMessage);
};

export const getServices_service = async () => {
  const url = `${servicesBaseUrl}/GetServices`;
  const successMessage = "Servicios obtenidos correctamente";
  const errorMessage = "Error al obtener servicios";
  return getData(url, successMessage, errorMessage);
};
