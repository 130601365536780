import { postConceptMovements_service } from "@Services/saleboxes/movements";

export const postConceptMovementsAdapter = async (
  text,
  type,
  setSearchInput,
  setForm,
  setError,
  updateNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void,
  onConceptCreation: (concept: any) => void
) => {
  updateNotification({
    message: "Creando concepto...",
    status: "pending",
    toastTimeDuration: "INFINITE",
  });
  const adaptObjet = {
    movementType: type,
    description: text.trim(),
  };
  const response = await postConceptMovements_service(adaptObjet);
  if (response.status !== 200) {
    updateNotification({
      message:
        "Hubo un error al crear el concepto de movimiento. Intentalo de nuevo.",
      status: 400,
      toastTimeDuration: 2000,
    });
    return {
      status: response.status,
      message: response.message,
      data: [],
    };
  }

  setSearchInput(response.data.description);
  const adaptNewConcept = {
    value: response.data.idSaleBoxMovementConcept,
    name: response.data.description,
    movementType: response.data.movementType,
  };

  onConceptCreation(adaptNewConcept);
  setForm((prev) => {
    return {
      ...prev,
      concept: response.data.idSaleBoxMovementConcept,
    };
  });
  setError({
    ...setError,
    concept: "",
  });
  updateNotification({
    message: "Concepto de movimiento creado correctamente",
    status: 200,
    toastTimeDuration: 2000,
  });
  return {
    status: response.status,
    message: "Concepto de movimiento creado correctamente",
    data: response.data,
  };
};
