// @ts-nocheck
import React from "react";
import CssFilterConverter from "css-filter-converter";

const ColoredIcon = ({ src, color, size, alt = "icono" }) => {
  const result = CssFilterConverter.hexToFilter(color);
  size = size / 16;
  return (
    <img
      src={src}
      style={{
        filter: result.color,
        width: `${size}rem`,
      }}
      alt={alt}
    />
  );
};

const Icon = ({
  path,
  color,
  size,
  alt,
}: {
  path: string;
  color: string;
  size: number;
  alt?: string;
}) => {
  return <ColoredIcon src={path} color={color} size={size} />;
};

export default Icon;
