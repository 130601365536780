import React from "react";
//@ts-ignore
import style from "./style/AsideSectionMenu.module.css";

const LINK_DEFAULT = [
  {
    name: "Home",
    href: "/",
  },
];

const AsideSectionMenu = ({
  children,
  linkArray = LINK_DEFAULT,
  currentSelected,
  setCurrentSelected,
}) => {
  return (
    <main className={style.asideSectionMenu}>
      <aside>
        <div>
          {linkArray?.map((link, index) => {
            return (
              <a
                className={index === currentSelected && style.itemSelected}
                href={link.href}
                key={link.href}
              >
                {link.name}
              </a>
            );
          })}
        </div>
      </aside>
      <section>{children}</section>
    </main>
  );
};

export default AsideSectionMenu;
