import React, { useEffect } from "react";

const useResultOverflow = ({ searchElementRef, resultElementRef }) => {
  // This variables is used to render the result, and this no affect the overflow of the container father
  const [resultTopPosition, setResultTopPosition] = React.useState(0);
  const [resultLeftPosition, setResultLeftPosition] = React.useState(0);
  const [resultWidth, setResultWidth] = React.useState(0);

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    if (!searchElementRef.current) return;

    const searchElement = searchElementRef.current;

    const searchElementRect = searchElement.getBoundingClientRect();
    const searchElementTop = searchElementRect.top;
    const searchElementLeft = searchElementRect.left;
    setResultTopPosition(searchElementTop + searchElementRect.height);
    setResultLeftPosition(searchElementLeft);
    setResultWidth(searchElementRect.width);
  }, [searchElementRef.current, resultElementRef.current, screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    top: resultTopPosition,
    left: resultLeftPosition,
    width: resultWidth,
  };
};

export default useResultOverflow;
