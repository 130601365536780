import React from "react";

//@ts-ignore
import style from "./DateInput.module.css";
import { iconPath } from "../../../../../models/localPath";
import { IDateInput } from "./interface/DateInput";

const DateInput = ({
  label = "",
  touched = false,
  error = "",
  disabled = false,
  required = false,
  containerStyle = {},
  inputStyle = {},
  inputProps = {},
}: IDateInput) => {
  return (
    <div className={style.inputDateContainer} style={containerStyle}>
      {label && (
        <label className={style.inputLabel}>
          <p>{label}</p>
          {!disabled && required && <p className={style.inputRequired}>*</p>}
        </label>
      )}
      <input
        className={`${style.inputDate} ${
          touched && error ? style.inputTextError : ""
        } ${disabled ? style.inputTextDisabled : ""}`}
        style={inputStyle}
        {...inputProps}
        type="date"
        disabled={disabled}
      />
      {touched && error && (
        <span className={style.inputErrorContainer}>
          <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
          <p className={style.inputErrorMessage}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default DateInput;
