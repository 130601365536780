import React, { useEffect, useState } from "react";
import style from "./CreateMovementModal.module.css";
// @ts-ignore
import { SelectInput, TextInput } from "@viuti/recursos";
import { capitalizeWords } from "@Utilities/CapitalizeWords";
import { postConceptMovementsAdapter } from "@Adapters/saleboxes/postConceptMovement.adapter";
import { ModalAcceptCancel } from "@Molecules/index";
import { typeMovements } from "../../functions/constMovements";
import { postNewMovement } from "@Adapters/saleboxes/postNewMovement.adapter";
import { SearchBarReutilizable } from "../SearchBarReutilizable/SearchBarReutilizable";

interface ICreateMovementModal {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  boxId: string;
  boxes: any;
  saleBoxId: string;
  setFormFilter: any;
  setInitialForm: any;
  currentDate: () => string;
  firstDayOfMonth: () => string;
  reduxSelector: {
    movements: {
      movements: any[];
      pages: number;
      currentPagination: number;
      shouldReloadTable: boolean;
    };
    saleConfig: {
      loungeEmployees: any[];
    };
  };
  getMovements: (
    idBox: string,
    type: string,
    searParameter: string,
    concept: string,
    startDate: string,
    endDate: string,
    page: number
  ) => Promise<void>;
  onNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number;
  }) => void;
  onCurrentPaginationChange: (pagination: number) => void;
  onShouldReloadTableChange: (shouldReloadTable: boolean) => void;
  movementConcepts: {
    concepts: { name: string; value: number; movementType: number }[];
    entryConcepts: { name: string; value: string }[];
    outputConcepts: { name: string; value: string }[];
  };
  onConceptCreation: (concept: any) => void;
}

const CreateMovementModal = ({
  isVisible,
  setIsVisible,
  boxId,
  boxes,
  saleBoxId,
  setFormFilter,
  setInitialForm,
  currentDate,
  firstDayOfMonth,
  reduxSelector,
  getMovements,
  onNotification,
  onCurrentPaginationChange,
  onShouldReloadTableChange,
  movementConcepts,
  onConceptCreation,
}: ICreateMovementModal) => {
  const { concepts, entryConcepts, outputConcepts } = movementConcepts;

  const [searchInput, setSearchInput] = useState("");
  const [form, setForm] = useState({
    idBox: "",
    type: "",
    concept: "",
    employee: "",
    amount: "",
    observations: "",
  });

  const [error, setError] = useState({
    concept: "",
  });

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false);

  const setDefaultForm = () => {
    setForm({
      idBox: boxId,
      type: "",
      concept: "",
      employee: "",
      amount: "",
      observations: "",
    });
  };

  useEffect(() => {
    setForm({
      ...form,
      concept: "",
    });
    setSearchInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.type]);

  const { currentPagination } = reduxSelector.movements;

  const loungeEmployees = reduxSelector.saleConfig.loungeEmployees;

  const adaptEmployees = loungeEmployees.map((employee) => {
    return {
      name: capitalizeWords(employee.name + " " + employee.lastName),
      value: employee.id,
    };
  });

  useEffect(() => {
    setForm({
      ...form,
      idBox: boxId === "Todas" ? saleBoxId : boxId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxId]);

  let filterConcepts = (
    form.type === "Todos" || form.type === ""
      ? concepts
      : form.type === "1"
      ? entryConcepts
      : outputConcepts
  ).map((concept) => ({
    name: concept.name,
    value: concept.value,
  }));

  const adaptFilterConcepts = filterConcepts.map((concept) => {
    return {
      name: concept.name,
      value: concept.value,
    };
  });

  const handleCreateMovement = async () => {
    if (form.concept === "") {
      setError({
        ...error,
        concept: "Debes seleccionar un concepto.",
      });
      return;
    }
    setIsPrimaryButtonDisabled(true);
    const res = await postNewMovement(
      form.idBox,
      form.employee,
      form.concept,
      form.type,
      form.amount,
      form.observations,
      onNotification
    );
    setIsPrimaryButtonDisabled(false);
    if (res && res.isSuccess) {
      setIsVisible(false);
      setSearchInput("");
      setFormFilter({
        searParameters: "",
        idBox: form.idBox,
        type: "",
        concept: "",
        startDate: firstDayOfMonth(),
        endDate: currentDate(),
      });
      setInitialForm({
        searParameters: "",
        idBox: form.idBox,
        type: "",
        concept: "",
        startDate: firstDayOfMonth(),
        endDate: currentDate(),
      });
      await getMovements(
        form.idBox,
        "",
        "",
        "",
        firstDayOfMonth(),
        currentDate(),
        1
      );
      if (currentPagination !== 1) {
        onShouldReloadTableChange(true);
      }
      onCurrentPaginationChange(1);
      setDefaultForm();
    }
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setForm({
      idBox: "",
      type: "",
      concept: "",
      employee: "",
      amount: "",
      observations: "",
    });
  };

  return (
    <ModalAcceptCancel
      title={"Registrar movimiento"}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Registrar",
        actionButtonTwo: handleCreateMovement,
        isDisabled: isPrimaryButtonDisabled,
      }}
      actionButtonClose={handleCloseModal}
    >
      <div className={style.container}>
        <div className={`${style.inputContainer} ${style.inputWidth}`}>
          <SelectInput
            label="Caja"
            value={form.idBox}
            items={boxes}
            handleChange={(e) => {
              setForm({ ...form, idBox: e.target.value });
            }}
            required
          />
        </div>
        <div className={style.flexContainer}>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <SelectInput
              label="Tipo de movimiento"
              value={form.type}
              items={typeMovements}
              handleChange={(e) => {
                setForm({ ...form, type: e.target.value });
              }}
              required
            />
          </div>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <SearchBarReutilizable
              label={"Concepto"}
              placeholder={"Buscar/agregar concepto"}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              data={adaptFilterConcepts}
              form={form}
              setForm={setForm}
              concepts={concepts}
              onClickResult={(result) => {
                const concept = concepts.find(
                  (concept) => concept.name === result
                );
                setForm({ ...form, concept: String(concept.value) });
                setError({
                  ...error,
                  concept: "",
                });
              }}
              onCreateItem={async (newConcept) => {
                if (newConcept === "") {
                  setError({
                    ...error,
                    concept: "Falta completar este campo.",
                  });
                  return;
                }
                if (!/^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ\s]*$/.test(newConcept)) {
                  setError({
                    ...error,
                    concept: "No se permiten caracteres especiales.",
                  });
                  return;
                }
                const validarSiElConceptoExiste = concepts.find(
                  (concept) =>
                    concept.name.toLowerCase().trim() ===
                      newConcept.toLowerCase().trim() &&
                    concept.movementType === Number(form.type)
                );
                if (validarSiElConceptoExiste) {
                  const concept = concepts.find(
                    (concept) =>
                      concept.name.toLowerCase().trim() ===
                        newConcept.toLowerCase().trim() &&
                      concept.movementType === Number(form.type)
                  );
                  setForm({ ...form, concept: String(concept.value) });
                  setError({
                    ...error,
                    concept: "",
                  });
                  return;
                } else {
                  await postConceptMovementsAdapter(
                    newConcept,
                    form.type,
                    setSearchInput,
                    setForm,
                    setError,
                    onNotification,
                    onConceptCreation
                  );
                }
              }}
              messageCreateItem="Pulsa intro para crear este nuevo concepto."
              disabled={form.type === ""}
              required={true}
              errorMessage={error.concept}
              setError={setError}
            />
          </div>
        </div>

        <div className={style.flexContainer}>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <SelectInput
              label="Empleado asociado"
              value={form.employee}
              items={adaptEmployees}
              handleChange={(e) => {
                setForm({ ...form, employee: e.target.value });
              }}
            />
          </div>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <TextInput
              label="Monto"
              value={form.amount}
              prefix={form.amount ? "S/" : null}
              handleChange={(e) => {
                let inputAmount = e.target.value;
                inputAmount = inputAmount.slice(0, 20);
                const formattedAmount = inputAmount.replace(/[^0-9.]/g, "");
                const isNumeric = /^[0-9.]*$/.test(formattedAmount);

                if (isNumeric) {
                  setForm({ ...form, amount: formattedAmount });
                }
              }}
              inputProps={{
                onBlur: (e: any) => {
                  let num = Number(form.amount);
                  let formattedAmount;
                  if (!isNaN(num)) {
                    formattedAmount = num
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                  } else {
                    formattedAmount = "0.00";
                  }

                  setForm({ ...form, amount: formattedAmount });
                },
              }}
              placeholder="Ingrese el monto"
              required
            />
          </div>
        </div>
        <div>
          <textarea
            className={style.textarea}
            placeholder="Observaciones"
            rows={4}
            cols={50}
            value={form.observations}
            onChange={(e) => {
              setForm({ ...form, observations: e.target.value });
            }}
          />
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default CreateMovementModal;
