import React, { CSSProperties } from "react";
//@ts-ignore
import style from "./buttonRoundedIcon.module.css";
import { QuestionMarkIcon } from "@Models/icons";
import Icon from "@Atoms/Icon/Icon";

const lightenColor = (color: string, amount: number) => {
  let usePound = false;
  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }
  const num = parseInt(color, 16);
  let r = (num >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

const ButtonRoundedIcon = ({
  icon = QuestionMarkIcon,
  iconColor = "#000",
  bgColor = iconColor,
  isPrimary = true,
  handleClick = () => {},
  width,
}) => {
  let bgHoverColor = lightenColor(bgColor, 5);

  if (bgColor === iconColor) {
    bgColor = lightenColor(bgColor, 100);
    bgHoverColor = lightenColor(bgColor, 105);
  }

  return (
    <button
      onClick={() => handleClick()}
      className={
        isPrimary ? style.bttnRoundedIconPrimary : style.bttnRoundedIcon
      }
      style={
        {
          backgroundColor: bgColor,
          "--bg-hover-color": bgHoverColor,
        } as CSSProperties
      }
    >
      <Icon path={icon} color={iconColor} size={width || 18} />
    </button>
  );
};

const ButtonRoundedIcon2 = ({
  icon = QuestionMarkIcon,
  iconColor = "#000",
  bgColor = "#fff",
  isPrimary = true,
  handleClick = () => {},
}) => {
  return (
    <button
      onClick={() => handleClick()}
      className={
        isPrimary ? style.bttnRoundedIconPrimary : style.bttnRoundedIcon
      }
    >
      <Icon path={icon} color={iconColor} size={18} />
    </button>
  );
};

export default ButtonRoundedIcon;
