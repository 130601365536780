import { STATUS_OK } from "@Models/httpsStatus";
import { getSalePDF_service } from "@Services/saleboxes/documents";

export const getSalePDF = async (saleDocumentId, onNotification) => {
  onNotification({
    message: "Descargando PDF...",
    status: "pending",
    toastTimeDuration: "INFINITE",
  });
  const response = await getSalePDF_service(saleDocumentId);
  if (!response.isSuccess) {
    onNotification({
      message: response.message,
      status: 400,
      toastTimeDuration: 4000,
    });
    return response;
  }
  onNotification({
    message: "PDF descargado correctamente",
    status: 200,
    toastTimeDuration: 4000,
  });
  return {
    status: STATUS_OK,
    message: "Descarga de PDF correcta",
    data: response.data,
  };
};
