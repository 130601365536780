import React, { useState } from "react";
//@ts-ignore
import style from "./FileUploader.module.css";
import DragAndDropFile from "../../atoms/dragAndDropFile/DragAndDropFile";
import UploadedFileStatus from "@Atoms/dragAndDropFile/components/UploadedFileStatus/UploadedFileStatus";

const FileUploader = ({
  file,
  setFile = (e) => {},
  accept = [".pfx"],
  label = null,
}) => {
  const [isUploadedFileOk, setIsUploadedFileOk] = useState(null);
  const [uploadedFileMessage, setUploadedFileMessage] = useState(null);

  const handleStatusClose = () => {
    if (isUploadedFileOk) {
      setFile(null);
      setIsUploadedFileOk(null);
    } else {
      setIsUploadedFileOk(null);
    }
  };

  return (
    <section className={style.file_uploader__container}>
      {label && <h2 className={style.newRucsPage__SectionRow__h2}>{label}</h2>}
      <DragAndDropFile
        accept={accept}
        file={file}
        setFile={setFile}
        setIsUploadedFileOk={setIsUploadedFileOk}
        setUploadedFileMessage={setUploadedFileMessage}
      />
      {isUploadedFileOk !== null && (
        <UploadedFileStatus
          type={isUploadedFileOk ? "success" : "error"}
          message={uploadedFileMessage}
          setClose={handleStatusClose}
        />
      )}
    </section>
  );
};

export default FileUploader;
