// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --MAX_HEIGHT_GLOBAL_STYLES: 850px;
}

:root {
  --BORDER-RADIUS: 0.625rem;
  --BORDER-WEIGHT: 0.12rem;
  --BORDER-WEIGHT-FOCUS: 0.18rem;
  --BORDER-WEIGHT-ACTIVE: 0.18rem;
  --BORDER-WEIGHT-HOVER: 0.18rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables/variables.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,8BAA8B;EAC9B,+BAA+B;EAC/B,8BAA8B;AAChC","sourcesContent":[":root {\n  --MAX_HEIGHT_GLOBAL_STYLES: 850px;\n}\n\n:root {\n  --BORDER-RADIUS: 0.625rem;\n  --BORDER-WEIGHT: 0.12rem;\n  --BORDER-WEIGHT-FOCUS: 0.18rem;\n  --BORDER-WEIGHT-ACTIVE: 0.18rem;\n  --BORDER-WEIGHT-HOVER: 0.18rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
