import React from "react";

// Style
// @ts-ignore
import style from "./loadingScreen.module.css";

// Icons
import { LoadingPulseIcon } from "@Models/icons";

const LoadingScreen = ({ visible, loaderWidth }) => {
  return (
    <div
      className={`${style.loadingContainer} ${
        visible ? style.visible : style.hidden
      }`}
    >
      <img src={LoadingPulseIcon} width={loaderWidth} alt="Cargando..." />
    </div>
  );
};

export default LoadingScreen;
