// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zPB_tgbfr6hMf7IBFiz3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.zDZtA76hFOvTBevdxS1b {
  display: flex;
  gap: 20px;
}

.HmspfhXRwxY_b0pdSXk4 {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  transition: border-color 0.3s ease;
}

.__WCKNLJZvyt1GfJ7eLj {
  width: 100%;
}

.KSHNBmxavMIWLmaiR4_J {
  width: 100%;
}

@media screen and (width < 500px) {
  .zPB_tgbfr6hMf7IBFiz3 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .zDZtA76hFOvTBevdxS1b {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .HmspfhXRwxY_b0pdSXk4 {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    transition: border-color 0.3s ease;
  }
  .__WCKNLJZvyt1GfJ7eLj {
    width: 100%;
  }
  .KSHNBmxavMIWLmaiR4_J {
    width: 100%;
  }
}

.ULsAiSDtE8XFrscgI_4C {
  display: flex;
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
}
.T_WhtzDHm6OMweay2k4N {
  margin-bottom: 0.625rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/SaleboxReports/SaleMovementsReportView/components/CreateMovementModal/CreateMovementModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;EACb;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;EACpC;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,cAAc;EACd,iCAAiC;EACjC,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n}\n\n.flexContainer {\n  display: flex;\n  gap: 20px;\n}\n\n.textarea {\n  width: 100%;\n  height: 100px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  resize: none;\n  transition: border-color 0.3s ease;\n}\n\n.inputContainer {\n  width: 100%;\n}\n\n.inputWidth {\n  width: 100%;\n}\n\n@media screen and (width < 500px) {\n  .container {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    width: 100%;\n  }\n  .flexContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n  .textarea {\n    width: 100%;\n    height: 100px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    resize: none;\n    transition: border-color 0.3s ease;\n  }\n  .inputContainer {\n    width: 100%;\n  }\n  .inputWidth {\n    width: 100%;\n  }\n}\n\n.inputLabel {\n  display: flex;\n  color: #4d4d4d;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 0.875rem;\n  font-style: normal;\n  font-weight: 500;\n}\n.brandLabel {\n  margin-bottom: 0.625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `zPB_tgbfr6hMf7IBFiz3`,
	"flexContainer": `zDZtA76hFOvTBevdxS1b`,
	"textarea": `HmspfhXRwxY_b0pdSXk4`,
	"inputContainer": `__WCKNLJZvyt1GfJ7eLj`,
	"inputWidth": `KSHNBmxavMIWLmaiR4_J`,
	"inputLabel": `ULsAiSDtE8XFrscgI_4C`,
	"brandLabel": `T_WhtzDHm6OMweay2k4N`
};
export default ___CSS_LOADER_EXPORT___;
