import React, { useState } from "react";
import style from "./CancelTicketModal.module.css";
import { STATUS_OK } from "@Models/httpsStatus";
import ModalAcceptCancel from "@Molecules/ModalAcceptCancel/ModalAcceptCancel";
import { deleteCancelTicket } from "@Adapters/saleboxes/deleteCancelTicket.adapter";

const CancelTicketModal = ({
  isVisible,
  setIsVisible,
  document,
  onNotification,
  reloadTable,
}) => {
  const [reason, setReason] = React.useState("");
  const handleReasonChange = (event) => {
    setReason(event.target.value);
    setIsPrimaryButtonDisabled(!event.target.value.trim());
  };

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);
  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false);

  const setDefaultForm = () => {
    setReason("");
  };

  const handleCreateMovement = async () => {
    setIsPrimaryButtonDisabled(true);
    setIsPrimaryButtonLoading(true);
    const res = await deleteCancelTicket(document.id, reason, onNotification);
    setIsPrimaryButtonDisabled(false);
    setIsPrimaryButtonLoading(false);

    if (res.status === STATUS_OK) {
      reloadTable();
      setIsVisible(false);
      setDefaultForm();
    }
  };

  const handleCloseModal = () => {
    setDefaultForm();
    setIsVisible(false);
  };

  return (
    <ModalAcceptCancel
      title={"Anular ticket"}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Anular",
        actionButtonTwo: handleCreateMovement,
        isDisabled: isPrimaryButtonDisabled,
        isLoading: isPrimaryButtonLoading,
      }}
      actionButtonClose={handleCloseModal}
    >
      <div className={style.cancel_ticket_container}>
        <section className={style.cancel_ticket_info_container}>
          <div className={style.cancel_ticket_info}>
            <h3>{document.type}</h3>
            <span className={style.cancel_ticket_concept}>
              {document.number}
            </span>
          </div>
          <div className={style.amount_info}>
            <p>{document.total}</p>
            <p className={style.cancel_ticket_date}>{document.justTheDate}</p>
          </div>
        </section>
        <div className={style.reason}>
          <textarea
            className={style.reason_input}
            placeholder="Motivo de anulación"
            value={reason}
            onChange={handleReasonChange}
          />
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default CancelTicketModal;
