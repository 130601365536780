// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gD6TCFyaOBYy6gNGwiv4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mnhvnwokZ9YO2uG6OuuK {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #dbd5e4;
  transition: all 150ms ease-in-out;

  &:hover {
    border: 1px solid #a97777;
  }
}

.e_788Da7kHwK9LAUujMv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fafafa;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dbd5e4;
  transition: all 150ms ease-in-out;

  &:hover {
    border: 1px solid #a97777;
  }

  & > .NpS4bc1FQu9EFM3Mb3YV {
    gap: 6px;
  }
}

.NpS4bc1FQu9EFM3Mb3YV {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.s0CpsGMHOuXIc7NWtQXa {
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/UnauthorizedMessage/UnauthorizedMessage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,iCAAiC;;EAEjC;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,iCAAiC;;EAEjC;IACE,yBAAyB;EAC3B;;EAEA;IACE,QAAQ;EACV;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.content {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  background-color: #fafafa;\n  padding: 20px;\n  border-radius: 12px;\n  border: 1px solid #dbd5e4;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    border: 1px solid #a97777;\n  }\n}\n\n.small_container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  background-color: #fafafa;\n  padding: 12px;\n  border-radius: 8px;\n  border: 1px solid #dbd5e4;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    border: 1px solid #a97777;\n  }\n\n  & > .info {\n    gap: 6px;\n  }\n}\n\n.info {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n}\n\n.message {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gD6TCFyaOBYy6gNGwiv4`,
	"content": `mnhvnwokZ9YO2uG6OuuK`,
	"small_container": `e_788Da7kHwK9LAUujMv`,
	"info": `NpS4bc1FQu9EFM3Mb3YV`,
	"message": `s0CpsGMHOuXIc7NWtQXa`
};
export default ___CSS_LOADER_EXPORT___;
