import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./style/slideNotification.module.css";
import { ISlideNotification } from "./interface/slideNotification";

const SlideNotification = (props: ISlideNotification) => {
  const { state, clearStatus } = props;
  let { message, status, toastTimeDuration } = state;
  // In case the toastTimeDuration is not defined, it will be set to 150 by default
  const DURATION = 4000;
  if (!toastTimeDuration) toastTimeDuration = DURATION;

  if (state.status !== 200 && state.message === "")
    message = "Ups! Algo salió mal. Inténtalo de nuevo más tarde.";

  const [active, setActive] = useState(false);

  const statusColorDictionary = {
    0: "#fff",
    200: "#45348e",
    400: "#C36363",
    401: "#C36363",
    404: "#C36363",
    405: "#C36363",
    500: "#C36363",
    501: "#C36363",
    502: "#C36363",
    pending: "#ECE7FD",
  };

  const infiniteTime = () => {
    if (state.status !== 0) return setActive(true);
    setActive(false);
  };

  useEffect(() => {
    // If time duration is infinite
    if (toastTimeDuration === "INFINITE") return infiniteTime();

    // If time duration is not infinite, the notification will be hidden after the time duration
    if (status !== 0) {
      setActive(true);
      setTimeout(() => {
        setActive(false);
        setTimeout(() => {
          clearStatus();
        }, 150);
      }, DURATION);
    }
  }, [status]);

  return (
    <div
      className={`${style.notificationContainer} ${active ? style.active : ""}`}
      style={{
        backgroundColor: statusColorDictionary[status],
        color: status === "pending" ? "#000" : "#fff",
      }}
    >
      {status === "pending" ? <div className={style.loader}></div> : null}
      {message}
    </div>
  );
};

export default SlideNotification;
