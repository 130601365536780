import { STATUS_OK } from "@Models/httpsStatus";
import { ApiResponse } from "@Services/constServices";
import { cancelOperation_service } from "@Services/saleboxes/documents";

export const deleteCancelTicket = async (
  id,
  observation,
  updateNotification
) => {
  const response = await cancelOperation_service(id, observation);
  if (!response.isSuccess) {
    updateNotification({
      message: response.message,
      status: response.status as any,
      toastTimeDuration: 7000,
    });
    return response;
  }

  updateNotification({
    message: "Ticket anulado correctamente",
    status: response.status,
    toastTimeDuration: 4000,
  });
  return response;
};
