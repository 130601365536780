import React from "react";
import { formatToSoles } from "@Utilities/FormatsHandler";
import style from "./Summary.module.css";

// Función para renderizar el resumen total
export const Summary = ({ selectedItems, services }) => {
  // Calcular el total en soles
  const totalAmount = formatToSoles(
    selectedItems.reduce((total, item) => {
      const selectedService = services.find(
        (service) => service.idPrice === item.service
      );
      return total + (selectedService ? selectedService.price : 0);
    }, 0)
  );

  // Calcular la duración total en horas y minutos
  const totalMinutes = selectedItems.reduce((total, item) => {
    const selectedService = services.find(
      (service) => service.idPrice === item.service
    );
    return total + (selectedService ? selectedService.duration : 0);
  }, 0);
  const hours = Math.floor(totalMinutes / 60); // Calcular horas
  const minutes = totalMinutes % 60; // Calcular minutos restantes

  return (
    <div className={style.summary}>
      <h3>Resumen Total</h3>
      <p>{totalAmount} </p>
      <p>
        {`${hours} hora${hours !== 1 ? "s" : ""} y ${minutes} minuto${
          minutes !== 1 ? "s" : ""
        }`}
      </p>
    </div>
  );
};
