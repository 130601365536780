export const RUC = "RUC";
export const DNI = "DNI";
export const PASAPORTE = "PASAPORTE";
export const CARNET_DE_EXTRANJERIA = "CARNET EXT.";

export const MASCULINO = "MASCULINO";
export const FEMENINO = "FEMENINO";
export const OTRO = "OTRO";

export const typeDocuments = [
  { value: DNI, name: DNI },
  { value: RUC, name: RUC },
  { value: PASAPORTE, name: PASAPORTE },
  { value: CARNET_DE_EXTRANJERIA, name: CARNET_DE_EXTRANJERIA },
];

export const clientsCategories = [
  { value: "1", name: "Regular" },
  { value: "2", name: "Premium" },
  { value: "3", name: "VIP" },
];
