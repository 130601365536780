// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Scrollbar */
*::-webkit-scrollbar {
  width: 16px;
}
*::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: none;
}

*::-webkit-scrollbar-track:hover {
  background-color: none;
}

*::-webkit-scrollbar-track:active {
  background-color: none;
}

*::-webkit-scrollbar-thumb {
  border: 5px solid white;
  border-radius: 99px;
  background-color: rgba(172, 172, 172, 0.473);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(172, 172, 172, 0.473);
}

*::-webkit-scrollbar-thumb:active {
  background-color: rgba(172, 172, 172, 0.473);
}

@media screen and (width <= 900px) {
  *::-webkit-scrollbar {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/scrollbar.styles.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,4CAA4C;AAC9C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* Scrollbar */\n*::-webkit-scrollbar {\n  width: 16px;\n}\n*::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: none;\n}\n\n*::-webkit-scrollbar-track:hover {\n  background-color: none;\n}\n\n*::-webkit-scrollbar-track:active {\n  background-color: none;\n}\n\n*::-webkit-scrollbar-thumb {\n  border: 5px solid white;\n  border-radius: 99px;\n  background-color: rgba(172, 172, 172, 0.473);\n}\n\n*::-webkit-scrollbar-thumb:hover {\n  background-color: rgba(172, 172, 172, 0.473);\n}\n\n*::-webkit-scrollbar-thumb:active {\n  background-color: rgba(172, 172, 172, 0.473);\n}\n\n@media screen and (width <= 900px) {\n  *::-webkit-scrollbar {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
