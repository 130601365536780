import React, { useEffect, useState } from "react";
// @ts-ignore
import { SchedulesWrapper, ModalAcceptCancel } from "@viuti/recursos";

const ModalSelectSchedules = ({
  isVisible,
  employeeSchedules,
  setEmployeeSchedules,
  closeModal,
  handleSubmit,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteSchedule = (idSchedule: number) => {
    const removeScheduleById = (arr, idScheduleToRemove: number) => {
      return arr.map((day) => {
        return {
          ...day,
          scheduleList: day.scheduleList.filter((schedule) => {
            return schedule.idSchedule !== idScheduleToRemove;
          }),
        };
      });
    };

    const newArray = removeScheduleById(employeeSchedules, idSchedule);
    setEmployeeSchedules(newArray);
  };

  useEffect(() => {
    const isAnyScheduleActive = employeeSchedules
      .map((e) => {
        return e.scheduleList.map((s) => {
          return s.isAvailable;
        });
      })
      .flat()
      .includes(true);

    if (isAnyScheduleActive) setIsDisabled(false);
    else setIsDisabled(true);
  }, [employeeSchedules]);

  return (
    <ModalAcceptCancel
      title={"Horarios"}
      visibility={{
        isVisible: isVisible,
      }}
      showButtons={{
        showButtonOne: true,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: () => closeModal(),
        isDisabled: false,
        isLoading: false,
      }}
      buttonTwo={{
        textButtonTwo: "Guardar",
        actionButtonTwo: handleSubmit,
        isDisabled: isDisabled || isLoading,
      }}
      actionButtonClose={() => closeModal()}
    >
      <SchedulesWrapper
        schedules={employeeSchedules}
        setSchedules={setEmployeeSchedules}
        scheduleActions={{ onDelete: onDeleteSchedule }}
      />
    </ModalAcceptCancel>
  );
};

export default ModalSelectSchedules;
