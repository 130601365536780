// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FKgueRK2MFE9rhtqksET {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  background: #fff;
  transition: background 0.3s, border-color 0.3s;
  gap: 8px;
}

.FKgueRK2MFE9rhtqksET:hover {
  background: #efefef;
}

.FKgueRK2MFE9rhtqksET:active {
  border: 1px solid #000000;
  background: #d4d4d4;
}

.FKgueRK2MFE9rhtqksET p {
  color: #4d4d4d;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/buttons/ButtonWithPlus/ButtonWithPlus.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,8CAA8C;EAC9C,QAAQ;AACV;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".buttonStyle {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  border-radius: 5px;\n  cursor: pointer;\n  border-radius: 12px;\n  border: 1px solid #c4c4c4;\n  background: #fff;\n  transition: background 0.3s, border-color 0.3s;\n  gap: 8px;\n}\n\n.buttonStyle:hover {\n  background: #efefef;\n}\n\n.buttonStyle:active {\n  border: 1px solid #000000;\n  background: #d4d4d4;\n}\n\n.buttonStyle p {\n  color: #4d4d4d;\n  font-family: Mulish;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonStyle": `FKgueRK2MFE9rhtqksET`
};
export default ___CSS_LOADER_EXPORT___;
