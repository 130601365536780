import React from "react";
// @ts-ignore
import style from "./UploadedFileStatus.module.css";
import { HiExclamationCircle, HiXMark } from "react-icons/hi2";

interface IUploadedFileStatusProps {
  type: "success" | "error";
  message: React.ReactNode;
  setClose: (value: boolean) => void;
}

const UploadedFileStatus = ({
  type,
  message,
  setClose,
}: IUploadedFileStatusProps) => {
  const isTypeSuccess = type === "success";

  return (
    <>
      {isTypeSuccess ? (
        <SuccessChip message={message} setClose={setClose} />
      ) : (
        <ErrorBanner message={message} setClose={setClose} />
      )}
    </>
  );
};

const ErrorBanner = ({ message, setClose }) => {
  return (
    <article className={style.section__error__container}>
      <span className={style.section__error__container__message}>
        <HiExclamationCircle size={16} color="#ff2323" />
        <p className={style.section__message__error}>{message}</p>
      </span>
      <button
        className={style.section__message__error__container__button}
        onClick={setClose}
      >
        <HiXMark size={14} color="#000000" />
      </button>
    </article>
  );
};

const SuccessChip = ({ message, setClose }) => {
  return (
    <section className={style.section__chip__container}>
      <article className={style.section__success__container}>
        <p className={style.section__message__success}>{message}</p>

        <button
          className={style.section__message__success__container__button}
          onClick={setClose}
        >
          <HiXMark size={14} color="#000000" />
        </button>
      </article>
    </section>
  );
};

export default UploadedFileStatus;
