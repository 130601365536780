import { JwtPayload, jwtDecode } from "jwt-decode";

const getUserPermissions = () => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    return null;
  }
  const decoded: {
    access: any;
    planHierarchy: string;
    MaxEmployee: string;
    MaxWareHouse: string;
    StartView: string;
    freeTrial: string;
    isAdmin: string;
    JwtPayload: JwtPayload;
  } = jwtDecode(token);
  const account_info: any = JSON.parse(localStorage.getItem("account_info"));
  try {
    const permissions = {
      accessKeyModules: JSON.parse(decoded?.access).map((item: any) => {
        return {
          id: item.UUIDModule,
          hierarchy: item.Hierarchy,
        };
      }),
      plan: {
        id: account_info.plan.id,
        name: account_info.plan.name,
        hierarchy: Number(decoded.planHierarchy) || 0,
        isTrial: JSON.parse(decoded.freeTrial?.toLowerCase()) || false,
      },
      account: {
        isAdmin: JSON.parse(decoded.isAdmin?.toLowerCase()),
      },
      employeesLimitCount: Number(decoded?.MaxEmployee),
      warehousesLimitCount: Number(decoded?.MaxWareHouse),
      startView: decoded?.StartView,
    };
    return permissions;
  } catch (error) {
    // console.log("Error getting user permissions");
    window.location.href = "/";
    return null;
  }
};

export default getUserPermissions;
