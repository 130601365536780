import React, { useState, useEffect } from "react";
import style from "./CreditNoteModal.module.css";
import { STATUS_OK } from "@Models/httpsStatus";
import ModalAcceptCancel from "@Molecules/ModalAcceptCancel/ModalAcceptCancel";
import SelectInput from "@Atoms/inputs/selectInput/SelectInput";
import TextAreaInput from "@Atoms/inputs/textAreaInput/TextAreaInput";
import TextInput from "@Atoms/inputs/textInput/TextInput";
import { createCreditNote } from "@Adapters/saleboxes/createCreditNote.adapter";
import { ANNUALMENT_ID, PARTIAL_ANNUALMENT_ID } from "./consts/reasons";
import { PARTIAL_AMOUNT_TYPE, TOTAL_AMOUNT_TYPE } from "./consts/amountType";

interface CreditNoteModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  document: any;
  onNotification: (notification: any) => void;
  reloadTable: () => void;
}

const CreditNoteModal = ({
  isVisible,
  setIsVisible,
  document,
  onNotification,
  reloadTable,
}: CreditNoteModalProps) => {
  const [form, setForm] = useState({
    amountType: TOTAL_AMOUNT_TYPE,
    reason: ANNUALMENT_ID,
    comments: "",
    amount: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
    comments: "",
  });

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false);
  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false);

  const reasonOptions = [
    {
      value: ANNUALMENT_ID,
      name: "Anulación de la operación",
    },
    {
      value: PARTIAL_ANNUALMENT_ID,
      name: "Descuento global",
    },
  ];

  const setDefaultForm = () => {
    setForm({
      amountType: TOTAL_AMOUNT_TYPE,
      reason: ANNUALMENT_ID,
      comments: "",
      amount: "",
    });
  };

  const handleAmountTypeChange = (value: number) => {
    setForm({
      ...form,
      amountType: value,
      reason:
        value === TOTAL_AMOUNT_TYPE ? ANNUALMENT_ID : PARTIAL_ANNUALMENT_ID,
      ...(value === TOTAL_AMOUNT_TYPE && {
        amount: "",
        comments: "",
      }),
    });
  };

  const handleAmountFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // Limpiamos el valor de cualquier formato previo
    let value = e.target.value.replace(/[^\d.,]/g, "");
    value = value.replace(",", ".");
    let numericValue = parseFloat(value);
    const numericTotal = parseFloat(document.total.replace("S/", ""));

    // Si no es un número válido o excede el máximo, usamos el total del documento
    if (isNaN(numericValue) || numericValue > numericTotal) {
      numericValue = numericTotal;
    }

    // Formateamos el número con 2 decimales fijos
    const fixedValue = numericValue.toFixed(2);

    // Formateamos para mostrar con el formato peruano
    const parts = fixedValue.split(".");
    const thousands = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedValue = `${thousands}.${parts[1]}`;

    setForm({
      ...form,
      amount: formattedValue,
    });
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setForm({
      amountType: TOTAL_AMOUNT_TYPE,
      reason: ANNUALMENT_ID,
      comments: "",
      amount: "",
    });
    setErrors({
      amount: "",
      comments: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      amount: "",
      comments: "",
    };

    // Solo validamos si es monto parcial
    if (form.amountType === PARTIAL_AMOUNT_TYPE) {
      if (!form.amount) {
        newErrors.amount = "El monto es requerido";
        isValid = false;
      } else {
        const numericAmount = parseFloat(form.amount.replace(/,/g, ""));
        const numericTotal = parseFloat(document.total.replace(/,/g, ""));

        if (numericAmount > numericTotal) {
          newErrors.amount =
            "El monto no puede ser mayor al total del documento";
          isValid = false;
        } else if (numericAmount <= 0) {
          newErrors.amount = "El monto debe ser mayor a 0";
          isValid = false;
        }
      }

      if (!form.comments.trim()) {
        newErrors.comments =
          "Los comentarios son requeridos para montos parciales";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleGenerate = async () => {
    if (!validateForm()) return;

    setIsPrimaryButtonDisabled(true);
    setIsPrimaryButtonLoading(true);
    const res = await createCreditNote(document.id, form, onNotification);
    setIsPrimaryButtonDisabled(false);
    setIsPrimaryButtonLoading(false);

    if (res.status === STATUS_OK) {
      reloadTable();
      setIsVisible(false);
      setDefaultForm();
    }
  };

  return (
    <ModalAcceptCancel
      title={`Generar nota de crédito`}
      visibility={{
        isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Generar",
        actionButtonTwo: handleGenerate,
        isDisabled:
          !form.amountType ||
          (form.amountType === PARTIAL_AMOUNT_TYPE &&
            (!form.amount || !form.comments)) ||
          isPrimaryButtonDisabled,
        isLoading: isPrimaryButtonLoading,
      }}
      actionButtonClose={handleCloseModal}
    >
      <div className={style.container}>
        <div className={style.radioGroup}>
          <p className={style.label}>Anular monto:</p>
          <div className={style.radioOptions}>
            <label>
              <input
                type="radio"
                name="amountType"
                value={TOTAL_AMOUNT_TYPE}
                checked={form.amountType === TOTAL_AMOUNT_TYPE}
                onChange={() => handleAmountTypeChange(TOTAL_AMOUNT_TYPE)}
              />
              Total
            </label>
            <label>
              <input
                type="radio"
                name="amountType"
                value={PARTIAL_AMOUNT_TYPE}
                checked={form.amountType === PARTIAL_AMOUNT_TYPE}
                onChange={() => handleAmountTypeChange(PARTIAL_AMOUNT_TYPE)}
              />
              Parcial
            </label>
          </div>
        </div>

        <div className={style.selectContainer}>
          <SelectInput
            label="Motivo"
            value={form.reason.toString()}
            items={reasonOptions}
            handleChange={() => {}}
            disabled={true}
          />
        </div>

        {form.amountType === PARTIAL_AMOUNT_TYPE && (
          <>
            <div className={style.inputContainer}>
              <TextInput
                label="Monto"
                inputProps={{
                  type: "text",
                  value: form.amount,
                  onChange: (e) => {
                    const value = e.target.value.replace(/[^\d.,]/g, "");
                    setForm({
                      ...form,
                      amount: value,
                    });
                  },
                  onBlur: handleAmountFocus,
                  placeholder: `Monto parcial a anular`,
                }}
                prefix="S/"
                error={errors.amount}
                touched={true}
                required
              />
              <span className={style.amountInfo}>
                Total disponible:{" "}
                <span className={style.amountInfoTotal}>{document.total}</span>
              </span>
            </div>
            <div className={style.textareaContainer}>
              <TextAreaInput
                label="Descripción"
                textareaProps={{
                  value: form.comments,
                  onChange: (e) =>
                    setForm({
                      ...form,
                      comments: e.target.value,
                    }),
                  placeholder:
                    "Especifique la justificación del descuento parcial",
                  resize: "none",
                }}
                error={errors.comments}
                touched={true}
                disabled={false}
                required={true}
              />
            </div>
          </>
        )}
      </div>
    </ModalAcceptCancel>
  );
};

export default CreditNoteModal;
