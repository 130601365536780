// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nuLlTIEwcYEitEI2vAYw {
  position: relative;
}

.ykCwVDTqasC2L3zJuj04 {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 10px;
}

.krEQ4mPWzwdYTyNFITVT {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  transition: all 150ms ease-in-out;
}

.nuLlTIEwcYEitEI2vAYw section {
  position: absolute;
  width: calc(100%);
  max-width: 730px;
  height: 100%;
  z-index: 3;
}

.E9Zg9ySitF52GlzRUBFT {
  border: 1px solid #dadada;
  background-color: #fff;
  max-height: 200px;
  overflow: auto;
}

.E9Zg9ySitF52GlzRUBFT li {
  display: flex;
  border-radius: 5px;
}

.E9Zg9ySitF52GlzRUBFT li:hover {
  background-color: #f5f3fe;
  cursor: pointer;
}

@keyframes EPh9HbeIe4FHmZDrgVIE {
  from {
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    max-height: 500px; /* Ajusta este valor según el contenido */
    opacity: 1;
    transform: translateY(0);
  }
}

.uWiHVX4HXrClAibgiTwX {
  animation: EPh9HbeIe4FHmZDrgVIE 0.2s ease-in forwards; /* Animación al mostrar */
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/DataList/DataList.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;IACb,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,iBAAiB,EAAE,yCAAyC;IAC5D,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE,qDAA0C,EAAE,yBAAyB;AACvE","sourcesContent":[".dataListContainer {\n  position: relative;\n}\n\n.searchContainer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  position: relative;\n  gap: 10px;\n}\n\n.search__spinner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n  transition: all 150ms ease-in-out;\n}\n\n.dataListContainer section {\n  position: absolute;\n  width: calc(100%);\n  max-width: 730px;\n  height: 100%;\n  z-index: 3;\n}\n\n.resultsList {\n  border: 1px solid #dadada;\n  background-color: #fff;\n  max-height: 200px;\n  overflow: auto;\n}\n\n.resultsList li {\n  display: flex;\n  border-radius: 5px;\n}\n\n.resultsList li:hover {\n  background-color: #f5f3fe;\n  cursor: pointer;\n}\n\n@keyframes slideDown {\n  from {\n    max-height: 0;\n    opacity: 0;\n    transform: translateY(-10px);\n  }\n  to {\n    max-height: 500px; /* Ajusta este valor según el contenido */\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n\n.containerResults {\n  animation: slideDown 0.2s ease-in forwards; /* Animación al mostrar */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataListContainer": `nuLlTIEwcYEitEI2vAYw`,
	"searchContainer": `ykCwVDTqasC2L3zJuj04`,
	"search__spinner": `krEQ4mPWzwdYTyNFITVT`,
	"resultsList": `E9Zg9ySitF52GlzRUBFT`,
	"containerResults": `uWiHVX4HXrClAibgiTwX`,
	"slideDown": `EPh9HbeIe4FHmZDrgVIE`
};
export default ___CSS_LOADER_EXPORT___;
