import { STATUS_OK } from "@Models/httpsStatus";
import { getSalesDocumentDetail_service } from "@Services/saleDetails/getSaleDetails";

export const getSalesDocumentDetail = async (
  saleDocumentId: string,
  saleBoxId: string,
  updateNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void
) => {
  const response = await getSalesDocumentDetail_service(
    saleDocumentId,
    saleBoxId
  );
  if (response.status !== STATUS_OK) {
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    });

    return response.data;
  }
  return adaptData(response.data);
};

const adaptData = (data) => ({
  // Detalles de los productos/servicios vendidos
  saleDocumentDetails: data.saleDocumentDetails.map((detail) => ({
    saleDocumentDetailId: detail.saleDocumentDetailId,
    priceServiceId: detail.priceServiceId,
    localProductId: detail.localProductId,
    quantity: detail.quantity,
    price: detail.price,
    discount: detail.discount,
    total: detail.total,
    description: detail.description,
  })),
  // Métodos de pago utilizados
  paymentMethod: data.paymentMethod.map((payment) => ({
    methodPayment: payment.methodPayment,
    amount: payment.amount,
  })),
  // Información general del documento
  change: data.change,
  saleDocumentId: data.saleDocument.saleDocumentId,
  saleBoxId: data.saleDocument.saleBoxId,
  saleBoxName: data.saleDocument.saleBoxName,
  clientId: data.saleDocument.clientId,
  clientName: data.saleDocument.clientName,
  clientDocumentName: data.saleDocument.clientDocumentName,
  clientDocumentNumber: data.saleDocument.clientDocumentNumber,
  typeDocument: data.saleDocument.typeDocument,
  typeDocumentName: data.saleDocument.typeDocumentName,
  serie: data.saleDocument.serie,
  number: data.saleDocument.number,
  discount: data.saleDocument.discount,
  subTotal: data.saleDocument.subTotal,
  total: data.saleDocument.total,
  igv: data.saleDocument.igv,
  icbper: data.saleDocument.icbper,
  dateCreated: data.saleDocument.dateCreated,
  dateEmision: data.saleDocument.dateEmision,
  status: data.saleDocument.status,
  statusName: data.saleDocument.statusName,
  error: data.saleDocument.error,
  statusFE: data.saleDocument.statusFE,
  statusFEName: data.saleDocument.statusFEName,
  employeeId: data.saleDocument.employeeId,
  employeeName: data.saleDocument.employeeName,
  // hash: "BfzdW0cjF2Dmg0x39EV2B/FFqragkWURkbDXKT/YNAI=",
  hash: data.saleDocument.hash,
  cancelComment: data.saleDocument.cancelComment,
  hasCreditNote: data.saleDocument.hasCreditNote,
  address: data.saleDocument.address,
  localName: data.saleDocument.localName,
  ruc: data.saleDocument.ruc,
  // qrData:
  //   "20607032603|03|B029|00000013|9.00|59.00|2024-12-18|03||BfzdW0cjF2Dmg0x39EV2B/FFqragkWURkbDXKT/YNAI",
  qrData: data.saleDocument.qrData,
});
