// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JfCGl_d3jIcitN6ZZqkk {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 6px;
  border: 1px solid #4887df;
  background-color: #f0f9ff;
  padding: 0.8rem 1rem;
  border-radius: 8px;

  & > .M3vGZdMCqPukayy3iKBv,
  .VBjOJIK7gJB13fp37xj9 {
    color: #4887df;
  }
}

.M3vGZdMCqPukayy3iKBv {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: bold;
  gap: 0.25rem;
}

.A8ErWdlFvvvTvMXC34hf {
  border-color: #4887df;
  background-color: #f0f9ff;

  & > .M3vGZdMCqPukayy3iKBv,
  .VBjOJIK7gJB13fp37xj9 {
    color: #4887df;
  }
}

.jpuD66UlQ9xKzI1GjlBK {
  border-color: #f5a623;
  background-color: #fff8f0;

  & > .M3vGZdMCqPukayy3iKBv,
  .VBjOJIK7gJB13fp37xj9 {
    color: #de8f11;
  }
}

.h2ZUj042kD0R1FC4M4YQ {
  border-color: #f44336;
  background-color: #fff0f0;

  & > .M3vGZdMCqPukayy3iKBv,
  .VBjOJIK7gJB13fp37xj9 {
    color: #f44336;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/InfoNote/InfoNote.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;EACrB,QAAQ;EACR,yBAAyB;EACzB,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;;EAElB;;IAEE,cAAc;EAChB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;;EAEzB;;IAEE,cAAc;EAChB;AACF;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;;EAEzB;;IAEE,cAAc;EAChB;AACF;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;;EAEzB;;IAEE,cAAc;EAChB;AACF","sourcesContent":[".note__container {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: baseline;\n  gap: 6px;\n  border: 1px solid #4887df;\n  background-color: #f0f9ff;\n  padding: 0.8rem 1rem;\n  border-radius: 8px;\n\n  & > .note__title,\n  .note__text {\n    color: #4887df;\n  }\n}\n\n.note__title {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  white-space: nowrap;\n  font-weight: bold;\n  gap: 0.25rem;\n}\n\n.info {\n  border-color: #4887df;\n  background-color: #f0f9ff;\n\n  & > .note__title,\n  .note__text {\n    color: #4887df;\n  }\n}\n\n.warning {\n  border-color: #f5a623;\n  background-color: #fff8f0;\n\n  & > .note__title,\n  .note__text {\n    color: #de8f11;\n  }\n}\n\n.error {\n  border-color: #f44336;\n  background-color: #fff0f0;\n\n  & > .note__title,\n  .note__text {\n    color: #f44336;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note__container": `JfCGl_d3jIcitN6ZZqkk`,
	"note__title": `M3vGZdMCqPukayy3iKBv`,
	"note__text": `VBjOJIK7gJB13fp37xj9`,
	"info": `A8ErWdlFvvvTvMXC34hf`,
	"warning": `jpuD66UlQ9xKzI1GjlBK`,
	"error": `h2ZUj042kD0R1FC4M4YQ`
};
export default ___CSS_LOADER_EXPORT___;
