import { billingBaseUrl } from "@Models/services/constGlobals";
import axios from "axios";

const ADD_NEW_RUC_URL = `${billingBaseUrl}/ElectronicBilling/AggregateCertificate`;

const addNewRucServices: any = async (rucObj, logo) => {
  const formData = new FormData();
  formData.append("BusinessName", rucObj.businessName);
  formData.append("ExpirationDate", rucObj.expirationDate);
  formData.append("CertificatePassword", rucObj.claveCertificado);
  formData.append("Ruc", rucObj.ruc);
  formData.append("AttachedFileCertificate", rucObj.file);
  formData.append("AttachedFileLogo", logo);

  const bearerToken = localStorage.getItem("access_token");

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const response = await axios.post(ADD_NEW_RUC_URL, formData, {
    headers: headers,
  });

  return response;
};

export default addNewRucServices;
