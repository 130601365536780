import React, { useEffect, useState } from "react";
import { IScheduleDayRow } from "./interface/scheduleDayRow";
import ScheduleRow from "./components/ScheduleRow";
//@ts-ignore
import style from "./style/ScheduleDayRow.module.css";

const ScheduleDayRow = ({
  dayData,
  scheduleState,
  setScheduleState,
  scheduleActions,
}: IScheduleDayRow) => {
  const [scheduleDimensions, setScheduleDimensions] = useState({
    width: 0,
    height: 0,
  });
  const scheduleRef = React.useRef(null);

  const resizeSchedule = () => {
    if (scheduleRef.current) {
      setScheduleDimensions({
        width: scheduleRef.current.offsetWidth,
        height: scheduleRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    resizeSchedule();
    window.addEventListener("resize", () => resizeSchedule());

    return () => {
      window.removeEventListener("resize", () => resizeSchedule());
    };
  }, [scheduleRef.current]);

  return (
    <section
      className={`${style.daySchedulesContainer} ${
        scheduleDimensions.width < 440 ? style.daySchedulesContainerMobile : ""
      }`}
      ref={scheduleRef}
    >
      {dayData.scheduleList.length > 0 &&
        dayData.scheduleList.map((schedule, index) => {
          return (
            <ScheduleRow
              key={schedule.idSchedule}
              dayData={dayData}
              currentSchedule={schedule}
              currentScheduleIndex={index} //For knowing which schedule is
              scheduleState={scheduleState} //For reading the state (not possible read prev from setter)
              setScheduleState={setScheduleState} //For updating the state
              scheduleActions={scheduleActions}
            />
          );
        })}
    </section>
  );
};

export default ScheduleDayRow;
