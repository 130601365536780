import React, { useEffect } from "react";
//@ts-ignore
import style from "./InputAdditionSubtract.module.css";
import TextInput from "@Atoms/inputs/textInput/TextInput";
import { MinusIcon, AddIcon } from "@Models/icons";
import Icon from "../../atoms/Icon/Icon";

const InputAdditionSubtract = ({
  value,
  setValue,
  min = 1,
  max = 999,
  disabled = false,
}) => {
  const handleSubtract = () => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  const handleAddition = () => {
    // if (!value) {
    //   setValue(1);
    // }
    if (value < max) {
      setValue(value + 1);
    }
  };

  const handleChange = (value, max, setValue) => {
    let numericValue = parseInt(value);
    // if (isNaN(numericValue)) {
    //   numericValue = 0;
    // }
    // if (numericValue < min) {
    //   numericValue = min;
    // }
    // if (numericValue > max) {
    //   numericValue = max;
    // }
    if (value.length > 3) return;

    setValue(numericValue);
  };

  return (
    <div className={style.containerInputAdditionSubtract}>
      {!disabled && (
        <i
          className={value <= min && style.menosOff}
          onClick={() => handleSubtract()}
        >
          {value > min && !disabled ? (
            <div className={`${style.imgL} ${style.imgActive}`}>
              <Icon path={MinusIcon} color="#fff" size={10} />
            </div>
          ) : (
            <div className={`${style.imgL} ${style.imgDesactive}`}>
              <Icon path={MinusIcon} color="#fff" size={10} />
            </div>
          )}
        </i>
      )}
      <TextInput
        name=""
        error=""
        handleBlur={() => {}}
        placeholder=""
        prefix=""
        suffix=""
        flex="none"
        inputProps={() => {}}
        rightIcon=""
        id=""
        label={""}
        value={value}
        handleChange={(e) => handleChange(e.target.value, max, setValue)}
        width={"60px"}
        keyboardType={"number"}
        disabled={disabled}
      />
      {!disabled && (
        <i
          className={value >= max && style.masOff}
          onClick={() => handleAddition()}
        >
          {value < max ? (
            <div className={`${style.imgR} ${style.imgActive}`}>
              <Icon path={AddIcon} color="#fff" size={10} />
            </div>
          ) : (
            <div className={`${style.imgR} ${style.imgDesactive}`}>
              <Icon path={AddIcon} color="#fff" size={10} />
            </div>
          )}
        </i>
      )}
    </div>
  );
};

export default InputAdditionSubtract;
