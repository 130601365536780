import React from "react";
// @ts-ignore
import style from "./SalesDetailsTable.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";

const SalesDetailsTable = ({ saleDocumentDetails }) => {
  const total = saleDocumentDetails.reduce((acc, curr) => acc + curr.total, 0);
  return (
    <div className={style.container}>
      <div>
        {saleDocumentDetails?.map((s, index) => (
          <div
            key={s.saleDocumentDetailId}
            style={{
              borderTop: index === 0 ? "none" : "1px solid #e0e0e0",
            }}
            className={style.container__items}
          >
            <div className={style.description}>{s.description}</div>

            <div className={style.line}>
              <p>Cantidad</p>
              <p className={style.amount}>x{s.quantity}</p>
            </div>
            <div className={style.line}>
              <p>Precio Unitario</p>
              <p className={style.amount}>
                {formatToSoles(s.price / s.quantity)}
              </p>
            </div>
            <div className={style.line}>
              <p>Precio Total</p>
              <p className={style.amount}>{formatToSoles(s.price)}</p>
            </div>
            {s.discount > 0 && (
              <div className={style.line}>
                <p>Descuento</p>
                <p className={style.discount}>{formatToSoles(s.discount)}</p>
              </div>
            )}
          </div>
        ))}
        <hr className={style.separator} />
        <div className={`${style.line} ${style.totalContainer}`}>
          <p className={style.total}>Total</p>
          <p className={style.totalAmount}>{formatToSoles(total)}</p>
        </div>
      </div>
    </div>
  );
};

export default SalesDetailsTable;
