import React, { ChangeEvent, useRef } from "react";
// @ts-ignore
import style from "./Avatar.module.css";
import Icon from "../Icon/Icon";
import { CameraIcon } from "@Models/icons";
import { AvatarProps } from "./interfaces/AvatarInterfaces";
import { getInitials } from "@Utilities/getInitials/getInitials";

const Avatar: React.FC<AvatarProps> = ({
  picture,
  name,
  setPicture,
  editImagen = true,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setPicture((prev) => ({
        ...prev,
        picture: reader.result as string,
        name: e.target.files![0].name,
      }));
    });
    reader.readAsDataURL(e.target.files![0]);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      // Click on the hidden input type="file"
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const target = event.target as HTMLImageElement;
    target.style.display = "none";
    // Explicitly type parentNode as Element
    const parentNode = target.parentNode as Element | null;
    // Show a default image of the first letter of the name
    if (parentNode) {
      parentNode.innerHTML = `<div class=${style.circleAvatar}>${getInitials(
        name
      )}</div>`;
    }
  };

  return (
    <div className={style.avatarContainer}>
      {picture ? (
        <img
          src={picture}
          alt="Imagen de perfil"
          className={style.pickedImage}
          onError={handleImageError}
        />
      ) : (
        <div className={style.circleAvatar}>{`${getInitials(name)}`}</div>
      )}
      {editImagen && (
        <button className={style.cameraLayout} onClick={handleButtonClick}>
          <Icon path={CameraIcon} color={"#ffffff"} size={36} />
        </button>
      )}
      {editImagen && (
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={(e) => saveFile(e)}
          style={{ display: "none" }} // Hide the input
        />
      )}
    </div>
  );
};

export default Avatar;
