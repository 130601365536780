import { useEffect } from "react";

const useFilter = (allData, textValue, cards, setResults, setCards) => {
  useEffect(() => {
    const filterResults = allData?.filter((result) => {
      return result?.name?.toLowerCase().includes(textValue.toLowerCase());
    });

    const sliceResult = filterResults.slice(0, 3);
    setResults(sliceResult);
  }, [textValue]);

  // useEffect(() => {
  // 	// search if any card has deleted or changed
  // 	const filterCards = cards.filter((card) => {
  // 		return allData.find((data) => data.id === card.id);
  // 	});
  // 	//@ts-ignore
  // 	setCards(filterCards);
  // }, [allData]);
};

export default useFilter;
