import React, { useEffect, useRef } from "react";
//@ts-ignore
import style from "./style/ModalAcceptCancel.module.css";
import Button from "@Atoms/buttons/buttonsWithoutIcons/button";
import { TimesIcon } from "@Models/icons";
import { IModalProps } from "./interface/modalAcceptCancel";
import ModalLayoutWrapper from "@Wrappers/ModalLayoutWrapper/ModalLayoutWrapper";
import Icon from "../../atoms/Icon/Icon";

const ModalAcceptCancel = (props: IModalProps) => {
  const {
    title = null,
    visibility = { isVisible: true, setIsVisible: () => {} },
    children,
    showButtons,
    buttonOne,
    buttonTwo,
    actionButtonClose,
    footer = null,
    zIndex = 1001,
  } = props;
  const { isVisible = true, setIsVisible = () => {} } = visibility;
  const modalRef = useRef(null);
  const ESCAPE_KEY = 27;

  // useEffect for click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsVisible(false);
      }
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === ESCAPE_KEY) {
        actionButtonClose && actionButtonClose(e);
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [modalRef, setIsVisible]);

  const renderFooter = () => {
    if (footer) {
      return footer;
    }
    if (showButtons?.showButtonOne || showButtons?.showButtonTwo) {
      return (
        <div
          className={`${style.modalFooterContainer} ${
            showButtons?.showButtonOne
              ? style.lowerPartTwoButtons
              : style.lowerPartOneButton
          }`}
        >
          {showButtons?.showButtonOne && (
            <Button
              isPrimary={false}
              textBttn={buttonOne?.textButtonOne}
              handleClick={buttonOne?.actionButtonOne}
              isDisabled={buttonOne?.isDisabled}
              isLoading={buttonOne?.isLoading}
            />
          )}
          {showButtons?.showButtonTwo && (
            <Button
              isPrimary={true}
              textBttn={buttonTwo?.textButtonTwo}
              handleClick={buttonTwo?.actionButtonTwo}
              isDisabled={buttonTwo?.isDisabled}
              isLoading={buttonTwo?.isLoading}
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <ModalLayoutWrapper isVisible={isVisible} zIndex={zIndex}>
      <div className={style.containerModalAcceptCancel} ref={modalRef}>
        <div
          className={`${style.topPartContainer} ${
            !showButtons?.showButtonClose ? style.singleTitleContainer : ""
          }`}
        >
          {title instanceof HTMLElement ? (
            { title }
          ) : (
            <h1 className={`${style.title}`}>{title}</h1>
          )}
          {showButtons?.showButtonClose && (
            <button
              className={style.closeButtonContainer}
              onClick={(e) => {
                actionButtonClose && actionButtonClose(e);
                setIsVisible(false);
              }}
            >
              <Icon path={TimesIcon} size={14} color={"#B4B4B4"} />
            </button>
          )}
        </div>
        <div className={style.bodyContainer}>{children}</div>
        {renderFooter()}
      </div>
    </ModalLayoutWrapper>
  );
};

export default ModalAcceptCancel;
