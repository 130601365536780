// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dUzXehzkXtZCGUvmVirf {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.pc99BUr24XtakDOpiDIF {
  border-radius: 2px;
  height: 100%;
  width: 100%;
}

.EuIiCd9SvssVWHCLnOuu {
  width: 80%;
  background: linear-gradient(
    45deg,
    #dcdcdc,
    #e0e0e0,
    rgb(188, 176, 235),
    rgb(188, 176, 235),
    #e0e0e0,
    #dcdcdc
  );
  background-size: 800% 800%;
  animation: FNCR4lPe7o1XxywGLnK6 3s ease-in-out infinite;
  opacity: 0.3;
}

.DAn39GO3D9bHUHuBr4MS {
  width: 40%;
  background: linear-gradient(
    45deg,
    #dcdcdc,
    #e0e0e0,
    rgb(188, 176, 235),
    rgb(188, 176, 235),
    #e0e0e0,
    #dcdcdc
  );
  background-size: 800% 800%;
  animation: FNCR4lPe7o1XxywGLnK6 3s ease-in-out infinite;
  opacity: 0.3;
}

@keyframes FNCR4lPe7o1XxywGLnK6 {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/DualBars/DualBars.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,UAAU;EACV;;;;;;;;GAQC;EACD,0BAA0B;EAC1B,uDAA+C;EAC/C,YAAY;AACd;;AAEA;EACE,UAAU;EACV;;;;;;;;GAQC;EACD,0BAA0B;EAC1B,uDAA+C;EAC/C,YAAY;AACd;;AAEA;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".dual_bars_container {\n  width: 100%;\n  height: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 4px;\n}\n\n.bar {\n  border-radius: 2px;\n  height: 100%;\n  width: 100%;\n}\n\n.top_bar {\n  width: 80%;\n  background: linear-gradient(\n    45deg,\n    #dcdcdc,\n    #e0e0e0,\n    rgb(188, 176, 235),\n    rgb(188, 176, 235),\n    #e0e0e0,\n    #dcdcdc\n  );\n  background-size: 800% 800%;\n  animation: changeColors 3s ease-in-out infinite;\n  opacity: 0.3;\n}\n\n.bottom_bar {\n  width: 40%;\n  background: linear-gradient(\n    45deg,\n    #dcdcdc,\n    #e0e0e0,\n    rgb(188, 176, 235),\n    rgb(188, 176, 235),\n    #e0e0e0,\n    #dcdcdc\n  );\n  background-size: 800% 800%;\n  animation: changeColors 3s ease-in-out infinite;\n  opacity: 0.3;\n}\n\n@keyframes changeColors {\n  0% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dual_bars_container": `dUzXehzkXtZCGUvmVirf`,
	"bar": `pc99BUr24XtakDOpiDIF`,
	"top_bar": `EuIiCd9SvssVWHCLnOuu`,
	"changeColors": `FNCR4lPe7o1XxywGLnK6`,
	"bottom_bar": `DAn39GO3D9bHUHuBr4MS`
};
export default ___CSS_LOADER_EXPORT___;
