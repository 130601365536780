import addNewRucServices from "../../../../services/addNewRucServices";

const addNewRucListAdapter = async (rucObj, logo) => {
  const newRucObj = {
    businessName: rucObj.businessName,
    expirationDate: rucObj.expirationDate,
    claveCertificado: rucObj.claveCertificado,
    ruc: rucObj.ruc,
    file: rucObj.file,
    logo: logo || null,
  };

  try {
    const backResponse = await addNewRucServices(newRucObj, logo);
    return backResponse;
  } catch (error) {
    return error;
  }
};

export default addNewRucListAdapter;
