import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import style from "./dragAndDropFile.module.css";
import { HiArrowUpTray } from "react-icons/hi2";
import { v4 as uuidv4 } from "uuid";

const DragAndDropFile = ({
  file,
  setFile,
  accept,
  setIsUploadedFileOk,
  setUploadedFileMessage,
}) => {
  const uuid = useRef(uuidv4()).current; // Generate UUID once
  const fileInputRef = useRef(null);
  const [dragOver, setDragOver] = useState(false);

  useEffect(() => {
    const fileDragAndDrop = document.getElementById(uuid);

    const handleDragOver = (e) => {
      e.preventDefault();
      fileDragAndDrop.classList.add(style.dragover);
      setDragOver(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      fileDragAndDrop.classList.remove(style.dragover);
      setDragOver(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      fileDragAndDrop.classList.remove(style.dragover);
      setDragOver(false);
      const file = e.dataTransfer.files[0];
      handleFile(file);
    };

    const handleChange = (e) => {
      const file = e.target.files[0];
      handleFile(file);
    };

    const handleFile = (file) => {
      let isFormatAvailable = false;
      accept.forEach((format) => {
        if (file?.name.endsWith(format)) {
          isFormatAvailable = true;
        }
      });
      if (isFormatAvailable) {
        setFile(file);
        setIsUploadedFileOk(true);
        setUploadedFileMessage(file.name);
      } else {
        setIsUploadedFileOk(false);
        setUploadedFileMessage(
          <p>
            <b>{file.name}</b> no tiene el formato requerido.
          </p>
        );
      }
      // Reset the input value to allow selecting the same file again
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    fileDragAndDrop.addEventListener("dragover", handleDragOver);
    fileDragAndDrop.addEventListener("dragleave", handleDragLeave);
    fileDragAndDrop.addEventListener("drop", handleDrop);

    const fileInput = fileInputRef.current;
    fileInput.addEventListener("change", handleChange);

    return () => {
      fileDragAndDrop.removeEventListener("dragover", handleDragOver);
      fileDragAndDrop.removeEventListener("dragleave", handleDragLeave);
      fileDragAndDrop.removeEventListener("drop", handleDrop);
      if (fileInput) {
        fileInput.removeEventListener("change", handleChange);
      }
    };
  }, [accept, setFile, setIsUploadedFileOk, setUploadedFileMessage, uuid]);

  return (
    <label
      className={`${style.dragDrop__container} ${
        file ? style.dragDrop__container__disabled : ""
      }`}
      id={uuid}
    >
      <input
        accept={accept}
        className={style.dragDrop__input}
        type="file"
        disabled={file}
        ref={fileInputRef}
      />

      <section className={style.dragDrop__section__message}>
        <div className={style.dragDrop__section__message__icon}>
          <HiArrowUpTray size={16} color={!file ? "#937cf4" : "#9a9a9a"} />
          <p
            className={`${style.dragDrop__section__message__text} ${
              file ? style.dragDrop__section__message__text__disabled : ""
            }`}
          >
            <span className={style.dragDrop__section__message__text__span}>
              Seleccionar
            </span>{" "}
            o arrastrar el archivo aquí
          </p>
        </div>
        <p className={style.dragDrop__section__message__format}>
          Solo puedes subir archivos{" "}
          {accept.map((format, index) => {
            if (index === accept.length - 1) {
              return format;
            }
            return format + ", ";
          })}
          .
        </p>
      </section>
    </label>
  );
};

export default DragAndDropFile;
