import { getAvailableHours_service } from "@Services/employee/getAvailableHours.service";

export const getAvailableHours_adapter = async ({
  date,
  employeeId,
  priceServiceId,
  reserveId = null,
}) => {
  const response = await getAvailableHours_service({
    date,
    employeeId,
    priceServiceId,
    reserveId,
  });

  // Verificamos si la respuesta fue exitosa
  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      message: response.message,
      data: [],
    };
  }

  const currentDate = new Date();
  const currentHours = currentDate.getHours();
  const currentDay = currentDate.toISOString().split("T")[0]; // Obtener la fecha actual en formato YYYY-MM-DD
  const requestedDate = new Date(date).toISOString().split("T")[0]; // Obtener la fecha solicitada

  // Filtrar horas dependiendo de si la fecha es hoy o no
  const filteredHours = response.data.hours.filter((hour) => {
    const [hourHour, hourMinute] = hour.split(":").map(Number);
    if (requestedDate === currentDay) {
      // Eliminar la limitación de horas actuales
      return true; // Devolver todas las horas si es hoy
    }
    // Si la fecha es mañana o posterior, devolver todas las horas
    return true;
  });

  return {
    isSuccess: true,
    status: response.status,
    message: response.message,
    data: filteredHours,
  };
};
