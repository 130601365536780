import React from "react";
//@ts-ignore
import style from "./OptionSupplier.module.css";

const OptionSupplier = ({ data, isLoading }) => {
  return data.length ? (
    data.map((item) => (
      <li onClick={item.onClick} key={item.id}>
        <span className={style.info}>
          <p>{item.name}</p>
        </span>
      </li>
    ))
  ) : (
    <li className={style.withoutResults}>
      {isLoading ? <p>Buscando..</p> : <p>No hay resultados</p>}
    </li>
  );
};

export default OptionSupplier;
