import React from "react";

// Components
import { MenuItem, Select } from "@mui/material";

//@ts-ignore
import style from "./SelectInput.module.css";
import { iconPath } from "@Models/localPath";
interface SelectInputProps {
  label?: string;
  name?: string;
  flex?: number;
  value?: string;
  touched?: boolean;
  error?: any;
  handleChange?: any;
  handleBlur?: any;
  disabled?: boolean;
  items?: any;
  required?: boolean;
  width?: string;
  marginBottom?: string | number;
  id?: string;
  placeholder?: string;
  containerStyle?: any;
}
const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  flex = 1,
  value = "",
  touched,
  error,
  handleChange = () => {},
  handleBlur = () => {},
  disabled,
  items = [],
  required,
  width = "100%",
  marginBottom,
  id,
  placeholder,
  containerStyle,
}) => {
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "left" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "left" as const,
    },
    getcontentanchorel: null,
  };

  const IconArrow = (props) => (
    <svg
      {...props}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        right: 12,
        position: "absolute",
        userSelect: "none",
        pointerEvents: "none",
      }}
    >
      <path
        d="M2.5 5.625L7.5 10.625L12.5 5.625"
        stroke="#392B75"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div
      className={style.inputContainer}
      style={{ flex: flex, width: width ? width : "auto", ...containerStyle }}
    >
      {label && (
        <label className={style.inputLabel}>
          {label}
          {!disabled && required && <p className={style.inputRequired}>*</p>}
        </label>
      )}
      <Select
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        className={style.customSelect}
        id={id}
        sx={{
          borderRadius: "5px",
          padding: "5px 10px 5px 0px",
          maxHeight: "40px",
          width: width || "fit-content",
          fontSize: "var(--font-size-reading)",
          fontFamily: "Mulish",
          outline: "none",
          transition: "all 0.2s ease-in-out",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#392b75 !important",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor:
              touched && error ? "red !important" : "#dadada !important",
            transition: "all 150ms ease-in-out",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#392b75 !important",
          },
          ".Mui-disabled": {
            WebkitTextFillColor: "#000",
          },
          bgcolor: disabled ? "#FAFAFA" : "#fff",
        }}
        displayEmpty
        disabled={disabled}
        required={required}
        IconComponent={IconArrow}
        MenuProps={menuProps}
      >
        <MenuItem
          aria-label="Seleccionar..."
          value=""
          sx={{
            fontSize: "var(--font-size-reading)",
            fontFamily: "Mulish",
          }}
          disabled
        >
          {placeholder ? placeholder : "Seleccionar"}
        </MenuItem>
        {items.length > 0 ? (
          items.map((item, index) => {
            return (
              <MenuItem
                id={id + "-" + index}
                value={item.value !== undefined ? item.value : item}
                key={item.value !== undefined ? item.value : item}
                sx={{
                  fontSize: "var(--font-size-reading)",
                  fontFamily: "Mulish",
                  color: "#4d4d4d",
                }}
              >
                {item.name ? item.name : item}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem
            value={""}
            key={""}
            disabled
            sx={{
              fontSize: "var(--font-size-reading)",
              fontFamily: "Mulish",
            }}
          >
            No hay opciones
          </MenuItem>
        )}
      </Select>
      {touched && error && (
        <span className={style.inputErrorContainer}>
          <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
          <p className={style.inputError}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default SelectInput;
