import React from "react";
import style from "./SearchAndFilter.module.css";
import { FilteredOutIcon } from "@Models/icons";
// @ts-ignore
import { Icon, SearchWithColoredBorder } from "@viuti/recursos";
interface ISearchAndFilter {
  placeholder?: string;
  searchInput: string;
  setSearchInput: (value: string) => void;
  isFilterAvailable?: boolean;
  handleOpenFilter: () => void;
}

const SearchAndFilter = ({
  placeholder = "Buscar",
  searchInput,
  setSearchInput,
  handleOpenFilter,
  isFilterAvailable = true,
}: ISearchAndFilter) => {
  return (
    <div className={style.containerSearchWithFilter}>
      <SearchWithColoredBorder
        width={"100%"}
        label={""}
        value={searchInput}
        placeholder={placeholder}
        setValue={setSearchInput}
        withoutTheContainerWithTheResults={true}
        clearInput={false}
      />
      {isFilterAvailable && (
        <button
          className={style.containerFilterIcon}
          onClick={handleOpenFilter}
        >
          <Icon path={FilteredOutIcon} alt={"Filtro"} size={"23px"} />
        </button>
      )}
    </div>
  );
};

export default SearchAndFilter;
