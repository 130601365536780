// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JLHRr3msgUJe0VLfVxAZ {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.gXsukg_TjCp7N4uEGMqe {
  width: 100%;
  color: #937cf4;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/FileUploader/FileUploader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,cAAc;EACd,iCAAiC;EACjC,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".file_uploader__container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n}\n\n.newRucsPage__SectionRow__h2 {\n  width: 100%;\n  color: #937cf4;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-label);\n  font-style: normal;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file_uploader__container": `JLHRr3msgUJe0VLfVxAZ`,
	"newRucsPage__SectionRow__h2": `gXsukg_TjCp7N4uEGMqe`
};
export default ___CSS_LOADER_EXPORT___;
