import React, { useRef } from "react";
import style from "./SalesDocumentDetailModal.module.css";
import SalesDocumentDetailTable from "./SalesDocumentDetailTable/SalesDocumentDetailTable";
import { capitalizeWords } from "@Utilities/CapitalizeWords";
import { LoadingPulseIcon } from "@Models/icons";
import { useReactToPrint } from "react-to-print";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import { formatToSoles } from "@Utilities/FormatsHandler";
import ModalAcceptCancel from "@Molecules/ModalAcceptCancel/ModalAcceptCancel";
import useDocumentDetail from "../../hook/useDocumentDetail/useDocumentDetail";
import PayoutTicket from "../PayoutTicket/PayoutTicket";
import useTicket from "../../hook/useTicket/useTicket";

const SalesDocumentDetailModal = ({
  modalTitle,
  isVisible,
  setIsVisible,
  document,
  onNotification,
}) => {
  const [loader, setLoader] = React.useState(true);
  const printableRef = useRef(null);
  const {
    paymentMethod,
    saleDocumentDetails,
    change,
    qrData,
    hash,
    saleBoxName,
    ruc,
    address,
    typeDocumentName,
    localName,
    clientDocumentName,
    saleCompleteDetails,
  } = useDocumentDetail(
    document.id,
    document.saleBoxId,
    setLoader,
    onNotification
  );

  const handlePrintSaleTicket = useReactToPrint({
    contentRef: printableRef,
  });

  const { summary } = useTicket(
    document,
    saleDocumentDetails,
    paymentMethod,
    handlePrintSaleTicket,
    qrData,
    hash,
    saleBoxName,
    ruc,
    address,
    typeDocumentName,
    localName,
    clientDocumentName
  );

  const footer = (
    <div className={style.footer}>
      <ButtonWithoutIcon
        isPrimary={false}
        textBttn={"Reimprimir ticket"}
        handleClick={handlePrintSaleTicket}
        isDisabled={false}
        isLoading={false}
        width={document.typeDocument === 5 ? "100%" : "auto"}
      />
    </div>
  );

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  return (
    <ModalAcceptCancel
      title={modalTitle}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
      // buttonTwo={{
      // 	textButtonTwo: "Registrar",
      // 	actionButtonTwo: handleCreateMovement,
      // 	isDisabled: isPrimaryButtonDisabled,
      // }}
      actionButtonClose={handleCloseModal}
      footer={footer}
    >
      <div className={style.containerTicket}>
        {loader ? (
          <div className={style.loader}>
            <img
              className={style.AnimatedLoader}
              src={LoadingPulseIcon}
              alt="AnimatedLoader"
            />
          </div>
        ) : (
          <>
            <section className={style.header}>
              <p className={style.type}>Documento</p>
              <p>
                {document.type} {document.series}-{document.number}
              </p>
              <p className={style.date}>Cliente</p>
              <p>{document.client}</p>
              <p>
                {document.clientDocumentName} {document.clientDocumentNumber}
              </p>
              <p className={style.date}>Fecha y hora de emisión</p>
              <p>{document.date}</p>
            </section>
            <SalesDocumentDetailTable
              saleDocumentDetails={saleDocumentDetails}
            />
            <div className={style.fourth_container}>
              <div className={style.flex}>
                <p>Subtotal</p>
                <p>
                  {formatToSoles(saleCompleteDetails.details.summary.subtotal)}
                </p>
              </div>
              <div className={style.flex}>
                <p>IGV(18%)</p>
                <p>{formatToSoles(saleCompleteDetails.details.summary.igv)}</p>
              </div>
            </div>
            <div className={`${style.flex} ${style.total}`}>
              <h4>Total</h4>
              <h4>
                {formatToSoles(saleCompleteDetails.details.summary.total)}
              </h4>
            </div>
            <div className={style.payment_container}>
              {paymentMethod?.map((payment, index) => (
                <div
                  className={`${style.flex} ${style.amountAndPaymentMethod}`}
                  key={index + payment.methodPayment}
                >
                  <p>{capitalizeWords(payment.methodPayment)}</p>
                  <p>{formatToSoles(payment.amount)}</p>
                </div>
              ))}
              {change !== 0 && (
                <div className={`${style.containerChange}`}>
                  <h4 className={style.change}>Vuelto</h4>
                  <h4 className={style.change}>{formatToSoles(change)}</h4>
                </div>
              )}
            </div>
          </>
        )}
        {/* TODO: Agregar los props que faltan de constructores en redux */}
        <PayoutTicket
          printableRef={printableRef}
          summary={summary}
          saleBoxName={saleBoxName}
          ruc={ruc}
          address={address}
          typeDocumentName={typeDocumentName}
          localName={localName}
          clientDocumentName={clientDocumentName}
          hash={hash}
          qrData={qrData}
          saleCompleteDetails={saleCompleteDetails}
        />
      </div>
    </ModalAcceptCancel>
  );
};

export default SalesDocumentDetailModal;
