import React from "react";

// @ts-ignore
import style from "./PasswordInput.module.css";
// @ts-ignore
import { EyeClosedIcon, EyeIcon } from "@Models/icons";

const PasswordInput = ({
  label,
  touched,
  error,
  prefix,
  suffix,
  width = "100%",
  flex = "none",
  inputProps = {
    disabled: false,
  },
  visibility,
  id,
}) => {
  return (
    <div className={style.inputContainer} style={{ width: width, flex: flex }}>
      {label && (
        <label className={style.inputLabel}>
          {label}
          {/*@ts-ignore*/}
          {!inputProps.disabled && inputProps.required && (
            <p className={style.inputRequired}>*</p>
          )}
        </label>
      )}
      <div className={style.inputContent}>
        <span className={style.inputPrefix}>{prefix}</span>
        <input
          // @ts-ignore
          error={false}
          sx={{
            borderRadius: "5px",
            width: "100%",
            minWidth: "60px",
          }}
          className={`${style.inputText} ${
            prefix ? style.inputTextPrefix : ""
          } ${suffix ? style.inputTextSuffix : ""} ${
            inputProps.disabled ? style.inputTextDisabled : ""
          } ${touched && error ? style.inputTextError : ""}`}
          {...inputProps}
          id={id}
        />
        {visibility.isToggleAvailable && (
          <span className={style.inputSuffix}>
            {visibility.isVisible ? (
              <img
                src={EyeIcon}
                alt=""
                onClick={() => visibility.setIsVisible(false)}
              />
            ) : (
              <img
                src={EyeClosedIcon}
                alt=""
                onClick={() => visibility.setIsVisible(true)}
              />
            )}
          </span>
        )}
      </div>
      {touched && error && <p className={style.inputError}>{error}</p>}
    </div>
  );
};

export default PasswordInput;
