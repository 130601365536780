import { appointmentsBaseUrl, postData } from "@Services/constServices";

interface PostNewAppointmentData {
  clientId: number;
  observation: string;
  prepaid: number;
  dateAttention: string;
  details: Detail[];
}

interface Detail {
  priceServiceId: number;
  employeeId: number;
  reserveHour: string;
  promoId: number;
}

export const postNewAppointment_service = async (
  data: PostNewAppointmentData
) => {
  const url = `${appointmentsBaseUrl}/Reserva/CreateReserve`;
  const successMessage = "Reserva creada correctamente";
  const errorMessage =
    "Hubo un problema al crear la reserva, intente nuevamente";
  return postData(url, data, successMessage, errorMessage);
};
