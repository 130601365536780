import {
  CARNET_DE_EXTRANJERIA,
  DNI,
  PASAPORTE,
  RUC,
} from "../constants/constants";

export const isPersonalInfoValid = (formData, status) => {
  const validation =
    status.validated &&
    formData.name &&
    (formData.phoneNumber.length === 9 || formData.phoneNumber.length === 0) &&
    (formData.email.length === 0 ||
      (formData.email.includes("@") && formData.email.includes(".")));
  return validation;
};

export const isCommonInfoValid = (formData, status) => {
  const validation =
    (status.validated && formData.documentType === DNI) ||
    formData.documentType === PASAPORTE ||
    formData.documentType === CARNET_DE_EXTRANJERIA
      ? formData.documentNumber &&
        formData.lastName &&
        formData.sex &&
        formData.birthdate
      : formData.name &&
        (formData.phoneNumber.length === 9 ||
          formData.phoneNumber.length === 0) &&
        (formData.email.length === 0 ||
          (formData.email.includes("@") && formData.email.includes("."))) &&
        formData.address;
  return validation;
};

export const isEmailValid = (email) => {
  const emailRegex = /^([\w.%+-ñÑ]+)@([\w-ñÑ]+\.)+([\wñÑ]{2,})$/i;
  return emailRegex.test(email);
};

export const sanitizeAndSetForm = (e, maxLength, setForm, form, label) => {
  const { name, value } = e.target;
  let sanitizedValue = value.replace(/[^0-9]/g, "").substring(0, maxLength);
  if (label === "Número de pasaporte" || label === "Número de carnet") {
    // el numero de pasaporte puede contener numeros y letras
    sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").substring(0, maxLength);
  }
  setForm({ ...form, [name]: sanitizedValue });
};

export const handleInputChange = (e, values, setValues) => {
  e.preventDefault();
  const { name, value } = e.target;
  const formattedValue = value.replace(/\D/g, "");
  setValues({ ...values, [name]: formattedValue });
};

export const calculateAge = (birthDate) => {
  const today = new Date();
  const dob = new Date(birthDate);
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  return age;
};

export const isValidDate = (dateString) => {
  const validDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!validDateRegex.test(dateString)) {
    return false;
  }

  const birthDate = new Date(dateString);
  const today = new Date();

  // Verificar si la fecha de nacimiento es válida (no futuro) y mayor que 1900
  if (birthDate >= today || birthDate.getFullYear() < 1900) {
    return false;
  }

  return true;
};
