import React from "react";
//@ts-ignore
import style from "./HeadBar.module.css";
import WarehouseIcon from "../../atoms/WarehouseIcon/WarehouseIcon";
import Button from "../../atoms/buttons/buttonsWithoutIcons/button";
import { iconPath } from "../../../../models/localPath";

const HeadBar = ({
  previousAction,
  warehouseProps,
  buttonProps,
  secondaryButtonProps,
  showButton,
  showSecondaryButton,
  styleContainer,
}) => {
  return (
    <header className={style.header} style={styleContainer}>
      <section className={style.titleContainer}>
        {previousAction && (
          <button onClick={previousAction} className={style.titleBackButton}>
            <img
              className={style.titleBackIcon}
              src={`${iconPath}/orientation/arrowLeft.svg`}
              alt="volver"
            />
          </button>
        )}
        <WarehouseIcon {...warehouseProps} />
      </section>
      <span className={style.buttonsContainer}>
        {showSecondaryButton && <Button {...secondaryButtonProps} />}
        {showButton && <Button {...buttonProps} />}
      </span>
    </header>
  );
};

export default HeadBar;
