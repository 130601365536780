import addNewRucAdapter from "../adapters/addNewRucAdapter/addNewRucAdapter";
import showPopUp from "../hooks/useShowPopUp";

const validTheCertificate = async (
  setShowModal,
  setModalProps,
  rucData,
  setRucData,
  file,
  setInValidation,
  setInProgress
) => {
  setInValidation(true);
  setInProgress(true);
  const result = await addNewRucAdapter({
    password: rucData.claveCertificado,
    file,
  });
  if (result?.error) {
    showPopUp(
      setShowModal,
      setModalProps,
      "Ups! ha ocurrido un error",
      result.message
    );
    setInProgress(false);
    setInValidation(false);
  } else {
    setInValidation(true);
    setInProgress(false);
    setRucData({ ...rucData, ...result });
  }
};

export default validTheCertificate;
