import React from "react";
import PrintableTicketHTML from "../PrintableTicketHTML/PrintableTicketHTML";
import { billingPrintbuilder } from "./consts/billingPrintData";
import { ISaleCompleteDetails, ISaleSummary } from "@Models/interfaces/salebox";

interface PayoutTicketProps {
  printableRef: React.RefObject<HTMLDivElement>;
  summary: ISaleSummary;
  saleBoxName: string;
  ruc: string;
  address: string;
  typeDocumentName: string;
  localName: string;
  clientDocumentName: string;
  qrData: string;
  hash: string;
  saleCompleteDetails: ISaleCompleteDetails;
}

const PayoutTicket = ({
  printableRef,
  summary,
  saleBoxName = "",
  ruc = "",
  address = "",
  typeDocumentName = "",
  localName = "",
  clientDocumentName = "Documento de identidad",
  qrData = "",
  hash = "",
  saleCompleteDetails,
}: PayoutTicketProps) => {
  if (!saleCompleteDetails) return null;

  // const sales = useAppSelector((state) => state.currentSale.sales);
  // const salebox = useAppSelector((state) => state.selectedSaleBox);
  //x const billingDocument = useAppSelector((state) => state.currentSale.billingDocument);
  //x const billingOptions = useAppSelector((state) => state.saleConfig.billingDocuments);
  // const selectedBillingOption = billingOptions.find(
  // 	// @ts-ignore
  // 	(option) => option.id === billingDocument
  // );
  const lounge = JSON.parse(localStorage.getItem("account_info"));
  // const finishedSaleResponse = useAppSelector(
  // 	(state) => state.currentSale.finishedSaleResponse
  // );
  // const client = useAppSelector((state) => state.currentSale.client);
  // const genericClient = useAppSelector((state) => state.currentSale.genericClient);

  // const selectedClient = genericClient || client;
  // const paymentMethods = useAppSelector((state) => state.currentSale.paymentMethods);
  // const account = useAppSelector((state) => state.saleConfig.account);

  const products = saleCompleteDetails?.details.items.map((item) => {
    return {
      DESCRIPTION: item.description,
      AMOUNT: item.quantity,
      TOTAL: item.total,
    };
  });

  const formattedPaymentMethods =
    saleCompleteDetails?.details.paymentMethods?.map((paymentMethod) => {
      return {
        DESCRIPTION: paymentMethod.name,
        AMOUNT: paymentMethod.amount,
      };
    });

  return (
    <div style={{ display: "none" }}>
      <PrintableTicketHTML
        reference={printableRef}
        billingData={billingPrintbuilder({
          titleName: saleCompleteDetails.selectedSaleBox.name,
          responsable: saleCompleteDetails.details.employee.name,
          business: {
            NAME: saleCompleteDetails.selectedSaleBox.name,
            RUC: saleCompleteDetails.billing.ruc,
          },
          local: {
            // TODO: CHANGE NOMBRE FOR NAME
            NAME: saleCompleteDetails.billing.localName,
            LOCATION: saleCompleteDetails.billing.address,
            PHONE: "",
            EMAIL: "",
          },
          billingCode:
            saleCompleteDetails.billing.serie +
            "-" +
            saleCompleteDetails.billing.document,
          billingDocument: typeDocumentName,
          products: products,
          client: {
            NAME: saleCompleteDetails.client.name,
            DOCUMENT_TYPE: saleCompleteDetails.client.documentType.name,
            DOCUMENT: saleCompleteDetails.client.documentType.number,
            LOCATION: "",
          },
          pricing: {
            OP_GRAVADAS: saleCompleteDetails.details.summary.subtotal,
            OP_EXONERADAS: 0,
            OP_IGV: saleCompleteDetails.details.summary.igv,
            OP_ICBPER: saleCompleteDetails.details.summary.icbper,
            OP_DESCUENTO: saleCompleteDetails.details.summary.discount,
            OP_TOTAL: saleCompleteDetails.details.summary.total,
          },
          paymentMethods: formattedPaymentMethods,
          dataTicket: {
            hash: saleCompleteDetails.details.hash,
            qrData: saleCompleteDetails.details.qrData,
          },
        })}
      />
    </div>
  );
};

export default PayoutTicket;
