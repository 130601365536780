export const renderPaginationBttns = (
  options: [],
  optionsPerPage: number,
  currentPage: number,
  visibleButtonsCount: number,
  numberOfButtons?: number
) => {
  const buttonsQuantity = numberOfButtons
    ? numberOfButtons
    : Math.ceil(options && options.length / optionsPerPage);

  /* The startPage const is calculating the starting page number for the pagination buttons. */
  const startPage = Math.max(
    1,
    currentPage - Math.floor(visibleButtonsCount / 2)
  );
  /* The endPage const is calculating the ending page number for the pagination buttons. */
  const endPage = Math.min(
    buttonsQuantity,
    startPage + visibleButtonsCount - 1
  );

  const buttons = [];
  for (let i = startPage; i <= endPage; i++) {
    buttons.push(i);
  }

  if (startPage > 2) {
    buttons.unshift("...");
  }
  if (startPage > 1) {
    buttons.unshift(1);
  }

  if (endPage < buttonsQuantity - 1) {
    buttons.push("...");
  }
  if (endPage < buttonsQuantity) {
    buttons.push(buttonsQuantity);
  }

  return buttons;
};

export const goToFirstPage = (setPagination) => {
  setPagination(1);
};

export const goToPreviousPage = (currentPagination, setPagination) => {
  setPagination(currentPagination - 1);
};

export const goToNextPage = (currentPagination, setPagination) => {
  setPagination(currentPagination + 1);
};

export const goToLastPage = (setPagination, lastOption) => {
  setPagination(lastOption);
};
