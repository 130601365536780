import React from "react";
//@ts-ignore
import style from "./OptionClient.module.css";

const OptionClient = ({ data, isLoading }) => {
  return data.length ? (
    data.map((item) => (
      <li onClick={item.onClick} key={item.idClient}>
        {item.img ? (
          <figure>
            <img src={item.image} alt="Foto de perfil" />
          </figure>
        ) : (
          <span className={style.circleInitialLetter}>
            <p>{item.fullName[0]}</p>
          </span>
        )}
        <span className={style.info}>
          <p>{item.fullName}</p>
          <p>{item.info}</p>
        </span>
      </li>
    ))
  ) : (
    <li className={style.withoutResults}>
      {isLoading ? <p>Buscando..</p> : <p>No hay resultados</p>}
    </li>
  );
};

export default OptionClient;
