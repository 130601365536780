import React from "react";
import axios from "axios";
import addNewRucListAdapter from "../adapters/addNewRucAdapter/addNewRucListAdapter";
import showPopUp from "../hooks/useShowPopUp";
import HeaderModal from "../../headerModal/HeaderModal";

const useAdapter = async ({
  rucData,
  file,
  setShowModal,
  setModalProps,
  setIsAdding,
  logo,
  onClick,
  setIsVisible,
}) => {
  setIsAdding(true);
  const response = await addNewRucListAdapter({ ...rucData, file }, logo);
  if (axios.isAxiosError(response)) {
    showPopUp(
      setShowModal,
      setModalProps,
      "¡Ups! ocurrió un problema",
      response.response.data.message
    );
    setIsVisible(false);
    setIsAdding(false);
    onClick({ result: {}, isSuccessful: false });
  } else {
    setShowModal(true);
    const result = {
      idCertificate: response.data.data.newCertificateId,
      rucData: rucData,
    };
    onClick({ result: result, isSuccessful: true });
    setModalProps({
      showButtons: {
        showButtonClose: false,
        showButtonTwo: true,
      },
      children: (
        <HeaderModal
          title={"Operación realizada con éxito"}
          content={"El RUC se ha agregado con éxito."}
          icon="success"
        />
      ),
      buttonTwo: {
        textButtonTwo: "Cerrar",
        actionButtonTwo: () => {
          setShowModal(false);
        },
      },
    });
    setIsAdding(false);
  }
};

export default useAdapter;
