import { ApiResponse } from "@Services/constServices";
import { createMovement_service } from "@Services/saleboxes/movements";

export const postNewMovement = async (
  idBox,
  idEmployee,
  idBoxMoveConcept,
  idMovementType,
  amount,
  observation,
  updateNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number;
  }) => void
) => {
  const payload = {
    idBox: Number(idBox),
    idEmployeeMovement: Number(idEmployee),
    idBoxMoveConcept: Number(idBoxMoveConcept),
    idMovementType: Number(idMovementType),
    amount: Number(amount.replace(/[^0-9.-]+/g, "")),
    observation: String(observation),
  };

  if (!payload.idBox || payload.idBox === null) {
    return updateNotification({
      message: "Debe seleccionar una caja",
      status: 400,
      toastTimeDuration: 4000,
    });
  }

  const response: ApiResponse = await createMovement_service(payload);

  if (!response.isSuccess) {
    updateNotification({
      message: response.message,
      status: response?.status,
      toastTimeDuration: 4000,
    });
    return response;
  }
  updateNotification({
    message: "Movimiento creado correctamente",
    status: response?.status,
    toastTimeDuration: 4000,
  });
  return response;
};
