// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oAQXvDJMEbxRDXl13czZ {
  position: relative;
  display: inline-block;
}

.FYrS3nNlq7oDZPkiiaIH {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 150px;
  height: auto;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  padding: 8px;
  background-color: #937cf4;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/Tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,gCAAgC;EAChC,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".tooltipContainer {\n  position: relative;\n  display: inline-block;\n}\n\n.tooltip {\n  position: fixed;\n  top: 40%;\n  left: 50%;\n  width: 150px;\n  height: auto;\n  overflow-y: auto;\n  transform: translate(-50%, -50%);\n  padding: 8px;\n  background-color: #937cf4;\n  color: #fff;\n  border-radius: 4px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  z-index: 1001;\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": `oAQXvDJMEbxRDXl13czZ`,
	"tooltip": `FYrS3nNlq7oDZPkiiaIH`
};
export default ___CSS_LOADER_EXPORT___;
