import { getCategoryClient } from "@Services/clients/getCustomerCategories";
import React, { useEffect, useState } from "react";

export const useClientsCategories = (setStateResponse) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getCategoryClient();
      if (res.isSuccess) {
        const adaptCategories = res.data.map((category) => {
          return {
            value: category.categoryClientId,
            name: category.categoryName,
          };
        });
        setCategories(adaptCategories);
      } else {
        setStateResponse({ message: res.message, status: res.status });
        setCategories([]);
      }
    })();
  }, []);

  return { categories };
};
