import React from "react";
// @ts-ignore
import style from "./NotFoundPage.module.css";
import { HiPuzzlePiece } from "react-icons/hi2";
import Button from "@Atoms/buttons/buttonsWithoutIcons/button";

const NotFoundPage = () => {
  /**
   * Layout used for not found pages in the applications that use
   * micro-frontend and has a basename in the router.
   */
  return (
    <div id="viuti-front-mainContent" className={style.container}>
      <HiPuzzlePiece size={128} color="#CCCCCC" />
      <span className={style.text__container}>
        <h1 className={style.title}>
          Lo sentimos, la página que buscas no existe.
        </h1>
        <p className={style.text}>
          Por favor, verifica la URL que has ingresado.
        </p>
      </span>
      <span className={style.button__container}>
        <Button
          isPrimary
          textBttn="Volver atrás"
          handleClick={() => window.history.back()}
        />
        <Button
          isPrimary={false}
          textBttn="Iniciar sesión"
          handleClick={() => (window.location.href = "/")}
        />
      </span>
    </div>
  );
};

export default NotFoundPage;
