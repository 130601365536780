import React, { useState, useRef, useEffect } from "react";
import style from "./TimeInput.module.css";
import { iconPath } from "../../../../../models/localPath";

interface TimeInputProps {
  label?: string;
  error?: string;
  touched?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  value: string;
  onChange: (value: string) => void;
  showHours?: boolean;
  showMinutes?: boolean;
  showSeconds?: boolean;
  minHour?: number;
  maxHour?: number;
  minMinute?: number;
  maxMinute?: number;
  minSecond?: number;
  maxSecond?: number;
  minuteStep?: number;
  secondStep?: number;
  showPrefix?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({
  label,
  error = "",
  touched = false,
  inputProps = {},
  value,
  onChange,
  showHours = true,
  showMinutes = true,
  showSeconds = false,
  minHour = 0,
  maxHour = 23,
  minMinute = 0,
  maxMinute = 59,
  minSecond = 0,
  maxSecond = 59,
  minuteStep = 1,
  secondStep = 1,
  showPrefix = true,
}) => {
  const [hours, minutes, seconds] = value?.split(":") || ["00", "00", "00"];
  const [openDropdown, setOpenDropdown] = useState<
    "hours" | "minutes" | "seconds" | null
  >(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const generateOptions = (min: number, max: number, step: number = 1) => {
    const options = [];
    for (let i = min; i <= max; i += step) {
      options.push(i.toString().padStart(2, "0"));
    }
    return options;
  };

  const handleTimeSelect = (
    type: "hours" | "minutes" | "seconds",
    value: string
  ) => {
    const timeValues = { hours, minutes, seconds };
    timeValues[type] = value;

    const timeString = `${timeValues.hours}:${timeValues.minutes}${
      showSeconds ? `:${timeValues.seconds}` : ""
    }`;
    onChange(timeString);
    setOpenDropdown(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const renderDropdown = (type: "hours" | "minutes" | "seconds") => {
    const options =
      type === "hours"
        ? generateOptions(minHour, maxHour)
        : type === "minutes"
        ? generateOptions(minMinute, maxMinute, minuteStep)
        : generateOptions(minSecond, maxSecond, secondStep);

    return (
      <div className={style.dropdown}>
        {options.map((option) => (
          <div
            key={option}
            className={`${style.option} ${
              option ===
              (type === "hours"
                ? hours
                : type === "minutes"
                ? minutes
                : seconds)
                ? style.selected
                : ""
            }`}
            onClick={() => handleTimeSelect(type, option)}
          >
            {option}
          </div>
        ))}
      </div>
    );
  };

  const formatTimeValue = (value: string) => {
    const formattedValue = value.padStart(2, "0");
    const isZero = parseInt(value) === 0;
    return { value: formattedValue, isZero };
  };

  return (
    <div
      className={style.inputContainer}
      ref={dropdownRef}
      style={{
        ...inputProps.style,
      }}
    >
      {label && (
        <label className={style.inputLabel}>
          {label}
          {inputProps.required && (
            <span className={style.inputRequired}>*</span>
          )}
        </label>
      )}
      <div
        className={`${style.timePickerContainer} ${
          touched && error ? style.error : ""
        }`}
      >
        {showPrefix && <span className={style.prefix}>HS</span>}
        {showHours && (
          <div className={style.inputWrapper}>
            <div
              className={`${style.timeValue} ${
                openDropdown === "hours" ? style.active : ""
              }`}
              onClick={() => !inputProps.disabled && setOpenDropdown("hours")}
            >
              <span
                className={`${
                  formatTimeValue(hours).isZero ? style.placeholder : ""
                }`}
              >
                {formatTimeValue(hours).value}
              </span>
            </div>
            {openDropdown === "hours" && renderDropdown("hours")}
          </div>
        )}
        {showHours && showMinutes && (
          <span className={style.timeSeparator}>:</span>
        )}
        {showMinutes && (
          <div className={style.inputWrapper}>
            <div
              className={`${style.timeValue} ${
                openDropdown === "minutes" ? style.active : ""
              }`}
              onClick={() => !inputProps.disabled && setOpenDropdown("minutes")}
            >
              <span
                className={`${
                  formatTimeValue(minutes).isZero ? style.placeholder : ""
                }`}
              >
                {formatTimeValue(minutes).value}
              </span>
            </div>
            {openDropdown === "minutes" && renderDropdown("minutes")}
          </div>
        )}
        {showSeconds && (
          <>
            <span className={style.timeSeparator}>:</span>
            <div className={style.inputWrapper}>
              <div
                className={`${style.timeValue} ${
                  openDropdown === "seconds" ? style.active : ""
                }`}
                onClick={() =>
                  !inputProps.disabled && setOpenDropdown("seconds")
                }
              >
                <span
                  className={`${
                    formatTimeValue(seconds).isZero ? style.placeholder : ""
                  }`}
                >
                  {formatTimeValue(seconds).value}
                </span>
              </div>
              {openDropdown === "seconds" && renderDropdown("seconds")}
            </div>
          </>
        )}
      </div>
      {touched && error && (
        <span className={style.errorContainer}>
          <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
          <p className={style.errorMessage}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default TimeInput;
