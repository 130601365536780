import React from "react";
// @ts-ignore
import styled from "./noInfoToShow.module.css";
import { OpenBoxIcon } from "@Models/icons";

interface NoInfoToShowProps {
  title?: string;
  message?: string;
  icon?: string;
  children?: React.ReactNode;
  error?: boolean;
}

const NoInfoToShow: React.FC<NoInfoToShowProps> = ({
  title = "",
  message = "",
  icon = OpenBoxIcon,
  children = null,
  error = false,
}) => {
  return (
    <article className={styled.noInfoToShow}>
      {children ? (
        <figure className={error ? styled.icon_error : styled.icon}>
          {children}
        </figure>
      ) : (
        <img
          width={100}
          height={100}
          src={icon}
          alt={title}
          className={styled.icon}
        />
      )}
      {title && (
        <h3
          className={
            error ? styled.noInfoToShow__h3_error : styled.noInfoToShow__h3
          }
        >
          {title}
        </h3>
      )}
      {message && <p className={styled.noInfoToShow__p}>{message}</p>}
    </article>
  );
};

export default NoInfoToShow;
