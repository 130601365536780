// @ts-ignore
import React, { useContext, useEffect, useState } from "react";
import style from "./SaleMovementsReportView.module.css";
import {
  SelectInput,
  DateInput,
  TableWrapper,
  PaginationWrapper,
  HeaderNavigation,
  ButtonWithoutIcon,
  IconWithOptions,
  Icon,
  TableMobile,
  NoInfoToShow,
  ModalAcceptCancel,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import { FilterIcon, TripleDotsIcon, TwoWaysArrowsIcon } from "@Models/icons";
import useScreen from "@Hooks/useScreen/useScreen";
import { createObjectMovementsMobile } from "./MovementsMobile/functionMovementsMobile";
import { createObject } from "./formatDataTable/formatDataTable";
import { IModalProps } from "@Molecules/ModalAcceptCancel/interface/modalAcceptCancel";
import CreateMovementModal from "./components/CreateMovementModal/CreateMovementModal";
import EditMovementModal from "./components/EditMovementModal/EditMovementModal";
import EditingHistoryModal from "./components/EditingHistoryModal/EditingHistoryModal";
import DeleteMovementModal from "./components/DeleteMovementModal/DeleteMovementModal";

interface FiltersForm {
  idBox: string;
  type: string;
  concept: string;
  startDate: string;
  endDate: string;
  searchParameter: string;
}

interface SaleMovementsReportViewProps {
  filters: FiltersForm;
  setFilters: (filters: FiltersForm) => void;
  initialFilters: FiltersForm;
  setInitialFilters: (initialFilters: FiltersForm) => void;
  AcceptCancelModalContext: any;
  movementConcepts: {
    concepts: { name: string; value: number; movementType: number }[];
    entryConcepts: { name: string; value: string }[];
    outputConcepts: { name: string; value: string }[];
  };
  movementTypes: { name: string; value: string }[];
  reduxSelector: {
    movements: {
      movements: any[];
      pages: number;
      currentPagination: number;
      shouldReloadTable: boolean;
    };
    saleConfig: {
      loungeEmployees: any[];
    };
  };
  onHeaderBackClick: () => void;
  getEmployees: () => Promise<void>;
  getMovements: () => Promise<void>;
  createMovementModalVisibility: {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
  };
  editMovementModalVisibility: {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
  };
  editingHistoryModalVisibility: {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
  };
  deleteMovementModalVisibility: {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
  };
  classCell: (type: string, style: any, isRounded?: boolean) => string;
  currentDate: () => string;
  firstDayOfMonth: () => string;
  boxesInfo: {
    boxes: any[];
    saleBoxId: string;
  };
  securityUuids: {
    EXPORT_MOVEMENTS_UUID: string;
    REGISTER_MOVEMENTS_UUID: string;
  };
  onNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number;
  }) => void;
  getCsvMovementsReport: () => Promise<{
    isSuccessful: boolean;
    data: { base64String: string };
  }>;
  onCurrentPaginationChange: (pagination: number) => void;
  onShouldReloadTableChange: (shouldReloadTable: boolean) => void;
  onConceptCreation: (concept: any) => void;
}

const SaleMovementsReportView = ({
  filters,
  setFilters,
  initialFilters,
  setInitialFilters,
  movementConcepts,
  movementTypes,
  reduxSelector,
  onHeaderBackClick,
  getEmployees,
  getMovements,
  createMovementModalVisibility,
  editMovementModalVisibility,
  editingHistoryModalVisibility,
  deleteMovementModalVisibility,
  classCell,
  currentDate,
  firstDayOfMonth,
  boxesInfo,
  securityUuids,
  onNotification,
  getCsvMovementsReport,
  onCurrentPaginationChange,
  onShouldReloadTableChange,
  onConceptCreation,
}: SaleMovementsReportViewProps) => {
  const {
    isVisible: isCreateMovementVisible,
    setIsVisible: setIsCreateMovementVisible,
  } = createMovementModalVisibility;
  const {
    isVisible: isEditMovementVisible,
    setIsVisible: setIsEditMovementVisible,
  } = editMovementModalVisibility;
  const {
    isVisible: isEditingHistoryVisible,
    setIsVisible: setIsEditingHistoryVisible,
  } = editingHistoryModalVisibility;
  const {
    isVisible: isDeleteMovementVisible,
    setIsVisible: setIsDeleteMovementVisible,
  } = deleteMovementModalVisibility;
  const { concepts, entryConcepts, outputConcepts } = movementConcepts;
  const { movements, pages, currentPagination, shouldReloadTable } =
    reduxSelector.movements;
  const { EXPORT_MOVEMENTS_UUID, REGISTER_MOVEMENTS_UUID } = securityUuids;
  const [buttonsPagination, setButtonsPagination] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [loader, setLoader] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [buttonFilterIsLoading, setButtonFilterIsLoading] = useState(false);
  const [isLoadingMovements, setIsLoadingMovements] = useState(true);
  const [error, setError] = useState({
    endDate: "",
  });
  const screen = useScreen();
  const { boxes, saleBoxId } = boxesInfo;
  const [selectedMovement, setSelectedMovement] = useState(null);
  const [selectedMovementEditingHistory, setSelectedMovementEditingHistory] =
    useState(null);
  const [selectedMovementToDelete, setSelectedMovementToDelete] =
    useState(null);
  // Primera carga de la página, se obtienen los empleados y los movimientos del mes actual
  useEffect(() => {
    (async () => {
      await getEmployees();
      await getMovements();
      setLoader(false);
      setIsLoadingMovements(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Se actualiza la tabla de movimientos y los botones de paginación cada vez que se hace una petición de movimientos
  useEffect(() => {
    setButtonsPagination(pages);
    setDataTable(movements);
  }, [movements, pages]);

  // Se obtienen los movimientos cuando se cambia de página
  useEffect(() => {
    if (!firstRender) {
      (async () => {
        onShouldReloadTableChange(false);
        // Esto evita que se haga la petición de nuevo
        if (shouldReloadTable) {
          return;
        }
        await getMovements();
        setIsLoadingMovements(false);
      })();
    } else {
      setIsLoadingMovements(false);
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination]);

  // Se obtienen los movimientos cuando se presiona el botón de cargar
  const handleLoad = async () => {
    if (filters.startDate > filters.endDate) {
      setError({ ...error, endDate: `Debe ser posterior a fecha inicio.` });
      return;
    }
    setError({ ...error, endDate: "" });
    setIsLoadingMovements(true);
    setButtonFilterIsLoading(true);
    // Si se cambia de página, se actualiza el estado de shouldReloadTable para que no se haga la petición de nuevo
    if (currentPagination !== 1) onShouldReloadTableChange(true);
    await getMovements();
    setButtonFilterIsLoading(false);
    setInitialFilters(filters);
    onCurrentPaginationChange(1);
    setIsLoadingMovements(false);
  };

  // Se renderiza el ícono de opciones
  const renderIconWithOptions = (movement) => {
    const actions = [
      {
        label: "Editar movimiento",
        handler: () => {
          setSelectedMovement(movement);
          setIsEditMovementVisible(true);
        },
      },
      {
        label: "Eliminar movimiento",
        handler: () => {
          setSelectedMovementToDelete(movement);
          setIsDeleteMovementVisible(true);
        },
      },
    ];
    // si el movimiento ha sido modificado, se agrega la opción de ver historial de cambios
    if (movement.modified) {
      actions.unshift({
        label: "Ver historial de edición",
        handler: () => {
          setSelectedMovementEditingHistory(movement);
          setIsEditingHistoryVisible(true);
        },
      });
    }
    return (
      <IconWithOptions
        icon={<Icon path={TripleDotsIcon} size={14} alt={"Tres puntos"} />}
        actions={actions}
      />
    );
  };

  // Se filtran los conceptos según el tipo de movimiento seleccionado
  let filterConcepts = (
    filters.type === "Todos" || filters.type === ""
      ? concepts
      : filters.type === "1"
      ? entryConcepts
      : outputConcepts
  ).map((concept) => ({
    name: concept.name,
    value: concept.value,
  }));

  const showFilter = screen.width >= 900 && screen.height >= 700;
  const showIconFilter = !showFilter;
  const showButtonLoad = !showIconFilter;
  const showButtonRegisterMovement = screen.width > 900;

  const closeFilter = () => {
    if (filters.startDate > filters.endDate) {
      setError({ ...error, endDate: `Debe ser posterior a fecha inicio.` });
      return;
    }
    handleLoad();
    setOpenFilters(false);
  };
  const [isDownloading, setIsDownloading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const otherComponent = showIconFilter ? (
    <div className={style.containerHeader}>
      <button
        onClick={() => {
          setOpenFilters(true);
        }}
        className={style.buttonFilter}
      >
        <Icon path={FilterIcon} size={22} alt={"Filtro"} />
      </button>
      <div className={style.button_with_options}>
        <IconWithOptions
          buttonsStyles={{
            padding: "15px",
          }}
          containerStyles={{
            borderRadius: "6px",
          }}
          icon={
            <span className={style.icon_options}>
              <Icon path={TripleDotsIcon} size={22} alt={"Tres puntos"} />
            </span>
          }
          actions={[
            {
              label: "Registrar movimiento",
              handler: () => {
                const uuidToValidate = REGISTER_MOVEMENTS_UUID;
                const isAccessOk = validateModuleAccess(uuidToValidate);
                if (isAccessOk) setIsCreateMovementVisible(true);
              },
            },
            {
              label: "Reporte",
              handler: async () => {
                const uuidToValidate = EXPORT_MOVEMENTS_UUID;
                const isAccessOk = validateModuleAccess(uuidToValidate);
                if (isAccessOk) {
                  try {
                    // Mostrar estado de carga
                    onNotification({
                      message:
                        "Descarga del reporte en proceso, por favor espera.",
                      status: "pending",
                      toastTimeDuration: 3000,
                    });

                    const response = await getCsvMovementsReport();
                    if (!response.isSuccessful) {
                      onNotification({
                        message:
                          "Error al descargar el reporte. Inténtalo de nuevo.",
                        status: 500,
                        toastTimeDuration: 4000,
                      });

                      return;
                    }

                    const base64Data = response.data?.base64String;
                    const byteCharacters = atob(base64Data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {
                      type: "application/zip",
                    });
                    const url = URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = `Reporte-de-movimientos.zip`;
                    document.body.appendChild(a);
                    a.click();
                    onNotification({
                      message:
                        "Descarga del reporte exitosa, revisa tu carpeta de descargas.",
                      status: 200,
                      toastTimeDuration: 6000,
                    });
                  } catch (error) {
                    onNotification({
                      message:
                        "Error al descargar el reporte. Inténtalo de nuevo.",
                      status: 500,
                      toastTimeDuration: 4000,
                    });
                  }
                }
              },
            },
          ]}
        />
      </div>
    </div>
  ) : (
    <div className={style.containerHeader}>
      <ButtonWithoutIcon
        textBttn={isDownloading ? "Descargando..." : "Reporte"}
        handleClick={async () => {
          const uuidToValidate = EXPORT_MOVEMENTS_UUID;
          const isAccessOk = validateModuleAccess(uuidToValidate);
          if (isAccessOk) {
            try {
              setIsDownloading(true);
              // Mostrar estado de carga

              onNotification({
                message: "Descarga del reporte en proceso, por favor espera.",
                status: "pending",
                toastTimeDuration: 3000,
              });

              const response = await getCsvMovementsReport();

              if (!response.isSuccessful) {
                onNotification({
                  message: "Error al descargar el reporte. Inténtalo de nuevo.",
                  status: 500,
                  toastTimeDuration: 4000,
                });

                return;
              }

              const base64Data = response.data?.base64String;
              const byteCharacters = atob(base64Data);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: "application/zip" });
              const url = URL.createObjectURL(blob);

              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = `Reporte-de-movimientos.zip`;
              document.body.appendChild(a);
              a.click();

              onNotification({
                message:
                  "Descarga del reporte exitosa, revisa tu carpeta de descargas.",
                status: 200,
                toastTimeDuration: 6000,
              });
            } catch (error) {
              onNotification({
                message: "Error al descargar el reporte. Inténtalo de nuevo.",
                status: 500,
                toastTimeDuration: 4000,
              });
            } finally {
              setIsDownloading(false);
            }
          }
        }}
        isPrimary={true}
        isDisabled={isDownloading}
        isLoading={isDownloading}
      />
    </div>
  );

  const headerProps = () => {
    if (showButtonRegisterMovement) {
      return {
        title: "Movimientos",
        previousAction: onHeaderBackClick,
        previousActionMovement: "back",
        buttonProps: {
          textBttn: "Reporte",
          handleClick: () => {},
          isDisabled: false,
          isHidden: true,
          isPrimary: true,
          isLoading: false,
        },
        buttonTwoProps: {
          textBttn: "Registrar movimiento",
          handleClick: () => {
            const uuidToValidate = REGISTER_MOVEMENTS_UUID;
            const isAccessOk = validateModuleAccess(uuidToValidate);
            if (isAccessOk) setIsCreateMovementVisible(true);
          },
          isDisabled: false,
          isHidden: false,
          isPrimary: false,
          isLoading: false,
        },
        otherComponent: otherComponent,
      };
    } else {
      return {
        title: "Movimientos",
        previousAction: onHeaderBackClick,
        previousActionMovement: "back",
        buttonProps: {
          textBttn: "Reporte",
          handleClick: () => {},
          isDisabled: false,
          isHidden: true,
          isPrimary: true,
          isLoading: false,
        },
        otherComponent: otherComponent,
      };
    }
  };
  const titles = (screenWidth) => {
    const titles = [
      "Tipo de mov.",
      "Nombre de la caja",
      "Fecha",
      "Hora",
      "Concepto",
      "Observaciones",
      "Nombre de empleado",
      "Monto",
      "-",
    ];
    if (screenWidth < 1300) {
      titles.splice(1, 1);
    }
    if (screenWidth < 1100) {
      titles.splice(2, 1);
    }
    return titles;
  };

  return (
    <>
      <div id="viuti-front-mainContent">
        <HeaderNavigation {...headerProps()} />
        <div className={style.body}>
          {openFilters && (
            <ModalAcceptCancel
              title={"Filtros"}
              showButtons={{
                showButtonTwo: true,
                showButtonClose: true,
              }}
              buttonTwo={{
                textButtonTwo: "Cargar",
                actionButtonTwo: () => {
                  closeFilter();
                },
              }}
              actionButtonClose={() => setOpenFilters(false)}
            >
              <div className={style.containerFilter}>
                <header className={style.headerfilter}>
                  <div className={style.inputContainer}>
                    <SelectInput
                      value={filters.idBox}
                      items={[
                        {
                          name: "Todas",
                          value: "Todas",
                        },
                        ...boxes,
                      ]}
                      handleChange={(e) =>
                        setFilters({ ...filters, idBox: e.target.value })
                      }
                      name="position"
                      placeholder="Caja"
                    />
                  </div>
                  <div className={style.inputContainer}>
                    <SelectInput
                      value={filters.type}
                      items={[
                        { name: "Todos", value: "Todos" },
                        ...movementTypes,
                      ]}
                      handleChange={(e) =>
                        setFilters({
                          ...filters,
                          type: e.target.value,
                          concept: "",
                        })
                      }
                      name="position"
                      placeholder="Tipo de movimiento"
                    />
                  </div>
                  <div className={style.inputContainer}>
                    <SelectInput
                      value={filters.concept}
                      items={[
                        {
                          name: "Todos",
                          value: "Todos",
                        },
                        ...filterConcepts,
                      ]}
                      handleChange={(e) =>
                        setFilters({ ...filters, concept: e.target.value })
                      }
                      name="position"
                      placeholder="Concepto"
                    />
                  </div>
                  <div className={style.inputContainer}>
                    <DateInput
                      inputProps={{
                        placeholder: "Seleccionar fecha",
                        value: filters.startDate,
                        onChange: (e) =>
                          setFilters({ ...filters, startDate: e.target.value }),
                      }}
                    />
                  </div>
                  <div className={style.inputContainer}>
                    <DateInput
                      inputProps={{
                        placeholder: "Seleccionar fecha",
                        value: filters.endDate,
                        onChange: (e) =>
                          setFilters({ ...filters, endDate: e.target.value }),
                      }}
                      error={error.endDate}
                      touched={true}
                    />
                  </div>
                </header>
              </div>
            </ModalAcceptCancel>
          )}

          {showFilter && (
            <header className={style.headerfilter}>
              <div className={style.inputContainer}>
                <SelectInput
                  label="Caja"
                  value={filters.idBox}
                  items={[
                    {
                      name: "Todas",
                      value: "Todas",
                    },
                    ...boxes,
                  ]}
                  handleChange={(e) =>
                    setFilters({ ...filters, idBox: e.target.value })
                  }
                  name="position"
                />
              </div>
              <div className={style.inputContainer}>
                <SelectInput
                  label="Tipo de movimiento"
                  value={filters.type}
                  items={[{ name: "Todos", value: "Todos" }, ...movementTypes]}
                  handleChange={(e) =>
                    setFilters({
                      ...filters,
                      type: e.target.value,
                      concept: "",
                    })
                  }
                  name="position"
                />
              </div>
              <div className={style.inputContainer}>
                <SelectInput
                  label="Concepto"
                  value={filters.concept}
                  items={[
                    {
                      name: "Todos",
                      value: "Todos",
                    },
                    ...filterConcepts,
                  ]}
                  handleChange={(e) =>
                    setFilters({ ...filters, concept: e.target.value })
                  }
                  name="position"
                />
              </div>
              <div className={style.dateContainer}>
                <div className={style.inputContainer}>
                  <DateInput
                    label="Fecha de inicio"
                    inputProps={{
                      placeholder: "Seleccionar fecha",
                      value: filters.startDate,
                      onChange: (e) =>
                        setFilters({ ...filters, startDate: e.target.value }),
                    }}
                  />
                </div>
                <div className={style.inputContainer}>
                  <DateInput
                    label="Fecha de fin"
                    inputProps={{
                      placeholder: "Seleccionar fecha",
                      value: filters.endDate,
                      onChange: (e) =>
                        setFilters({ ...filters, endDate: e.target.value }),
                    }}
                    error={error.endDate}
                    touched={true}
                  />
                </div>
                {showButtonLoad && (
                  <ButtonWithoutIcon
                    textBttn={"Cargar"}
                    handleClick={handleLoad}
                    isLoading={buttonFilterIsLoading}
                    isPrimary={false}
                  />
                )}
              </div>
            </header>
          )}
          {dataTable?.length === 0 && !loader ? (
            <section>
              <NoInfoToShow
                title="No se encontraron movimientos."
                message="Intenta con otros filtros o verifica que existan movimientos en el rango de fechas seleccionado."
                icon={TwoWaysArrowsIcon}
              />
            </section>
          ) : screen.width >= 900 ? (
            <TableWrapper
              titles={titles(screen.width)}
              data={dataTable?.map((movement_) => ({
                ...createObject(
                  movement_,
                  screen.width,
                  renderIconWithOptions,
                  classCell
                ),
              }))}
              isLoading={isLoadingMovements || loader}
              containerStyle={{
                height: "min-content",
              }}
            />
          ) : (
            <div
              style={{
                paddingBottom: dataTable?.length < 10 ? "" : "50px",
              }}
            >
              <TableMobile
                isLoading={isLoadingMovements || loader}
                dataTable={dataTable?.map((movement) => ({
                  ...createObjectMovementsMobile(
                    movement,
                    classCell,
                    setIsEditMovementVisible,
                    setSelectedMovement,
                    setIsEditingHistoryVisible,
                    setSelectedMovementEditingHistory,
                    setIsDeleteMovementVisible,
                    setSelectedMovementToDelete
                  ),
                }))}
              />
            </div>
          )}
          {buttonsPagination > 1 && (
            <PaginationWrapper
              currentPagination={currentPagination}
              setCurrentPagination={(e) => {
                setIsLoadingMovements(true);
                onCurrentPaginationChange(e);
              }}
              optionsPerPage={10}
              numberOfButtons={buttonsPagination}
            />
          )}
        </div>
      </div>
      {isCreateMovementVisible && (
        <CreateMovementModal
          setFormFilter={setFilters}
          setInitialForm={setInitialFilters}
          isVisible={isCreateMovementVisible}
          setIsVisible={setIsCreateMovementVisible}
          boxId={filters.idBox}
          boxes={boxes}
          saleBoxId={saleBoxId}
          currentDate={currentDate}
          firstDayOfMonth={firstDayOfMonth}
          reduxSelector={reduxSelector}
          getMovements={getMovements}
          onNotification={onNotification}
          onCurrentPaginationChange={onCurrentPaginationChange}
          onShouldReloadTableChange={onShouldReloadTableChange}
          movementConcepts={movementConcepts}
          onConceptCreation={onConceptCreation}
        />
      )}
      {isEditMovementVisible && (
        <EditMovementModal
          movement={selectedMovement}
          onClose={() => {
            setIsEditMovementVisible(false);
            setSelectedMovement(null);
          }}
          formFilter={filters}
          movementConcepts={movementConcepts}
          reduxSelector={reduxSelector}
          getMovements={getMovements}
          movementTypes={movementTypes}
          onNotification={onNotification}
          onConceptCreation={onConceptCreation}
          isVisible={isEditMovementVisible}
          setIsVisible={setIsEditMovementVisible}
        />
      )}
      {isEditingHistoryVisible && (
        <EditingHistoryModal
          isVisible={isEditingHistoryVisible}
          setIsVisible={setIsEditingHistoryVisible}
          movement={selectedMovementEditingHistory}
          onNotification={onNotification}
        />
      )}
      {isDeleteMovementVisible && (
        <DeleteMovementModal
          isVisible={isDeleteMovementVisible}
          setIsVisible={setIsDeleteMovementVisible}
          movement={selectedMovementToDelete}
          currentPagination={currentPagination}
          getMovements={getMovements}
          initialForm={initialFilters}
          onCurrentPaginationChange={onCurrentPaginationChange}
          onShouldReloadTableChange={onShouldReloadTableChange}
          onNotification={onNotification}
        />
      )}
    </>
  );
};

export default SaleMovementsReportView;
