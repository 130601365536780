// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uzPca12wrG_1B5_ugx9G {
  display: flex;
  flex-direction: column;
  max-width: 730px;
  min-width: 150px;
}

.uzPca12wrG_1B5_ugx9G label {
  color: #4d4d4d;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.241px;
  margin-bottom: 8px;
}

.uzPca12wrG_1B5_ugx9G .EjuTLqnU2pVAWOZauolo {
  display: flex;
  position: relative;
}

.uzPca12wrG_1B5_ugx9G input {
  width: 100%;
  border: none;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 40px;
  align-self: stretch;
  height: 40px;
  color: #333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.uzPca12wrG_1B5_ugx9G img {
  position: absolute;
  right: 93%;
  top: 50%;
  transform: translateY(-50%);
}
.skhX7t3cUpSx9ei4oROx {
  font-family: Mulish;
  color: red;
  font-size: 12px;
  margin-left: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/inputs/searchInput/SearchInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,+CAA+C;EAC/C,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  max-width: 730px;\n  min-width: 150px;\n}\n\n.container label {\n  color: #4d4d4d;\n  font-family: Mulish;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 0.241px;\n  margin-bottom: 8px;\n}\n\n.container .inputContainer {\n  display: flex;\n  position: relative;\n}\n\n.container input {\n  width: 100%;\n  border: none;\n  border-radius: 4px;\n  background: #fff;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n  padding: 5px 40px;\n  align-self: stretch;\n  height: 40px;\n  color: #333;\n  font-family: Mulish;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.container img {\n  position: absolute;\n  right: 93%;\n  top: 50%;\n  transform: translateY(-50%);\n}\n.inputRequired {\n  font-family: Mulish;\n  color: red;\n  font-size: 12px;\n  margin-left: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `uzPca12wrG_1B5_ugx9G`,
	"inputContainer": `EjuTLqnU2pVAWOZauolo`,
	"inputRequired": `skhX7t3cUpSx9ei4oROx`
};
export default ___CSS_LOADER_EXPORT___;
