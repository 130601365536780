import React, { useEffect, useState } from "react";
import { getEndSchedulesList } from "../functions/getEndScheduleList";
import { getLastEndSchedule } from "../functions/getLastEndSchedule";
import { getLastSchedule } from "../functions/getLastSchedule";
import { getSchedulesList } from "../functions/getSchedulesList";
import { isTimeValid } from "../functions/isTimeValid";
//@ts-ignore
import style from "../style/ScheduleDayRow.module.css";
import {
  addNewSchedule,
  deleteSchedule,
  handleScheduleAvailabilityChange,
  handleScheduleChange,
} from "../functions/handleScheduleChange";
import SelectInput from "@Atoms/inputs/selectInput/SelectInput";
import SwitchInput from "@Atoms/inputs/switchInput/SwitchInput";
import { AddIcon, MinusIcon } from "@Models/icons";
// @ts-ignore
import Icon from "@Atoms/Icon/Icon";

interface IScheduleRow {
  dayData: any;
  currentSchedule: any;
  currentScheduleIndex: number;
  scheduleState: any;
  setScheduleState: any;
  scheduleActions: any;
}

const ScheduleRow = ({
  dayData,
  currentSchedule,
  currentScheduleIndex,
  scheduleState,
  setScheduleState,
  scheduleActions,
}: IScheduleRow) => {
  const { isAvailable, startTime, endTime } = currentSchedule;
  const { scheduleList, day, idDay } = dayData;
  const { onDelete } = scheduleActions;
  const TABLET_BREAKPOINT = 552;
  const MOBILE_BREAKPOINT = 526;

  const scheduleRef = React.useRef(null);
  const [scheduleDimensions, setScheduleDimensions] = useState({
    width: 0,
    height: 0,
  });

  const scheduleOptions = getSchedulesList(
    getLastSchedule(scheduleList, currentScheduleIndex)
  );
  const endScheduleOptions = getEndSchedulesList(
    getLastEndSchedule(currentSchedule)
  );

  const isAddButtonDisabled = () => {
    if (scheduleList.length < 2) {
      if (currentScheduleIndex === 0) {
        return (
          isTimeValid(scheduleOptions, startTime) === "" ||
          isTimeValid(endScheduleOptions, endTime) === "" ||
          !isAvailable
        );
      } else {
        const prevSchedule = scheduleList[currentScheduleIndex - 1];

        return (
          !prevSchedule.isAvailable ||
          prevSchedule.startTime === "" ||
          prevSchedule.endTime === "" ||
          currentSchedule.startTime === "" ||
          currentSchedule.endTime === ""
        );
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    isAddButtonDisabled();
  }, [startTime, endTime, isAvailable]);

  // detectar cuando se cambia el estado de scheduleOptions y si es "" cambiar el estado de startTime y endTime a ""
  useEffect(() => {
    if (!scheduleOptions.includes(startTime) && startTime !== "") {
      const editedSchedules = [
        ...scheduleState.map((day, index) =>
          index === idDay
            ? {
                ...day,
                scheduleList: scheduleList.map((schedule, index) =>
                  index === currentScheduleIndex
                    ? {
                        ...schedule,
                        startTime: "",
                        endTime: "",
                      }
                    : schedule
                ),
              }
            : day
        ),
      ];
      setScheduleState(editedSchedules);
    }
  }, [scheduleOptions]);

  // detectar cuando se cambia el estado de endScheduleOptions y si es "" cambiar el estado de startTime y endTime a ""
  useEffect(() => {
    if (!endScheduleOptions.includes(endTime) && endTime !== "") {
      const editedSchedules = [
        ...scheduleState.map((day, index) =>
          index === idDay
            ? {
                ...day,
                scheduleList: scheduleList.map((schedule, index) =>
                  index === currentScheduleIndex
                    ? {
                        ...schedule,
                        endTime: "",
                      }
                    : schedule
                ),
              }
            : day
        ),
      ];
      setScheduleState(editedSchedules);
    }
  }, [endScheduleOptions]);

  // detectar cuando se modifica el tamaño de pantalla y cambiar el tamaño del contenedor de scheduleDimensions
  const handleResize = () => {
    if (scheduleRef.current) {
      setScheduleDimensions({
        width: scheduleRef.current.offsetWidth,
        height: scheduleRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", () => handleResize());

    return () => {
      window.removeEventListener("resize", () => handleResize());
    };
  }, [scheduleRef.current]);

  const isMobile = scheduleDimensions.width < MOBILE_BREAKPOINT;

  return (
    <div
      className={`${style.scheduleRowContainer} ${
        !isAvailable ? style.scheduleRowContainerDisabled : ""
      }`}
      ref={scheduleRef}
    >
      <p className={style.scheduleDay}>{currentScheduleIndex === 0 && day}</p>

      <span className={style.switchInputContainer}>
        <SwitchInput
          value={isAvailable}
          //@ts-ignore
          handleChange={(e) =>
            handleScheduleAvailabilityChange(
              e,
              dayData,
              scheduleList,
              currentScheduleIndex,
              scheduleState,
              setScheduleState
            )
          }
          name="isAvailable"
        />
      </span>
      {!isMobile && <p className={style.scheduleLabel}>Desde</p>}
      <div
        className={
          !isMobile
            ? style.scheduleInputContainer
            : style.scheduleInputContainerMobile
        }
      >
        <SelectInput
          value={isTimeValid(scheduleOptions, startTime)}
          label={isMobile ? "Desde" : ""}
          handleChange={(e) => {
            handleScheduleChange(
              e,
              dayData,
              scheduleList,
              currentScheduleIndex,
              scheduleState,
              setScheduleState
            );
          }}
          name={"startTime"}
          items={scheduleOptions}
          disabled={!isAvailable}
        />
      </div>
      {scheduleDimensions.width > TABLET_BREAKPOINT ? (
        <p className={style.scheduleLabel}>Hasta</p>
      ) : (
        !isMobile && <p className={style.scheduleLabel}>a</p>
      )}
      <div
        className={
          !isMobile
            ? style.scheduleInputContainer
            : style.scheduleInputContainerMobile
        }
      >
        <SelectInput
          value={isTimeValid(endScheduleOptions, endTime)}
          label={isMobile ? "Hasta" : ""}
          handleChange={(e) => {
            handleScheduleChange(
              e,
              dayData,
              scheduleList,
              currentScheduleIndex,
              scheduleState,
              setScheduleState
            );
          }}
          name={"endTime"}
          items={endScheduleOptions}
          disabled={!isAvailable}
        />
      </div>
      {currentScheduleIndex === 0 ? (
        <button
          className={`${style.addScheduleContainer} ${
            isAddButtonDisabled() ? style.addScheduleContainerDisabled : ""
          }`}
          disabled={isAddButtonDisabled()}
          onClick={() =>
            addNewSchedule(
              dayData,
              scheduleList,
              scheduleState,
              setScheduleState
            )
          }
        >
          <Icon path={AddIcon} color="#fff" size={14} alt="+" />
        </button>
      ) : (
        <button
          className={`${style.addScheduleContainer} ${
            !isAvailable ? style.addScheduleContainerDisabled : ""
          }`}
          onClick={() =>
            deleteSchedule(
              dayData,
              scheduleList,
              currentScheduleIndex,
              scheduleState,
              setScheduleState,
              onDelete
            )
          }
        >
          <Icon path={MinusIcon} color="#fff" size={14} alt="+" />
        </button>
      )}
    </div>
  );
};

export default ScheduleRow;
