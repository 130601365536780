import React, { useEffect } from "react";
//@ts-ignore
import style from "./ValidationInput.module.css";
import { CircleAlertIcon, ValidatedIcon, ValidatingIcon } from "@Models/icons";

const ValidationInput = ({
  label,
  name,
  value = "",
  handleChange,
  placeholder,
  maxLength,
  width = "",
  status,
  disabled,
}) => {
  const borderColorInputDocument = () => {
    if (disabled) {
      return "1px solid #D9D9D9";
    }
    if (status.error) {
      return "1px solid #FF4D4F";
    }
    if (status.validated) {
      return "1px solid #00B780";
    }
    if (status.loading) {
      return "1px solid #B4A5F3";
    }
    return "1px solid #D9D9D9";
  };

  return (
    <div className={style.inputContainer} style={{ width: width }}>
      {label && (
        <label className={style.inputLabel}>
          <p>{label}</p>
          <p className={style.inputRequired}>*</p>
        </label>
      )}
      <div className={style.inputContent}>
        <input
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          type="text"
          max={maxLength}
          className={style.inputText}
          style={{
            border: borderColorInputDocument(),
          }}
          disabled={disabled}
        />
      </div>
      {status.error && (
        <span className={style.inputContainerMessage}>
          <img src={CircleAlertIcon} alt="Alerta" />
          <p className={style.inputErrorMessage}>{status.message}</p>
        </span>
      )}
      {status.validated && (
        <span className={style.inputContainerMessage}>
          <img src={ValidatedIcon} alt="Validado" />
          <p className={style.inputValidatedMessage}>{status.message}</p>
        </span>
      )}
      {status.loading && (
        <span className={style.inputContainerMessage}>
          <img
            src={ValidatingIcon}
            alt="Validando"
            className={style.validatingSvg}
          />
          <p className={style.inputValidatingMessage}>{status.message}</p>
        </span>
      )}
    </div>
  );
};

export default ValidationInput;
