import { CashRegisterBaseUrl } from "@Models/services/constGlobals";
import { getData } from "@Services/constServices";

export const getSalesDocumentDetail_service = async (
  saleDocumentId: string,
  saleBoxId: string
) => {
  const url = `${CashRegisterBaseUrl}/SalesDocuments/SalesDocumentDetail?saleDocumentId=${saleDocumentId}&saleBoxId=${saleBoxId}`;
  return getData(url);
};
