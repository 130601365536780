// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /*PRIMARY*/
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE: #1a0063;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-25: #1a006340;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-50: #1a006380;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-75: #1a0063bf;

  /*DARK ON BASEPLATE*/
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE: #f4eff4;
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-25: #f4eff425;
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-50: #f4eff480;
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-75: #f4eff4bf;

  /* LIGHT BASEPLATE */
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE: #fefbff;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-25: #fefbff40;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-50: #fefbff80;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-75: #fefbffbf;
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables/colors.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,wDAAwD;EACxD,6DAA6D;EAC7D,6DAA6D;EAC7D,6DAA6D;;EAE7D,oBAAoB;EACpB,uDAAuD;EACvD,4DAA4D;EAC5D,4DAA4D;EAC5D,4DAA4D;;EAE5D,oBAAoB;EACpB,qDAAqD;EACrD,0DAA0D;EAC1D,0DAA0D;EAC1D,0DAA0D;AAC5D","sourcesContent":[":root {\n  /*PRIMARY*/\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE: #1a0063;\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-25: #1a006340;\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-50: #1a006380;\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-75: #1a0063bf;\n\n  /*DARK ON BASEPLATE*/\n  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE: #f4eff4;\n  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-25: #f4eff425;\n  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-50: #f4eff480;\n  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-75: #f4eff4bf;\n\n  /* LIGHT BASEPLATE */\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE: #fefbff;\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-25: #fefbff40;\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-50: #fefbff80;\n  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-75: #fefbffbf;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
