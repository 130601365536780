// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yWt5A6nHxUQ6DQiAbtFY {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  & div {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/FormReservation/components/AppointmentDetail/ServicePriceDuration/ServicePriceDuration.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,eAAe;;EAEf;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;AACF","sourcesContent":[".servicePriceDuration {\n  display: flex;\n  gap: 0.5rem;\n  flex-wrap: wrap;\n\n  & div {\n    display: flex;\n    flex-direction: row;\n    gap: 0.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"servicePriceDuration": `yWt5A6nHxUQ6DQiAbtFY`
};
export default ___CSS_LOADER_EXPORT___;
