import { ApiResponse } from "@Services/constServices";
import { deleteMovement_service } from "@Services/saleboxes/movements";

export const deleteMovement = async (
  idMovement: number,
  onNotification: (notification: any) => void
) => {
  const response: ApiResponse = await deleteMovement_service(idMovement);

  if (!response.isSuccess) {
    onNotification({
      message: response.message,
      status: response.status as any,
      toastTimeDuration: 4000,
    });
    return response;
  }
  onNotification({
    message: "Movimiento eliminado correctamente",
    status: response.status,
    toastTimeDuration: 4000,
  });
  return response;
};
