import { getSaleDetails_adapter } from "@Adapters/getSaleDetails.adapter";
import { DataSaleDetails } from "@Models/interfaces/GetSaleDetailsAdapter";
import React, { useEffect, useState } from "react";

const useSaleDetails = (
  saleDocumentId,
  saleBoxId,
  setLoader,
  setStateResponse
) => {
  const [saleDetails, setSaleDetails] = useState<DataSaleDetails>({
    saleDetails: [],
    paymentMethod: [],
    change: 0,
  });

  useEffect(() => {
    (async () => {
      if (!saleDocumentId || !saleBoxId) return;
      const response = await getSaleDetails_adapter(
        saleDocumentId,
        saleBoxId,
        setLoader
      );
      if (!response.isSuccess) {
        setStateResponse({
          message: response.message,
          status: response.status,
        });
        return;
      } else {
        setSaleDetails(response.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleDocumentId]);

  return { saleDetails };
};

export default useSaleDetails;
