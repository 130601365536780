// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --Z-INDEX-0: 0;
  --Z-INDEX-20: 20;
  --Z-INDEX-40: 40;
  --Z-INDEX-60: 60;
  --Z-INDEX-80: 80;
  --Z-INDEX-100: 100;
  --Z-INDEX-120: 120;
  --Z-INDEX-140: 140;
  --Z-INDEX-160: 160;
  --Z-INDEX-180: 180;
  --Z-INDEX-200: 200;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global/zindex.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[":root {\n  --Z-INDEX-0: 0;\n  --Z-INDEX-20: 20;\n  --Z-INDEX-40: 40;\n  --Z-INDEX-60: 60;\n  --Z-INDEX-80: 80;\n  --Z-INDEX-100: 100;\n  --Z-INDEX-120: 120;\n  --Z-INDEX-140: 140;\n  --Z-INDEX-160: 160;\n  --Z-INDEX-180: 180;\n  --Z-INDEX-200: 200;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
