// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NXSxjOgaTN6n5zPAHvCd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.EjC8U2EIGnycqFOggYgv {
  color: #45348e;
  font: 700 1.1rem "Mulish", sans-serif;
}

.sZP7dxT7AA6O2XYn3i0T {
  color: #484848;
  font: 400 0.9rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ViewMessage/ViewMessage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,cAAc;EACd,qCAAqC;AACvC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  gap: 8px;\n}\n\n.title {\n  color: #45348e;\n  font: 700 1.1rem \"Mulish\", sans-serif;\n}\n\n.message {\n  color: #484848;\n  font: 400 0.9rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NXSxjOgaTN6n5zPAHvCd`,
	"title": `EjC8U2EIGnycqFOggYgv`,
	"message": `sZP7dxT7AA6O2XYn3i0T`
};
export default ___CSS_LOADER_EXPORT___;
