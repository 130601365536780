// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K_6ErZArrAuFVMe2spZj {
  border: none;
  background-color: transparent;
  white-space: nowrap;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  padding: 24px 24px;
  color: #45348e;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 500;
}

.K_6ErZArrAuFVMe2spZj:hover {
  cursor: pointer;
  background: #e5dfff;
}

.fPHQoww5KELqPB8XtosQ {
  display: flex;
  width: 25px;
  height: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/selectWrapper/component/item.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,SAAS;EACT,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd","sourcesContent":[".selectWrapper__menu__item {\n  border: none;\n  background-color: transparent;\n  white-space: nowrap;\n  display: flex;\n  gap: 20px;\n  justify-content: flex-start;\n  align-items: center;\n  height: 36px;\n  padding: 24px 24px;\n  color: #45348e;\n  font-family: Mulish;\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.selectWrapper__menu__item:hover {\n  cursor: pointer;\n  background: #e5dfff;\n}\n\n.selectWrapper__menu__item__svg {\n  display: flex;\n  width: 25px;\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWrapper__menu__item": `K_6ErZArrAuFVMe2spZj`,
	"selectWrapper__menu__item__svg": `fPHQoww5KELqPB8XtosQ`
};
export default ___CSS_LOADER_EXPORT___;
