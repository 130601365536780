import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./ModalSaleDetails.module.css";
// @ts-ignore
import { SlideNotification, ModalAcceptCancel } from "@viuti/recursos";
import SalesDocumentDetailTable from "./SalesDetailsTable/SalesDetailsTable";
// import convertStringToNumber from "@Utilities/convertStringToNumber";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { capitalizeWords } from "@Utilities/CapitalizeWords";
import useSaleDetails from "./hook/useSaleDetails";
import convertStringToNumber from "@Utilities/convertStringToNumber";

const ModalSaleDetails = ({
  saleDocumentId,
  saleBoxId,
  onClose,
  isVisible,
}) => {
  if (!isVisible) return null;
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [loader, setLoader] = useState(true);
  const { saleDetails } = useSaleDetails(
    saleDocumentId,
    saleBoxId,
    setLoader,
    setStateResponse
  );

  return (
    <ModalAcceptCancel
      title="Detalle de venta"
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
      actionButtonClose={() => onClose()}
    >
      <div className={style.containerTicket}>
        {loader ? (
          <h1>CARGANDO...</h1>
        ) : (
          <>
            <section className={style.header}>
              <p className={style.type}>Documento</p>
              <p>{/* {document.type} {document.series}-{document.number} */}</p>
              <p className={style.date}>Cliente</p>
              {/* <p>{document.client}</p> */}
              <p>{"Cliente de prueba"}</p>
              <p>
                {/* {document.clientDocumentName} {document.clientDocumentNumber} */}
                {"DNI 12345678"}
              </p>
              <p className={style.date}>Fecha y hora de emisión</p>
              {/* <p>{document.date}</p> */}
              <p>{"05-07-2024 04:00PM"}</p>
            </section>
            <SalesDocumentDetailTable
              saleDocumentDetails={saleDetails.saleDetails}
            />
            <div className={style.fourth_container}>
              <div className={style.flex}>
                <p>Subtotal</p>
                {/* <p>{formatToSoles(convertStringToNumber(document.subTotal))}</p> */}
                {"S/ 3.28"}
              </div>
              <div className={style.flex}>
                <p>IGV(18%)</p>
                {/* <p>{formatToSoles(convertStringToNumber(document.igv))}</p> */}
                {"S/ 0.72"}
              </div>
            </div>
            <div className={style.flex}>
              <h4>Total</h4>
              {/* <h4>{formatToSoles(convertStringToNumber(document.total))}</h4> */}
              <h4>{"S/ 4.00"}</h4>
            </div>
            <div className={style.payment_container}>
              {saleDetails.paymentMethod?.map((payment, index) => (
                <div
                  className={`${style.flex} ${style.amountAndPaymentMethod}`}
                  key={index + payment.methodPayment}
                >
                  <p>{capitalizeWords(payment.methodPayment)}</p>
                  <p>{formatToSoles(payment.amount)}</p>
                </div>
              ))}
              {saleDetails.change !== 0 && (
                <div className={`${style.containerChange}`}>
                  <h4 className={style.change}>Vuelto</h4>
                  <h4 className={style.change}>
                    {formatToSoles(saleDetails.change)}
                  </h4>
                </div>
              )}
            </div>
          </>
        )}
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    </ModalAcceptCancel>
  );
};

export default ModalSaleDetails;
