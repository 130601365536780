// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aDz1UiLa8jHsiGZzB6Gw {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.iD514lKAedwHyWZy3R2p {
  justify-content: flex-start;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/SchedulesWrapper/styles/SchedulesWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,uBAAuB;AACzB","sourcesContent":[".schedulesWrapperContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  gap: 20px;\n}\n\n.schedulesWrapperContainerMobile {\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedulesWrapperContainer": `aDz1UiLa8jHsiGZzB6Gw`,
	"schedulesWrapperContainerMobile": `iD514lKAedwHyWZy3R2p`
};
export default ___CSS_LOADER_EXPORT___;
