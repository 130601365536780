// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dw51nn3s_xZCD5URR4r3 {
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s_lG5AQaTgRtfBnt3LuD {
  display: inline-block;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  max-height: 35px;
  transition: all 200ms;
  margin: 0;
}

.yaqUsWcUw2jxDZe4jqqX {
  background-color: #dad1f4;
}

.s_lG5AQaTgRtfBnt3LuD::after {
  position: absolute;
  content: "";
  width: auto;
  height: calc(100% - 4px);
  background-color: rgba(128, 128, 128, 0.74);
  border-radius: 100px;

  left: 2px;
  top: 0;
  bottom: 0;
  margin: auto;

  aspect-ratio: 1 / 1;
  transition: all 200ms;
}

.DR8lIEFAXqlkLAvZiy5w:checked ~ .s_lG5AQaTgRtfBnt3LuD::after {
  background-color: #00de66;
  left: 21px;
}

.DR8lIEFAXqlkLAvZiy5w:not(:checked) ~ .s_lG5AQaTgRtfBnt3LuD::after {
  background-color: #937cf4;
}

.DR8lIEFAXqlkLAvZiy5w:checked .s_lG5AQaTgRtfBnt3LuD {
  background-color: beige;
}

.DR8lIEFAXqlkLAvZiy5w:disabled ~ .s_lG5AQaTgRtfBnt3LuD {
  cursor: not-allowed;

  &::after {
    background-color: #bababa;
  }
}

.DR8lIEFAXqlkLAvZiy5w {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/inputs/switchInput/switchInput.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,wBAAwB;EACxB,2CAA2C;EAC3C,oBAAoB;;EAEpB,SAAS;EACT,MAAM;EACN,SAAS;EACT,YAAY;;EAEZ,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;;EAEnB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;AACf","sourcesContent":[".buttonCheck {\n  position: relative;\n  height: fit-content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.lblSwitch {\n  display: inline-block;\n  border: 1px solid #d6d6d6;\n  background-color: #ffffff;\n  border-radius: 100px;\n  position: relative;\n  cursor: pointer;\n  max-height: 35px;\n  transition: all 200ms;\n  margin: 0;\n}\n\n.uncheckedSwitch {\n  background-color: #dad1f4;\n}\n\n.lblSwitch::after {\n  position: absolute;\n  content: \"\";\n  width: auto;\n  height: calc(100% - 4px);\n  background-color: rgba(128, 128, 128, 0.74);\n  border-radius: 100px;\n\n  left: 2px;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n\n  aspect-ratio: 1 / 1;\n  transition: all 200ms;\n}\n\n.btnSwitch:checked ~ .lblSwitch::after {\n  background-color: #00de66;\n  left: 21px;\n}\n\n.btnSwitch:not(:checked) ~ .lblSwitch::after {\n  background-color: #937cf4;\n}\n\n.btnSwitch:checked .lblSwitch {\n  background-color: beige;\n}\n\n.btnSwitch:disabled ~ .lblSwitch {\n  cursor: not-allowed;\n\n  &::after {\n    background-color: #bababa;\n  }\n}\n\n.btnSwitch {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonCheck": `Dw51nn3s_xZCD5URR4r3`,
	"lblSwitch": `s_lG5AQaTgRtfBnt3LuD`,
	"uncheckedSwitch": `yaqUsWcUw2jxDZe4jqqX`,
	"btnSwitch": `DR8lIEFAXqlkLAvZiy5w`
};
export default ___CSS_LOADER_EXPORT___;
