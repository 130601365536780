import { appointmentsBaseUrl, putData } from "@Services/constServices";
interface NewReserveDetail {
  priceServiceId: number;
  employeeId: number;
  reserveHour: string;
  promoId: number;
}
interface EditReserveDetail {
  reserveDetailId: number;
  priceServiceId: number;
  employeeId: number;
  reserveHour: string;
}
interface AdaptRequest {
  reserveId: number;
  // newStatus: number;
  deleteReserveDetailsIds: number[];
  newReserveDetails: NewReserveDetail[];
  editReserveDetails: EditReserveDetail[];
}

export const putUpdateReserveDetail_service = async (payload: AdaptRequest) => {
  const url = `${appointmentsBaseUrl}/Reserva/EditReserve`;
  const successMessage = "Reserva actualizada correctamente";
  const errorMessage =
    "Hubo un problema al actualizar la reserva, intente nuevamente";
  return putData(url, payload, successMessage, errorMessage);
};
