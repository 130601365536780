import React from "react";
import style from "./PencilButton.module.css";
import { iconPath } from "../../../../../models/localPath";

const PencilButton = ({ alt = "editar", onClick }) => {
  return (
    <div className={style.pencil} onClick={() => onClick()}>
      <img src={`${iconPath}/userActions/edit.svg`} alt={alt} />
    </div>
  );
};

export default PencilButton;
