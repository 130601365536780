// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E58wzbgX1tuwmvrN41gC {
  display: flex;
  flex-direction: column;
  position: relative;

  gap: 10px;
  min-width: 580px;
  min-height: 300px;
  max-height: 485px;
  overflow-y: auto;
}

.xv2PsKsqbmHdQZBKAn66 {
  display: flex;
  flex-direction: column;

  gap: 30px;
  overflow-y: auto;
}

@media screen and (width < 746px) {
  .E58wzbgX1tuwmvrN41gC,
  .xv2PsKsqbmHdQZBKAn66 {
    min-width: 100%;
  }
}

.u7q4XB0dEvCUiZ4dsfgA {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }
}

.UCPfn8uN2vRfZfsHU6rg {
  font: 400 14px/20px "Mulish", sans-serif;
  text-align: center;
}

.L3j7R3z8lSAjYYbUPHB_ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  position: absolute;
  background-color: #ffffff82;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/SaleboxReports/SaleDocumentReportView/components/ShareSalesDocumentsReportModal/ShareSalesDocumentsReportModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;;EAElB,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;;EAEtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE;;IAEE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,iCAAiC;;EAEjC;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;;EAEX,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  gap: 10px;\n  min-width: 580px;\n  min-height: 300px;\n  max-height: 485px;\n  overflow-y: auto;\n}\n\n.share__container {\n  display: flex;\n  flex-direction: column;\n\n  gap: 30px;\n  overflow-y: auto;\n}\n\n@media screen and (width < 746px) {\n  .body,\n  .share__container {\n    min-width: 100%;\n  }\n}\n\n.share__button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #e0e0e0;\n  border-radius: 5px;\n  padding: 10px;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    background-color: #f5f5f5;\n  }\n}\n\n.share__message {\n  font: 400 14px/20px \"Mulish\", sans-serif;\n  text-align: center;\n}\n\n.loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n\n  position: absolute;\n  background-color: #ffffff82;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `E58wzbgX1tuwmvrN41gC`,
	"share__container": `xv2PsKsqbmHdQZBKAn66`,
	"share__button": `u7q4XB0dEvCUiZ4dsfgA`,
	"share__message": `UCPfn8uN2vRfZfsHU6rg`,
	"loading": `L3j7R3z8lSAjYYbUPHB_`
};
export default ___CSS_LOADER_EXPORT___;
