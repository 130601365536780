import { ApiResponse } from "@Services/constServices";
import { updateMovement_service } from "@Services/saleboxes/movements";

export const updateMovement = async (
  idBox,
  idEmployee,
  idBoxMoveConcept,
  idMovementType,
  amount,
  observation,
  initialForm,
  updateNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void
) => {
  const changedFields: any = {};

  if (idMovementType !== initialForm.type) {
    changedFields.idMovementType = Number(idMovementType);
  }
  if (Number(idBoxMoveConcept) !== Number(initialForm.concept)) {
    changedFields.idBoxMoveConcept = Number(idBoxMoveConcept);
  }
  if (idEmployee !== initialForm.employee) {
    changedFields.idEmployeeMovement = Number(idEmployee);
  }
  if (amount !== initialForm.amount) {
    changedFields.amount = Number(amount.replace(/[^0-9.-]+/g, ""));
  }
  if (observation !== initialForm.observations) {
    changedFields.observation = String(observation);
  }

  const payload = {
    idMovement: Number(idBox),
    ...changedFields,
  };

  const response: ApiResponse = await updateMovement_service(payload);

  if (!response.isSuccess) {
    updateNotification({
      message: response.message,
      status: response.status as any,
      toastTimeDuration: 4000,
    });
    return response;
  }

  updateNotification({
    message: "Movimiento editado correctamente",
    status: response.status,
    toastTimeDuration: 4000,
  });
  return response;
};
