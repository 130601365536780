// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lAHCvXDwOV1cbcxp7wbb {
  border-radius: 4px;
  background: #937cf4;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 100%;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 16px;
}
.lAHCvXDwOV1cbcxp7wbb:hover {
  background: #a996f3;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/buttons/ButtonAddMore/style/ButtonAddMore.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,aAAa;AACf;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".containerButtonAddMore {\n  border-radius: 4px;\n  background: #937cf4;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  font-family: Mulish;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  width: 100%;\n  height: 40px;\n  border: none;\n  cursor: pointer;\n  padding: 16px;\n}\n.containerButtonAddMore:hover {\n  background: #a996f3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerButtonAddMore": `lAHCvXDwOV1cbcxp7wbb`
};
export default ___CSS_LOADER_EXPORT___;
