import React from "react";
import { getClientsAdapter } from "@Adapters/clients/adaptedCustomerSearch";
//@ts-ignore
import { DataList } from "@viuti/recursos";
import { useEffect, useState } from "react";

export const SelectClientSearch = ({
  setCustomerSelected,
  inputRef,
  setStateResponse,
  setInitialSelectCustomer,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [listOfClientsFound, setListOfClientsFound] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchInput.trim() !== "") {
        setIsLoading(true);
        const clients = await getClientsAdapter(
          searchInput.trim(),
          setCustomerSelected,
          setSearchInput,
          setInitialSelectCustomer
        );
        setIsLoading(false);
        if (clients.isSuccess) {
          setListOfClientsFound(clients.data);
        } else {
          setStateResponse({
            message: "Hubo un error al buscar clientes",
            status: 400,
          });
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useEffect(() => {
    if (searchInput !== "") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [searchInput]);

  const handleSearch = async () => {
    setIsLoading(true);
    const clients = await getClientsAdapter(
      "a",
      setCustomerSelected,
      setSearchInput,
      setInitialSelectCustomer
    );
    setIsLoading(false);
    if (clients.isSuccess) {
      setListOfClientsFound(clients.data);
    } else {
      setStateResponse({
        message: "Hubo un error al buscar clientes",
        status: 400,
      });
    }
  };

  const handleChange = (event) => {
    setSearchInput(event);
  };

  return (
    <DataList
      data={listOfClientsFound}
      value={searchInput}
      handleChange={handleChange}
      category={"clients"}
      placeholder="Buscar cliente por nombre o DNI"
      width={"100%"}
      isLoading={isLoading}
      inputRef={inputRef}
      onFocus={async () => {
        if (searchInput === "") {
          await handleSearch();
        }
      }}
    />
  );
};
