// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pov2NP9SlPTBPq8SseSp {
  width: 100vw;
  height: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  padding: 35px;
  transition: all 150ms ease-in-out;
  @media screen and (width < 580px) {
    padding: 24px;
  }
}

.bg24w1X7cXGsducg0vzd {
  opacity: 1;
  visibility: visible;
}

.LVauWjp9_2so9_nQpQEe {
  opacity: 0;
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/ModalLayoutWrapper/ModalLayoutWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,MAAM;EACN,OAAO;EACP,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,sBAAsB;EACtB,aAAa;EACb,iCAAiC;EACjC;IACE,aAAa;EACf;AACF;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".modalLayoutWrapper {\n  width: 100vw;\n  height: 100%;\n  z-index: 1001;\n  top: 0;\n  left: 0;\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.4);\n  flex-direction: column;\n  padding: 35px;\n  transition: all 150ms ease-in-out;\n  @media screen and (width < 580px) {\n    padding: 24px;\n  }\n}\n\n.modalLayoutWrapperVisible {\n  opacity: 1;\n  visibility: visible;\n}\n\n.modalLayoutWrapperHidden {\n  opacity: 0;\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalLayoutWrapper": `pov2NP9SlPTBPq8SseSp`,
	"modalLayoutWrapperVisible": `bg24w1X7cXGsducg0vzd`,
	"modalLayoutWrapperHidden": `LVauWjp9_2so9_nQpQEe`
};
export default ___CSS_LOADER_EXPORT___;
