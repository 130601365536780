export const truncateString = (str: string, number: number): string => {
  if (!str) {
    return "";
  }
  if (str.length > number) {
    return str.slice(0, number) + "...";
  } else {
    return str;
  }
};
