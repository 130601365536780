import React from "react";
import {
  TextInput,
  SelectInput,
  // @ts-ignore
} from "@viuti/recursos";
import style from "./GiftcardCreationForm.module.css";

const GiftcardFormCreation = ({
  handleInputChange,
  giftcardForm,
  giftcardFormError,
  codeError,
  isCodeLoading,
}) => {
  const modality_items = [
    {
      value: 1,
      name: "Unico",
    },
    {
      value: 2,
      name: "Parcial",
    },
  ];

  const period_items = [
    {
      name: "Dias",
      value: "0",
    },
    {
      name: "Meses",
      value: "1",
    },
    {
      name: "Años",
      value: "2",
    },
  ];

  return (
    <article className={style.form_container}>
      <div className={style.form_grid}>
        {/* Columna izquierda - Información básica */}
        <div className={style.form_section}>
          <h3 className={style.section_title}>Información básica</h3>
          <div className={style.input_group}>
            <TextInput
              label="Código"
              type="text"
              name="code"
              placeholder="Ingrese el código de la giftcard"
              value={giftcardForm.code || ""}
              handleChange={handleInputChange}
              className={style.input}
              id="code-giftcard"
              touched={true}
              error={!isCodeLoading ? giftcardFormError.code || codeError : ""}
              isLoading={isCodeLoading}
              required
            />

            <SelectInput
              label="Tipo de Giftcard"
              placeholder="Seleccionar tipo"
              value={giftcardForm.modalityId?.toString() || ""}
              items={modality_items}
              handleChange={handleInputChange}
              name="modalityId"
              required
            />

            <TextInput
              label="Monto"
              prefix="S/."
              type="number"
              name="amount"
              placeholder="Ingresar el valor del giftcard"
              value={giftcardForm.amount}
              handleChange={handleInputChange}
              error={giftcardFormError.amount}
              touched={true}
              className={style.input}
              id="price-giftcard"
              required
            />
          </div>
        </div>

        {/* Columna derecha - Configuración de vigencia */}
        <div className={style.form_section}>
          <h3 className={style.section_title}>Configuración de vigencia</h3>
          <div className={style.input_group}>
            <SelectInput
              label="Periodo"
              placeholder="Seleccionar un periodo"
              value={giftcardForm.period}
              items={period_items}
              handleChange={handleInputChange}
              name="period"
              required
            />

            <TextInput
              label={`${giftcardForm.periodSelected} de vigencia ${
                giftcardForm.periodSelected === "Meses"
                  ? "(30 Días)"
                  : giftcardForm.periodSelected === "Años"
                  ? "(365 Días)"
                  : ""
              }`}
              type="number"
              name="periodDays"
              placeholder="Ingresar los días de vigencia"
              value={giftcardForm.periodDays}
              handleChange={handleInputChange}
              className={style.input}
              id="day-giftcard"
              error={giftcardFormError.periodDays}
              touched={true}
              required
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default GiftcardFormCreation;
