import React from "react";
import { STATUS_OK } from "@Models/httpsStatus";
import { ApiResponse } from "@Services/constServices";
import { getEditingHistory_service } from "@Services/saleboxes/movements";
import { formattedDateAndHour } from "src/components/ui/organism/SaleboxReports/SaleMovementsReportView/MovementsMobile/functionMovementsMobile";

export const getMovementsEditLogs = async (
  id: number,
  updateNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void
) => {
  const response: ApiResponse = await getEditingHistory_service(id);
  const adaptComment = (comment: string) => {
    // I want a function that cuts the text when there is a \\n and returns a br in its place for each \\n it finds, except if it is the last
    const commentArray = comment.split("\\n");
    const commentLength = commentArray.length;
    return commentArray.map((item, index) => {
      if (index === commentLength - 1) {
        return <span key={index}>{item}</span>;
      }
      return (
        <span key={index}>
          {item}
          <br />
        </span>
      );
    });
  };
  const adaptObject = response.data.map((item) => {
    return {
      responsable: item.employeeModificationName,
      fecha: new Date(item.dateModification).toLocaleString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
      edition: <div>{adaptComment(item.comment)}</div>,
      idEdition: item.employeeModificationId,
    };
  });
  if (response.status !== STATUS_OK) {
    updateNotification({
      message: response.message,
      status: response.status as any,
      toastTimeDuration: 4000,
    });
    return response.data;
  }
  return adaptObject.length === 0
    ? [
        {
          responsable: "-",
          fecha: "-",
          edicion: "-",
        },
      ]
    : adaptObject;
};
