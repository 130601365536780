import React from "react";
import ButtonRoundedIcon from "./buttonRoundedIcon/buttonRoundedIcon";
import { IButton } from "../buttonsWithoutIcons/interface/button";

const ButtonWithIcon = ({
  bgColor,
  iconSvg,
  iconColor,
  isPrimary,
  handleClick,
  width,
}: IButton) => {
  return (
    <ButtonRoundedIcon
      icon={iconSvg}
      isPrimary={isPrimary}
      handleClick={handleClick}
      iconColor={iconColor}
      bgColor={bgColor}
      width={width}
    />
  );
};

export default ButtonWithIcon;
