import React from "react";

interface IProps {
  path: string;
  props?: any;
  children: React.ReactNode;
  id: string;
}

const LinkButton = ({ props, path, children, id }: IProps) => {
  const getBaseUrl = () => {
    const hostname = window.location.hostname;

    if (hostname.includes("qa")) {
      return "https://qa.zymple.io";
    } else if (hostname.includes("app")) {
      return "https://app.zymple.io";
    } else {
      return "http://localhost:9000";
    }
  };

  const baseUrl = getBaseUrl();

  return (
    <a href={`${baseUrl}${path}`} id={id} {...props}>
      {children}
    </a>
  );
};

export default LinkButton;
