export const getSchedulesList = (lastAvailableTime: number) => {
  let scheduleOptionsTemp = [];
  for (let index = lastAvailableTime; index < 24; index += 0.25) {
    const hour =
      Math.floor(index) <= 12 ? Math.floor(index) : Math.floor(index) - 12;
    const minutes =
      index % 1 === 0
        ? "00"
        : index % 1 === 0.25
        ? "15"
        : index % 1 === 0.5
        ? "30"
        : "45";
    const period = index < 12 ? "AM" : "PM";
    const hourWithZero = hour.toString().padStart(2, "0"); // Agregar un cero antes si es necesario
    const option = `${hourWithZero}:${minutes} ${period}`;
    scheduleOptionsTemp.push(option);
  }
  return scheduleOptionsTemp;
};
