import React, { useEffect, useRef, useState } from "react";
import style from "./SearchBarReutilizable.module.css";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { MagnifyingGlassIcon } from "@Models/icons";
import { truncateString } from "@Utilities/truncateString";

export const SearchBarReutilizable = ({
  label,
  placeholder,
  data,
  onClickResult,
  onCreateItem,
  messageCreateItem,
  disabled,
  required,
  searchInput,
  setSearchInput,
  form,
  setForm,
  concepts,
  errorMessage,
  setError,
}) => {
  const [searchView, setSearchView] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchContainer = useRef<HTMLDivElement | null>(null);

  const onClickItem = (result) => {
    onClickResult(result);
    setSearchInput(result);
    setSearchView(false);
  };

  const changeInput = (e) => {
    setSearchInput(e.target.value);
  };

  const filterSearchOnlyStrings = ({ dataSearchable, input }) => {
    const result = dataSearchable.filter((item) => {
      return item.toLowerCase().includes(input.toLowerCase().trim());
    });
    const index = result.findIndex(
      (item) => item.toLowerCase() === input.toLowerCase().trim()
    );
    if (index !== -1) {
      const item = result[index];
      result.splice(index, 1);
      result.unshift(item);
    }
    return result;
  };

  const arreglodeNombres = data.map((item) => {
    return item.name;
  });

  const resultsArr = filterSearchOnlyStrings({
    dataSearchable: arreglodeNombres,
    input: searchInput,
  }).slice(0, 3);

  const validateInputIsNotInResults = () => {
    return resultsArr.every(
      (result) =>
        result.toLowerCase().trim() !== searchInput.toLowerCase().trim()
    );
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (searchInput === "") {
        return;
      }
      if (event.key === "Enter") {
        event.preventDefault();
        if (resultsArr.includes(searchInput)) {
          onClickItem(searchInput);
          setSearchView(false);
          return;
        }
        if (document.activeElement === searchInputRef.current) {
          searchInputRef.current?.blur();
          onCreateItem(searchInput);
          setSearchView(false);
        }
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, searchInputRef, onCreateItem]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainer.current &&
        !searchContainer.current.contains(event.target)
      ) {
        setSearchView(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fijarmeSiElConceptoEscritoExiste = () => {
      const concept = concepts.find(
        (concept) => concept.name.toLowerCase() === searchInput.toLowerCase()
      );
      if (!concept) {
        setForm({ ...form, concept: "" });
      }
    };
    fijarmeSiElConceptoEscritoExiste();
    setError((prev) => ({ ...prev, concept: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  return (
    <div className={style.searchBar}>
      <section className={style.searchBar__container}>
        <button
          className={style.searchBar__article}
          onClick={() => setSearchView(true)}
        >
          <TextInput
            label={label}
            name={"search"}
            value={searchInput}
            touched={true}
            handleChange={changeInput}
            placeholder={placeholder}
            required={required}
            type="text"
            disabled={disabled}
            inputProps={{ ref: searchInputRef, autocomplete: "off" }}
            leftIcon={MagnifyingGlassIcon}
            flex="1"
            error={errorMessage}
          />
        </button>

        <section
          className={
            searchView
              ? style.searchBarResults__containerActive
              : style.searchBarResults__container
          }
          ref={searchContainer}
        >
          <section
            className={`${style.resultsContainer} ${
              searchInput !== "" ? style.animation : ""
            }`}
            style={{
              border: searchInput !== "" ? "1px solid #dadada" : "none",
            }}
          >
            {resultsArr.length === 0 || searchInput === "" ? (
              <></>
            ) : (
              resultsArr.map((result, index) => {
                return (
                  <button key={index} onClick={() => onClickItem(result)}>
                    <p>{truncateString(result, 16)}</p>
                  </button>
                );
              })
            )}
            {/* {resultsArr.length === 0 && !!searchInput.length && (
              <p className={style.noResult}>No se han encontrado resultados.</p>
            )} */}
            {!!searchInput.length && validateInputIsNotInResults() && (
              <button
                className={`${style.resultSimpleDataButton} ${style.buttonSimpleData}`}
                style={{
                  borderRadius: "0px 0px 15px 15px",
                  borderTop: "1px solid #e0e0e0",
                }}
                onClick={() => {
                  if (resultsArr.includes(searchInput)) {
                    onClickItem(searchInput);
                    setSearchView(false);
                    return;
                  }
                  onCreateItem(searchInput);
                  setSearchView(false);
                }}
              >
                <p>{messageCreateItem}</p>
              </button>
            )}
          </section>
        </section>
      </section>
    </div>
  );
};
