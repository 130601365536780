// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bYnTFZj0uoJzyIEDOBc2 {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background-color: #c0b2ff;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.D4PivxSUEO4IXyGpRYfC {
  margin: 5px;
}

.D4PivxSUEO4IXyGpRYfC p:last-child {
  font-size: 0.77rem;
  font-weight: 100;
  color: #9d9d9d;
}

.NTVQqWUoMWg07ky9pJeT {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #9d9d9d;
}

.NTVQqWUoMWg07ky9pJeT:hover {
  cursor: default !important;
  background-color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/DataList/OptionClient/OptionClient.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,0BAA0B;EAC1B,kCAAkC;AACpC","sourcesContent":[".circleInitialLetter {\n  width: 35px;\n  height: 35px;\n  min-width: 35px;\n  min-height: 35px;\n  background-color: #c0b2ff;\n  border-radius: 50%;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 5px;\n}\n\n.info {\n  margin: 5px;\n}\n\n.info p:last-child {\n  font-size: 0.77rem;\n  font-weight: 100;\n  color: #9d9d9d;\n}\n\n.withoutResults {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 40px;\n  color: #9d9d9d;\n}\n\n.withoutResults:hover {\n  cursor: default !important;\n  background-color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleInitialLetter": `bYnTFZj0uoJzyIEDOBc2`,
	"info": `D4PivxSUEO4IXyGpRYfC`,
	"withoutResults": `NTVQqWUoMWg07ky9pJeT`
};
export default ___CSS_LOADER_EXPORT___;
