import axios from "axios";
import {
  ApiResponse,
  cashRegisterBaseUrl,
  headerAuthorization,
} from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getSalesDocuments_service = async (
  payload
): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/SalesDocuments/SalesDocuments?searParameter=${payload.searchParameter}&idBox=${payload.idBox}&typeDocumetnId=${payload.typeDocumentId}&status=${payload.status}&statusFE=${payload.statusFE}&startDate=${payload.startDate}&endDate=${payload.endDate}&page=${payload.page}`;
  // const carga_documentos_venta = 65;
  // trackUserAction(carga_documentos_venta);
  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      message: response.data.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los documentos de venta. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const cancelOperation_service = async (
  idSaleDocument: string,
  observation: string
): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/Payment/CancelOperation?idSaleDocument=${idSaleDocument}&reason=${observation}`;
  const anular_ticket = 67;
  trackUserAction(anular_ticket);

  try {
    const response: ApiResponse = await axios
      .delete(url, headerAuthorization)
      .then((res) => res.data);
    return {
      isSuccess: true,
      status: response.status,
      message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al cancelar la operación. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const createCreditNote_service = async (payload: {
  saleDocumentId: number;
  conceptId: number;
  restoreAll: boolean;
}): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/Payment/CreateCreditNoteV2`;
  // const crear_nota_credito = 69;
  // trackUserAction(crear_nota_credito);

  try {
    const response: ApiResponse = await axios
      .post(url, payload, headerAuthorization)
      .then((res) => res.data);
    return {
      isSuccess: true,
      status: response.status,
      message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al cancelar la operación. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const getSalesDocumentDetail_service = async (
  saleDocumentId: string,
  saleBoxId: string
) => {
  const url = `${cashRegisterBaseUrl}/SalesDocuments/SalesDocumentDetail?saleDocumentId=${saleDocumentId}&saleBoxId=${saleBoxId}`;
  const ve_detalles_de_documento = 66;
  trackUserAction(ve_detalles_de_documento);

  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener el detalle del documento de venta. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const getSalePDF_service = async (
  saleDocumentId: string
): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/Payment/GetSalePDF?idSaleDocument=${saleDocumentId}`;

  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener el PDF",
      data: [],
    };
  }
};

export const getSaleCDR_service = async (
  saleDocumentId: string
): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/Payment/GetSaleCDR?idSaleDocument=${saleDocumentId}`;

  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener el CDR",
      data: [],
    };
  }
};

export const getSaleXML_service = async (
  saleDocumentId: string
): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/Payment/GetSaleXML?idSaleDocument=${saleDocumentId}`;

  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener el XML",
      data: [],
    };
  }
};

export const downloadSaleDocument_service = async (payload) => {
  const url = `${cashRegisterBaseUrl}/SalesDocuments/DonwloadSaleDocumentReportBase64string?searParameter=${payload.searchParameter}&idBox=${payload.idBox}&typeDocumetnId=${payload.typeDocumentId}&status=${payload.status}&statusFE=${payload.statusFE}&startDate=${payload.startDate}&endDate=${payload.endDate}&format=${payload.format}`;
  const descargar_reporte = 68;
  trackUserAction(descargar_reporte);

  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccessful: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message || "Error al descargar el reporte",
      data: [],
    };
  }
};

export const resendPayment_service = async (saleDocumentId: string) => {
  const url = `${cashRegisterBaseUrl}/Payment/ResendPayment`;

  try {
    const response = await axios.post(
      url,
      { saleDocumentId },
      headerAuthorization
    );
    return {
      isSuccessful: true,
      status: response.status,
      message: response.data.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Error al retransmitir el documento a SUNAT",
      data: [],
    };
  }
};
