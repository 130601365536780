import React from "react";
import PrimaryBttn from "./primaryButton/primaryBttn";
import SecondaryBttn from "./secondaryButton/secondaryBttn";
import { IButton } from "./interface/button";

const Button = ({
  isPrimary = false,
  textBttn = "Button",
  handleClick = () => {},
  isDisabled,
  disabled,
  isLoading = false,
  width,
  fontSize,
  style,
  padding,
  testId,
  color = "#937cf4",
  id,
  buttonProps,
}: IButton) => {
  if (isPrimary)
    return (
      <PrimaryBttn
        textBttn={textBttn}
        isDisabled={isDisabled}
        //@ts-ignore
        fontSize={fontSize}
        //@ts-ignore
        disabled={disabled}
        handleClick={handleClick}
        width={width}
        style={style}
        isLoading={isLoading}
        id={id}
        padding={padding}
        buttonProps={buttonProps}
      />
    );
  return (
    <SecondaryBttn
      textBttn={textBttn}
      fontSize={fontSize}
      //@ts-ignore
      disabled={disabled}
      loading={isLoading}
      handleClick={handleClick}
      width={width}
      testId={testId}
      color={color}
      padding={padding}
      buttonProps={buttonProps}
      id={id}
    />
  );
};

export default Button;
