// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iKgRT3QNdcxByacKrwwg {
  display: flex;
  border-radius: 8px;
  background-color: #f0edfe;
  width: fit-content;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border: 1px solid #937cf4;
}

.Jq3azTFtfO6xRLdY1l2l {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
  background-color: #937cf438;
  width: 36px;
  height: 36px;
  text-align: center;
}

.eKhzyviC2v_pdLkTaguQ img {
  width: 18px;
  height: 18.88px;
  margin-top: 3px;
}

.iKgRT3QNdcxByacKrwwg h2 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #45348e;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/WarehouseIcon/style/WarehouseIcon.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".warehouseContainer {\n  display: flex;\n  border-radius: 8px;\n  background-color: #f0edfe;\n  width: fit-content;\n  gap: 10px;\n  align-items: center;\n  padding: 10px;\n  border: 1px solid #937cf4;\n}\n\n.backgroundImg {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  padding: 5px;\n  background-color: #937cf438;\n  width: 36px;\n  height: 36px;\n  text-align: center;\n}\n\n.warehouseImg img {\n  width: 18px;\n  height: 18.88px;\n  margin-top: 3px;\n}\n\n.warehouseContainer h2 {\n  font-family: \"Mulish\", sans-serif;\n  font-weight: 700;\n  color: #45348e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warehouseContainer": `iKgRT3QNdcxByacKrwwg`,
	"backgroundImg": `Jq3azTFtfO6xRLdY1l2l`,
	"warehouseImg": `eKhzyviC2v_pdLkTaguQ`
};
export default ___CSS_LOADER_EXPORT___;
