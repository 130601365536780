import { clientsBaseUrl } from "@Models/services/constGlobals";
import { ApiResponse, postData } from "@Services/constServices";
import { typeDoc } from "@Utilities/typeDoc";

export const postCreateNewClient = async (form): Promise<ApiResponse> => {
  const sex = (s: string) => {
    if (s === "MASCULINO") return 1;
    if (s === "FEMENINO") return 2;
    if (s === "OTRO") return 3;
    return 0;
  };

  const categoryId =
    form.categoryClientId === "" ? null : Number(form.categoryClientId);

  const data = {
    firstName: form.name,
    lastName: form.lastName,
    email: form.email,
    phone: form.phoneNumber,
    codeCountryPhone: form.phoneCode,
    documentType: typeDoc(form.documentType),
    documentNumber: `${form.documentNumber}`,
    categoryClientId: categoryId,
    gender: sex(form.sex),
    birthdate: form.birthdate === "" ? null : form.birthdate,
    address: form.address,
  };

  const url = `${clientsBaseUrl}/Cliente/CreateNewClient`;
  return postData(url, data);
};
