import {
  ApiResponse,
  appointmentsBaseUrl,
  getData,
} from "@Services/constServices";

export const getAvailableHours_service = async ({
  date,
  employeeId,
  priceServiceId,
  reserveId,
}): Promise<ApiResponse> => {
  let url;
  if (reserveId) {
    url = `${appointmentsBaseUrl}/Employee/ListAvailableEmployeeHours?date=${date}&EmployeeId=${employeeId}&priceServiceId=${priceServiceId}&reserveIdOnlyForUpdate=${reserveId}`;
  } else {
    url = `${appointmentsBaseUrl}/Employee/ListAvailableEmployeeHours?date=${date}&EmployeeId=${employeeId}&priceServiceId=${priceServiceId}`;
  }
  return getData(url);
};
