// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N5mYEvGcsYtqFOImisac {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background: #dad1f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.N5mYEvGcsYtqFOImisac img {
  width: 18px;
  height: 18px;
}

.N5mYEvGcsYtqFOImisac:hover {
  background-color: #b7a7e0;
}

.N5mYEvGcsYtqFOImisac:active {
  background: #7b6a9c;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/buttons/PencilButton/PencilButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".pencil {\n  width: 36px;\n  height: 36px;\n  border-radius: 100px;\n  background: #dad1f4;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.pencil img {\n  width: 18px;\n  height: 18px;\n}\n\n.pencil:hover {\n  background-color: #b7a7e0;\n}\n\n.pencil:active {\n  background: #7b6a9c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pencil": `N5mYEvGcsYtqFOImisac`
};
export default ___CSS_LOADER_EXPORT___;
