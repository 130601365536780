import React from "react";
// @ts-ignore
import style from "./InfoNote.module.css";
import {
  HiExclamationCircle,
  HiExclamationTriangle,
  HiInformationCircle,
} from "react-icons/hi2";

interface IInfoNoteProps {
  type?: "info" | "warning" | "error";
  children: React.ReactNode;
}
const InfoNote = ({ type = "info", children }: IInfoNoteProps) => {
  let typeClass = "";
  let typeIcon = null;

  if (typeof children !== "string" && typeof children !== "object") {
    throw new Error(
      "InfoNote component only accepts string or ReactNodes as children"
    );
  }

  switch (type) {
    case "info":
      typeClass = style.info;
      typeIcon = <HiInformationCircle />;
      break;
    case "warning":
      typeClass = style.warning;
      typeIcon = <HiExclamationTriangle />;
      break;
    case "error":
      typeClass = style.error;
      typeIcon = <HiExclamationCircle />;
      break;
    default:
      break;
  }

  return (
    <article className={`${style.note__container} ${typeClass}`}>
      <p className={style.note__title}>{typeIcon} Nota:</p>
      <span className={style.note__text}>{children}</span>
    </article>
  );
};

export default InfoNote;
