import { getAvailableHours_adapter } from "@Adapters/employee/getAvailableHours.adapter";
import { getAppointmentById_adapter } from "@Adapters/reserve/getAppointmentById.adapter";
import { useEffect } from "react";

export const useAdaptReserveDetail = (
  reserveId,
  setStateResponse,
  setCustomerSelected,
  setSelectedItems,
  setIsLoadingDetail,
  setInitialSelectItems,
  generateId,
  setStatusReserve,
  setInitialStatusReserve,
  setInitialSelectCustomer,
  setAvailableHours,
  setLoadingHours,
  setLoadingHoursId,
  setDateReservation
) => {
  useEffect(() => {
    (async () => {
      // nueva reserva
      if (!reserveId) {
        const existingItem = {
          id: generateId(),
          service: "",
          employee: "",
          schedule: "",
        };
        setSelectedItems([existingItem]);
        return;
      }
      // reserva existente
      setLoadingHours(true);
      setIsLoadingDetail(true);
      const responseAppointment = await getAppointmentById_adapter(reserveId);

      if (!responseAppointment.isSuccess) {
        setStateResponse({
          status: 400,
          message: responseAppointment.message,
        });
        return;
      }
      const formatDate = (date: string) => {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
      };
      // TRABAJO
      setInitialSelectItems(responseAppointment.data.services);
      setSelectedItems(responseAppointment.data.services);
      setCustomerSelected(responseAppointment.data.client);
      setInitialSelectCustomer(responseAppointment.data.client);
      setStatusReserve(responseAppointment.data.statusReserve);
      setInitialStatusReserve(responseAppointment.data.statusReserve);
      setDateReservation(formatDate(responseAppointment.data.dateReserve));
      // por cada servicio, va a tomar el id del empleado y va a hacer un get de los horarios disponibles y se le va a asignar el id del item

      // Obtener horarios disponibles para cada servicio
      const availableHoursData = {};
      for (const service of responseAppointment.data.services) {
        setLoadingHoursId(service.id);
        const hoursResponse = await getAvailableHours_adapter({
          date: new Date().toISOString().split("T")[0], // Cambia esto si necesitas una fecha específica
          employeeId: service.employee,
          priceServiceId: service.service,
          reserveId: reserveId,
        });
        setLoadingHoursId(null);

        // Asignar solo los horarios disponibles al id del servicio
        availableHoursData[service.id] = hoursResponse.data; // Cambiado para solo obtener los horarios
      }
      setAvailableHours(availableHoursData); // Setear los horarios disponibles
      setIsLoadingDetail(false);
      setLoadingHours(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    })();
  }, [reserveId]);
};
