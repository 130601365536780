// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rwcuB9czgg0JMIXJtgRJ {
  height: 100%;
  border-bottom: none;
}

.rwcuB9czgg0JMIXJtgRJ table {
  border-collapse: collapse;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/Tables/TableWrapper/TableWrapper/TableWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".table_container {\n  height: 100%;\n  border-bottom: none;\n}\n\n.table_container table {\n  border-collapse: collapse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_container": `rwcuB9czgg0JMIXJtgRJ`
};
export default ___CSS_LOADER_EXPORT___;
