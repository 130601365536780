import React, { useState } from "react";
// @ts-ignore
import style from "./Tooltip.module.css";

interface TooltipProps {
  message: string;
  children: any;
  activated?: boolean;
  styles?: object;
  distance?: { bottom: number; left: number };
}

const Tooltip: React.FC<TooltipProps> = ({
  message,
  children,
  activated,
  styles,
  distance = { bottom: 0, left: 0 },
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [placement, setPlacement] = useState<"top" | "bottom">("bottom");

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isHovered && activated) {
      setIsHovered(true);

      const rect = event.currentTarget.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      // quiero una si esta muy pegado a la izquierda que me lo renderice a la derecha
      if (rect.left < 100) {
        setPosition({ top: rect.bottom, left: rect.left + 100 });
        setPlacement("bottom");
      } else {
        if (rect.bottom + 150 > windowHeight && message.length > 500) {
          // Si no hay suficiente espacio debajo, muestra arriba
          setPosition({ top: rect.top - 150, left: rect.left });
          setPlacement("top");
        } else {
          // Si hay suficiente espacio debajo, muestra abajo
          setPosition({
            top: rect.bottom + distance.bottom,
            left: rect.left + distance.left,
          });
          setPlacement("bottom");
        }
      }
    }
  };

  const handleMouseLeave = () => {
    if (isHovered) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={style.tooltipContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div
          className={`${style.tooltip} ${style[placement]}`}
          style={{ ...styles, top: position.top, left: position.left }}
        >
          {message}
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
