import React from "react";
//@ts-ignore
import styled from "./style/fieldsWrapper.module.css";
import useValidation from "./hooks/useValidation";
import useCompleteData from "./hooks/useCompleteData";
//@ts-ignore
import { TextInput } from "@viuti/recursos";

interface IFieldsWrapperProps {
  rucData: any;
  setRucData: any;
}

const FieldsWrapper: React.SFC<IFieldsWrapperProps> = (props) => {
  const { setRucData, rucData } = props;
  const rucValue = useValidation(rucData.ruc);
  const businessName = useValidation(rucData.businessName);
  const certificateDate = useValidation(rucData.expirationDate);
  const certificatePassword = useValidation(rucData.claveCertificado, true);
  useCompleteData(
    {
      rucValue: rucValue.valueInput,
      businessName: businessName.valueInput,
      certificateDate: certificateDate.valueInput,
      certificatePassword: certificatePassword.valueInput,
    },
    setRucData
  );

  return (
    <section className={styled.newRucsPage__Section}>
      <TextInputBuilder
        props={rucValue}
        required={true}
        label={"Ruc"}
        disabled={true}
      />
      <TextInputBuilder
        props={businessName}
        label={"Razón social"}
        disabled={true}
      />
      <TextInputBuilder
        props={certificateDate}
        label={"Fecha de vencimiento"}
        disabled={true}
      />
      <TextInputBuilder
        props={certificatePassword}
        required={true}
        label={"Clave de certificado"}
        keyboardType="password"
        disabled={false}
      />
    </section>
  );
};

export default FieldsWrapper;

const TextInputBuilder = ({
  props,
  label,
  required,
  keyboardType,
  disabled = false,
}: any) => {
  const { valueInput, changeValueInput, validateValueInput, error } = props;
  return (
    <TextInput
      label={label}
      value={valueInput}
      handleChange={changeValueInput}
      handleBlur={validateValueInput}
      error={error}
      touched={true}
      required={required}
      keyboardType={keyboardType}
      disabled={disabled}
    />
  );
};
