import React from "react";
import {
  TooltipForStrings,
  // @ts-ignore
} from "@viuti/recursos";
import style from "../SaleMovementsReportView.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";
import {
  HiOutlineArrowSmallDown,
  HiOutlineArrowSmallUp,
} from "react-icons/hi2";
import IconWrapper from "../../../../atoms/IconWrapper/IconWrapper";

export const createObject = (
  movement: any,
  screenWidth: number,
  renderIconWithOptions: (movement: any) => React.ReactNode,
  classCell: (type: string, style: any, isRounded?: boolean) => string
) => {
  const EGRESO = "Egreso";
  const validatedIngreso = movement.type !== EGRESO;
  if (screenWidth >= 1300) {
    return {
      type: (
        <span className={classCell(movement.type, style)}>
          {validatedIngreso ? (
            <IconWrapper icon={HiOutlineArrowSmallUp} />
          ) : (
            <IconWrapper icon={HiOutlineArrowSmallDown} />
          )}
          {movement.type}
        </span>
      ),
      box: TooltipForStrings(movement.saleBoxName, 14),
      date: (
        <span className={style.dateMovement}> {movement.dateMovement}</span>
      ),
      hour: movement.hourMovement,
      concept: <span>{TooltipForStrings(movement.concept, 14)}</span>,
      observation: movement.observation,
      employeeName: TooltipForStrings(movement.employeeName, 14),
      amount: (
        <span className={classCell(movement.type, style)}>
          {" "}
          {formatToSoles(movement.amount)}
        </span>
      ),
      guion:
        movement.saleDocumentId === null ? renderIconWithOptions(movement) : "",
    };
  }
  if (screenWidth < 1300 && screenWidth >= 1100) {
    return {
      type: (
        <span className={classCell(movement.type, style)}>
          {validatedIngreso ? (
            <IconWrapper icon={HiOutlineArrowSmallUp} />
          ) : (
            <IconWrapper icon={HiOutlineArrowSmallDown} />
          )}
          {movement.type}
        </span>
      ),
      date: (
        <span className={style.dateMovement}> {movement.dateMovement}</span>
      ),
      hour: movement.hourMovement,
      concept: <span>{TooltipForStrings(movement.concept, 14)}</span>,
      observation: movement.observation,
      employeeName: TooltipForStrings(movement.employeeName, 14),
      amount: (
        <span className={classCell(movement.type, style)}>
          {" "}
          {formatToSoles(movement.amount)}
        </span>
      ),
      guion:
        movement.saleDocumentId === null ? renderIconWithOptions(movement) : "",
    };
  }
  if (screenWidth < 1100) {
    return {
      type: (
        <span className={classCell(movement.type, style)}>
          {validatedIngreso ? (
            <IconWrapper icon={HiOutlineArrowSmallUp} />
          ) : (
            <IconWrapper icon={HiOutlineArrowSmallDown} />
          )}
          {movement.type}
        </span>
      ),
      date: (
        <span className={style.dateMovement}> {movement.dateMovement}</span>
      ),
      concept: <span>{TooltipForStrings(movement.concept, 14)}</span>,
      observation: movement.observation,
      employeeName: TooltipForStrings(movement.employeeName, 14),
      amount: (
        <span className={classCell(movement.type, style)}>
          {" "}
          {formatToSoles(movement.amount)}
        </span>
      ),
      guion:
        movement.saleDocumentId === null ? renderIconWithOptions(movement) : "",
    };
  }
};
