import React from "react";
import style from "./switchInput.module.css";

interface ISwitchInput {
  value: boolean;
  handleChange?: (value: boolean) => void;
  disabled?: boolean;
  width?: number;
}

const SwitchInput: React.FC<ISwitchInput> = ({
  value,
  handleChange = () => {},
  disabled,
  width = 40,
}) => {
  const aspectRelation = 0.55;

  const handleLabelClick = () => {
    if (disabled) return;
    handleChange(!value); // Invertir el valor del SwitchInput y notificar el cambio
  };

  return (
    <span className={style.buttonCheck}>
      <input
        type="checkbox"
        className={`${style.btnSwitch} ${disabled ? style.disabled : ""}`}
        checked={value}
        disabled={disabled}
      />
      <label
        className={`${style.lblSwitch} ${!value ? style.uncheckedSwitch : ""}`}
        style={{ width: width, height: width * aspectRelation }}
        htmlFor="switch"
        onClick={handleLabelClick} // Agregar la función de cambio al label
        aria-disabled={disabled}
      ></label>
    </span>
  );
};

export default SwitchInput;
