import React from "react";
import { useContext, useEffect, useState } from "react";
import { getFirstDayOfMonth } from "./utils/getFirstDayOfMonth";
import { getCurrentDate } from "./utils/getCurrentDate";
import useScreen from "@Hooks/useScreen/useScreen";
import useSelectBox from "./hook/useSelectBox";
import IconWithOptions from "../../../molecules/IconWithOptions/IconWithOptions";
import Icon from "../../../atoms/Icon/Icon";
import { BillIcon, FilterIcon, TripleDotsIcon } from "@Models/icons";
import style from "./SaleDocumentReportView.module.css";
import validateModuleAccess from "../../../../../utilities/jwt/validateModuleAccess";
import { HeaderNavigation, ModalAcceptCancel } from "@Molecules/index";
import SelectInput from "../../../atoms/inputs/selectInput/SelectInput";
import DateInput from "../../../atoms/inputs/dateInput/DateInput";
import SearchWithColoredBorder from "../../../atoms/inputs/searchWithColoredBorder/SearchWithColoredBorder";
import ButtonWithoutIcon from "../../../atoms/buttons/buttonsWithoutIcons/button";
import NoInfoToShow from "../../../atoms/noInfoToShow/NoInfoToShow";
import TableMobile from "../../../../wrappers/Tables/TableMobile/TableMobile";
import TableWrapper from "../../../../wrappers/Tables/TableWrapper/TableWrapper/TableWrapper";
import { formatDataTable } from "./utils/formDataTable";
import { createObjectMobile, downloadFile } from "./utils/crateObjectMobile";
import PaginationWrapper from "../../../../wrappers/PaginationWrapper/PaginationWrapper";
import SalesDocumentDetailModal from "./components/SalesDocumentDetailModal/SalesDocumentDetailModal";
import CancelTicketModal from "./components/CancelTicketModal/CancelTicketModal";
import CreditNoteModal from "./components/CreditNoteModal/CreditNoteModal";
import { resendPayment } from "@Adapters/saleboxes/resendPayment.adapter";
import ShareSalesDocumentsReportModal from "./components/ShareSalesDocumentsReportModal/ShareSalesDocumentsReportModal";

const SaleDocumentReportView = ({
  boxes,
  saleBoxId,
  saleDocuments,
  pages,
  currentPagination,
  shouldReloadTable,
  getAccountInfo,
  getSalesDocuments,
  updateShouldReloadTable,
  updateCurrentPagination,
  onNotification,
  saleDetailsModalVisibility,
  cancelTicketModalVisibility,
  exportModalVisibility,
  creditNoteModalVisibility,
  EXPORT_SALE_DOCUMENTS_UUID,
  onHeaderBackClick,
  typeDocuments,
  state,
  stateFE,
  setCurrentPagination,
}) => {
  const [buttonsPagination, setButtonsPagination] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [buttonFilterIsLoading, setButtonFilterIsLoading] = useState(false);
  const [isLoadingSalesDocuments, setIsLoadingSalesDocuments] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    isVisible: isSaleDetailsModalVisible,
    setIsVisible: setIsSaleDetailsModalVisible,
  } = saleDetailsModalVisibility;
  const {
    isVisible: isCancelTicketModalVisible,
    setIsVisible: setIsCancelTicketModalVisible,
  } = cancelTicketModalVisibility;
  const {
    isVisible: isExportModalVisible,
    setIsVisible: setIsExportModalVisible,
  } = exportModalVisibility;
  const {
    isVisible: isCreditNoteModalVisible,
    setIsVisible: setIsCreditNoteModalVisible,
  } = creditNoteModalVisibility;

  const [selectedDocumentDetails, setSelectedDocumentDetails] = useState(null);
  const [
    selectedDocumentDetailsModalTitle,
    setSelectedDocumentDetailsModalTitle,
  ] = useState("");
  const [selectedTicketToCancel, setSelectedTicketToCancel] = useState(null);
  const [selectedDocumentForCreditNote, setSelectedDocumentForCreditNote] =
    useState(null);

  const [form, setForm] = useState({
    searParameter: "",
    idBox: "",
    typeDocumetnId: "",
    status: "",
    statusFE: "",
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
  } as any);

  const [initialForm, setInitialForm] = useState({
    searParameter: "",
    idBox: "",
    typeDocumetnId: "",
    status: "",
    statusFE: "",
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
  } as any);
  const [error, setError] = useState({
    endDate: "",
  });
  const { width: screenWidth, height: screenHeight } = useScreen();

  useSelectBox(boxes, saleBoxId, form, setForm, setInitialForm);

  useEffect(() => {
    (async () => {
      await getAccountInfo();
      await getSalesDocuments(
        {
          searParameter: "",
          idBox: saleBoxId,
          typeDocumetnId: "",
          status: "",
          statusFE: "",
          startDate: getFirstDayOfMonth(),
          endDate: getCurrentDate(),
        },
        1
      );
      setIsLoadingSalesDocuments(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setButtonsPagination(pages);
    setDataTable(saleDocuments);
  }, [saleDocuments, pages]);

  useEffect(() => {
    if (!firstRender) {
      (async () => {
        updateShouldReloadTable(false);
        // Esto evita que se haga la petición de nuevo
        if (shouldReloadTable) {
          return;
        }
        await getSalesDocuments(initialForm, currentPagination);
        setIsLoadingSalesDocuments(false);
      })();
    } else {
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination]);

  const titles = (screenWidth) => {
    const titles = [
      "Nombre de caja",
      "Tipo de documento",
      "Número de documento",
      "Fecha",
      "Hora",
      "Nombre del cajero",
      "Nombre del cliente",
      "Total",
      "Estado",
      "Estado F.E.",
      "",
    ];

    if (screenWidth < 1380) {
      titles.splice(4, 1);
    }
    if (screenWidth < 1300) {
      titles.splice(5, 1);
    }
    if (screenWidth < 1100) {
      titles.splice(6, 1);
    }
    if (screenWidth < 1020) {
      titles.splice(1, 1);
    }

    return titles;
  };

  const handleLoad = async () => {
    if (form.startDate > form.endDate) {
      setError({ ...error, endDate: `Debe ser posterior a fecha inicio.` });
      return;
    }
    setError({ ...error, endDate: "" });
    setIsLoadingSalesDocuments(true);
    setButtonFilterIsLoading(true);
    // Si se cambia de página, se actualiza el estado de shouldReloadTable para que no se haga la petición de nuevo
    if (currentPagination !== 1) updateShouldReloadTable(true);
    await getSalesDocuments(form, 1);
    setButtonFilterIsLoading(false);
    setInitialForm({
      ...form,
      searParameter: "",
    });
    setForm({
      ...form,
      searParameter: "",
    });
    updateCurrentPagination(1);
    setIsLoadingSalesDocuments(false);
  };

  const showFilter = screenWidth >= 900 && screenHeight >= 700;
  const showIconFilter = !showFilter;
  const showButtonLoad = !showIconFilter;

  const messagePlaceholder = () => {
    if (!showButtonLoad) {
      return showFilter
        ? "Buscar por documento o empleado"
        : "Escribe el número de documento o empleado y presiona intro.";
    } else {
      return "Buscar por documento o empleado";
    }
  };

  const closeFilter = () => {
    if (form.startDate > form.endDate) {
      setError({ ...error, endDate: `Debe ser posterior a fecha inicio.` });
      return;
    }
    handleLoad();
    setOpenFilters(false);
  };

  const renderIconWithOptions = (document) => {
    const isRejected = document.statusFE === "Rechazado";

    // Grupo 1: Acciones principales
    const mainActions = [
      {
        label: "Ver detalles",
        handler: () => {
          const title = `Detalles #${document.series}-${document.number}`;
          setSelectedDocumentDetails(document);
          setSelectedDocumentDetailsModalTitle(title);
          setIsSaleDetailsModalVisible(true);
        },
      },
      {
        label: "Anular ticket",
        handler: () => {
          setSelectedTicketToCancel(document);
          setIsCancelTicketModalVisible(true);
        },
      },
      {
        label: "Generar nota de crédito",
        handler: () => {
          setSelectedDocumentForCreditNote(document);
          setIsCreditNoteModalVisible(true);
        },
      },
    ];

    // Grupo 2: Opciones de descarga (solo si no es ticket interno - typeDocument !== 5)
    const downloadActions =
      document.typeDocument !== 5
        ? [
            {
              type: "separator",
              label: "",
              handler: () => {},
              styles: { margin: "8px 0" },
            },
            {
              label: "Descargar PDF",
              handler: () => downloadFile(document, "PDF", onNotification),
            },
            {
              label: "Descargar CDR",
              handler: () => downloadFile(document, "CDR", onNotification),
            },
            {
              label: "Descargar XML",
              handler: () => downloadFile(document, "XML", onNotification),
            },
          ]
        : [];

    // Grupo 3: Retransmisión SUNAT (solo si está rechazado)
    const sunatActions = isRejected
      ? [
          {
            type: "separator",
            label: "",
            handler: () => {},
            styles: { margin: "8px 0" },
          },
          {
            label: "Retransmitir a SUNAT",
            handler: async () => {
              const response = await resendPayment(document.id, onNotification);
              if (response.isSuccessful) handleLoad();
            },
          },
        ]
      : [];

    // Combinamos todos los grupos de acciones
    const actions = [...mainActions, ...downloadActions, ...sunatActions];

    return (
      <IconWithOptions
        icon={
          <Icon color="" path={TripleDotsIcon} size={14} alt={"Tres puntos"} />
        }
        actions={actions}
      />
    );
  };

  const optionsSalesDocuments = (
    <IconWithOptions
      buttonsStyles={{
        padding: "15px",
      }}
      containerStyles={{
        borderRadius: "6px",
      }}
      icon={
        <span className={style.icon_options}>
          <Icon color="" path={TripleDotsIcon} size={22} alt={"Tres puntos"} />
        </span>
      }
      actions={[
        {
          label: isDownloading ? "Descargando..." : "Reporte",
          handler: () => {
            const uuidToValidate = EXPORT_SALE_DOCUMENTS_UUID;
            const isAccessOk = validateModuleAccess(uuidToValidate);
            if (isAccessOk && !isDownloading) {
              setIsExportModalVisible(true);
            }
          },
          //@ts-expect-error
          disabled: isDownloading,
        },
      ]}
    />
  );

  const headerProps = {
    title: "Documentos de ventas",
    previousAction: () => onHeaderBackClick(),
    previousActionMovement: "back",
    buttonProps: {
      textBttn: isDownloading ? "Descargando..." : "Reporte",
      handleClick: () => {
        const uuidToValidate = EXPORT_SALE_DOCUMENTS_UUID;
        const isAccessOk = validateModuleAccess(uuidToValidate);
        if (isAccessOk) setIsExportModalVisible(true);
      },
      isDisabled: isDownloading,
      isHidden: screenWidth <= 900,
      isPrimary: true,
      isLoading: isDownloading,
    },
    otherComponent: screenWidth <= 900 ? optionsSalesDocuments : null,
  };

  return (
    <>
      <div id="viuti-front-mainContent">
        {/*@ts-ignore*/}
        <HeaderNavigation {...headerProps} />
        <div className={style.body}>
          {openFilters && (
            <ModalAcceptCancel
              title={"Filtros"}
              showButtons={{
                showButtonTwo: true,
                showButtonClose: true,
                showButtonOne: false,
              }}
              buttonTwo={{
                textButtonTwo: "Cargar",
                actionButtonTwo: () => {
                  closeFilter();
                },
              }}
              actionButtonClose={() => setOpenFilters(false)}
            >
              <div className={style.containerFilter}>
                <header className={style.headerfilter}>
                  <div className={style.inputContainer}>
                    <SelectInput
                      label=""
                      value={form.idBox}
                      items={[
                        {
                          name: "Todas",
                          value: "Todas",
                        },
                        ...boxes,
                      ]}
                      handleChange={(e) =>
                        setForm({ ...form, idBox: e.target.value })
                      }
                      name="position"
                      placeholder="Caja"
                    />
                  </div>
                  <div className={style.inputContainer}>
                    <SelectInput
                      label=""
                      value={form.typeDocumetnId}
                      items={typeDocuments}
                      handleChange={(e) =>
                        setForm({ ...form, typeDocumetnId: e.target.value })
                      }
                      name="position"
                      placeholder="Tipo de documento"
                    />
                  </div>
                  <div className={style.inputContainer}>
                    <SelectInput
                      label=""
                      value={form.status}
                      items={state}
                      handleChange={(e) =>
                        setForm({ ...form, status: e.target.value })
                      }
                      name="position"
                      placeholder="Estado"
                    />
                  </div>

                  <div className={style.inputContainer}>
                    <SelectInput
                      label=""
                      value={form.statusFE}
                      items={stateFE}
                      handleChange={(e) =>
                        setForm({ ...form, statusFE: e.target.value })
                      }
                      name="position"
                      placeholder="Estado FE"
                    />
                  </div>
                  <div className={style.inputContainer}>
                    {/*@ts-ignore*/}
                    <DateInput
                      label=""
                      inputProps={{
                        placeholder: "Seleccionar fecha",
                        value: form.startDate,
                        onChange: (e) =>
                          setForm({ ...form, startDate: e.target.value }),
                      }}
                    />
                  </div>
                  <div className={style.inputContainer}>
                    {/*@ts-ignore*/}
                    <DateInput
                      label=""
                      inputProps={{
                        placeholder: "Seleccionar fecha",
                        value: form.endDate,
                        onChange: (e) =>
                          setForm({ ...form, endDate: e.target.value }),
                      }}
                      error={error.endDate}
                      touched={true}
                    />
                  </div>
                </header>
              </div>
            </ModalAcceptCancel>
          )}

          {showFilter && (
            <header className={style.headerfilter}>
              <div className={style.inputContainer}>
                <SelectInput
                  label="Caja"
                  value={form.idBox}
                  items={[
                    {
                      name: "Todas",
                      value: "Todas",
                    },
                    ...boxes,
                  ]}
                  handleChange={(e) =>
                    setForm({ ...form, idBox: e.target.value })
                  }
                  name="position"
                />
              </div>
              <div className={style.inputContainer}>
                <SelectInput
                  label="Tipo de documento"
                  value={form.typeDocumetnId}
                  items={typeDocuments}
                  handleChange={(e) =>
                    setForm({ ...form, typeDocumetnId: e.target.value })
                  }
                  name="position"
                />
              </div>
              <div className={style.inputContainer}>
                <SelectInput
                  label="Estado"
                  value={form.status}
                  items={state}
                  handleChange={(e) =>
                    setForm({ ...form, status: e.target.value })
                  }
                  name="position"
                />
              </div>

              <div className={style.inputContainer}>
                <SelectInput
                  label="Estado FE"
                  value={form.statusFE}
                  items={stateFE}
                  handleChange={(e) =>
                    setForm({ ...form, statusFE: e.target.value })
                  }
                  name="position"
                />
              </div>
              <div className={style.dateContainer}>
                <div className={style.inputContainer}>
                  {/*@ts-ignore*/}
                  <DateInput
                    label="Fecha de inicio"
                    inputProps={{
                      placeholder: "Seleccionar fecha",
                      value: form.startDate,
                      onChange: (e) =>
                        setForm({ ...form, startDate: e.target.value }),
                    }}
                  />
                </div>
                <div className={style.inputContainer}>
                  {/*@ts-ignore*/}
                  <DateInput
                    label="Fecha de fin"
                    inputProps={{
                      placeholder: "Seleccionar fecha",
                      value: form.endDate,
                      onChange: (e) =>
                        setForm({ ...form, endDate: e.target.value }),
                    }}
                    error={error.endDate}
                    touched={true}
                  />
                </div>
              </div>
            </header>
          )}

          <div className={style.searchContainer}>
            <SearchWithColoredBorder
              // @ts-ignore
              width={"100%"}
              label={""}
              value={form.searParameter}
              placeholder={messagePlaceholder()}
              setValue={(value) => setForm({ ...form, searParameter: value })}
              withoutTheContainerWithTheResults={true}
              clearInput={false}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLoad();
                }
              }}
              inputMode={"search"}
            />
            {showIconFilter ? (
              <button
                onClick={() => {
                  setOpenFilters(true);
                }}
                className={style.buttonFilter}
              >
                {/*@ts-ignore*/}
                <Icon path={FilterIcon} size={25} alt={"Filtro"} />
              </button>
            ) : null}
            {showButtonLoad && (
              <ButtonWithoutIcon
                textBttn={"Cargar"}
                handleClick={handleLoad}
                isLoading={buttonFilterIsLoading}
                isPrimary={false}
              />
            )}
          </div>
          <div className={style.tableContainer}>
            {dataTable.length === 0 && !isLoadingSalesDocuments ? (
              <section>
                <NoInfoToShow
                  title="No se encontraron documentos de ventas."
                  message="Intenta con otros filtros o verifica que existan documentos de ventas en el rango de fechas seleccionado."
                  icon={BillIcon}
                />
              </section>
            ) : screenWidth >= 900 ? (
              <TableWrapper
                titles={titles(screenWidth)}
                data={dataTable.map((document_) => ({
                  ...formatDataTable(
                    document_,
                    screenWidth,
                    renderIconWithOptions
                  ),
                }))}
                isLoading={isLoadingSalesDocuments}
                containerStyle={{
                  height: "min-content",
                }}
              />
            ) : (
              // @ts-ignore
              <TableMobile
                isLoading={isLoadingSalesDocuments}
                dataTable={dataTable.map((document) => ({
                  ...createObjectMobile({
                    document,
                    screenWidth,
                    setSelectedDocumentDetails,
                    setSelectedDocumentDetailsModalTitle,
                    setIsSaleDetailsModalVisible,
                    setSelectedTicketToCancel,
                    setIsCancelTicketModalVisible,
                    setSelectedDocumentForCreditNote,
                    setIsCreditNoteModalVisible,
                    onNotification,
                    reloadTable: handleLoad,
                  }),
                }))}
              />
            )}
            {buttonsPagination > 1 && (
              //@ts-ignore
              <PaginationWrapper
                currentPagination={currentPagination}
                setCurrentPagination={(e) => setCurrentPagination(e)}
                optionsPerPage={10}
                numberOfButtons={buttonsPagination}
              />
            )}
          </div>
        </div>
      </div>
      {isSaleDetailsModalVisible && (
        <SalesDocumentDetailModal
          modalTitle={selectedDocumentDetailsModalTitle}
          isVisible={isSaleDetailsModalVisible}
          setIsVisible={setIsSaleDetailsModalVisible}
          document={selectedDocumentDetails}
          onNotification={onNotification}
        />
      )}
      {isCancelTicketModalVisible && (
        <CancelTicketModal
          isVisible={isCancelTicketModalVisible}
          setIsVisible={setIsCancelTicketModalVisible}
          document={selectedTicketToCancel}
          onNotification={onNotification}
          reloadTable={() => handleLoad()}
        />
      )}
      {isExportModalVisible && (
        <ShareSalesDocumentsReportModal
          isVisible={isExportModalVisible}
          setIsVisible={setIsExportModalVisible}
          onNotification={onNotification}
          filters={form}
        />
      )}
      {isCreditNoteModalVisible && (
        <CreditNoteModal
          isVisible={isCreditNoteModalVisible}
          setIsVisible={setIsCreditNoteModalVisible}
          document={selectedDocumentForCreditNote}
          onNotification={onNotification}
          reloadTable={() => handleLoad()}
        />
      )}
    </>
  );
};

export default SaleDocumentReportView;
