import React, { useEffect, useState } from "react";
// @ts-ignore
import styles from "./TableMobile.module.css";
import Icon from "../../../ui/atoms/Icon/Icon";
import { AltArrowDownIcon } from "@Models/icons";
import Dropdown from "../../../../../src/components/ui/organism/Dropdown/Dropdown";
import ListSkeleton from "@Molecules/ListSkeleton/ListSkeleton";

const TableMobile = ({
  dataTable,
  numberOfRows = 10,
  isLoading,
  icon = AltArrowDownIcon,
  actionExist = false,
  action,
  styleTitles = {},
  rowStyles = {},
}) => {
  const [showDropdown, setShowDropdown] = useState(null);

  useEffect(() => {
    setShowDropdown(null);
  }, [isLoading, dataTable]);

  const handleChatClick = (id) => {
    if (actionExist) {
      return action(id);
    } else {
      if (String(id).slice(0, 7) === "default") return;
      setShowDropdown(showDropdown === id ? null : id);
    }
  };

  if (isLoading) return <ListSkeleton numberOfRows={numberOfRows} />;
  return (
    <ul className={styles.container}>
      {dataTable.map((data) => (
        <li key={data.id} className={styles.containerDocument}>
          <button
            className={styles.header}
            onClick={() => handleChatClick(data.id)}
            style={rowStyles}
          >
            <div
              style={styleTitles}
              className={`${styles.titles} ${
                showDropdown === data.id ? styles.titleActive : ""
              }`}
            >
              {data.header.map((header, index) => (
                <span key={index + String(data.id)}>{header}</span>
              ))}
            </div>
            <div
              className={`${styles.iconTable} ${
                showDropdown === data.id
                  ? styles.iconActive
                  : styles.iconInactive
              }`}
              style={{
                opacity: String(data.id).slice(0, 7) !== "default" ? 1 : 0,
              }}
            >
              <Icon path={icon} size={15} alt={"flecha"} color={"#fff"} />
            </div>
          </button>
          <Dropdown
            isOpen={showDropdown === data.id}
            transitionDurationInMs={350}
            id={data.id}
            containerStyle={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              overflow: "hidden",
              borderTop: "none",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <ul className={styles.bodyList}>
              {data.body.map((row, index) => (
                <li key={index + String(data.id)} className={styles.row}>
                  <span>{row.item1}</span>
                  <span>{row.item2}</span>
                </li>
              ))}
            </ul>
          </Dropdown>
        </li>
      ))}
    </ul>
  );
};

export default TableMobile;
