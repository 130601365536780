// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z7Qu2DNirtBtd9pq4hh4 {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.ok2JZNqIjoaPo7WJYecg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    45deg,
    #dcdcdc,
    #dcdcdc,
    rgb(97, 58, 255),
    rgb(94, 65, 212),
    #e0e0e0,
    #dcdcdc
  );
  opacity: 0.3;
  background-size: 600% 300%;
  color: #333;
  border-radius: 5px;
  animation: ui_6NOUikkkY3H3J6UWR 2s ease-in-out infinite;
}

.IHZ61Zm_O8TH3L8i1sPs {
  width: 70%;
  height: 18px;
  border-radius: 4px;
  background-color: #a9a9a9;
  opacity: 0.7;
}

.vETMAw6j8PyPmFBuq62M {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #4700cc;
  opacity: 0.4;
}

@keyframes ui_6NOUikkkY3H3J6UWR {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ListSkeleton/ListSkeleton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B;;;;;;;;GAQC;EACD,YAAY;EACZ,0BAA0B;EAC1B,WAAW;EACX,kBAAkB;EAClB,uDAA+C;AACjD;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,6BAA6B;EAC/B;;EAEA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".containerSkeleton {\n  width: 100%;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n}\n\n.headerSkeleton {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: linear-gradient(\n    45deg,\n    #dcdcdc,\n    #dcdcdc,\n    rgb(97, 58, 255),\n    rgb(94, 65, 212),\n    #e0e0e0,\n    #dcdcdc\n  );\n  opacity: 0.3;\n  background-size: 600% 300%;\n  color: #333;\n  border-radius: 5px;\n  animation: changeColors 2s ease-in-out infinite;\n}\n\n.titleSkeleton {\n  width: 70%;\n  height: 18px;\n  border-radius: 4px;\n  background-color: #a9a9a9;\n  opacity: 0.7;\n}\n\n.iconSkeleton {\n  width: 26px;\n  height: 26px;\n  border-radius: 50%;\n  background-color: #4700cc;\n  opacity: 0.4;\n}\n\n@keyframes changeColors {\n  0% {\n    background-position: 100% 50%;\n  }\n\n  100% {\n    background-position: 0% 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerSkeleton": `Z7Qu2DNirtBtd9pq4hh4`,
	"headerSkeleton": `ok2JZNqIjoaPo7WJYecg`,
	"changeColors": `ui_6NOUikkkY3H3J6UWR`,
	"titleSkeleton": `IHZ61Zm_O8TH3L8i1sPs`,
	"iconSkeleton": `vETMAw6j8PyPmFBuq62M`
};
export default ___CSS_LOADER_EXPORT___;
