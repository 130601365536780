import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import style from "./SearchableSelect.module.css";
import { iconPath } from "@Models/localPath";

interface Option {
  name: string;
  value: any;
}

interface SearchableSelectProps {
  label?: string;
  name?: string;
  flex?: number;
  value?: Option;
  touched?: boolean;
  error?: string;
  handleChange?: any;
  handleBlur?: () => void;
  disabled?: boolean;
  items: Option[];
  required?: boolean;
  width?: string;
  marginBottom?: string | number;
  id?: string;
  placeholder?: string;
  isClearable?: boolean;
  containerStyle?: any;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  label,
  name,
  flex = 1,
  value,
  touched,
  error,
  handleChange,
  handleBlur,
  disabled,
  items,
  required,
  width = "auto",
  marginBottom,
  id,
  placeholder = "Seleccionar",
  isClearable = false,
  containerStyle,
}) => {
  // const [inputWidth, setInputWidth] = useState("auto");

  const adaptedItems = items.map((item) => ({
    label: item?.name,
    id: item?.value,
  }));
  console.log(value, "value");

  return (
    <div
      className={style.inputContainer}
      style={{
        flex,
        // width: inputWidth,
        minWidth: "200px",
        width: "100%",
        ...containerStyle,
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      {label && (
        <label className={style.inputLabel}>
          {label}
          {!disabled && required && <p className={style.inputRequired}>*</p>}
        </label>
      )}
      <Autocomplete
        value={value?.value}
        onChange={(_, newValue) => {
          handleChange(newValue);
        }}
        options={adaptedItems}
        getOptionLabel={(option) => option?.label || ""}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        disableClearable={!isClearable}
        disabled={disabled}
        id={disabled ? "disabled" : id}
        noOptionsText="No hay resultados disponibles"
        ListboxProps={{
          style: {
            maxWidth: "none",
            width: "max-content",
            minWidth: "100%",
          },
        }}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            padding: "5px 10px 5px 0px",
            maxHeight: "40px",
            fontSize: "var(--font-size-reading)",
            fontFamily: "Mulish",
            borderRadius: "5px",
            whiteSpace: "nowrap",
            width: "100%",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#392b75",
            },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: touched && error ? "red" : "#dadada",
            transition: "all 150ms ease-in-out",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#392b75",
          },
          "& .MuiAutocomplete-input": {
            padding: "0px 10px !important",
            whiteSpace: "nowrap",
            overflow: "visible",
            textOverflow: "clip",
            width: "100% !important",
          },
          "& .MuiAutocomplete-endAdornment": {
            right: "9px !important",
          },
          "& .MuiAutocomplete-popper": {
            marginTop: "8px !important",
            width: "max-content !important",
            minWidth: "100% !important",
          },
          "& .MuiAutocomplete-paper": {
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            marginTop: "4px",
            width: "max-content",
            minWidth: "100%",
          },
          "& .MuiAutocomplete-listbox": {
            padding: "8px 0",
            maxHeight: "250px",
            width: "max-content",
            minWidth: "100%",
            "& .MuiAutocomplete-option": {
              padding: "8px 16px",
              // whiteSpace: "nowrap",
              width: "max-content",
              minWidth: "100%",
              '&[aria-selected="true"]': {
                backgroundColor: "rgba(57, 43, 117, 0.08)",
              },
              "&.Mui-focused": {
                backgroundColor: "rgba(57, 43, 117, 0.04)",
              },
            },
          },
          bgcolor: disabled ? "#FAFAFA" : "#fff",
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? "none" : "auto",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                display: "none",
              },
              "& .MuiInputBase-root": {
                padding: "0px",
                width: "100%",
              },
              "& .MuiInputBase-input": {
                height: "40px",
                fontSize: "var(--font-size-reading)",
                fontFamily: "Mulish",
                color: "#4d4d4d",
                minHeight: "1.4375em",
                whiteSpace: "nowrap",
                overflow: "visible",
                textOverflow: "clip",
                width: "100%",
              },
            }}
          />
        )}
        renderOption={(props, option, { index }) => {
          const style = {
            fontSize: "var(--font-size-reading)",
            fontFamily: "Mulish",
            padding: "8px 16px",
            width: "100%",
            minWidth: "100%",
          };

          if (index === 0) {
            return (
              <li
                style={{
                  ...style,
                  color: "#4d4d4d",
                  backgroundColor: "rgba(25, 118, 210, 0.08)",
                  opacity: 0.38,
                }}
              >
                {placeholder}
              </li>
            );
          }
          return (
            <li {...props} style={style}>
              {option.label}
            </li>
          );
        }}
      />
      {touched && error && (
        <span className={style.inputErrorContainer}>
          <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
          <p className={style.inputError}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default SearchableSelect;
