import { trackAction_service } from "@Services/tracking/tracking.service";
import { mapActionToModuleAndAction } from "./functions/mapActionToModuleAndAction";

const trackUserAction = async (actionId) => {
  try {
    // Obtén los datos desde el localStorage
    const account_info = JSON.parse(localStorage.getItem("account_info"));

    const { modulo, action } = mapActionToModuleAndAction(actionId);

    const { company, lounge, employee } = account_info;

    const data = {
      empresaId: company.id,
      empresaNombre: company.name,
      localId: lounge.id,
      localNombre: lounge.name,
      usuarioId: employee.id,
      usuarioNombre: `${employee?.name} ${employee?.lastName}`,
      moduloId: modulo.id,
      accionId: action.id,
    };

    trackAction_service(data);
  } catch (err) {
    // console.log(err);
  }
};

export default trackUserAction;
