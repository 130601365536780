import React from "react";
import { CircleClientIcon } from "@Models/icons";
import style from "./Customer.module.css";
//@ts-ignore
import { Icon } from "@viuti/recursos";
import { ValidationMessage } from "../AppointmentDetail/ValidationMessage/ValidationMessage";

export function NoCustomer({ handleButtonClickFocusInput, isLoading }) {
  return (
    <div>
      {isLoading ? (
        <div className={style.headerSkeleton}>
          <div className={style.titleSkeleton}></div>
          <div className={style.titleSkeleton}></div>
          <div className={style.titleSkeleton}></div>
          <div className={style.titleSkeleton}></div>
          <ValidationMessage message="Cargando reserva..." />
        </div>
      ) : (
        <button
          className={style.section_no_customer}
          onClick={() => handleButtonClickFocusInput()}
        >
          <Icon path={CircleClientIcon} size={48} color={"#937cf4"} alt="" />
          <p>No hay un cliente seleccionado.</p>
          <strong>Click aquí para seleccionar un cliente</strong>
        </button>
      )}
    </div>
  );
}
