import React, { useLayoutEffect, useState } from "react";
import { iconPath } from "../../../../models/localPath";
//@ts-ignore
import style from "./DatePicker.module.css";

interface DayObject {
  number?: number;
  month?: number;
  year?: number;
}

export function DatePicker({ handleDatePicker }) {
  const [dateList, setDateList] = useState<any>([]);
  const [day, setDay] = useState<DayObject>({});
  const [intervalsLeft, setIntervalsLeft] = useState([]);
  const [intervalsRight, setIntervalsRight] = useState([]);

  const [intervalIdLeft, setIntervalIdLeft] = useState<NodeJS.Timer | null>(
    null
  );
  const [intervalIdRight, setIntervalIdRight] = useState<NodeJS.Timer | null>(
    null
  );

  const toDayName = (n) => {
    switch (n) {
      case 0:
        return "Domingo";
      case 1:
        return "Lunes";
      case 2:
        return "Martes";
      case 3:
        return "Miercoles";
      case 4:
        return "Jueves";
      case 5:
        return "Viernes";
      case 6:
        return "Sabado";
    }
  };

  const toMonthName = (n) => {
    switch (n) {
      case 1:
        return "ENE";
      case 2:
        return "FEB";
      case 3:
        return "MAR";
      case 4:
        return "ABR";
      case 5:
        return "MAY";
      case 6:
        return "JUN";
      case 7:
        return "JUL";
      case 8:
        return "AGO";
      case 9:
        return "SEP";
      case 10:
        return "OCT";
      case 11:
        return "NOV";
      case 12:
        return "DIC";
    }
  };

  const generateDateList = () => {
    let date = new Date();
    let newDateList = [];
    for (let i = 0; i < 365; i++) {
      newDateList.push({
        number: String(date.getDate()).padStart(2, "0"),
        day: toDayName(date.getDay()),
        month: String(date.getMonth() + 1).padStart(2, "0"),
        year: date.getFullYear(),
      });
      date.setDate(date.getDate() + 1);
    }
    return newDateList;
  };

  const excludedDates = ["2023-04-05", "2023-04-10", "2023-04-15"];

  useLayoutEffect(() => {
    // instance.defaults.headers.common.Authorization = `Bearer ${authToken}`; // Here we set the mock authorization token
    const newDateList = generateDateList();
    setDateList(newDateList);

    // const enabledDates = newDateList.filter(
    //   (d) =>
    //     !excludedDates.includes(
    //       `${d.year}-${("0" + d.month).slice(-2)}-${("0" + d.number).slice(-2)}`
    //     )
    // );
    setDay(dateList);
  }, []);

  const autoScroll = (direction) => {
    if (direction === "left") {
      setIntervalIdLeft(
        setInterval(() => {
          document.getElementById("scroll")?.scrollBy(-3, 0);
        }, 5)
      );
      setIntervalsLeft([...intervalsLeft, intervalIdLeft]);
    } else {
      setIntervalIdRight(
        setInterval(() => {
          document.getElementById("scroll")?.scrollBy(3, 0);
        }, 5)
      );
      setIntervalsRight([...intervalsRight, intervalIdRight]);
    }
  };
  const clearRunningIntervals = () => {
    intervalsLeft.map((i) => {
      clearInterval(i);
      return null;
    });
    intervalsRight.map((i) => {
      clearInterval(i);
      return null;
    });
  };

  return (
    <div
      style={{
        marginTop: "25px",
        backgroundColor: "white",
        border: "2px solid #937CF4",
        borderColor: "#937CF4",
      }}
    >
      <div
        style={{
          margin: "7px",
          color: "#937CF4",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        Fecha
      </div>
      <div
        style={{
          // width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {window.innerWidth > 10 ? (
          <img
            src={`${iconPath}/orientation/altArrowLeft.svg`}
            alt="left"
            style={{
              position: "absolute",
              left: "15px",
              height: "15px",
              cursor: "pointer",
            }}
            id="scroll-left"
            onMouseDown={() => autoScroll("left")}
            onMouseUp={() => {
              clearInterval(intervalIdLeft);
              clearRunningIntervals();
            }}
            onDrag={() => {
              clearInterval(intervalIdLeft);
            }}
          />
        ) : null}

        <div className={style.DataContainer} id="scroll">
          {dateList.map((d, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                marginTop: "-30px",
              }}
            >
              <span
                style={{
                  fontSize: "13px",
                  lineHeight: "40px",
                  color: excludedDates.includes(
                    `${d.year}-${("0" + d.month).slice(-2)}-${(
                      "0" + d.number
                    ).slice(-2)}`
                  )
                    ? "#9e9e9e"
                    : "inherit",
                }}
              >
                {d.day[0]}
              </span>
              <div
                style={{
                  width: "40px",
                  margin: "0",
                  padding: "0",
                  backgroundColor: "transparent",
                  height: "0",
                  border:
                    "1px solid " +
                    (excludedDates.includes(
                      `${d.year}-${("0" + d.month).slice(-2)}-${(
                        "0" + d.number
                      ).slice(-2)}`
                    )
                      ? "#9e9e9e"
                      : "#9e9e9e"),
                }}
              />
              <div
                className={style.hovereffect}
                onClick={() => {
                  if (
                    !excludedDates.includes(
                      `${d.year}-${("0" + d.month).slice(-2)}-${(
                        "0" + d.number
                      ).slice(-2)}`
                    )
                  )
                    setDay(d);
                  handleDatePicker(`${d.year}-${d.month}-${d.number}`);
                }}
                style={{
                  borderRadius: "50%",
                  // backgroundColor:
                  //   day.number === d.number &&
                  //   day.month === d.month &&
                  //   day.year === d.year
                  //     ? "#9e3ab3"
                  //     : "transparent",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: "9px 0 4px",
                }}
              >
                <span
                  style={{
                    color: excludedDates.includes(
                      `${d.year}-${("0" + d.month).slice(-2)}-${(
                        "0" + d.number
                      ).slice(-2)}`
                    )
                      ? "#9e3ab3"
                      : day.number === d.number &&
                        day.month === d.month &&
                        day.year === d.year
                      ? "#ffffff"
                      : "#bdbdbd",
                    fontSize: "13px",
                    lineHeight: "40px",
                  }}
                >
                  {("0" + d.number).slice(-2)}
                </span>
              </div>
              <span
                style={{
                  fontSize: "13px",
                  color: "#9e3ab3",
                  fontWeight:
                    day.number === d.number &&
                    day.month === d.month &&
                    day.year === d.year
                      ? "700"
                      : "500",
                  visibility:
                    d.number === 1 ||
                    index === 0 ||
                    (day.number === d.number &&
                      day.month === d.month &&
                      day.year === d.year)
                      ? "visible"
                      : "hidden",
                }}
              >
                {toMonthName(parseInt(d.month) || 1)}
              </span>
            </div>
          ))}
        </div>
        {window.innerWidth > 10 ? (
          <img
            src={`${iconPath}/orientation/altArrowLeft.svg`}
            alt="right"
            style={{
              position: "absolute",
              right: "15px",
              height: "15px",
              transform: "rotate(180deg)",
              cursor: "pointer",
            }}
            onMouseDown={() => autoScroll("right")}
            onMouseUp={() => {
              clearInterval(intervalIdRight);
              clearRunningIntervals();
            }}
            onDrag={() => {
              clearInterval(intervalIdRight);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default DatePicker;
