import React, { useEffect } from "react";
//@ts-ignore
import style from "./FormAddMore.module.css";
import ButtonAddMore from "../../atoms/buttons/ButtonAddMore/ButtonAddMore";

const FormAddMore = ({ childrens, buttonText, buttonAction = () => {} }) => {
  const [childrensState, setChildrensState] = React.useState(childrens);
  useEffect(() => setChildrensState(childrens), [childrens]);
  return (
    <div className={style.containerFormAddMore}>
      {childrensState &&
        childrensState.map((child, index) => {
          return (
            <div key={child.props.id} className={style.subContainer}>
              {child}
            </div>
          );
        })}
      {buttonText && (
        <ButtonAddMore textButton={buttonText} handleClick={buttonAction} />
      )}
    </div>
  );
};

export default FormAddMore;
