import React, { useRef } from "react";
//@ts-ignore
import style from "./searchCard.module.css";
import CardResult from "./components/cardResult";

const SearchCard = ({
  results,
  addCard,
  setTextValue,
  setResults,
  hasIntro,
  cards,
  messageButtonCreate,
}) => {
  const smallRef = useRef(null);

  return (
    <div className={style.textInput__result}>
      {results.length === 0 ? (
        <p>No se han encontrado resultados.</p>
      ) : (
        <ul>
          {results.map((result) => {
            return (
              <CardResult
                key={result.id}
                result={result}
                addCard={addCard}
                setResults={setResults}
                setTextValue={setTextValue}
                cards={cards}
              />
            );
          })}
        </ul>
      )}
      {hasIntro && <small ref={smallRef}>{messageButtonCreate}</small>}
    </div>
  );
};

export default SearchCard;
