import React from "react";
//@ts-ignore
import style from "./style/WarehouseIcon.module.css";
import { iconPath } from "../../../../models/localPath";

const WarehouseIcon = ({ title }) => {
  return (
    <section className={style.warehouseContainer}>
      <figure className={style.backgroundImg}>
        <img src={`${iconPath}/products/findBox.svg`} alt="Icono Almacen" />
      </figure>
      <h2>{title}</h2>
    </section>
  );
};

export default WarehouseIcon;
