// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OAtlaWEJXcbS8ClIGgHe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  gap: 20px;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 0px;
    padding: 0px 0px 20px 0px;
  }
}

.OAtlaWEJXcbS8ClIGgHe .ZqvBKiUhm7k4vOVm2Zmh {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 750px) {
    justify-content: start;
  }
}

.kTPNtlKZ2TVs5jEfIevf {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #937cf49c;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

.kTPNtlKZ2TVs5jEfIevf img {
  width: 18px;
  height: auto;
  aspect-ratio: 1;
}

.kTPNtlKZ2TVs5jEfIevf:hover {
  background-color: #ac97ff9c;
}

.PbQfEygCljLLt7oeE4at {
  filter: brightness(0) invert(1);
}

.Oi9Ngkd5M7bHtMRlhpyX {
  display: flex;
  align-items: center;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/HeadBar/HeadBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,SAAS;;EAET;IACE,sBAAsB;IACtB,QAAQ;IACR,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,WAAW;EACX;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 24px 0px;\n  gap: 20px;\n\n  @media screen and (max-width: 750px) {\n    flex-direction: column;\n    gap: 0px;\n    padding: 0px 0px 20px 0px;\n  }\n}\n\n.header .titleContainer {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  width: 100%;\n  @media screen and (max-width: 750px) {\n    justify-content: start;\n  }\n}\n\n.titleBackButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 10px;\n  background-color: #937cf49c;\n  border-radius: 50%;\n  transition: all 150ms ease-in-out;\n  cursor: pointer;\n}\n\n.titleBackButton img {\n  width: 18px;\n  height: auto;\n  aspect-ratio: 1;\n}\n\n.titleBackButton:hover {\n  background-color: #ac97ff9c;\n}\n\n.titleBackIcon {\n  filter: brightness(0) invert(1);\n}\n\n.buttonsContainer {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `OAtlaWEJXcbS8ClIGgHe`,
	"titleContainer": `ZqvBKiUhm7k4vOVm2Zmh`,
	"titleBackButton": `kTPNtlKZ2TVs5jEfIevf`,
	"titleBackIcon": `PbQfEygCljLLt7oeE4at`,
	"buttonsContainer": `Oi9Ngkd5M7bHtMRlhpyX`
};
export default ___CSS_LOADER_EXPORT___;
