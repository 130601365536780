// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --small-screen: 420px;
  --mobile-breakpoint: "";
  --tablet-breakpoint: "";
  --laptop-breakpoint: "";
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables/breakpoints.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[":root {\n  --small-screen: 420px;\n  --mobile-breakpoint: \"\";\n  --tablet-breakpoint: \"\";\n  --laptop-breakpoint: \"\";\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
