import React from "react";
import style from "./UnauthorizedMessage.module.css";
import { FaRegTimesCircle } from "react-icons/fa";

interface IAuthProps {
  restrictedFeatureName: string;
  onClick?: () => void;
}

interface IUnauthorizedMessageProps extends IAuthProps {
  restrictedUUID: string;
  type?: string;
}
const UnauthorizedMessage: React.FC<IUnauthorizedMessageProps> = ({
  restrictedUUID,
  restrictedFeatureName,
  type = "normal",
}) => {
  if (!restrictedUUID || !restrictedFeatureName) {
    throw new Error("UnauthorizedMessage: missing required props");
  }
  const triggerDeniedEvent = () => {
    window.dispatchEvent(
      new CustomEvent("access-denied", { detail: { accessId: restrictedUUID } })
    );
  };

  switch (type) {
    case "small":
      return (
        <SmallVariant
          restrictedFeatureName={restrictedFeatureName}
          onClick={triggerDeniedEvent}
        />
      );
    case "normal":
      return (
        <NormalVariant
          restrictedFeatureName={restrictedFeatureName}
          onClick={triggerDeniedEvent}
        />
      );
    default:
      return (
        <NormalVariant
          restrictedFeatureName={restrictedFeatureName}
          onClick={triggerDeniedEvent}
        />
      );
  }
};

const SmallVariant: React.FC<IAuthProps> = ({
  restrictedFeatureName,
  onClick,
}) => {
  return (
    <button className={style.small_container} onClick={onClick}>
      <FaRegTimesCircle size={24} color={"#D13112"} />
      <span className={style.info}>
        <p className={style.header}>Acceso denegado</p>
        <p className={style.message}>
          Tu acceso a <b>{restrictedFeatureName}</b> no ha sido autorizado.
        </p>
      </span>
    </button>
  );
};

const NormalVariant: React.FC<IAuthProps> = ({
  restrictedFeatureName,
  onClick,
}) => {
  return (
    <article className={style.container}>
      <button className={style.content} onClick={onClick}>
        <FaRegTimesCircle size={48} color={"#D13112"} />
        <span className={style.info}>
          <p className={style.header}>Acceso denegado</p>
          <p className={style.message}>
            Tu acceso a <b>{restrictedFeatureName}</b> no ha sido autorizado.
          </p>
        </span>
      </button>
    </article>
  );
};

export default UnauthorizedMessage;
