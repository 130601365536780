import React, { useEffect } from "react";
//@ts-ignore
import style from "./SquareRadioButton.module.css";

const SquareRadioButton = ({
  fontSize,
  label,
  checked,
  setCheck,
  disabled,
  id,
}) => {
  const handleChange = () => {
    setCheck(!checked);
  };

  return (
    <label className={style.radioLabel}>
      <input
        style={{ fontSize: fontSize ? `${fontSize}px` : "auto" }}
        type="radio"
        checked={checked}
        onClick={handleChange}
        className={style.radio}
        disabled={disabled}
        id={id}
      />
      {/*@ts-ignore*/}
      <span htmlFor="square-radio" className={style.labelIcon} />
      {label}
    </label>
  );
};

export default SquareRadioButton;
