import React from "react";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import style from "./formDataTable.module.css";
import { getStatusClassName } from "./formDataTable";
import { TripleDotsIcon } from "@Models/icons";

import { getSalePDF } from "@Adapters/saleboxes/getSalePDF.adapter";
import { getSaleCDR } from "@Adapters/saleboxes/getSaleCDR.adapter";
import { getSaleXML } from "@Adapters/saleboxes/getSaleXML.adapter";
import { resendPayment } from "@Adapters/saleboxes/resendPayment.adapter";
import Icon from "../../../../atoms/Icon/Icon";
import IconWithOptions from "../../../../molecules/IconWithOptions/IconWithOptions";

export const createObjectMobile = ({
  document,
  screenWidth,
  setIsSaleDetailsModalVisible,
  setSelectedDocumentDetails,
  setSelectedDocumentDetailsModalTitle,
  setSelectedTicketToCancel,
  setIsCancelTicketModalVisible,
  setSelectedDocumentForCreditNote,
  setIsCreditNoteModalVisible,
  onNotification,
  reloadTable,
}) => {
  // TODO: Cambiar el 00.00 por Gratuito
  const total =
    Number(document.total.replace("S/", "")) === 0 ? "S/ 0.00" : document.total;
  let headerTitles = [
    document.series + "-" + document.number,
    document.justTheDate,
    total,
  ];

  if (screenWidth <= 450) {
    headerTitles = [document.series + "-" + document.number, total];
  }

  const title = `Detalles #${document.series}-${document.number}`;
  const isRejected = document.statusFE === "Rechazado";

  // Grupo 1: Acciones principales
  const mainActions = [
    {
      label: "Ver detalles",
      handler: () => {
        setSelectedDocumentDetails(document);
        setSelectedDocumentDetailsModalTitle(title);
        setIsSaleDetailsModalVisible(true);
      },
    },
    {
      label: "Anular ticket",
      handler: () => {
        setSelectedTicketToCancel(document);
        setIsCancelTicketModalVisible(true);
      },
    },
    {
      label: "Generar nota de crédito",
      handler: () => {
        setSelectedDocumentForCreditNote(document);
        setIsCreditNoteModalVisible(true);
      },
    },
  ];

  // Grupo 2: Opciones de descarga
  const downloadActions =
    document.typeDocument !== 5
      ? [
          {
            type: "separator",
            label: "",
            handler: () => {},
            styles: { margin: "8px 0" },
          },
          {
            label: "Descargar PDF",
            handler: () => downloadFile(document, "PDF", onNotification),
          },
          {
            label: "Descargar CDR",
            handler: () => downloadFile(document, "CDR", onNotification),
          },
          {
            label: "Descargar XML",
            handler: () => downloadFile(document, "XML", onNotification),
          },
        ]
      : [];

  // Grupo 3: Retransmisión SUNAT
  const sunatActions = isRejected
    ? [
        {
          type: "separator",
          label: "",
          handler: () => {},
          styles: { margin: "8px 0" },
        },
        {
          label: "Retransmitir a SUNAT",
          handler: async () => {
            const response = await resendPayment(document.id, onNotification);
            if (response.isSuccessful) reloadTable();
          },
        },
      ]
    : [];

  return {
    id: document.id,
    header: headerTitles,
    body: [
      { item1: "Caja", item2: document.box },
      { item1: "Tipo de doc.", item2: document.type },
      {
        item1: "N° de doc.",
        item2: document.series + "-" + document.number,
      },
      { item1: "Fecha", item2: document.justTheDate },
      { item1: "Hora", item2: document.time },
      {
        item1: "Cajero",
        item2: document.employee,
      },
      {
        item1: "Cliente",
        item2: document.client,
      },
      {
        item1: "Total",
        item2: total,
      },
      { item1: "Estado", item2: document.status },
      {
        item1: "Estado FE",
        item2: (
          <div className={getStatusClassName(document.statusFE, style)}>
            {document.statusFE}
          </div>
        ),
      },
      {
        item1: "Opciones",
        item2: (
          <div style={{ display: "flex", gap: "12px", paddingBottom: "10px" }}>
            <ButtonWithoutIcon
              isPrimary={false}
              textBttn={"Anular"}
              padding={"10px 15px 10px 15px"}
              handleClick={() => {
                setSelectedTicketToCancel(document);
                setIsCancelTicketModalVisible(true);
              }}
            />
            <ButtonWithoutIcon
              isPrimary={true}
              textBttn={"Ver detalles"}
              padding={"10px"}
              handleClick={() => {
                setSelectedDocumentDetails(document);
                setSelectedDocumentDetailsModalTitle(title);
                setIsSaleDetailsModalVisible(true);
              }}
            />
            <IconWithOptions
              icon={
                <Icon
                  color=""
                  path={TripleDotsIcon}
                  size={20}
                  alt={"Tres puntos"}
                />
              }
              actions={[...mainActions, ...downloadActions, ...sunatActions]}
              containerStyles={{
                width: "200px", // Hacemos el menú más ancho en móvil
              }}
              mainButtonStyles={{
                padding: "10px",
                backgroundColor: "#f3f4f6",
                borderRadius: "6px",
                width: "40px",
                height: "40px",
                transition: "background-color 150ms ease-in-out",
              }}
            />
          </div>
        ),
      },
    ],
  };
};

export const downloadFile = async (document, fileType, onNotification) => {
  const fileServiceMap = {
    PDF: getSalePDF,
    CDR: getSaleCDR,
    XML: getSaleXML,
  };

  const contentTypeMap = {
    PDF: "application/pdf",
    CDR: "application/xml",
    XML: "application/xml",
  };

  const serviceFunction = fileServiceMap[fileType];
  const contentType = contentTypeMap[fileType];

  if (serviceFunction) {
    try {
      const data = await serviceFunction(document.id, onNotification);
      if (data.status === 200) {
        let base64String = data.data.base64XMLString;

        if (base64String.includes(";base64,")) {
          base64String = base64String.split(";base64,")[1];
        }

        const fileExtension = fileType.toLowerCase();
        const fileName = `${document.series}-${document.number}.${fileExtension}`;

        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });

        // Usar window.document explícitamente
        if (typeof window !== "undefined") {
          const url = window.URL.createObjectURL(blob);
          const link = window.document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.style.display = "none";
          window.document.body.appendChild(link);
          link.click();
          window.document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
      onNotification({
        message: "Hubo un error al procesar el archivo",
        status: 400,
        toastTimeDuration: 4000,
      });
      console.error("Error al procesar el archivo:", error);
    }
  }
};
