import React from "react";
import {
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage,
  renderPaginationBttns,
} from "./functions/paginationController";

//@ts-ignore
import style from "./styles/PaginationWrapper.module.css";

// Icons
// import { AltArrowLeftIcon, AltArrowRightIcon, AltDoubleArrowLeftIcon, AltDoubleArrowRightIcon } from '@Models/icons';

import { iconPath } from "../../../models/localPath";

const PaginationWrapper = ({
  currentPagination,
  setCurrentPagination,
  options,
  optionsPerPage,
  isFixedToBottom,
  numberOfButtons,
}) => {
  // "numberOfButtons" is the number of buttons that will be passed to the "renderPaginationBttns" function
  /**
   *  The `visibleButtonsCount ` variable is storing the number of available buttons to display in the
   *  pagination component.
   **/
  const visibleButtonsCount = 4;
  let paginationBttns = renderPaginationBttns(
    options,
    optionsPerPage,
    currentPagination,
    visibleButtonsCount,
    numberOfButtons
  );
  let lastPagination =
    numberOfButtons ?? Math.ceil(options && options.length / optionsPerPage);

  return (
    <div
      className={isFixedToBottom ? style.containerFixed : style.containerAll}
    >
      <div className={style.containerPaginated}>
        <div className={style.buttons}>
          <button
            onClick={() => goToFirstPage(setCurrentPagination)}
            className={`${style.orientationBtn} ${
              currentPagination === 1 ? style.disabledBtn : ""
            }`}
            disabled={currentPagination === 1}
          >
            <img
              src={`${iconPath}/orientation/altDoubleArrowLeft.svg`}
              alt="|<"
            />
          </button>
          <button
            onClick={() =>
              goToPreviousPage(currentPagination, setCurrentPagination)
            }
            className={`${style.orientationBtn} ${
              currentPagination === 1 ? style.disabledBtn : ""
            }`}
            disabled={currentPagination === 1}
          >
            <img src={`${iconPath}/orientation/altArrowLeft.svg`} alt="<" />
          </button>
        </div>
        <div className={style.paginationButtonsContainer}>
          {paginationBttns.map((page, index) => (
            <button
              key={`${page}-${index}`}
              className={
                currentPagination === page
                  ? style.cliqueado
                  : style.botonNoCliqueado
              }
              onClick={() => {
                if (page === "...") return;
                setCurrentPagination(page);
              }}
            >
              {page}
            </button>
          ))}
        </div>
        <div className={style.buttons}>
          <button
            onClick={() =>
              goToNextPage(currentPagination, setCurrentPagination)
            }
            className={`${style.orientationBtn} ${
              currentPagination === lastPagination ? style.disabledBtn : ""
            }`}
            disabled={currentPagination === lastPagination}
          >
            <img src={`${iconPath}/orientation/altArrowRight.svg`} alt=">" />
          </button>
          <button
            onClick={() => goToLastPage(setCurrentPagination, lastPagination)}
            className={` ${style.orientationBtn} ${
              currentPagination === lastPagination ? style.disabledBtn : ""
            }`}
            disabled={currentPagination === lastPagination}
          >
            <img
              src={`${iconPath}/orientation/altDoubleArrowRight.svg`}
              alt=">|"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationWrapper;
