import React from "react";
import {
  Icon,
  // @ts-ignore
} from "@viuti/recursos";
import style from "../SaleMovementsReportView.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { CommandIcon, DeleteIcon, PencilWithFrameIcon } from "@Models/icons";
import {
  HiOutlineArrowSmallDown,
  HiOutlineArrowSmallUp,
} from "react-icons/hi2";
import IconWrapper from "../../../../atoms/IconWrapper/IconWrapper";

export const formattedDateAndHour = (inputDate: string): string => {
  if (!inputDate) {
    return " - ";
  }

  // Eliminar cualquier separador y obtener solo los números
  const cleanDate = inputDate.replace(/[^0-9]/g, "");

  // Extraer día, mes y año
  const day = cleanDate.slice(0, 2);
  const month = cleanDate.slice(2, 4);
  const year = cleanDate.slice(4);

  const months = [
    "ene.",
    "feb.",
    "mar.",
    "abr.",
    "may.",
    "jun.",
    "jul.",
    "ago.",
    "sep.",
    "oct.",
    "nov.",
    "dic.",
  ];

  // Validar que el mes sea válido (1-12)
  const monthIndex = parseInt(month) - 1;
  if (monthIndex < 0 || monthIndex >= 12) {
    return " - ";
  }

  const monthName = months[monthIndex];
  const formattedDate = `${day} ${monthName} ${year}`;
  return formattedDate;
};

export const createObjectMovementsMobile = (
  movement: any,
  classCell: (type: string, style: any, isRounded?: boolean) => string,
  setIsEditMovementVisible: (isVisible: boolean) => void,
  setSelectedMovement: (movement: any) => void,
  setIsEditingHistoryVisible: (isVisible: boolean) => void,
  setSelectedMovementEditingHistory: (movement: any) => void,
  setIsDeleteMovementVisible: (isVisible: boolean) => void,
  setSelectedMovementToDelete: (movement: any) => void
) => {
  const EGRESO = "Egreso";
  const validatedIngreso = movement.type !== EGRESO;
  const isRounded = true;
  let headerTitles = [
    <span className={classCell(movement.type, style, isRounded)}>
      {validatedIngreso ? (
        <IconWrapper icon={HiOutlineArrowSmallUp} />
      ) : (
        <IconWrapper icon={HiOutlineArrowSmallDown} />
      )}
      {movement.type}
    </span>,
    formattedDateAndHour(movement.dateMovement),
    formatToSoles(movement.amount),
  ];

  return {
    id: movement.id,
    header: headerTitles,
    body: [
      {
        item1: "Tipo",
        item2: (
          <span className={classCell(movement.type, style)}>
            {movement.type}
          </span>
        ),
      },
      { item1: "Fecha", item2: movement.dateMovement },
      { item1: "Hora", item2: movement.hourMovement },
      {
        item1: "Concepto",
        item2: movement.concept || " - ",
      },
      {
        item1: "Observaciones",
        item2: movement.observation || " - ",
      },
      {
        item1: "Empleado",
        item2: movement.employeeName,
      },
      {
        item1: "Monto",
        item2: (
          <span key={movement.id} className={classCell(movement.type, style)}>
            {formatToSoles(movement.amount)}
          </span>
        ),
      },
      {
        item1: "Opciones",
        item2:
          movement.saleDocumentId === null ? (
            <div className={style.containerButtonsMovementsTable}>
              <button
                onClick={() => {
                  setSelectedMovement(movement);
                  setIsEditMovementVisible(true);
                }}
                className={style.icon__edit}
              >
                <Icon
                  path={PencilWithFrameIcon}
                  color="#45348E"
                  size={16}
                  alt="trash icon"
                />
              </button>
              {movement.modified && (
                <button
                  onClick={() => {
                    setSelectedMovementEditingHistory(movement);
                    setIsEditingHistoryVisible(true);
                  }}
                  className={style.icon__hitory}
                >
                  <Icon
                    path={CommandIcon}
                    color="#45348E"
                    size={16}
                    alt="trash icon"
                  />
                </button>
              )}
              <button
                onClick={() => {
                  setSelectedMovementToDelete(movement);
                  setIsDeleteMovementVisible(true);
                }}
                className={style.icon__delete}
              >
                <Icon
                  path={DeleteIcon}
                  color="#C36363"
                  size={16}
                  alt="trash icon"
                />
              </button>
            </div>
          ) : (
            <p className={style.containerButtonsMovementsTable}> - </p>
          ),
      },
    ],
  };
};
