import React from "react";
//@ts-ignore
import style from "./ButtonWithPlus.module.css";
import { AddIcon } from "../../../../../models/icons";
import Icon from "../../Icon/Icon";

const ButtonWithPlus = ({ text, svg = AddIcon, alt = "imagen", onClick }) => {
  return (
    <button className={style.buttonStyle} onClick={onClick}>
      <Icon path={AddIcon} size={20} color="#937CF4" />
      <p className={style.textStyles}>{text}</p>
    </button>
  );
};

export default ButtonWithPlus;
