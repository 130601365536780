import React from "react";
//@ts-ignore
import componentStyle from "./styles/primaryBttn.module.css";

const PrimaryBttn = ({
  textBttn,
  handleClick,
  isDisabled,
  width,
  isLoading = false,
  fontSize,
  style,
  id,
  padding,
  buttonProps,
}) => {
  return (
    <button
      type="button"
      className={`${componentStyle.primaryButton} ${
        isDisabled ? componentStyle.disabled : ""
      }`}
      style={{
        padding: padding || "0 35px",
        width: width || "auto",
        fontSize: fontSize || "auto",
        ...style,
      }}
      onClick={(e) => {
        try {
          handleClick(e);
        } catch (error) {
          console.warn(error);
        }
      }}
      disabled={isDisabled}
      id={id}
      {...buttonProps}
    >
      {!isLoading ? (
        textBttn
      ) : (
        <div className={componentStyle.ldsRing}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </button>
  );
};

export default PrimaryBttn;
