import React from "react";
//@ts-ignore
import style from "./secondaryBttn.module.css";

const SecondaryBttn = ({
  width,
  textBttn,
  handleClick,
  disabled,
  loading = false,
  fontSize,
  color,
  testId,
  padding,
  buttonProps,
  id = "",
}) => {
  return (
    <button
      style={{
        width: width ? `${width}` : "auto",
        fontSize: fontSize ? `${fontSize}` : "auto",
        color: color ? `${color}` : "#937cf4",
        borderColor: color ? `${color}` : "#937cf4",
        padding: padding ? `${padding}` : "0 35px",
      }}
      className={`${style.secundaryButton} ${
        disabled || loading ? style.disabled : ""
      }`}
      onClick={(e) => handleClick(e)}
      disabled={disabled}
      data-testid={testId}
      id={id}
      {...buttonProps}
    >
      {!loading ? (
        textBttn
      ) : (
        <div className={style.ldsRing}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </button>
  );
};

export default SecondaryBttn;
