import React from "react";
//@ts-ignore
import { DeleteIcon, DocumentIcon } from "@Models/icons";
//@ts-ignore
import styled from "./FileUploader.module.css";
//@ts-ignore
import { DragAndDropFile, ButtonWithIcon } from "@viuti/recursos";

const FileUploader = ({
  file,
  setFile = (e) => {},
  topInformation = true,
  topInformationMsg = "Subir certificado",
  isRemoveAvaliable = true,
  accept = [".pfx"],
  fileMsg = "",
}) => {
  return (
    <section className={styled.newRucsPage__SectionRow}>
      {topInformation && (
        <h2 className={styled.newRucsPage__SectionRow__h2}>
          {topInformationMsg}
        </h2>
      )}
      {!file ? (
        <DragAndDropFile accept={accept} setFile={setFile} />
      ) : (
        <article className={styled.fileLoad}>
          {isRemoveAvaliable && (
            <ButtonWithIcon
              iconSvg={DeleteIcon}
              handleClick={() => {
                setFile(null);
              }}
              iconColor={"#C66464"}
              bgColor={"#fde6e6"}
            />
          )}
          {isRemoveAvaliable && (
            <span className={styled.documentFile}>
              <img src={DocumentIcon} alt="doc icon" />
            </span>
          )}
          <p className={styled.fileLoad__success_message}>
            {isRemoveAvaliable
              ? `Archivo ${file.name} cargado exitosamente!`
              : fileMsg}
          </p>
        </article>
      )}
    </section>
  );
};

export default FileUploader;
