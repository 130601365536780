import React, { useEffect, useState } from "react";
import style from "./EditMovementModal.module.css";
// @ts-ignore
import { SelectInput, TextInput } from "@viuti/recursos";
import { capitalizeWords } from "@Utilities/CapitalizeWords";

import { STATUS_OK } from "@Models/httpsStatus";
import { postConceptMovementsAdapter } from "@Adapters/saleboxes/postConceptMovement.adapter";
import { SearchBarReutilizable } from "../SearchBarReutilizable/SearchBarReutilizable";
import { ModalAcceptCancel } from "@Molecules/index";
import { updateMovement } from "@Adapters/saleboxes/updateMovement.adapter";

interface IEditMovementModal {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  movement: any;
  formFilter: any;
  getMovements: (
    idBox: string,
    type: string,
    searParameter: string,
    concept: string,
    startDate: string,
    endDate: string,
    page: number
  ) => Promise<void>;
  movementConcepts: {
    concepts: { name: string; value: number; movementType: number }[];
    entryConcepts: { name: string; value: string }[];
    outputConcepts: { name: string; value: string }[];
  };
  movementTypes: { name: string; value: string }[];
  reduxSelector: {
    movements: {
      movements: any[];
      pages: number;
      currentPagination: number;
      shouldReloadTable: boolean;
    };
    saleConfig: {
      loungeEmployees: any[];
    };
  };
  onNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void;
  onConceptCreation: (concept: any) => void;
  onClose: () => void;
}

const EditMovementModal = ({
  isVisible,
  setIsVisible,
  movement,
  formFilter,
  movementConcepts,
  reduxSelector,
  getMovements,
  movementTypes,
  onNotification,
  onConceptCreation,
  onClose,
}: IEditMovementModal) => {
  const { concepts, entryConcepts, outputConcepts } = movementConcepts;
  const [error, setError] = useState({
    type: "",
    amount: "",
    concept: "",
  });
  const { currentPagination } = reduxSelector.movements;
  const loungeEmployees = reduxSelector.saleConfig.loungeEmployees;

  const adaptEmployees = loungeEmployees.map((employee) => {
    return {
      name: capitalizeWords(employee.name + " " + employee.lastName),
      value: employee.id,
    };
  });

  const defaultConcept = concepts.find(
    (concept) => concept.name === movement.concept
  );

  const defaultType = movementTypes.find((type) => type.name === movement.type);
  const [isModalPrimaryButtonDisabled, setIsModalPrimaryButtonDisabled] =
    useState(false);

  const defaultEmployee = adaptEmployees.find(
    (employee) =>
      employee.name.toLocaleLowerCase() ===
      movement.employeeName.toLocaleLowerCase()
  );

  const initialForm = {
    type: String(defaultType?.value),
    concept: Number(defaultConcept?.value),
    employee: Number(defaultEmployee?.value),
    amount: String(movement.amount),
    observations:
      String(movement?.observation) === "null" ? "" : movement?.observation,
  };

  const [searchInput, setSearchInput] = useState(defaultConcept.name);
  const [form, setForm] = useState({
    type: String(defaultType?.value),
    concept: Number(defaultConcept?.value) || 0 || "",
    employee: Number(defaultEmployee?.value),
    amount: String(movement?.amount),
    observations:
      String(movement?.observation) === "null" ? "" : movement?.observation,
  });
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (update) {
      setUpdate(false);
    }
    setForm(initialForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, movement]);

  const setDefaultForm = () => {
    setForm({
      type: "",
      concept: 0,
      employee: 0,
      amount: "",
      observations: "",
    });
  };

  useEffect(() => {
    setError({
      type: "",
      amount: "",
      concept: "",
    });
  }, [form.type, form.amount, form.concept]);

  const actionButtonTwo = async () => {
    setIsModalPrimaryButtonDisabled(true);
    if (form.amount === "" || form.concept === 0 || form.concept === "") {
      if (form.amount === "") {
        setError({ ...error, amount: "Falta completar este campo." });
      }
      if (form.concept === 0 || form.concept === "") {
        setError({ ...error, concept: "Debes seleccionar un concepto." });
      }
      if (form.type === "") {
        setError({ ...error, type: "Falta completar este campo." });
      }
      return;
    }
    const res = await updateMovement(
      movement.id,
      form.employee,
      form.concept,
      form.type,
      form.amount,
      form.observations,
      initialForm,
      onNotification
    );
    if (res.status === STATUS_OK) {
      setDefaultForm();
      await getMovements(
        formFilter.idBox,
        formFilter.type,
        formFilter.searParameter,
        formFilter.concept,
        formFilter.startDate,
        formFilter.endDate,
        currentPagination
      );
      setIsVisible(false);
    }
    setIsModalPrimaryButtonDisabled(false);
  };

  // useEffect(() => {
  // 	modalContext.handleButtonTwoChange({
  // 		...modalContext.buttonTwo,
  // 		textButtonTwo: "Editar",
  // 		actionButtonTwo: actionButtonTwo,
  // 		isDisabled: JSON.stringify(initialForm) === JSON.stringify(form),
  // 	});
  // 	modalContext.handleActionButtonCloseChange(() => {
  // 		setDefaultForm();
  // 		modalContext.handleClose();
  // 		setUpdate(true);
  // 	});
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [form, initialForm]);

  useEffect(() => {
    if (form.type !== initialForm.type) {
      setForm({ ...form, concept: 0 });
      setSearchInput("");
    } else {
      setForm({ ...form, concept: initialForm.concept });
      setSearchInput(
        concepts.find((concept) => concept.value === initialForm.concept).name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.type]);

  let filterConcepts = (
    form.type === "Todos" || form.type === ""
      ? concepts
      : form.type === "1"
      ? entryConcepts
      : outputConcepts
  ).map((concept) => ({
    name: concept.name,
    value: concept.value,
  }));

  const adaptFilterConcepts = filterConcepts.map((concept) => {
    return {
      name: concept.name,
      value: concept.value,
    };
  });

  const handleCloseModal = () => {
    setIsVisible(false);
    setForm(initialForm);
    onClose();
  };

  return (
    <ModalAcceptCancel
      title={"Editar movimiento"}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Editar",
        actionButtonTwo: actionButtonTwo,
        isDisabled: isModalPrimaryButtonDisabled,
        isLoading: isModalPrimaryButtonDisabled,
      }}
      actionButtonClose={handleCloseModal}
    >
      <div className={style.container}>
        <div className={style.flexContainer}>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <SelectInput
              label="Tipo de movimiento"
              value={form.type}
              items={movementTypes}
              handleChange={(e) => {
                setForm({ ...form, type: e.target.value });
              }}
              name="position"
              required
              error={error.type}
            />
          </div>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <SearchBarReutilizable
              label={"Concepto"}
              placeholder={"Buscar/agregar concepto"}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              data={adaptFilterConcepts}
              form={form}
              setForm={setForm}
              concepts={concepts}
              onClickResult={(result) => {
                const concept = concepts.find(
                  (concept) => concept.name === result
                );
                setForm({ ...form, concept: concept.value });
                setError({
                  ...error,
                  concept: "",
                });
              }}
              onCreateItem={async (newConcept) => {
                if (newConcept === "") {
                  setError({
                    ...error,
                    concept: "Falta completar este campo.",
                  });
                  return;
                }
                if (!/^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ\s]*$/.test(newConcept)) {
                  setError({
                    ...error,
                    concept: "No se permiten caracteres especiales.",
                  });
                  return;
                }
                const validarSiElConceptoExiste = concepts.find(
                  (concept) =>
                    concept.name.toLowerCase().trim() ===
                      newConcept.toLowerCase().trim() &&
                    concept.movementType === Number(form.type)
                );
                if (validarSiElConceptoExiste) {
                  const concept = concepts.find(
                    (concept) =>
                      concept.name.toLowerCase().trim() ===
                        newConcept.toLowerCase().trim() &&
                      concept.movementType === Number(form.type)
                  );
                  setForm({ ...form, concept: concept.value });
                  setError({
                    ...error,
                    concept: "",
                  });
                  return;
                } else {
                  await postConceptMovementsAdapter(
                    newConcept,
                    form.type,
                    setSearchInput,
                    setForm,
                    setError,
                    onNotification,
                    onConceptCreation
                  );
                }
              }}
              messageCreateItem="Pulsa intro para crear este nuevo concepto."
              disabled={form.type === ""}
              required={true}
              errorMessage={error.concept}
              setError={setError}
            />
          </div>
        </div>

        <div className={style.flexContainer}>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <SelectInput
              label="Empleado asociado"
              value={form.employee}
              items={adaptEmployees}
              handleChange={(e) => {
                setForm({ ...form, employee: e.target.value });
              }}
              name="position"
            />
          </div>
          <div className={`${style.inputContainer} ${style.inputWidth}`}>
            <TextInput
              label="Monto"
              value={form.amount}
              prefix={form.amount ? "S/" : null}
              handleChange={(e) => {
                let inputAmount = e.target.value;
                inputAmount = inputAmount.slice(0, 20); // Limitar a 20 caracteres
                const formattedAmount = inputAmount.replace(/[^0-9.]/g, ""); // Elimina todos los caracteres excepto números y puntos
                const isNumeric = /^[0-9.]*$/.test(formattedAmount); // Verifica si la cadena contiene solo números y puntos

                if (isNumeric) {
                  setForm({ ...form, amount: formattedAmount });
                }
              }}
              inputProps={{
                onBlur: (e: any) => {
                  let num = Number(form.amount);
                  let formattedAmount;
                  if (!isNaN(num)) {
                    formattedAmount = num
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                  } else {
                    formattedAmount = "0.00";
                  }

                  setForm({ ...form, amount: formattedAmount });
                },
              }}
              placeholder="Ingrese el monto"
              required
              touched
              error={error.amount}
            />
          </div>
        </div>
        <div>
          <textarea
            className={style.textarea}
            placeholder="Observaciones"
            rows={4}
            cols={50}
            value={form.observations}
            onChange={(e) => {
              setForm({ ...form, observations: e.target.value });
            }}
          />
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default EditMovementModal;
