// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FnRYRpG6DPnGg5b7UmTA {
  width: 100%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.JzM7D57AM_wkcXiCEHJS {
  width: 100%;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  border-bottom: 1px solid #dadada;

  &::-webkit-scrollbar {
    display: none;
  }
}

.V6xQAjLZU9bxDcuXMlsS {
  width: 100%;
  height: -webkit-fill-available;
  margin-top: 20px;
  overflow-y: auto;
}

.LCmV0oOBwHtC5YFj70GO {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 14px 32px;
  border-bottom: 2px solid;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #45348e;
  border-bottom-color: transparent;

  &:hover {
    border-bottom-color: #9285c7;
  }
}

.yKOJIeSAChCVQksHK4pw {
  border-bottom-color: #45348e;
  font-weight: 700;

  &:hover {
    border-bottom-color: #45348e;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/TabNavigation/TabNavigation.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,gCAAgC;;EAEhC;IACE,aAAa;EACf;AACF;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,iCAAiC;EACjC,iCAAiC;EACjC,eAAe;EACf,cAAc;EACd,gCAAgC;;EAEhC;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;;EAEhB;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".screenContent {\n  width: 100%;\n  height: -webkit-fill-available;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n}\n\n.navigationContainer {\n  width: 100%;\n  display: flex;\n  white-space: nowrap;\n  overflow-x: auto;\n  border-bottom: 1px solid #dadada;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.componentContainer {\n  width: 100%;\n  height: -webkit-fill-available;\n  margin-top: 20px;\n  overflow-y: auto;\n}\n\n.navigationButton {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  padding: 14px 32px;\n  border-bottom: 2px solid;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  color: #45348e;\n  border-bottom-color: transparent;\n\n  &:hover {\n    border-bottom-color: #9285c7;\n  }\n}\n\n.navigationButtonActive {\n  border-bottom-color: #45348e;\n  font-weight: 700;\n\n  &:hover {\n    border-bottom-color: #45348e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenContent": `FnRYRpG6DPnGg5b7UmTA`,
	"navigationContainer": `JzM7D57AM_wkcXiCEHJS`,
	"componentContainer": `V6xQAjLZU9bxDcuXMlsS`,
	"navigationButton": `LCmV0oOBwHtC5YFj70GO`,
	"navigationButtonActive": `yKOJIeSAChCVQksHK4pw`
};
export default ___CSS_LOADER_EXPORT___;
