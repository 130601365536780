import React, { useEffect, useState } from "react";
//@ts-ignore
import style from "./styles/SchedulesWrapper.module.css";
import ScheduleDayRow from "@Atoms/ScheduleRow/ScheduleDayRow";
import { IScheduleWrapperProps } from "./interface/scheduleWrapper";

const SchedulesWrapper = ({
  schedules,
  setSchedules = () => {},
  scheduleActions,
}: IScheduleWrapperProps) => {
  const [scheduleDimensions, setScheduleDimensions] = useState({
    width: 0,
    height: 0,
  });
  const scheduleRef = React.useRef(null);

  const resizeSchedule = () => {
    if (scheduleRef.current) {
      setScheduleDimensions({
        width: scheduleRef.current.offsetWidth,
        height: scheduleRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    resizeSchedule();
    window.addEventListener("resize", () => resizeSchedule());

    return () => {
      window.removeEventListener("resize", () => resizeSchedule());
    };
  }, [scheduleRef.current]);

  if (schedules?.length <= 0) return <p>No hay horarios...</p>;

  return (
    <section
      className={`${style.schedulesWrapperContainer} ${
        scheduleDimensions.width < 440
          ? style.schedulesWrapperContainerMobile
          : ""
      }`}
      ref={scheduleRef}
    >
      {schedules?.map((dayData, index) => {
        const uniqueKey = `${dayData.idDay}-${dayData.day}`;
        return (
          <ScheduleDayRow
            key={uniqueKey}
            dayData={dayData}
            scheduleState={schedules}
            setScheduleState={setSchedules}
            scheduleActions={scheduleActions}
          />
        );
      })}
    </section>
  );
};

export default SchedulesWrapper;
