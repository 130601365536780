const containerStyleDefault = {
  fontFamily: "Mulish",
  fontSize: "14px",
  fontStyle: "normal",
};
const containerTableStyleDefault = {
  width: "100%",
  border: "1px solid #cccccc",
  minHeight: "461px",
};

const headerStyleDefault = {
  backgroundColor: "#937CF4",
  color: "#FFF",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "0.14px",
  borderRight: "1px solid #937CF4",
  borderLeft: "1px solid #937CF4",
  padding: "8px 22px ",
  borderBottom: "none",
  textAlign: "center",
  whiteSpace: "nowrap",
};

const bodyStyleDefault = {};

const rowStyleDefault = {
  height: "2.5rem",
  border: "1px solid #947cf43b",
  borderRight: "none",
  borderLeft: "none",
  borderTop: "none",
};

const cellStyleDefault = {
  fontWeight: 400,
  border: "none",
  textAlign: "center",
  paddingTop: "8px",
  paddingBottom: "8px",
  color: "#333333",
};

export {
  containerStyleDefault,
  containerTableStyleDefault,
  headerStyleDefault,
  bodyStyleDefault,
  cellStyleDefault,
  rowStyleDefault,
};
