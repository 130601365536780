import { useEffect } from "react";

export const useValidateScheduleConflicts = (
  selectedItems,
  services,
  setEmpleadosOcupados
) => {
  // useEffect para validar conflictos de horarios
  useEffect(() => {
    const ocupados = []; // Arreglo para almacenar empleados ocupados
    const horarios = {}; // Objeto para almacenar los horarios de los empleados
    const newConflictingItems = []; // Arreglo para almacenar los IDs en conflicto

    selectedItems.forEach((item) => {
      const { employee, schedule, service } = item;
      const selectedService = services.find((s) => s.idPrice === service);

      if (selectedService && schedule) {
        // Convertir la cadena de horario a horas y minutos
        const [hours, minutes] = schedule.split(":").map(Number);
        const startTime = new Date(); // Crear un nuevo objeto Date
        startTime.setHours(hours, minutes, 0, 0); // Establecer la hora de inicio

        const endTime = new Date(
          startTime.getTime() + selectedService.duration * 60000
        ); // Hora de fin

        // Verificar si el empleado ya tiene un horario registrado
        if (!horarios[employee]) {
          horarios[employee] = [];
        }

        // Comprobar si hay conflictos con los horarios existentes
        horarios[employee].forEach((ocupado) => {
          const ocupadoDesde = new Date(ocupado.empleadoOcupadoDesde).getTime();
          const ocupadoHasta = new Date(ocupado.empleadoOcupadoHasta).getTime();

          // Si hay un conflicto de horarios
          if (
            (startTime.getTime() >= ocupadoDesde &&
              startTime.getTime() < ocupadoHasta) ||
            (endTime.getTime() > ocupadoDesde &&
              endTime.getTime() <= ocupadoHasta)
          ) {
            // Buscar el nombre del empleado
            const employeeData = selectedService.employees.find(
              (emp) => emp.idEmployee === employee
            );
            const employeeName = employeeData
              ? employeeData.nameEmployee
              : "Desconocido";

            // Determinar el mensaje del servicio más temprano
            const earliestService = ocupados.find(
              (ocupado) => ocupado.empleado === employeeName
            );

            // Cambiar la lógica para capturar el horario más temprano
            if (
              !earliestService ||
              startTime < new Date(earliestService.empleadoOcupadoDesde)
            ) {
              ocupados.push({
                empleado: employeeName, // Usar el nombre del empleado
                idEmpleado: item.id,
                empleadoOcupadoDesde: `${String(hours).padStart(
                  2,
                  "0"
                )}:${String(minutes).padStart(2, "0")}`, // Formato "00:00"
                empleadoOcupadoHasta: `${String(endTime.getHours()).padStart(
                  2,
                  "0"
                )}:${String(endTime.getMinutes()).padStart(2, "0")}`, // Formato "00:00"
                servicioQueRealiza: selectedService.namePrice,
                mensaje: `Por favor cambia los horarios de ${employeeName} teniendo en cuenta la duración del servicio para que NO se superpongan.`,
              });
            }

            // Agregar el ID del horario más temprano al arreglo de conflictos
            newConflictingItems.push(item.id); // Agregar el ID al arreglo de conflictos
          }
        });

        // Agregar el nuevo horario al registro
        horarios[employee].push({
          empleadoOcupadoDesde: startTime.toISOString(), // Guardar como cadena ISO
          empleadoOcupadoHasta: endTime.toISOString(), // Guardar como cadena ISO
          servicioQueRealiza: selectedService.namePrice,
        });
      }
    });

    // Actualizar el estado de empleados ocupados y conflictos
    setEmpleadosOcupados(ocupados);
  }, [selectedItems, services]);
};
