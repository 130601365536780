import React from "react";
import { ValidatingIcon } from "@Models/icons";
import style from "./ValidationMessage.module.css";

export const ValidationMessage = ({ message }) => {
  return (
    <span className={style.inputContainerMessage}>
      <img
        src={ValidatingIcon}
        alt="Validando"
        className={style.validatingSvg}
      />
      <p className={style.inputValidatingMessage}>{message}</p>
    </span>
  );
};
