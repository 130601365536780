import React from "react";

interface TableRowProps {
  rowData: any;
  rowStyle?: object;
  cellStyle?: object;
}

const TableRow: React.FC<TableRowProps> = ({
  rowData,
  rowStyle,
  cellStyle,
}) => {
  return (
    <tr style={rowStyle}>
      {Object.values(rowData).map((cellData, index) => (
        <td style={cellStyle} key={index}>
          {cellData}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
