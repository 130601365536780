import React, { useEffect } from "react";
//@ts-ignore
import style from "./Form.module.css";
import RucForm from "./RucForm/RucForm";
import DocumentForm from "./DocumentForm/DocumentForm";
import Button from "@Atoms/buttons/buttonsWithoutIcons/button";
import Icon from "@Atoms/Icon/Icon";
import { TimesIcon } from "@Models/icons";
import {
  CARNET_DE_EXTRANJERIA,
  DNI,
  PASAPORTE,
  RUC,
} from "./constants/constants";
import { isEmailValid, isValidDate } from "./FormFunctions/FormFunctions";

const Form = ({
  title = "Cliente nuevo",
  subTitle = "Completa los siguientes datos.",
  backClick,
  nextClick = () => {},
  width = "",
  form = {
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    codeCountryPhone: "51",
    documentType: "DNI",
    documentNumber: "",
    categoryClientId: "",
    sex: "",
    birthdate: "",
    address: "",
  },
  status = {
    error: false,
    validated: false,
    loading: false,
    message: "",
  },
  setForm,
  textButton = "Siguiente",
  edit = false,
  isLoading = false,
  forceDisabled = false,
  categories = [],
  searchingInReniec,
}) => {
  const [isDisabled, setIsDisabled] = React.useState(true);

  useEffect(() => {
    if (
      (form.documentType === DNI ||
        form.documentType === CARNET_DE_EXTRANJERIA ||
        form.documentType === PASAPORTE) &&
      form.name &&
      form.lastName &&
      form.documentNumber &&
      form.birthdate &&
      isValidDate(form.birthdate) &&
      !searchingInReniec &&
      form.sex &&
      !(form.phoneNumber.length < 9 && form.phoneNumber.length > 0) &&
      (form.email.length === 0 || isEmailValid(form.email)) &&
      forceDisabled === false &&
      status.message !== "Cliente ya registrado."
    ) {
      setIsDisabled(false); // Habilitar el botón
    } else if (
      // Validaciones específicas para RUC
      form.documentType === RUC &&
      form.name &&
      form.documentType &&
      form.documentNumber &&
      form.address &&
      !searchingInReniec &&
      !(form.phoneNumber.length < 9 && form.phoneNumber.length > 0) &&
      (form.email.length === 0 ||
        (form.email.includes(".") && form.email.includes("@"))) &&
      forceDisabled === false &&
      status.message !== "Cliente ya registrado."
    ) {
      setIsDisabled(false); // Habilitar el botón
    } else {
      setIsDisabled(true); // Deshabilitar el botón si no se cumplen las condiciones
    }
  }, [form, status, forceDisabled, searchingInReniec]);

  useEffect(() => {
    if (!edit) {
      setForm({
        name: "",
        lastName: "",
        email: "",
        codeCountryPhone: "51",
        phoneNumber: "",
        documentType: form.documentType,
        documentNumber: "",
        categoryClientId: "",
        sex: "",
        birthdate: "",
        address: "",
      });
    }
  }, [form.documentType]);

  return (
    <form
      className={style.containerForm}
      style={{ width: width }}
      onSubmit={(e) => {
        e.preventDefault();
        nextClick();
      }}
    >
      <header>
        <h1>{title}</h1>
        <button onClick={backClick} type="button" className={style.closeButton}>
          <Icon path={TimesIcon} color={"#ACACAC"} size={18} />
        </button>
      </header>
      <main>
        <h3 className={style.subTitle}>{subTitle}</h3>
        {form.documentType === RUC ? (
          <RucForm
            form={form}
            setForm={setForm}
            status={status}
            edit={edit}
            isLoading={isLoading}
            categories={categories}
            searchingInReniec={searchingInReniec}
          />
        ) : (
          <DocumentForm
            form={form}
            setForm={setForm}
            status={status}
            edit={edit}
            isLoading={isLoading}
            categories={categories}
            searchingInReniec={searchingInReniec}
          />
        )}
      </main>
      <footer>
        <Button
          isPrimary={true}
          isDisabled={isDisabled || isLoading || searchingInReniec}
          textBttn={textButton}
          handleClick={() => {
            nextClick();
          }}
          isLoading={isLoading}
        />
      </footer>
    </form>
  );
};

export default Form;
