import React from "react";
import style from "./TertiaryButton.module.css";

// @ts-ignore
import { Icon } from "@viuti/recursos";
import { AddIcon } from "@Models/icons";

interface TertiaryButtonProps {
  buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>;
  icon: string;
  value: string;
  containerStyle?: React.CSSProperties;
}

const TertiaryButton = ({
  buttonProps,
  icon = "",
  value,
  containerStyle,
}: TertiaryButtonProps) => {
  return (
    <button className={style.button} {...buttonProps} style={containerStyle}>
      {icon && <Icon path={icon} color="#b5b5b5" size={12} />}
      {value}
    </button>
  );
};

export default TertiaryButton;
