// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tArUbF1v6pU6yccnFEg_ {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
}
.FwElJ8CONTh9WT3nKn_o {
  opacity: 100%;
  max-width: 20px;
  text-align: center;
  border: 1px solid #fff;
}

.FwElJ8CONTh9WT3nKn_o {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  appearance: none;
  text-align: center;
  -webkit-appearance: none;
  border: 1px solid #f2e8f3;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.FwElJ8CONTh9WT3nKn_o:checked {
  background: #937cf4;
  border: 1px solid #f2e8f3;
}

.DOEdHWDJeFPIdagU7A8Q {
  padding-left: 10px;
  cursor: pointer;
  text-align: center;
  color: #424242;
  font-weight: 400;
  font-family: "Mulish";
}
.DOEdHWDJeFPIdagU7A8Q:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0px 2px 2px 0px;
  transform: scaleX(1) rotate(1847deg);
  margin-right: 14px;
  margin-left: -24px;
  margin-bottom: 0px;
}

.FwElJ8CONTh9WT3nKn_o:checked + .DOEdHWDJeFPIdagU7A8Q:before {
  border-color: rgb(255, 255, 255);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/buttons/RadioButton/SquareRadioButton.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB;AACA;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,qBAAqB;EACrB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,6BAA6B;EAC7B,oCAAoC;EACpC,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".radioLabel {\n  display: inline-block;\n  vertical-align: middle;\n  width: 100%;\n  cursor: pointer;\n}\n.radio {\n  opacity: 100%;\n  max-width: 20px;\n  text-align: center;\n  border: 1px solid #fff;\n}\n\n.radio {\n  display: inline-block;\n  vertical-align: middle;\n  width: 20px;\n  height: 20px;\n  appearance: none;\n  text-align: center;\n  -webkit-appearance: none;\n  border: 1px solid #f2e8f3;\n  background-color: #fff;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.radio:checked {\n  background: #937cf4;\n  border: 1px solid #f2e8f3;\n}\n\n.labelIcon {\n  padding-left: 10px;\n  cursor: pointer;\n  text-align: center;\n  color: #424242;\n  font-weight: 400;\n  font-family: \"Mulish\";\n}\n.labelIcon:before {\n  content: \"\";\n  display: inline-block;\n  width: 6px;\n  height: 11px;\n  border: solid white;\n  border-width: 0px 2px 2px 0px;\n  transform: scaleX(1) rotate(1847deg);\n  margin-right: 14px;\n  margin-left: -24px;\n  margin-bottom: 0px;\n}\n\n.radio:checked + .labelIcon:before {\n  border-color: rgb(255, 255, 255);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioLabel": `tArUbF1v6pU6yccnFEg_`,
	"radio": `FwElJ8CONTh9WT3nKn_o`,
	"labelIcon": `DOEdHWDJeFPIdagU7A8Q`
};
export default ___CSS_LOADER_EXPORT___;
