// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-size-base: 16px;
  --font-size-label: clamp(0.8rem, 1vw + 1rem, 0.9rem);

  /* Encabezados */
  --font-size-h1: clamp(
    2.5rem,
    3vw + 1.5rem,
    3rem
  ); /* Tamaño de fuente para h1 */
  --font-size-h2: clamp(
    2rem,
    2.5vw + 1rem,
    2.5rem
  ); /* Tamaño de fuente para h2 */
  --font-size-h3: clamp(
    1.75rem,
    2vw + 0.75rem,
    2rem
  ); /* Tamaño de fuente para h3 */
  --font-size-h4: clamp(
    1.5rem,
    1.75vw + 0.5rem,
    1.5rem
  ); /* Tamaño de fuente para h4 */
  --font-size-h5: clamp(
    1.25rem,
    1.5vw + 0.25rem,
    1rem
  ); /* Tamaño de fuente para h5 */
  --font-size-h6: clamp(
    1rem,
    1.25vw + 0.25rem,
    0.5rem
  ); /* Tamaño de fuente para h6 */
  --font-size-body: clamp(1rem, 1.1vw + 0.2rem, 1.125rem);
  --font-size-title: clamp(1.5rem, 1.75vw + 0.5rem, 1.25rem);
  --font-size-reading: clamp(0.8rem, 0.9rem, 0.9rem);
  --font-size-nav: clamp(1rem, 1.1vw + 0.1rem, 1.125rem);
  --font-size-small: clamp(0.875rem, 1vw, 1rem);
  --font-size-large: clamp(1rem, 1.25vw + 0.25rem, 1.5rem);
  --font-size-button: clamp(1rem, 1.1vw + 0.1rem, 1.125rem);
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables/fonts.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,oDAAoD;;EAEpD,gBAAgB;EAChB;;;;GAIC,EAAE,6BAA6B;EAChC;;;;GAIC,EAAE,6BAA6B;EAChC;;;;GAIC,EAAE,6BAA6B;EAChC;;;;GAIC,EAAE,6BAA6B;EAChC;;;;GAIC,EAAE,6BAA6B;EAChC;;;;GAIC,EAAE,6BAA6B;EAChC,uDAAuD;EACvD,0DAA0D;EAC1D,kDAAkD;EAClD,sDAAsD;EACtD,6CAA6C;EAC7C,wDAAwD;EACxD,yDAAyD;AAC3D","sourcesContent":[":root {\n  --font-size-base: 16px;\n  --font-size-label: clamp(0.8rem, 1vw + 1rem, 0.9rem);\n\n  /* Encabezados */\n  --font-size-h1: clamp(\n    2.5rem,\n    3vw + 1.5rem,\n    3rem\n  ); /* Tamaño de fuente para h1 */\n  --font-size-h2: clamp(\n    2rem,\n    2.5vw + 1rem,\n    2.5rem\n  ); /* Tamaño de fuente para h2 */\n  --font-size-h3: clamp(\n    1.75rem,\n    2vw + 0.75rem,\n    2rem\n  ); /* Tamaño de fuente para h3 */\n  --font-size-h4: clamp(\n    1.5rem,\n    1.75vw + 0.5rem,\n    1.5rem\n  ); /* Tamaño de fuente para h4 */\n  --font-size-h5: clamp(\n    1.25rem,\n    1.5vw + 0.25rem,\n    1rem\n  ); /* Tamaño de fuente para h5 */\n  --font-size-h6: clamp(\n    1rem,\n    1.25vw + 0.25rem,\n    0.5rem\n  ); /* Tamaño de fuente para h6 */\n  --font-size-body: clamp(1rem, 1.1vw + 0.2rem, 1.125rem);\n  --font-size-title: clamp(1.5rem, 1.75vw + 0.5rem, 1.25rem);\n  --font-size-reading: clamp(0.8rem, 0.9rem, 0.9rem);\n  --font-size-nav: clamp(1rem, 1.1vw + 0.1rem, 1.125rem);\n  --font-size-small: clamp(0.875rem, 1vw, 1rem);\n  --font-size-large: clamp(1rem, 1.25vw + 0.25rem, 1.5rem);\n  --font-size-button: clamp(1rem, 1.1vw + 0.1rem, 1.125rem);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
