import { downloadSaleDocument_service } from "@Services/saleboxes/documents";

export const getBase64downloadSaleDocument = async (
  payload,
  onNotification,
  type: 1 | 2
) => {
  const obj = {
    searchParameter:
      payload.searchParameter === undefined ? "" : payload.searchParameter,
    idBox: payload.idBox === "Todas" ? "" : payload.idBox,
    typeDocumentId:
      payload.typeDocumentId === undefined ? "" : payload.typeDocumentId,
    status: payload.status === "" ? "" : payload.status,
    statusFE: payload.statusFE === "" ? "" : payload.statusFE,
    startDate: payload.startDate === "" ? "" : payload.startDate,
    endDate: payload.endDate === "" ? "" : payload.endDate,
    format: type,
  };

  const response = await downloadSaleDocument_service(obj);
  if (!response.isSuccessful) {
    onNotification({
      message: response.message,
      status: 400,
      toastTimeDuration: 4000,
    });
    return response;
  }

  return {
    ...response,
    data: response.data.base64String,
  };
};
