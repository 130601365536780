export const moduloAction = [
  {
    nombreModulo: "Estadísticas",
    id: 5,
    actions: [
      { id: 1, nombreAction: "Ingresa vista estadísticas" },
      { id: 2, nombreAction: "Fija meta semanal" },
      { id: 3, nombreAction: "Fija meta mensual" },
    ],
  },
  {
    nombreModulo: "Permisos",
    id: 14,
    actions: [
      { id: 4, nombreAction: "Ingresa vista roles" },
      { id: 5, nombreAction: "Crea un nuevo rol" },
      { id: 6, nombreAction: "Edita un rol" },
      { id: 7, nombreAction: "Elimina un rol" },
    ],
  },
  {
    nombreModulo: "Clientes",
    id: 6,
    actions: [
      { id: 8, nombreAction: "Ingresa vista clientes" },
      { id: 9, nombreAction: "Ingresa vista perfil del cliente" },
      { id: 10, nombreAction: "Ingresa vista historial del cliente" },
    ],
  },
  {
    nombreModulo: "Servicios",
    id: 9,
    actions: [
      { id: 11, nombreAction: "Ingresa vista servicios" },
      { id: 12, nombreAction: "Crea un servicio" },
      { id: 13, nombreAction: "Edita un servicio" },
      { id: 14, nombreAction: "Elimina un servicio" },
    ],
  },
  {
    nombreModulo: "Facturación electrónica",
    id: 16,
    actions: [
      { id: 15, nombreAction: "Ingresa vista facturación" },
      { id: 16, nombreAction: "Agrega nuevo RUC" },
      { id: 17, nombreAction: "Elimina RUC" },
    ],
  },
  {
    nombreModulo: "Almacén",
    id: 10,
    actions: [
      { id: 18, nombreAction: "Ingresa vista productos" },
      { id: 19, nombreAction: "Crea nuevo almacén" },
      { id: 20, nombreAction: "Edita nombre de almacén" },
      { id: 21, nombreAction: "Elimina almacén" },
      { id: 22, nombreAction: "Establece como almacén de venta" },
      { id: 23, nombreAction: "Crea nuevo producto" },
      { id: 24, nombreAction: "Edita producto" },
      { id: 25, nombreAction: "Elimina producto" },
      { id: 26, nombreAction: "Ingresa unidades" },
      { id: 27, nombreAction: "Retira unidades" },
      { id: 28, nombreAction: "Transfiere unidades" },
      { id: 29, nombreAction: "Hace inventario" },
      { id: 30, nombreAction: "Ve historial de inventario" },
      { id: 31, nombreAction: "Vista carga masiva de productos" },
      { id: 32, nombreAction: "Ve información de producto" },
      { id: 33, nombreAction: "Ve movimientos de stock de producto" },
    ],
  },
  {
    nombreModulo: "Locales",
    id: 12,
    actions: [
      { id: 34, nombreAction: "Ingresa vista de locales" },
      { id: 35, nombreAction: "Ve información del local" },
      { id: 36, nombreAction: "Edita información del local" },
      { id: 37, nombreAction: "Edita horarios del local" },
      { id: 38, nombreAction: "Añade caja" },
      { id: 39, nombreAction: "Agrega concepto de movimiento" },
    ],
  },
  {
    nombreModulo: "Empleados",
    id: 8,
    actions: [
      { id: 40, nombreAction: "Ingresa vista de empleados" },
      { id: 41, nombreAction: "Ve la informacion de un empleado" },
      { id: 42, nombreAction: "Edita información de empleado" },
      { id: 43, nombreAction: "Elimina un empleado" },
      { id: 44, nombreAction: "Da acceso al sistema" },
      { id: 45, nombreAction: "Revoca acceso al sistema" },
      { id: 46, nombreAction: "Invita a un miembro" },
      { id: 47, nombreAction: "Elimina la invitación" },
      { id: 48, nombreAction: "Reenvia invitación" },
      { id: 49, nombreAction: "Agrega empleados" },
    ],
  },
  {
    nombreModulo: "Mi cuenta",
    id: 15,
    actions: [
      { id: 50, nombreAction: "Ingresa vista de mi cuenta" },
      { id: 51, nombreAction: "Ingresa vista administrar suscripción" },
      { id: 52, nombreAction: "Edita información de facturación" },
      { id: 53, nombreAction: "Cancela suscripción" },
      { id: 54, nombreAction: "Ingresa vista planes disponibles" },
    ],
  },
  {
    nombreModulo: "Cajas",
    id: 1,
    actions: [
      { id: 55, nombreAction: "Abre caja" },
      { id: 56, nombreAction: "Cierra caja" },
      { id: 57, nombreAction: "Cobra una venta" },
    ],
  },
  {
    nombreModulo: "Movimientos",
    id: 2,
    actions: [
      { id: 58, nombreAction: "Carga los movimientos" },
      { id: 59, nombreAction: "Crea un nuevo movimiento" },
      { id: 60, nombreAction: "Edita un movimiento" },
      { id: 61, nombreAction: "Elimina un movimiento" },
      { id: 62, nombreAction: "Descarga reporte de movimientos" },
      { id: 63, nombreAction: "Crea concepto de movimiento" },
      { id: 64, nombreAction: "Mira el historial de edición del movimiento" },
    ],
  },
  {
    nombreModulo: "Documentos de venta",
    id: 3,
    actions: [
      { id: 65, nombreAction: "Carga los documentos de venta" },
      { id: 66, nombreAction: "Ve detalles de documento de venta" },
      { id: 67, nombreAction: "Anula ticket" },
      { id: 68, nombreAction: "Descarga reporte de documentos" },
    ],
  },
  {
    nombreModulo: "Mi caja",
    id: 4,
    actions: [
      { id: 69, nombreAction: "Carga información de mi caja" },
      { id: 70, nombreAction: "Envía a su correo el reporte de caja" },
    ],
  },
  {
    nombreModulo: "Reservas",
    id: 7,
    actions: [
      { id: 71, nombreAction: "Carga reservas" },
      { id: 72, nombreAction: "Crea una nueva reserva" },
      { id: 73, nombreAction: "Edita una reserva" },
      { id: 74, nombreAction: "Cancela una reserva" },
    ],
  },
  {
    nombreModulo: "Proveedores",
    id: 13,
    actions: [
      { id: 75, nombreAction: "Carga proveedores" },
      { id: 76, nombreAction: "Carga cuentas por pagar" },
      { id: 77, nombreAction: "Crea nuevo proveedor" },
    ],
  },
];
