import React from "react";
// @ts-ignore
import style from "./CircularInput.module.css";

const CircularInput = ({
  width,
  checked,
  setCheck,
  onClick,
  id,
  locked = false,
}: any) => {
  const handleChange = () => {
    setCheck(!checked);
  };
  return (
    <div
      className={style.container}
      style={{
        width: width ? `${width}px` : "22px",
        height: width ? `${width}px` : "22px",
        border: locked ? `1px solid #e0e0e0` : "1px solid #45348e",
        cursor: locked ? "default" : "pointer",
      }}
      onClick={onClick ? onClick : handleChange}
      id={id}
    >
      <div
        className={checked && style.checked}
        style={{
          cursor: locked ? "default" : "pointer",
          backgroundColor:
            locked && checked ? "#e0e0e0" : checked ? "#45348e" : "white",
        }}
      ></div>
    </div>
  );
};

export default CircularInput;
