interface Props {
  titleName: string;
  business: {
    NAME: string;
    RUC: string;
  };
  local: {
    NAME: string;
    LOCATION: string;
    EMAIL: string;
    PHONE: string;
  };
  billingCode: string;
  billingDocument: string;
  products: {
    DESCRIPTION: string;
    AMOUNT: number;
    TOTAL: number;
  }[];
  client: {
    DOCUMENT_TYPE: string;
    DOCUMENT: string;
    NAME: string;
    LOCATION: string;
  };
  pricing: {
    OP_ICBPER: number;
    OP_EXONERADAS: number;
    OP_IGV: number;
    OP_GRAVADAS: number;
    OP_DESCUENTO: number;
    OP_TOTAL: number;
  };
  paymentMethods: {
    DESCRIPTION: string;
    AMOUNT: number;
  }[];
  responsable: string;
  dataTicket?: {
    hash: string;
    qrData: string;
  } | null;
}

export const billingPrintbuilder = ({
  titleName,
  business,
  local,
  billingCode,
  billingDocument,
  products,
  client,
  pricing,
  paymentMethods,
  responsable,
  dataTicket,
}: Props) => ({
  TITLE_NAME: titleName,
  BUSINESS: {
    NAME: business.NAME,
    RUC: business.RUC,
    RESPONSABLE: responsable,
  },
  LOCAL: {
    NAME: local.NAME,
    LOCATION: local.LOCATION,
    EMAIL: local.EMAIL,
    PHONE: local.PHONE,
  },
  BILLING: {
    CODE: billingCode,
    DOCUMENT: billingDocument,
    PRODUCTS: products,
  },
  CLIENT: {
    DOCUMENT_TYPE: client.DOCUMENT_TYPE,
    DOCUMENT: client.DOCUMENT,
    NAME: client.NAME,
    LOCATION: client.LOCATION,
  },
  PRICING: {
    OP_ICBPER: pricing?.OP_ICBPER || 0,
    OP_EXONERADAS: pricing?.OP_EXONERADAS || 0,
    OP_IGV: pricing?.OP_IGV || 0,
    OP_GRAVADAS: pricing?.OP_GRAVADAS || 0,
    OP_DESCUENTO: pricing?.OP_DESCUENTO || 0,
    OP_TOTAL: pricing?.OP_TOTAL || 0,
  },
  PAYMENT_METHODS: paymentMethods || [],
  DATA_TICKET: {
    HASH: dataTicket?.hash,
    QR_DATA: dataTicket?.qrData,
  },
});
