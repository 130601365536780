// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vHwMyB2ybGBSncH8YbuO {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.PtF_8njDwf5BTxQRVotQ {
  display: flex;
  gap: 20px;
}

.ar8HPCP1OaPFDcqMtT9N {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  transition: border-color 0.3s ease;
}

.l7F8yIC6WaOE4QCgYhXQ {
  width: 100%;
}

.lorLmaiYlyGsO1VCsAuZ {
  width: 100%;
  min-width: 150px;
}

@media screen and (width < 500px) {
  .vHwMyB2ybGBSncH8YbuO {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .PtF_8njDwf5BTxQRVotQ {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .ar8HPCP1OaPFDcqMtT9N {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    transition: border-color 0.3s ease;
  }
  .l7F8yIC6WaOE4QCgYhXQ {
    width: 100%;
  }
  .lorLmaiYlyGsO1VCsAuZ {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/SaleboxReports/SaleMovementsReportView/components/EditMovementModal/EditMovementModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;EACb;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;EACpC;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n}\n\n.flexContainer {\n  display: flex;\n  gap: 20px;\n}\n\n.textarea {\n  width: 100%;\n  height: 100px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  resize: none;\n  transition: border-color 0.3s ease;\n}\n\n.inputContainer {\n  width: 100%;\n}\n\n.inputWidth {\n  width: 100%;\n  min-width: 150px;\n}\n\n@media screen and (width < 500px) {\n  .container {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    width: 100%;\n  }\n  .flexContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n  .textarea {\n    width: 100%;\n    height: 100px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    resize: none;\n    transition: border-color 0.3s ease;\n  }\n  .inputContainer {\n    width: 100%;\n  }\n  .inputWidth {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vHwMyB2ybGBSncH8YbuO`,
	"flexContainer": `PtF_8njDwf5BTxQRVotQ`,
	"textarea": `ar8HPCP1OaPFDcqMtT9N`,
	"inputContainer": `l7F8yIC6WaOE4QCgYhXQ`,
	"inputWidth": `lorLmaiYlyGsO1VCsAuZ`
};
export default ___CSS_LOADER_EXPORT___;
