import React, { useEffect, useState } from "react";
import {
  AddIcon,
  CommandIcon,
  DeleteIcon,
  PencilWithFrameIcon,
  TimesIcon,
  ValidatingIcon,
} from "@Models/icons";
import style from "./AppointmentDetail.module.css";
import {
  TertiaryButton,
  SelectInput,
  Icon,
  //@ts-ignore
} from "@viuti/recursos";
import { getAvailableHours_adapter } from "@Adapters/employee/getAvailableHours.adapter";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { Summary } from "./Summary/Summary";
import { ServicePriceDuration } from "./ServicePriceDuration/ServicePriceDuration";
import { ConflictMessage } from "./ConflictMessage/ConflictMessage";
import { ValidationMessage } from "./ValidationMessage/ValidationMessage";
import { OccupiedEmployeesMessage } from "./OccupiedEmployeesMessage/OccupiedEmployeesMessage";
import { useValidateScheduleConflicts } from "./hook/useValidateScheduleConflicts";

export const AppointmentDetail = ({
  selectedItems,
  generateId,
  services,
  setSelectedItems,
  date,
  setStateResponse,
  isEdit,
  availableHours,
  setAvailableHours,
  loadingServices,
  setMessageConflicting,
  messageConflicting,
  empleadosOcupados,
  setEmpleadosOcupados,
  idConflictingItem,
  setIdConflictingItem,
  incompleteItems,
  setIncompleteItems,
  orderHours,
  reserveId,
  isDisabled,
  isDisabledNoShow,
  loadingHours,
  setLoadingHours,
  loadingHoursId,
  setLoadingHoursId,
  getStatusMessage,
}) => {
  const [editService, setEditService] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});

  const [animationClass, setAnimationClass] = useState([]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedItems((prevItems) =>
        prevItems.map((item) => ({ ...item, employee: "", schedule: "" }))
      );
      setAvailableHours({});
      setErrorMessage({});
    }
  }, [date]);

  const serviceItems = services.map((service) => ({
    name: service.namePrice,
    value: service.idPrice,
    price: service.price,
    duration: service.duration,
  }));

  const handleAddService = () => {
    // Validar que todos los campos requeridos estén completos
    const allFieldsCompleted = selectedItems.every(
      (item) =>
        item.service !== "" && item.employee !== "" && item.schedule !== ""
    );

    if (!allFieldsCompleted) {
      // Si falta completar algún campo, no se añade un nuevo servicio
      setMessageConflicting(
        "Por favor, completa todos los campos antes de añadir otro servicio."
      );
      const incompleteItems = selectedItems
        .filter(
          (item) =>
            item.service === "" || item.employee === "" || item.schedule === ""
        )
        .map((item) => item.id);
      setIncompleteItems(incompleteItems);
      return; // Salir de la función
    }
    const newService = { id: generateId(), service: "", employee: "" };
    // Cambiar a unshift para agregar al principio
    setSelectedItems((prevItems) => [newService, ...prevItems]);
    // Agregar la clase de animación
    setAnimationClass((prev) => [...prev, newService.id]);
  };

  useEffect(() => {
    const allFieldsCompleted = selectedItems.every(
      (item) =>
        item.service !== "" && item.employee !== "" && item.schedule !== ""
    );
    if (allFieldsCompleted) {
      setIncompleteItems([]);
    }
  }, [selectedItems]);

  const handleRemoveService = (id) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== id));
  };

  const handleServiceChange = (id, value) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id
          ? { ...item, service: value, employee: "", schedule: "" }
          : item
      )
    );
    setErrorMessage((prev) => ({
      ...prev,
      [id]: "",
    }));
  };

  const handleEmployeeChange = async (id, value) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, employee: value, schedule: "" } : item
      )
    );

    if (value) {
      setLoadingHoursId(id);
      setLoadingHours(true);

      const getAvailableHoursParams = {
        date,
        employeeId: value,
        priceServiceId: selectedItems.find((item) => item.id === id).service,
        reserveId: isEdit ? reserveId : null,
      };

      const hoursResponse = await getAvailableHours_adapter(
        getAvailableHoursParams
      );

      if (hoursResponse.data.length === 0 && hoursResponse.isSuccess) {
        const today = new Date().toISOString().split("T")[0];
        const errorMessage =
          date === today
            ? "El empleado ya no tiene horas disponibles para hoy."
            : "El empleado no tiene horas disponibles para el día seleccionado.";

        setErrorMessage((prev) => ({
          ...prev,
          [id]: errorMessage,
        }));
        setAvailableHours((prev) => ({ ...prev, [id]: [] }));
      } else if (hoursResponse.isSuccess) {
        setErrorMessage((prev) => ({ ...prev, [id]: "" }));
        setAvailableHours((prev) => ({ ...prev, [id]: hoursResponse.data }));
      } else {
        setAvailableHours((prev) => ({ ...prev, [id]: [] }));
        setStateResponse({
          message: "Hubo un error al traer los horarios, intente de nuevo.",
          status: 400,
        });
      }
      setLoadingHours(false);
      setLoadingHoursId(null);
    }
  };

  const getEmployeesForService = (serviceId) => {
    const selectedService = services.find(
      (service) => service.idPrice === serviceId
    );
    return selectedService ? selectedService.employees : [];
  };

  useEffect(() => {
    const conflictMessages = {};
    const newConflictingItems = []; // Arreglo para almacenar los IDs en conflicto

    selectedItems.forEach((item) => {
      const conflicts = selectedItems.filter(
        (otherItem) =>
          otherItem.employee === item.employee &&
          otherItem.schedule === item.schedule &&
          otherItem.id !== item.id && // Asegúrate de que no se compare consigo mismo
          otherItem.schedule !== ""
      );

      if (conflicts.length > 0) {
        const selectedService = services.find(
          (service) => service.idPrice === item.service
        );

        // Validar que el servicio seleccionado existe
        if (selectedService) {
          const employee = selectedService.employees.find(
            (emp) => emp.idEmployee === item.employee
          );

          // Validar que el empleado existe
          if (employee) {
            // Cambiar para que solo se agregue un mensaje único
            if (!conflictMessages[employee.nameEmployee]) {
              conflictMessages[employee.nameEmployee] = `${
                employee.nameEmployee
              } tiene ${
                conflicts.length + 1
              } servicios a la misma hora, por favor corregir.`;
            }
            newConflictingItems.push(item.id); // Agregar el ID al arreglo de conflictos
          }
        }
      }
    });

    // Actualizar el estado de los mensajes de conflicto
    if (Object.keys(conflictMessages).length > 0) {
      setMessageConflicting(Object.values(conflictMessages).join(" "));
      setIdConflictingItem(newConflictingItems); // Solo los IDs en conflicto
    } else {
      setMessageConflicting(""); // No hay conflictos, se limpia el mensaje
      setIdConflictingItem([]);
    }
  }, [selectedItems, services]);

  const handleScheduleChange = (id, value) => {
    setSelectedItems((prevItems) => {
      return prevItems.map((item) =>
        item.id === id ? { ...item, schedule: value } : item
      );
    });
  };

  const validateOrderHours = () => {
    try {
      const selectedItemsSorted = [...selectedItems].sort((a, b) => {
        const timeA = a.schedule.split(":").map(Number);
        const timeB = b.schedule.split(":").map(Number);
        return timeA[0] - timeB[0] || timeA[1] - timeB[1];
      });
      return (
        JSON.stringify(selectedItems) !== JSON.stringify(selectedItemsSorted)
      );
    } catch (error) {
      return false;
    }
  };

  const handleEditService = () => {
    setEditService(true);
  };

  const handleCancelService = () => {
    setEditService(false);
  };

  // useEffect para validar conflictos de horarios
  useValidateScheduleConflicts(selectedItems, services, setEmpleadosOcupados);

  return (
    <div className={`${style.appointmentDetail} ${style.slideIn}`}>
      {/* <h2 className={style.section_header}>Detalle de la reserva</h2> */}
      <div className={style.SelectedCashregisterBody__left__salesOptions}>
        <div className={style.servicesContainer}>
          {/* {isEdit && !editService && (
            <TertiaryButton
              buttonProps={{
                onClick: handleEditService,
                disabled: false,
              }}
              icon={PencilWithFrameIcon}
              value="Modificar"
            />
          )} */}
          {/* {isEdit && editService && (
            <TertiaryButton
              buttonProps={{
                onClick: handleCancelService,
                disabled: false,
              }}
              icon={TimesIcon}
              value="Cancelar"
            />
          )} */}
          {isEdit && editService && !isDisabled && !isDisabledNoShow && (
            <TertiaryButton
              buttonProps={{
                onClick: handleAddService,
                disabled: isDisabled,
              }}
              icon={AddIcon}
              value="Añadir servicio"
            />
          )}
          {!isEdit && !isDisabledNoShow && (
            <div className={style.buttonsContainer}>
              <TertiaryButton
                buttonProps={{
                  onClick: handleAddService,
                  disabled: isDisabled,
                }}
                icon={AddIcon}
                value="Añadir servicio"
              />
              {selectedItems.length > 1 && validateOrderHours() && (
                <button className={style.orderHoursButton} onClick={orderHours}>
                  <HiOutlineBarsArrowDown />
                  Ordenar horarios
                </button>
              )}
            </div>
          )}
          {getStatusMessage() && (
            <div className={style.comandaMessage}>
              <p>{getStatusMessage()}</p>
              <Icon path={CommandIcon} color="#45348e" size={12} />
            </div>
          )}
          {messageConflicting && (
            <ConflictMessage message={messageConflicting} />
          )}
          {empleadosOcupados.length > 0 && !messageConflicting && (
            <OccupiedEmployeesMessage empleadosOcupados={empleadosOcupados} />
          )}
          {selectedItems?.map((item) => {
            const selectedService = services?.find(
              (service) => service.idPrice === item.service
            );
            const servicePrice = selectedService ? selectedService.price : 0;
            const serviceDuration = selectedService
              ? selectedService.duration
              : 0;

            // Verificar si el empleado tiene conflictos
            const hasConflict =
              empleadosOcupados.some(
                (ocupado) => ocupado.idEmpleado === item.id
              ) || idConflictingItem.includes(item.employee); // Verificar si hay conflictos

            const hoursSelect = [
              ...(availableHours[item.id]?.map((hour) => ({
                name: hour,
                value: hour,
              })) || []),
              ...(item.schedule &&
              !availableHours[item.id]?.includes(item.schedule)
                ? [
                    {
                      name: String(item.schedule),
                      value: String(item.schedule),
                    },
                  ]
                : []),
            ];

            return (
              <div
                key={item.id}
                className={`${style.serviceEmployeePair} ${
                  style.animatedSchedule
                } ${animationClass.includes(item.id) ? style.slideDown : ""}
                ${incompleteItems.includes(item.id) ? style.incomplete : ""}
                ${hasConflict ? style.error : ""} // Clase para conflicto
                `}
              >
                {selectedItems?.length > 1 &&
                  !isDisabledNoShow &&
                  !isDisabled && (
                    <button
                      onClick={() => handleRemoveService(item.id)}
                      className={style.removeServiceButton}
                    >
                      <Icon path={DeleteIcon} color="#b5b5b5" size={12} />
                    </button>
                  )}
                <SelectInput
                  value={item.service}
                  handleChange={(e) =>
                    handleServiceChange(item.id, e.target.value)
                  }
                  items={serviceItems}
                  required={true}
                  placeholder="Selecciona un servicio"
                  disabled={
                    loadingServices ||
                    (isEdit && !editService) ||
                    isDisabled ||
                    isDisabledNoShow
                  }
                />
                {/* <SearchableSelect
                  value={item.service}
                  handleChange={
                    (value) => handleServiceChange(item.id, value) // Cambia aquí para pasar el valor directamente
                  }
                  items={serviceItems.map((service) => ({
                    name: service.name, // Cambia 'name' a 'label'
                    value: service.value, // Cambia 'value' a 'id'
                  }))} // Asegúrate de que las opciones tengan la estructura correcta
                  required={true}
                  // label="Servicio"
                  placeholder="Selecciona un servicio"
                  disabled={isDisabled || isDisabledNoShow}
                /> */}
                <>
                  <SelectInput
                    value={item.employee}
                    handleChange={(e) =>
                      handleEmployeeChange(item.id, e.target.value)
                    }
                    items={getEmployeesForService(item.service)?.map((emp) => ({
                      name: emp.nameEmployee,
                      value: emp.idEmployee,
                    }))}
                    required={true}
                    placeholder="Selecciona un empleado"
                    error={errorMessage[item.id]}
                    touched={errorMessage[item.id] !== ""}
                    disabled={
                      item.service === "" ||
                      (isEdit && !editService) ||
                      isDisabled ||
                      isDisabledNoShow
                    }
                  />
                  {loadingHours && loadingHoursId === item.id ? (
                    <span className={style.inputContainerMessage}>
                      <img
                        src={ValidatingIcon}
                        alt="Validando"
                        className={style.validatingSvg}
                      />
                      <p className={style.inputValidatingMessage}>
                        Buscando horarios...
                      </p>
                    </span>
                  ) : (
                    <SelectInput
                      value={item.schedule}
                      handleChange={(e) =>
                        handleScheduleChange(item.id, e.target.value)
                      }
                      items={hoursSelect}
                      required={true}
                      placeholder="hh:mm"
                      touched={true}
                      error={
                        (idConflictingItem.includes(item.employee) &&
                        selectedItems.filter(
                          (i) =>
                            i.employee === item.employee &&
                            i.schedule === item.schedule
                        ).length > 1
                          ? "Modificación necesaria"
                          : "") || (hasConflict ? "Modificación necesaria" : "")
                      }
                      disabled={
                        item.employee === "" ||
                        (isEdit && !editService) ||
                        isDisabled ||
                        isDisabledNoShow
                      } // Deshabilitar si hay elementos iniciales
                    />
                  )}
                </>
                <ServicePriceDuration
                  servicePrice={servicePrice}
                  serviceDuration={serviceDuration}
                />
              </div>
            );
          })}
          {loadingServices && (
            <ValidationMessage message="Buscando servicios..." />
          )}
        </div>
        <Summary selectedItems={selectedItems} services={services} />
      </div>
      {/* <TextAreaInput
        placeholder="Comentarios adicionales"
        value={aditionalComments}
        handleChange={(e) => setAditionalComments(e)}
        height="20px"
        width="100%"
        maxWidth="100%"
      /> */}
    </div>
  );
};
