import getUserPermissions from "./getUserPermissions";

const validateModuleAccess = (
  uuidToValidate: string,
  dispatchModal: boolean = true
) => {
  if (!uuidToValidate) return true;

  const permissions = getUserPermissions();
  const accessKeyModules = permissions?.accessKeyModules;
  const currentPlan = permissions?.plan;

  if (!accessKeyModules || !currentPlan.hierarchy) {
    return false;
  }

  const selectedAccessKey = accessKeyModules.find(
    (module: any) => module.id === uuidToValidate
  );

  if (!selectedAccessKey) {
    dispatchModal &&
      window.dispatchEvent(
        new CustomEvent("access-denied", {
          detail: { accessId: uuidToValidate },
        })
      );
    return false;
  }

  if (currentPlan.id === 1 && !permissions.plan.isTrial) {
    dispatchModal &&
      window.dispatchEvent(
        new CustomEvent("plan-denied", {
          detail: { accessId: uuidToValidate },
        })
      );
    return false;
  }

  if (selectedAccessKey.hierarchy > currentPlan.hierarchy) {
    dispatchModal &&
      window.dispatchEvent(
        new CustomEvent("plan-denied", {
          detail: { accessId: uuidToValidate },
        })
      );
    return false;
  }

  return true;
};

export default validateModuleAccess;
