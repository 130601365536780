import { STATUS_OK } from "@Models/httpsStatus";
import { getSaleXML_service } from "@Services/saleboxes/documents";

export const getSaleXML = async (saleDocumentId, onNotification) => {
  onNotification({
    message: "Descargando XML...",
    status: "pending",
    toastTimeDuration: "INFINITE",
  });
  const response = await getSaleXML_service(saleDocumentId);
  if (!response.isSuccess) {
    onNotification({
      message: response.message,
      status: 400,
      toastTimeDuration: 4000,
    });
    return response;
  }
  onNotification({
    message: "XML descargado correctamente",
    status: 200,
    toastTimeDuration: 4000,
  });
  return {
    status: STATUS_OK,
    message: "Descarga de XML correcta",
    data: response.data,
  };
};
