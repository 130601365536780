import { postNewAppointment_service } from "@Services/reservation/postNewReserve.service";

export const postNewReserve_adapter = async (data: any) => {
  const adaptData = {
    clientId: data.clientId,
    observation: data.observation,
    prepaid: 0,
    dateAttention: data.dateAttention,
    details: data.details.map((detail) => ({
      priceServiceId: detail.service,
      employeeId: detail.employee,
      reserveHour: detail.schedule,
      promoId: 0,
    })),
  };
  const response = await postNewAppointment_service(adaptData);
  return response;
};

// {
//     "clientId": 3334083,
//     "observation": "",
//     "prepaid": 0,
//     "dateAttention": "2024-10-22",
//     "details": [
//         {
//             "priceServiceId": "",
//             "employeeId": 4171,
//             "reserveHour": "19:15",
//             "promoId": 0
//         }
//     ]
// }

// {
//     "clientId": 3334084,
//     "observation": "",
//     "prepaid": 0,
//     "dateAttention": "2024-10-18",
//     "details": [
//         {
//             "priceServiceId": 1156,
//             "employeeId": 4191,
//             "reserveHour": "02:15",
//             "promoId": 0
//         }
//     ]
// }
