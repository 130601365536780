import { ISchedule } from "../interface/scheduleDayRow";

export const getLastEndSchedule = (currentSchedule: ISchedule) => {
  let localCurrentSchedule = {
    startTime: currentSchedule.startTime,
    endTime: currentSchedule.endTime,
  };

  // If the current schedule doesn't have a start time, then will not show end time options
  if (localCurrentSchedule.startTime === "") {
    return 24;
  }

  // Convert the start and end time to 24 hours format
  let startTimeHour = Number(localCurrentSchedule.startTime.split(":")[0]);
  const startTimeMinutes = Number(
    localCurrentSchedule.startTime.split(" ")[0].split(":")[1]
  );
  if (localCurrentSchedule.startTime.split(" ")[1] === "PM") {
    if (startTimeHour !== 12) {
      startTimeHour += 12;
    }
  }

  localCurrentSchedule.startTime = startTimeHour + ":" + startTimeMinutes;

  if (localCurrentSchedule.startTime !== "") {
    const convertedStartTime =
      Number(localCurrentSchedule.startTime.split(":")[0]) +
      Number(localCurrentSchedule.startTime.split(":")[1]) / 60;
    return convertedStartTime + 0.25;
  } else {
    return 0;
  }
};
