import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import style from "./CustomSelect.module.css";
import {
  AltArrowRightIcon,
  CircleAlertIcon,
  PeruFlagIcon,
} from "@Models/icons";

// Hacer este componente reutilizable
const CustomSelect = ({
  setPhoneValues,
  phoneValues,
  handleInputChange,
  placeholder = "Ingrese su número de teléfono",
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [error, setError] = useState("");
  useEffect(() => {
    if (
      phoneValues.phoneNumber.length > 0 &&
      phoneValues.phoneNumber.length < 9
    ) {
      setError("El número de teléfono debe tener 9 dígitos");
    } else {
      setError("");
    }
  }, [phoneValues.phoneNumber]);

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleCodeChange = (e) => {
    setPhoneValues({
      ...phoneValues,
      codeCountryPhone: e.target.value,
    });
    setIsOpen(false);
  };

  const countryCodes = [
    { value: 51, text: "+51", image: PeruFlagIcon, imageAlt: "peru" },
  ];

  const selectedCountryCode = countryCodes[0];

  const toggleSelect = (e) => {
    e.preventDefault();
    if (isDisabled) return;
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className={
          error
            ? `${style.phoneContainer} ${style.errorInput}`
            : style.phoneContainer
        }
        style={isDisabled ? { backgroundColor: "#f0f0f0" } : {}}
      >
        <button
          className={style.selectTrigger}
          onClick={toggleSelect}
          ref={selectRef}
          style={isDisabled ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <img
            src={selectedCountryCode?.image}
            alt={selectedCountryCode?.imageAlt}
            className={style.selectedOption}
          />
          <img
            src={AltArrowRightIcon}
            className={`${style.arrowIcon} ${
              isOpen ? style.activeArrowIcon : ""
            }`}
            alt={selectedCountryCode?.imageAlt}
          />
          <span className={style.codeCountryPhone}>
            {selectedCountryCode?.text}
          </span>
        </button>
        <input
          className={style.phoneInput}
          type="text"
          placeholder={placeholder}
          value={phoneValues.phoneNumber}
          onChange={(e) => handleInputChange(e, phoneValues, setPhoneValues)}
          name="phoneNumber"
          maxLength={9}
          style={isDisabled ? { backgroundColor: "#f0f0f0" } : {}}
          disabled={isDisabled}
        />
      </div>
      {error && (
        <div className={style.inputErrorContainer}>
          <img src={CircleAlertIcon} alt="warn" className={style.warnYou} />
          <p className={style.inputErrorMessage}>{error}</p>
        </div>
      )}
      {isOpen && (
        <div className={style.contenedorScroll}>
          <ul className={style.selectOptions}>
            {countryCodes.map((option) => (
              <li key={option.value}>
                <button value={option.value} onClick={handleCodeChange}>
                  <img src={option.image} alt={option.text} />
                  {option.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CustomSelect;
