// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoHhbm7Hdko0JHOPFPDL {
  border-radius: 4px;
  border: 1px solid #937cf4;
  background: #eae6fd;
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.RQpP4IHOoAIegOhSKMIo {
  padding: 24px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #937cf4;
  background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/FormAddMore/FormAddMore.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".containerFormAddMore {\n  border-radius: 4px;\n  border: 1px solid #937cf4;\n  background: #eae6fd;\n  padding: 28px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.subContainer {\n  padding: 24px;\n  border-radius: 0px 4px 4px 0px;\n  border: 1px solid #937cf4;\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFormAddMore": `CoHhbm7Hdko0JHOPFPDL`,
	"subContainer": `RQpP4IHOoAIegOhSKMIo`
};
export default ___CSS_LOADER_EXPORT___;
