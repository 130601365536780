import React from "react";
// @ts-ignore
import style from "./CriticalErrorPage.module.css";
import { HiFaceFrown } from "react-icons/hi2";
import Button from "@Atoms/buttons/buttonsWithoutIcons/button";

const CriticalErrorPage = () => {
  /**
   * Layout used for not found pages in the applications that use
   * micro-frontend and has a basename in the router.
   */
  return (
    <div id="viuti-front-mainContent" className={style.container}>
      <HiFaceFrown size={128} color="#CCCCCC" />
      <span className={style.text__container}>
        <h1 className={style.title}>Ups! Algo salió mal.</h1>
        <p className={style.text}>
          Lo sentimos, ha ocurrido un error inesperado. Por favor, inténtalo de
          nuevo.
        </p>
      </span>
      <span className={style.button__container}>
        <Button
          isPrimary={false}
          textBttn="Reintentar"
          handleClick={() => window.location.reload()}
        />
      </span>
    </div>
  );
};

export default CriticalErrorPage;
