import React from "react";
// @ts-ignore
import styles from "./ListSkeleton.module.css";

const ListSkeleton = ({
  numberOfRows = 10,
  gap = 4,
  paddingRow = "13.5px",
}) => {
  return (
    <ul className={styles.containerSkeleton} style={{ gap: `${gap}px` }}>
      {Array.from({ length: numberOfRows }).map((_, index) => (
        <li
          key={`header-skeleton-${index}`}
          className={styles.headerSkeleton}
          style={{ padding: paddingRow }}
        >
          <div className={styles.titleSkeleton} />
          <div className={styles.iconSkeleton} />
        </li>
      ))}
    </ul>
  );
};

export default ListSkeleton;
