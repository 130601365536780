import { resendPayment_service } from "@Services/saleboxes/documents";

export const resendPayment = async (
  saleDocumentId: string,
  onNotification: (notification: any) => void
) => {
  onNotification({
    message: "Retransmitiendo documento a SUNAT...",
    status: "pending",
    toastTimeDuration: "INFINITE",
  });

  const response = await resendPayment_service(saleDocumentId);

  if (!response.isSuccessful) {
    onNotification({
      message: response.message,
      status: 400,
      toastTimeDuration: 4000,
    });
    return response;
  }

  onNotification({
    message: response.data.data.message,
    status: 200,
    toastTimeDuration: 4000,
  });
  return response;
};
