import React from "react";
//@ts-ignore
import style from "./SearchInput.module.css";
import { iconPath } from "../../../../../models/localPath";

const SearchInput = ({
  label,
  value,
  setValue,
  placeholder,
  icon = `${iconPath}/search/magnifyingGlass.svg`,
  width = "100%",
  withoutIcon = true,
  onClick,
  onBlur,
  onKeyDown,
  LabelmarginBottom,
  required,
  boxShadow,
  border,
}) => {
  return (
    <div className={style.container} style={{ width: width }}>
      <label
        style={{
          marginBottom: LabelmarginBottom ? `${LabelmarginBottom}px` : "auto",
          display: "flex",
        }}
      >
        {label}
        {required && <p className={style.inputRequired}>*</p>}
      </label>
      <div className={style.inputContainer}>
        <input
          style={{
            boxShadow: boxShadow ? boxShadow : "auto",
            border: border ? border : "auto",
          }}
          type="text"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder={placeholder}
          maxLength={35}
          onClick={onClick}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          required={required}
        />
        {withoutIcon && <img src={icon} alt="lupa" />}
      </div>
    </div>
  );
};

export default SearchInput;
