import React from "react";
import style from "./formDataTable.module.css";
import {
  TooltipForStrings,
  // @ts-ignore
} from "@viuti/recursos";

export const formatDataTable = (
  document,
  screenWidth,
  renderIconWithOptions
) => {
  // TODO: Cambiar el 00.00 por Gratuito
  const total =
    Number(document.total.replace("S/", "")) === 0 ? "S/ 0.00" : document.total;
  if (screenWidth >= 1380) {
    return {
      box: TooltipForStrings(document.box, 14),
      type: document.type,
      number: document.series + "-" + document.number,
      date: document.justTheDate,
      time: document.time,
      employee: TooltipForStrings(document.employee, 14),
      client: TooltipForStrings(document.client, 14),
      total: total,
      status: document.status,
      statusFE: (
        <div className={getStatusClassName(document.statusFE, style)}>
          {document.statusFE}
        </div>
      ),
      guion: renderIconWithOptions(document),
    };
  }
  if (screenWidth < 1380 && screenWidth >= 1300) {
    return {
      box: TooltipForStrings(document.box, 10),
      type: document.type,
      number: document.series + "-" + document.number,
      date: document.justTheDate,
      employee: TooltipForStrings(document.employee, 14),
      client: TooltipForStrings(document.client, 14),
      total: total,
      status: document.status,
      statusFE: (
        <div className={getStatusClassName(document.statusFE, style)}>
          {document.statusFE}
        </div>
      ),
      guion: renderIconWithOptions(document),
    };
  }
  if (screenWidth < 1300 && screenWidth >= 1100) {
    return {
      box: TooltipForStrings(document.box, 10),
      type: document.type,
      number: document.series + "-" + document.number,
      date: document.justTheDate,
      employee: TooltipForStrings(document.employee, 14),
      total: total,
      status: document.status,
      statusFE: (
        <div className={getStatusClassName(document.statusFE, style)}>
          {document.statusFE}
        </div>
      ),
      guion: renderIconWithOptions(document),
    };
  }
  if (screenWidth >= 1020 && screenWidth < 1100) {
    return {
      box: TooltipForStrings(document.box, 10),
      type: document.type,
      number: document.series + "-" + document.number,
      date: document.justTheDate,
      employee: TooltipForStrings(document.employee, 14),
      total: total,
      statusFE: (
        <div className={getStatusClassName(document.statusFE, style)}>
          {document.statusFE}
        </div>
      ),
      guion: renderIconWithOptions(document),
    };
  }
  if (screenWidth < 1020) {
    return {
      box: TooltipForStrings(document.box, 10),
      number: document.series + "-" + document.number,
      date: document.justTheDate,
      employee: TooltipForStrings(document.employee, 14),
      total: total,
      statusFE: (
        <div className={getStatusClassName(document.statusFE, style)}>
          {document.statusFE}
        </div>
      ),
      guion: renderIconWithOptions(document),
    };
  }
};

export const getStatusClassName = (status: string, style: any): string => {
  switch (status) {
    case "Aceptado":
      return style.acceptedStatus;
    case "Emitido":
      return "";
    case "No transmitido":
      return "";
    default:
      return style.rejectedStatus;
  }
};
