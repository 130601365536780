import React, { useState } from "react";
// @ts-ignore
import styled from "./style/newRucsPage.module.css";
import { UserInfoIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import FieldsWrapper from "./components/FieldsWrapper/FieldsWrapper";
import FileUploader from "./components/FileUploader/FileUploader";
import useDisabledToContinue from "./components/FieldsWrapper/hooks/useDisabledToContinue";

const NewRucPage = ({ rucData, setRucData, file, setFile, logo, setLogo }) => {
  return (
    <div className={styled.container}>
      <p className={styled.newRucsPage__p}>
        Para obtener un Certificado Digital sigue las instrucciones que te damos{" "}
        <a
          className={styled.newRucsPage__a}
          href="https://cpe.sunat.gob.pe/certificado-digital"
          target="_blank"
        >
          aquí
        </a>
        . Una vez realizados todos los pasos, podrás usar ese RUC para
        facturación electrónica.
      </p>
      <div className={styled.infoBox}>
        <Icon path={UserInfoIcon} alt="" size={16} color={"#937cf4"} />
        <p>
          Recuerde que para realizar la facturación electronica, se debe dar
          primero de alta en el SUNAT, siguiendo las instrucciones que te damos{" "}
          <a
            className={styled.newRucsPage__a}
            href="https://viuti-archivos.s3.amazonaws.com/recursos/NubefactAltaOSEManual"
            target="_blank"
          >
            aquí
          </a>
          .
        </p>
      </div>
      <FieldsWrapper rucData={rucData} setRucData={setRucData} />
      <FileUploader file={file} setFile={setFile} accept={[".p12", ".pfx"]} />
      <FileUploader
        topInformationMsg="Subir logo de facturación"
        file={logo}
        setFile={setLogo}
        accept={[".png", ".jpg", ".jpeg"]}
      />
    </div>
  );
};

export default NewRucPage;
