import { getSearchClientReniec } from "@Services/clients/getSearchClientReniec";
import {
  handleApiError,
  handleSuccessfulResponse,
} from "@Services/constServices";
import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";

export const adaptedSearchClientReniec = async (documentNumber) => {
  try {
    const response = await getSearchClientReniec(documentNumber);
    if (response.data.mensaje === "Cliente ya registrado.") {
      return {
        ...handleApiError(response),
        names: "",
        lastName: "",
        sex: "",
        birthdate: "",
        msg: "Cliente ya registrado.",
        error: true,
        validated: false,
        address: "",
      };
    } else if (
      response.data.mensaje === "No se encontró." ||
      response.data.mensaje === "Error al buscar al cliente."
    ) {
      return {
        ...handleApiError(response),
        names: "",
        lastName: "",
        sex: "",
        birthdate: "",
        msg: "No encontrado.",
        error: true,
        validated: false,
        address: "",
      };
    } else {
      let name = "";
      let lastN = "";
      const removeQuotes = (str) => {
        if (
          (str.startsWith('"') && str.endsWith('"')) ||
          (str.startsWith("'") && str.endsWith("'"))
        ) {
          return str.slice(1, -1);
        }
        return str;
      };
      // funcion apra si es null retorne ""
      const isNull = (str) => {
        if (str === null) {
          return "";
        }
        return str;
      };
      if (response.data.nombreNegocio) {
        name = removeQuotes(response.data.nombreNegocio);
        lastN = "";
      } else {
        name = capitalizeFirstLetters(response.data.nombres);
        lastN = isNull(
          capitalizeFirstLetters(
            response.data.apellidoPaterno + " " + response.data.apellidoMaterno
          )
        );
      }
      return {
        ...handleSuccessfulResponse(response),
        names: name,
        lastName: lastN,
        sex: isNull(response.data.sexo),
        birthdate: isNull(response.data.fechaDeNacimiento),
        msg: "Validado.",
        error: false,
        validated: true,
        address: isNull(response.data.direccion),
      };
    }
  } catch (error) {
    return {
      ...handleApiError(error),
      names: "",
      lastName: "",
      sex: "",
      birthdate: "",
      msg: "Ha ocurrido un error.",
      error: true,
      validated: false,
      address: "",
    };
  }
};
