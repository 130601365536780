import { v4 as uuidv4 } from "uuid";

export const handleScheduleChange = (
  e,
  dayData,
  scheduleList,
  currentScheduleIndex,
  scheduleState,
  setScheduleState
) => {
  let localScheduleList = [...scheduleState];
  const { name, value } = e.target;
  const newSchedule = {
    ...scheduleList[currentScheduleIndex],
    [name]: value,
  };

  const newSchedules = [...scheduleList];
  newSchedules[currentScheduleIndex] = newSchedule;

  localScheduleList[dayData.idDay] = {
    ...dayData,
    scheduleList: newSchedules,
  };

  setScheduleState(localScheduleList);
};

export const handleScheduleAvailabilityChange = (
  value,
  dayData,
  scheduleList,
  currentScheduleIndex,
  scheduleState,
  setScheduleState
) => {
  let localScheduleList = [...scheduleState];
  const newSchedule = {
    ...scheduleList[currentScheduleIndex],
    isAvailable: value,
  };

  const newSchedules = [...scheduleList];
  newSchedules[currentScheduleIndex] = newSchedule;

  localScheduleList[dayData.idDay] = {
    ...dayData,
    scheduleList: newSchedules,
  };

  setScheduleState(localScheduleList);
};

export const addNewSchedule = (
  dayData,
  scheduleList,
  scheduleState,
  setScheduleState
) => {
  let localScheduleList = [...scheduleState];
  const newSchedule = {
    idSchedule: "static" + uuidv4(),
    startTime: "",
    endTime: "",
    isAvailable: true,
  };

  const newSchedules = [...scheduleList, newSchedule];

  localScheduleList[dayData.idDay] = {
    ...dayData,
    scheduleList: newSchedules,
  };

  setScheduleState(localScheduleList);
};

export const deleteSchedule = async (
  dayData,
  scheduleList,
  currentScheduleIndex,
  scheduleState,
  setScheduleState,
  onDelete
) => {
  let localScheduleList = [...scheduleState];
  const newSchedules = scheduleList.filter(
    (schedule, index) => index !== currentScheduleIndex
  );
  const scheduleToDelete = scheduleList[currentScheduleIndex];

  localScheduleList[dayData.idDay] = {
    ...dayData,
    scheduleList: newSchedules,
  };

  await onDelete(scheduleToDelete);

  setScheduleState(localScheduleList);
};
