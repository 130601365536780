import React, { useState, useRef, useEffect } from "react";
// @ts-ignore
import style from "./IconWithOptions.module.css";

interface IconWithOptions {
  icon: any;
  actions: Action[];
  mainButtonStyles?: object;
  buttonsStyles?: object;
  containerStyles?: object;
}

interface Action {
  label: string;
  handler: () => void;
  icon?: any;
  type?: "button" | "separator";
  styles?: React.CSSProperties;
}

const IconWithOptions = ({
  icon,
  actions,
  mainButtonStyles,
  buttonsStyles,
  containerStyles,
}: IconWithOptions) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState("right");
  const modalRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (modalOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      if (rect.left < 50) {
        setModalPosition("left");
      } else {
        setModalPosition("right");
      }
    }
  }, [modalOpen]);

  const toggleModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setModalOpen((prevModalOpen) => !prevModalOpen);
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: Action
  ) => {
    action.handler();
    setModalOpen(false);
  };

  return (
    <div className={style.container}>
      <button
        className={style.tripleDotsContainer}
        style={mainButtonStyles}
        onClick={toggleModal}
        ref={buttonRef}
      >
        {icon}
      </button>

      <div
        className={`${style.window} ${modalOpen ? style.open : ""} ${
          modalPosition === "left" ? style.left : style.right
        }`}
        ref={modalRef}
      >
        <ul className={style.modalContent} style={containerStyles}>
          {actions.map((action, index) => (
            <li key={index}>
              {action.type === "separator" ? (
                <div className={style.separator} style={action.styles} />
              ) : (
                <button
                  onClick={(event) => handleActionClick(event, action)}
                  style={{ ...buttonsStyles, ...action.styles }}
                >
                  {action.icon}
                  <span>{action.label}</span>
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default IconWithOptions;
