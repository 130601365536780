import React from "react";
import style from "./item.module.css";

const Item = ({ itemSvg, itemLabel, onItemSelected, itemLabelStyle }) => {
  return (
    <button
      className={style.selectWrapper__menu__item}
      onClick={() => onItemSelected()}
    >
      {itemSvg && (
        <img
          width={25}
          height={25}
          className={style.selectWrapper__menu__item__svg}
          src={itemSvg}
          alt={itemLabel}
        />
      )}
      <p style={itemLabelStyle}>{itemLabel}</p>
    </button>
  );
};

export default Item;
