import { adaptedSearchClientReniec } from "@Adapters/clients/adaptedSearchClientReniec";
import { adapterVerifyClientExists } from "@Adapters/clients/adapterVerifyClientExists";
import { CARNET_DE_EXTRANJERIA } from "../../Form/constants/constants";

export const validateDNIAndRUC = async (setStatus, status, setForm, form) => {
  setStatus({
    ...status,
    loading: true,
    message: "Validando...",
  });

  let res = await adaptedSearchClientReniec(form.documentNumber);
  try {
    if (res.isSuccess) {
      setForm({
        ...form,
        name: res.names,
        lastName: res.lastName,
        sex: res.sex,
        birthdate: res.birthdate,
        address: res.address,
      });
      setStatus({
        ...status,
        validated: res.validated,
        loading: false,
        message: res.msg,
        error: false,
      });
    } else {
      setStatus({
        ...status,
        error: res.error,
        message: res.msg,
        loading: false,
        validated: res.validated,
      });
    }
  } catch (error) {
    setStatus({
      ...status,
      validated: res.validated,
      error: res.error,
      message: res.msg,
      loading: false,
    });
  }
};

export const validateCarnetDeExtranjeriaAndPasaport = async (
  setStatus,
  status,
  form
) => {
  // quiero hacer una validacion que me retorne que debe tener solo 1 letra y 6 numeros
  if (form.documentType === CARNET_DE_EXTRANJERIA) {
    if (!/^[a-zA-Z]{1}[0-9]{6}$/.test(form.documentNumber)) {
      setStatus({
        ...status,
        error: true,
        message: "Debe tener 1 letra y 6 números",
        loading: false,
      });
      return;
    }
  }
  const res = await adapterVerifyClientExists(form.documentNumber);
  try {
    if (res.isSuccess) {
      setStatus({
        ...status,
        validated: res.validated,
        loading: false,
        error: res.error,
        message: res.msg,
      });
    } else {
      setStatus({
        ...status,
        error: true,
        message: res.msg,
        loading: false,
      });
    }
  } catch (error) {
    setStatus({
      ...status,
      error: true,
      message: res.msg,
      loading: false,
    });
  }
};
