// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bpCkOVbGBGFaS3WVWx1p {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}

.bpCkOVbGBGFaS3WVWx1p img {
  width: 16px;
  height: 16px;
}
.yA3vBm7YdVg67j4gZZSA {
  color: #b4a5f3;
  margin-left: 4px;
}

.hjICCS_I1XthJQViBH8g {
  -webkit-animation: 1s umnTlAfJK0Sv3t84hMt5 linear infinite;
  animation: 1s umnTlAfJK0Sv3t84hMt5 linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@keyframes umnTlAfJK0Sv3t84hMt5 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes umnTlAfJK0Sv3t84hMt5 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/FormReservation/components/AppointmentDetail/ValidationMessage/ValidationMessage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,0DAA4C;EAC5C,kDAAoC;EACpC,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,+BAA+B;EACjC;;EAEA;IACE,iCAAiC;EACnC;AACF","sourcesContent":[".inputContainerMessage {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  margin-top: 4px;\n}\n\n.inputContainerMessage img {\n  width: 16px;\n  height: 16px;\n}\n.inputValidatingMessage {\n  color: #b4a5f3;\n  margin-left: 4px;\n}\n\n.validatingSvg {\n  -webkit-animation: 1s rotate linear infinite;\n  animation: 1s rotate linear infinite;\n  -webkit-transform-origin: 50% 50%;\n  transform-origin: 50% 50%;\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n@-webkit-keyframes rotate {\n  from {\n    -webkit-transform: rotate(0deg);\n  }\n\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainerMessage": `bpCkOVbGBGFaS3WVWx1p`,
	"inputValidatingMessage": `yA3vBm7YdVg67j4gZZSA`,
	"validatingSvg": `hjICCS_I1XthJQViBH8g`,
	"rotate": `umnTlAfJK0Sv3t84hMt5`
};
export default ___CSS_LOADER_EXPORT___;
