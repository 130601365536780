import React from "react";
// @ts-ignore
import { WarningTriangleIcon } from "@Models/icons";
//@ts-ignore
import style from "./ModalUnsavedChanges.module.css";
//@ts-ignore
// TODO
import ModalAcceptCancel from "@Molecules/ModalAcceptCancel/ModalAcceptCancel";
import { ModalUnsavedChangesProps } from "./interface/modalUnsavedChanges";

const ModalUnsavedChanges = ({
  changesModalVisible,
  setChangesModalVisible,
  message = "Si cierras la página, se perderán todos los cambios que hayas realizado.",
  navigate,
  navigateTo,
  handleDiscardChanges,
}: ModalUnsavedChangesProps) => {
  return (
    changesModalVisible && (
      <ModalAcceptCancel
        showButtons={{
          showButtonClose: false,
          showButtonOne: true,
          showButtonTwo: true,
        }}
        buttonOne={{
          textButtonOne: "Descartar cambios",
          actionButtonOne: () => {
            handleDiscardChanges && handleDiscardChanges();
            navigate && navigate(navigateTo);
          },
        }}
        buttonTwo={{
          textButtonTwo: "Seguir aquí",
          actionButtonTwo: () => setChangesModalVisible(false),
        }}
      >
        <div className={style.body}>
          <h3 className={style.title}>Cambios sin guardar</h3>
          <img
            src={WarningTriangleIcon}
            alt="Advertencia"
            className={style.modalIcon}
          />
          <p className={style.modalDescription}>{message}</p>
        </div>
      </ModalAcceptCancel>
    )
  );
};

export default ModalUnsavedChanges;
