import React, { useEffect, useState } from "react";
import { ISaleSummary } from "@Models/interfaces/salebox";
import convertStringToNumber from "@Utilities/convertStringToNumber";
// import {
// 	addSaleProducts,
// 	addSalesServices,
// 	resetClientSales,
// 	updateClient,
// 	updateFinishedSaleResponse,
// 	updateSaleBillingDocument,
// 	updateSalePaymentMethods,
// } from "@ReduxService/states/currentSaleSlice";
// import { updateSelectedSaleBox } from "@ReduxService/states/selectedSaleBoxSlice";

const useTicket = (
  document,
  saleDocumentDetails,
  paymentMethod,
  handlePrintSaleTicket,
  qrData,
  hash,
  saleBoxName,
  ruc,
  address,
  typeDocumentName,
  localName,
  clientDocumentName
) => {
  const [summary, setSummary] = useState<ISaleSummary>({
    subtotal: 0,
    prepayment: 0,
    discount: 0,
    discountGiftcard: 0,
    igv: 0,
    icbp: 0,
    total: 0,
  });
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // dispatch(resetClientSales());
    setServices([]);
    setProducts([]);
    saleDocumentDetails?.forEach((saleDocumentDetail) => {
      if (saleDocumentDetail.priceServiceId) {
        setServices((services) => [
          ...services,
          {
            index: saleDocumentDetail.saleDocumentDetailId,
            employeeId: saleDocumentDetail.saleDocumentDetailId,
            availableEmployees: [
              {
                id: 0,
                name: "",
                lastName: "",
              },
            ],
            item: {
              id: saleDocumentDetail.saleDocumentDetailId,
              igv: 0,
              isFree: false,
              name: saleDocumentDetail.description,
              price: saleDocumentDetail.price,
              priceId: 0,
              total: 0,
            },
            summary: {
              addition: 0,
              discount: saleDocumentDetail.discount,
              modifierIsPercentage: false,
              quantity: saleDocumentDetail.quantity,
              subtotal: saleDocumentDetail.price,
              total: saleDocumentDetail.total,
            },
          },
        ]);
      } else if (saleDocumentDetail.localProductId) {
        setProducts((products) => [
          ...products,
          {
            index: saleDocumentDetail.saleDocumentDetailId,
            employeeId: 0,
            item: {
              brand: {
                id: 0,
                name: "",
              },
              categories: [
                {
                  id: 0,
                  name: "",
                },
              ],
              code: "",
              id: saleDocumentDetail.saleDocumentDetailId,
              igv: 0,
              localId: 0,
              name: saleDocumentDetail.description,
              price: saleDocumentDetail.price,
              stock: 0,
              subBrand: {
                id: 0,
                name: "",
              },
              warehouseId: 0,
            },
            summary: {
              addition: 0,
              discount: saleDocumentDetail.discount,
              modifierIsPercentage: false,
              quantity: saleDocumentDetail.quantity,
              subtotal: saleDocumentDetail.price,
              total: saleDocumentDetail.total,
            },
          },
        ]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleDocumentDetails, document.id]);

  const subtotal = convertStringToNumber(document.subTotal);
  const igv = convertStringToNumber(document.igv);
  const icbp = convertStringToNumber(document.icbper);
  const discount = convertStringToNumber(document.discount);
  const total = convertStringToNumber(document.total);
  const client = {
    id: document.clientId,
    name: document.client,
    lastName: "",
    email: "",
    gender: "",
    phoneNumber: "",
    birthdate: "",
    documentType: document.clientDocumentName,
    documentNumber: document.clientDocumentNumber,
    category: {
      id: 0,
      name: "",
      discount: {
        services: 0,
        products: 0,
      },
    },
  };

  const adaptPaymentMethods = paymentMethod.map((payment, index) => {
    return {
      paymentMethod: {
        id: index,
        name: payment.methodPayment,
        subCategories: [
          {
            id: 0,
            name: "",
          },
        ],
      },
      paymentCategory: {
        id: index,
        name: payment.methodPayment,
      },
      value: payment.amount,
    };
  });

  const adaptBillingDocument = {
    id: 1,
    serie: 28,
    serialNumber: document.series,
    isAvailable: 1,
    name: document.type,
  };
  const adaptFinishedSaleResponse = {
    status: 200,
    message: "",
    data: {
      saleId: document.id,
      saledocumentsDetailsId: 0,
      billing: {
        serie: document.series,
        document: document.number,
      },
      dataTicket: {
        hash: hash,
        qrData: qrData,
      },
    },
  };

  // useEffect(() => {
  // 	dispatch(updateSelectedSaleBox(document.saleBoxId));
  // 	dispatch(updateClient(client));
  // 	dispatch(updateSaleBillingDocument(adaptBillingDocument));
  // 	dispatch(updateFinishedSaleResponse(adaptFinishedSaleResponse));
  // 	dispatch(updateSalePaymentMethods(adaptPaymentMethods));
  // 	dispatch(addSalesServices(services));
  // 	dispatch(addSaleProducts(products));
  // 	setSummary({
  // 		subtotal,
  // 		prepayment: 0,
  // 		discountGiftcard: 0,
  // 		icbp,
  // 		igv: igv,
  // 		discount: discount,
  // 		total: total,
  // 	});
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [document.id, services, products]);

  return { summary };
};

export default useTicket;
