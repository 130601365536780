// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./normalizer/common.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!./normalizer/form.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js!./normalizer/links.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

html,
body,
main {
  height: 100%;
}

p,
a {
  font-size: 0.88rem;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

select {
  height: 38px;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

button {
  font-family: "Mulish", sans-serif;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

/* Media queries */

@media (height <= VAR(--MAX_HEIGHT_GLOBAL_STYLES)) {
  p,
  span {
    font-size: 0.75rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h4,
  button {
    font-size: 0.87rem;
  }
}

html {
  overflow: hidden;
  font-size: 16px; /* Tamaño base para navegadores de escritorio */
}

@media (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/global/normalize.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,iCAAiC;AACnC;;AAMA;;;EAGE,YAAY;AACd;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;;EAEE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,eAAe;EACf,YAAY;EACZ,6BAA6B;AAC/B;;AAEA,kBAAkB;;AAElB;EACE;;IAEE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;;IAEE,kBAAkB;EACpB;AACF;;AAEA;EACE,gBAAgB;EAChB,eAAe,EAAE,+CAA+C;AAClE;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Mulish\", sans-serif;\n}\n\n@import url(\"./normalizer/common.css\");\n@import url(\"./normalizer/form.css\");\n@import url(\"./normalizer/links.css\");\n\nhtml,\nbody,\nmain {\n  height: 100%;\n}\n\np,\na {\n  font-size: 0.88rem;\n}\n\na,\na:hover {\n  text-decoration: none;\n  color: inherit;\n}\n\nselect {\n  height: 38px;\n}\n\ninput:focus {\n  outline: none;\n}\n\nselect:focus {\n  outline: none;\n}\n\nbutton {\n  font-family: \"Mulish\", sans-serif;\n  cursor: pointer;\n  border: none;\n  background-color: transparent;\n}\n\n/* Media queries */\n\n@media (height <= VAR(--MAX_HEIGHT_GLOBAL_STYLES)) {\n  p,\n  span {\n    font-size: 0.75rem;\n  }\n\n  h1 {\n    font-size: 1.5rem;\n  }\n\n  h4,\n  button {\n    font-size: 0.87rem;\n  }\n}\n\nhtml {\n  overflow: hidden;\n  font-size: 16px; /* Tamaño base para navegadores de escritorio */\n}\n\n@media (max-width: 1200px) {\n  html {\n    font-size: 15px;\n  }\n}\n\n@media (max-width: 992px) {\n  html {\n    font-size: 14px;\n  }\n}\n\n@media (max-width: 768px) {\n  html {\n    font-size: 13px;\n  }\n}\n\n@media (max-width: 576px) {\n  html {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
