import React from "react";
import getUserPermissions from "../../utilities/jwt/getUserPermissions";

export const useAuth = (functionId: string) => {
  const permissions = getUserPermissions(localStorage.getItem("access_token"));

  const hasAccess = () => {
    const permission = permissions.accessKeyModules.find(
      (module) => module.id === functionId
    );
    return permission;
  };

  const hasPlanAccess = () => {
    const plan = permissions.plan.hierarchy;
    return plan >= hasAccess()?.hierarchy;
  };

  return { hasAccess, hasPlanAccess };
};
