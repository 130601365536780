// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GQ13HhRJPN6VT050vqGW {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  @media screen and (width > 745px) {
    min-width: 500px;
  }
}

.QaGm4Ajwr8V82ADeuDxf {
  padding-right: 10px;
}

.hXPnOsl_jZmQVZGVQRSq {
  min-width: 150px;
  @media screen and (width < 745px) {
    min-width: 80px;
  }
}

.uET5bYP4gzbcmKZrUY6Y {
  max-height: 150px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/SaleboxReports/SaleMovementsReportView/components/EditingHistoryModal/EditingHistoryModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".editingHistoryContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 500px;\n  min-height: 200px;\n  @media screen and (width > 745px) {\n    min-width: 500px;\n  }\n}\n\n.dataDate {\n  padding-right: 10px;\n}\n\n.editionTitle {\n  min-width: 150px;\n  @media screen and (width < 745px) {\n    min-width: 80px;\n  }\n}\n\n.editionHistory {\n  max-height: 150px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editingHistoryContainer": `GQ13HhRJPN6VT050vqGW`,
	"dataDate": `QaGm4Ajwr8V82ADeuDxf`,
	"editionTitle": `hXPnOsl_jZmQVZGVQRSq`,
	"editionHistory": `uET5bYP4gzbcmKZrUY6Y`
};
export default ___CSS_LOADER_EXPORT___;
