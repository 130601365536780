// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kQR_Lv9qss4XjULid1Wu {
  width: fit-content;
  position: relative;
  overflow: visible;
}

.R2YqkjdEqX85dOOpM7EL {
  border-radius: 10px;
  background: #fff;
  box-shadow: -4px -4px 5px 0px rgba(0, 0, 0, 0.1),
    4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 160px;
  width: fit-content;
  box-sizing: border-box;
  padding: 10px 0px;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: all 150ms ease-in-out;
}

.duseKGtFqXKNTshAx5rP {
  margin-top: 10px;
  visibility: visible;
  opacity: 1;
}

@keyframes LBMcf7oMfFT5xSw4NsdT {
  0% {
    transform: translateY(-15px);
    opacity: 0;
    overflow: hidden;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/selectWrapper/styles/selectWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB;sCACoC;EACpC,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE;IACE,4BAA4B;IAC5B,UAAU;IACV,gBAAgB;EAClB;EACA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF","sourcesContent":[".selectWrapper {\n  width: fit-content;\n  position: relative;\n  overflow: visible;\n}\n\n.selectWrapper__menu {\n  border-radius: 10px;\n  background: #fff;\n  box-shadow: -4px -4px 5px 0px rgba(0, 0, 0, 0.1),\n    4px 4px 4px 0px rgba(0, 0, 0, 0.1);\n  margin-top: 0px;\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  min-width: 160px;\n  width: fit-content;\n  box-sizing: border-box;\n  padding: 10px 0px;\n  visibility: hidden;\n  opacity: 0;\n  z-index: 100;\n  transition: all 150ms ease-in-out;\n}\n\n.selectWrapper__menu__show {\n  margin-top: 10px;\n  visibility: visible;\n  opacity: 1;\n}\n\n@keyframes slide-in-top {\n  0% {\n    transform: translateY(-15px);\n    opacity: 0;\n    overflow: hidden;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWrapper": `kQR_Lv9qss4XjULid1Wu`,
	"selectWrapper__menu": `R2YqkjdEqX85dOOpM7EL`,
	"selectWrapper__menu__show": `duseKGtFqXKNTshAx5rP`,
	"slide-in-top": `LBMcf7oMfFT5xSw4NsdT`
};
export default ___CSS_LOADER_EXPORT___;
