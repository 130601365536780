// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fYkMd9EeWiM6qcOEXgq_ {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/SearchAndFilter/SearchAndFilter.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".containerSearchWithFilter {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerSearchWithFilter": `fYkMd9EeWiM6qcOEXgq_`
};
export default ___CSS_LOADER_EXPORT___;
