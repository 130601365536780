import React from "react";
// @ts-ignore
import style from "./UnauthorizedPage.module.css";
import { HiHandRaised } from "react-icons/hi2";

const UnauthorizedPage = () => {
  /**
   * Layout used for unauthorized access pages in the applications that use
   * micro-frontend and has a basename in the router.
   */
  return (
    <div id="viuti-front-mainContent" className={style.container}>
      <HiHandRaised size={128} color="#CCCCCC" />
      <span className={style.text__container}>
        <h1 className={style.title}>
          Lo sentimos, no tienes autorización para acceder a esta página.
        </h1>
        <p className={style.text}>
          Si crees que esto es un error, por favor contacta al administrador del
          sistema.
        </p>
      </span>
    </div>
  );
};

export default UnauthorizedPage;
