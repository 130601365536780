// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eNTG6AE1Os60QIk0jdIG {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DGLQzlmghwGwoZIQhQpZ {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease-in-out;
}

.A6pdDCfAOvELc8yiTROQ {
  transform: scale(1);
}

.PvjWFimOu5LkdXsvcNcO {
  transform: scale(0);
}

.DGLQzlmghwGwoZIQhQpZ div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: DGLQzlmghwGwoZIQhQpZ 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #45348e transparent transparent transparent;
}

.DGLQzlmghwGwoZIQhQpZ div:nth-child(1) {
  animation-delay: -0.45s;
}
.DGLQzlmghwGwoZIQhQpZ div:nth-child(2) {
  animation-delay: -0.3s;
}
.DGLQzlmghwGwoZIQhQpZ div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes DGLQzlmghwGwoZIQhQpZ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/loadingSpinner/LoadingSpinner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,0EAA6D;EAC7D,yDAAyD;AAC3D;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loaderContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.ldsRing {\n  display: flex;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n  transition: all 150ms ease-in-out;\n}\n\n.visible {\n  transform: scale(1);\n}\n\n.hidden {\n  transform: scale(0);\n}\n\n.ldsRing div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  margin: 8px;\n  border: 2px solid #fff;\n  border-radius: 50%;\n  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #45348e transparent transparent transparent;\n}\n\n.ldsRing div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n.ldsRing div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n.ldsRing div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n@keyframes ldsRing {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `eNTG6AE1Os60QIk0jdIG`,
	"ldsRing": `DGLQzlmghwGwoZIQhQpZ`,
	"visible": `A6pdDCfAOvELc8yiTROQ`,
	"hidden": `PvjWFimOu5LkdXsvcNcO`
};
export default ___CSS_LOADER_EXPORT___;
