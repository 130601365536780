import React from "react";

//@ts-ignore
import style from "./TextAreaInput.module.css";
import { iconPath } from "@Models/localPath";

const TextAreaInput = ({
  label,
  value = "",
  name = "",
  touched,
  error,
  disabled,
  required,
  prefix = "",
  textareaProps,
  width = "100%",
  maxWidth = "730px",
  height = "60px",
  id = "",
  placeholder = "",
  handleChange = (e) => {},
  handleChangeTemp = null,
  handleBlur = () => {},
}) => {
  return (
    <div className={style.inputContainer}>
      <label className={style.inputLabel}>
        <p>{label}</p>
        {!disabled && required && <p className={style.inputRequired}>*</p>}
      </label>
      <div
        className={style.inputContent}
        style={{ width: width, maxWidth: maxWidth ? maxWidth : "730px" }}
      >
        <span className={style.inputPrefix}>{prefix}</span>
        <textarea
          onChange={(e) => {
            return handleChangeTemp
              ? handleChangeTemp(e)
              : handleChange(e.target.value);
          }}
          onBlur={handleBlur}
          value={value}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          prefix={prefix}
          sx={{
            borderRadius: "5px",
            width: "100%",
            minWidth: "60px",
            height: height,
          }}
          className={`${style.inputText} ${prefix && style.inputTextPrefix} ${
            disabled && style.inputTextDisabled
          } ${touched && error && style.inputTextError}`}
          {...textareaProps}
          id={id}
        ></textarea>
      </div>
      {touched && error && (
        <span className={style.inputErrorContainer}>
          <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
          <p className={style.inputError}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default TextAreaInput;
