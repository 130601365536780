import React from "react";

//@ts-ignore
import style from "./TextInput.module.css";
import { iconPath } from "../../../../../models/localPath";

interface TextInputProps {
  label?: string;
  name?: string;
  value?: string;
  touched?: boolean;
  error?: any;
  handleChange?: any;
  handleBlur?: any;
  placeholder?: string;
  disabled?: boolean;
  keyboardType?: string;
  required?: boolean;
  maxLength?: number;
  prefix?: string;
  suffix?: string;
  width?: string;
  flex?: string;
  inputProps?: any;
  rightIcon?: string;
  leftIcon?: string;
  id?: string;
  onKeyDown?: any;
  inputMode?: string;
  minWidth?: string;
  autoFocus?: boolean;
  inputRef?: any;
  onClick?: any;
  onFocus?: any;
}
const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  value = "",
  touched,
  error,
  handleChange = (e) => {},
  handleBlur = (e) => {},
  placeholder,
  disabled,
  keyboardType,
  required,
  maxLength,
  prefix,
  suffix,
  width = "",
  minWidth = "",
  flex = "none",
  inputProps = {},
  rightIcon,
  leftIcon,
  id = "",
  onKeyDown = () => {},
  inputMode,
  inputRef,
  onClick,
  onFocus,
}) => {
  return (
    <div
      className={style.inputContainer}
      style={{ width: width, flex: flex, minWidth: minWidth }}
    >
      {label && (
        <label className={style.inputLabel}>
          {label}
          {required && <p className={style.inputRequired}>*</p>}
        </label>
      )}
      <div className={style.inputContent}>
        {prefix && <span className={style.inputPrefix}>{prefix}</span>}
        <input
          onFocus={onFocus}
          onClick={onClick}
          ref={inputRef}
          name={name}
          value={value}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleBlur(e)}
          placeholder={placeholder}
          disabled={disabled}
          type={keyboardType}
          onKeyDown={onKeyDown}
          inputMode={inputMode}
          min={0}
          max={maxLength}
          required={required}
          id={id}
          className={`${style.inputText} ${
            prefix || leftIcon ? style.inputTextPrefix : ""
          } ${suffix || rightIcon ? style.inputTextSuffix : ""} ${
            disabled ? style.inputTextDisabled : ""
          } ${touched && error ? style.inputTextError : ""}`}
          {...inputProps}
        />
        {suffix && <span className={style.inputSuffix}>{suffix}</span>}
        {rightIcon && (
          <img className={style.rightIcon} src={rightIcon} alt="right icon" />
        )}
        {leftIcon && (
          <img className={style.leftIcon} src={leftIcon} alt="left icon" />
        )}
      </div>
      {touched && error && (
        <span className={style.inputErrorContainer}>
          <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
          <p className={style.inputErrorMessage}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default TextInput;
