// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xpjk0MCRwKACl0tVRlsr {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.l3qgUp94OIzYBbPCtUCz {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  transition: border-color 0.3s ease;
}

.l3qgUp94OIzYBbPCtUCz:hover,
.l3qgUp94OIzYBbPCtUCz:focus {
  border-color: #666;
}

.l3qgUp94OIzYBbPCtUCz:active {
  border-color: #787878;
}

.whYRir94N6ABCS5n9jFw .l3qgUp94OIzYBbPCtUCz::placeholder {
  color: #999;
}

.egLoxSj79sk_k_VnZtKP {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  background: #f9f8fe;
}

.BbGxhNo0cIV3WRE470ya,
.afHVKmxa8lPeYBxH83WE {
  color: rgba(0, 0, 0, 0.55);
  gap: 10px;
}

.NNXNBUpAK5RRTGcy0A2S {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.H5fLnVa9ZO8wqIsgek5Q {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  padding-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/SaleboxReports/SaleDocumentReportView/components/CancelTicketModal/CancelTicketModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,kCAAkC;AACpC;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,8BAA8B;EAC9B,eAAe;EACf,oCAAoC;EACpC,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;EAEE,0BAA0B;EAC1B,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".cancel_ticket_container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.reason_input {\n  width: 100%;\n  height: 100px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  resize: none;\n  transition: border-color 0.3s ease;\n}\n\n.reason_input:hover,\n.reason_input:focus {\n  border-color: #666;\n}\n\n.reason_input:active {\n  border-color: #787878;\n}\n\n.reason .reason_input::placeholder {\n  color: #999;\n}\n\n.cancel_ticket_info_container {\n  display: flex;\n  gap: 20px;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  border-radius: 4px;\n  background: #f9f8fe;\n}\n\n.cancel_ticket_concept,\n.cancel_ticket_date {\n  color: rgba(0, 0, 0, 0.55);\n  gap: 10px;\n}\n\n.cancel_ticket_info {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.amount_info {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  justify-content: space-between;\n  padding-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancel_ticket_container": `Xpjk0MCRwKACl0tVRlsr`,
	"reason_input": `l3qgUp94OIzYBbPCtUCz`,
	"reason": `whYRir94N6ABCS5n9jFw`,
	"cancel_ticket_info_container": `egLoxSj79sk_k_VnZtKP`,
	"cancel_ticket_concept": `BbGxhNo0cIV3WRE470ya`,
	"cancel_ticket_date": `afHVKmxa8lPeYBxH83WE`,
	"cancel_ticket_info": `NNXNBUpAK5RRTGcy0A2S`,
	"amount_info": `H5fLnVa9ZO8wqIsgek5Q`
};
export default ___CSS_LOADER_EXPORT___;
