import React, { useRef, useState } from "react";
//@ts-ignore
import style from "./styles/selectWrapper.module.css";
import Item from "./component/Item";
import useWhenClickOutsideBox from "./hooks/useWhenClickOutSideBox";
import { v4 as uuidv4 } from "uuid";
import { ISelectWrapperProps } from "./interfaces/selectWrapper";

const selectItemsDefault = [];

const uuid = uuidv4();

const SelectWrapper = ({
  children,
  selectItems = selectItemsDefault,
  selectId = uuid,
  testId,
}: ISelectWrapperProps) => {
  const menuListRef = useRef(null);
  const [showMenuList, setShowMenuList] = useState(false);
  const [position, setPosition] = useState(null);

  //When click outside the box, this close the menu list
  useWhenClickOutsideBox({
    boxElementId: selectId,
    onClickOutside: () => {
      setShowMenuList(false);
    },
  });

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setShowMenuList(!showMenuList);

    const rect = menuListRef.current.getBoundingClientRect();
    const viewportWidth = document.documentElement.clientWidth;

    if (
      rect.left + rect.width > viewportWidth ||
      rect.bottom > window.innerHeight
    ) {
      // The element is off the screen, adjust its position
      setPosition({
        right: 0,
      });
    }
  };

  return (
    <button
      id={selectId}
      className={style.selectWrapper}
      onClick={handleOpenMenu}
    >
      {children}

      <article
        className={`${style.selectWrapper__menu} ${
          showMenuList ? style.selectWrapper__menu__show : ""
        }`}
        style={{ ...position }}
        id={testId}
        ref={menuListRef}
      >
        {selectItems.map((el, i) => {
          return <Item key={testId} {...el} testId={testId + "-" + i} />;
        })}
      </article>
    </button>
  );
};

export default SelectWrapper;
