import { createCreditNote_service } from "@Services/saleboxes/documents";
import { PARTIAL_AMOUNT_TYPE } from "../../components/ui/organism/SaleboxReports/SaleDocumentReportView/components/CreditNoteModal/consts/amountType";

export const createCreditNote = async (
  documentId: number,
  form: {
    reason: number;
    comments: string;
    amountType: number;
    amount: string;
  },
  updateNotification: (notification: {
    message: string;
    status: number;
    toastTimeDuration: number;
  }) => void
) => {
  let payload = {
    saleDocumentId: documentId,
    conceptId: form.reason,
    restoreAll: true,
    items: [],
  };

  if (form.amountType === PARTIAL_AMOUNT_TYPE) {
    payload.items = [
      {
        description: form.comments,
        unitAmount: Number(form.amount),
      },
    ];
    delete payload.restoreAll;
  } else {
    delete payload.items;
  }

  const response = await createCreditNote_service(payload);
  if (!response.isSuccess) {
    updateNotification({
      message: response.message,
      status: response.status as any,
      toastTimeDuration: 7000,
    });
    return response;
  }

  updateNotification({
    message: "Nota de crédito creada correctamente",
    status: response.status,
    toastTimeDuration: 4000,
  });
  return response;
};
