import {
  ApiResponse,
  appointmentsBaseUrl,
  getData,
} from "@Services/constServices";

export const getAppointmentById_service = async (
  id: number
): Promise<ApiResponse> => {
  const url = `${appointmentsBaseUrl}/Reserva/GetOneReservation?reserveId=${id}`;
  const successMessage = "Reserva encontrada correctamente.";
  const errorMessage =
    "Hubo un error al obtener la reserva, intente nuevamente.";
  return getData(url, successMessage, errorMessage);
};
