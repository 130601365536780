import React from "react";
// @ts-ignore
import styles from "./DualBars.module.css";

const DualBars = () => {
  return (
    <div className={styles.dual_bars_container}>
      <div className={`${styles.bar} ${styles.top_bar}`}></div>
      <div className={`${styles.bar} ${styles.bottom_bar}`}></div>
    </div>
  );
};

export default DualBars;
