// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iIwCOZdNEaC1XTnTlIot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 3rem;
}

._XIlhwqizJ3iTfSmxQik {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.R_GlxgEK2mzTQVK_4HYs,
._9pD6iS5x4feonUbKkkQ {
  text-align: center;
  font-family: "Mulish", sans-serif;
  color: rgb(22, 22, 22);
}

.R_GlxgEK2mzTQVK_4HYs {
  font-size: 1.3rem;
}

._9pD6iS5x4feonUbKkkQ {
  font-size: 1rem;
}

.IVygBti3msHlSWw3ALV6 {
  display: flex;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;;EAEE,kBAAkB;EAClB,iCAAiC;EACjC,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  gap: 3rem;\n}\n\n.text__container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 2rem;\n}\n\n.title,\n.text {\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  color: rgb(22, 22, 22);\n}\n\n.title {\n  font-size: 1.3rem;\n}\n\n.text {\n  font-size: 1rem;\n}\n\n.button__container {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `iIwCOZdNEaC1XTnTlIot`,
	"text__container": `_XIlhwqizJ3iTfSmxQik`,
	"title": `R_GlxgEK2mzTQVK_4HYs`,
	"text": `_9pD6iS5x4feonUbKkkQ`,
	"button__container": `IVygBti3msHlSWw3ALV6`
};
export default ___CSS_LOADER_EXPORT___;
