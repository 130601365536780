import React from "react";
//@ts-ignore
import style from "./ModalLayoutWrapper.module.css";

const ModalLayoutWrapper = ({ children, isVisible = true, zIndex = 1001 }) => {
  return (
    <div
      className={`${style.modalLayoutWrapper} ${
        isVisible
          ? style.modalLayoutWrapperVisible
          : style.modalLayoutWrapperHidden
      }`}
      style={{ zIndex: zIndex }}
    >
      {children}
    </div>
  );
};

export default ModalLayoutWrapper;
