import { moduloAction } from "@Models/moduloAction/moduloAction";

// Interfaz para la acción
export interface Action {
  id: number;
  nombreAction: string;
}

// Interfaz para el módulo
export interface Modulo {
  id: number;
  nombreModulo: string;
}

// Interfaz para el resultado de la función
export interface ActionModuleResult {
  action: Action;
  modulo: Modulo;
}

// Tipo para la función mapActionToModuleAndAction
export type MapActionToModuleAndActionFunction = (
  actionId: number
) => ActionModuleResult | null;

// Actualización de la función para buscar solo por id de acción
export const mapActionToModuleAndAction: MapActionToModuleAndActionFunction = (
  actionId
) => {
  // Buscar la acción en todos los módulos
  const action = moduloAction
    .flatMap((m) => m.actions)
    .find((a) => a.id === actionId);

  if (action) {
    // Encontrar el módulo al que pertenece la acción
    const modulo = moduloAction.find((m) =>
      m.actions.some((a) => a.id === actionId)
    );

    return {
      action: { id: action.id, nombreAction: action.nombreAction },
      modulo: { id: modulo.id, nombreModulo: modulo.nombreModulo },
    };
  }

  return null;
};
