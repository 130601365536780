import { searchCustomers } from "@Services/clients/searchCustomers";
import { handleApiError } from "@Services/constServices";

export const adapterVerifyClientExists = async (documentNumber) => {
  try {
    const customersResponse = await searchCustomers(documentNumber);

    if (customersResponse.isSuccess) {
      const client = customersResponse.data[0];
      if (
        client &&
        String(client.customerDocument) === String(documentNumber)
      ) {
        return {
          ...customersResponse,
          msg: "Cliente ya registrado",
          validated: false,
          error: true,
        };
      } else {
        return {
          ...customersResponse,
          msg: "Validado",
          validated: true,
          error: false,
        };
      }
    } else {
      return {
        ...customersResponse,
        msg: "Problemas al validar",
        validated: false,
        error: true,
      };
    }
  } catch (error) {
    return {
      ...handleApiError(error),
      msg: "Problemas al validar",
      validated: false,
      error: true,
    };
  }
};
