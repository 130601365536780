import React, { useEffect, useState } from "react";
// @ts-ignore
import { TableMobile } from "@viuti/recursos";
import styles from "./EditingHistoryModal.module.css";
import { getMovementsEditLogs } from "@Adapters/saleboxes/getMovementsEditLogs.adapter";
import useScreen from "@Hooks/useScreen/useScreen";
import { ModalAcceptCancel } from "@Molecules/index";

interface IEditingHistoryModal {
  movement: any;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onNotification: ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void;
}

const EditingHistoryModal = ({
  movement,
  isVisible,
  setIsVisible,
  onNotification,
}: IEditingHistoryModal) => {
  const [data, setData] = useState([
    {
      responsable: "",
      fecha: "",
      edicion: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfRows, setNumberOffRows] = useState(5);
  const screen = useScreen();

  const createObjectHistory = (data, index) => {
    let headerTitles = [data.responsable, data.fecha];
    if (screen.width < 500) {
      headerTitles = [data.fecha];
    }
    if (screen.width > 780) {
      return {
        id: index,
        header: headerTitles,
        body: [
          {
            item1: "Edición Realizada:",
            item2: data.edition,
          },
        ],
      };
    }
    return {
      id: index,
      header: headerTitles,
      body: [
        {
          item1: "Edición:",
          item2: data.edition,
        },
      ],
    };
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getMovementsEditLogs(movement.id, onNotification);
      setData(response);
      setIsLoading(false);
      setNumberOffRows(response.length > 5 ? response.length : 5);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movement.id]);

  return (
    <ModalAcceptCancel
      title={"Historial de ediciones"}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
      actionButtonClose={() => {
        setIsVisible(false);
      }}
    >
      <div className={styles.editingHistoryContainer}>
        <TableMobile
          isLoading={isLoading}
          dataTable={data.map((item, index) =>
            createObjectHistory(item, index)
          )}
          numberOfRows={numberOfRows}
        />
      </div>
    </ModalAcceptCancel>
  );
};

export default EditingHistoryModal;
