import React from "react";
// @ts-ignore
import style from "./LoadingSpinner.module.css";

interface ILoadingSpinnerProps {
  isVisible: boolean;
  size: number;
}

const LoadingSpinner = ({
  isVisible = true,
  size = 20,
}: ILoadingSpinnerProps) => {
  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <span
      className={`${style.loaderContainer} ${
        isVisible ? style.visible : style.hidden
      }`}
      style={sizeStyle}
    >
      <div className={style.ldsRing} style={sizeStyle}>
        <div style={sizeStyle}></div>
        <div style={sizeStyle}></div>
        <div style={sizeStyle}></div>
        <div style={sizeStyle}></div>
      </div>
    </span>
  );
};

export default LoadingSpinner;
