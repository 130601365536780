import { useEffect, useState } from "react";

const useWhenClickOutsideBox = ({
  boxElementId,
  onClickOutside = () => {},
}) => {
  useEffect(() => {
    if (!boxElementId) return;
    window.addEventListener("click", function (e) {
      if (!document.getElementById(boxElementId)?.contains(e.target)) {
        onClickOutside();
      }
    });
  }, []);
};

export default useWhenClickOutsideBox;
