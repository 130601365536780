import React from "react";

//@ts-ignore
import style from "./CheckboxInput.module.css";

const CheckboxInput = ({
  label,
  touched,
  error,
  disabled,
  required,
  inputProps,
}) => {
  return (
    <div className={style.inputContainer}>
      <div className={style.inputContent}>
        <input
          type="checkbox"
          className={style.checkBoxInput}
          checked={inputProps.checked}
          onChange={inputProps.onChange}
          {...inputProps}
        />
      </div>
      {label && (
        <label className={style.inputLabel}>
          {label}
          {!disabled && required && <p className={style.inputRequired}>*</p>}
        </label>
      )}
      {touched && error && <p className={style.inputError}>{error}</p>}
    </div>
  );
};

export default CheckboxInput;
