import React, { useEffect, useState } from "react";
import style from "./DeleteMovementModal.module.css";
import { STATUS_OK } from "@Models/httpsStatus";
import { WarningTriangleIcon } from "@Models/icons";

import { formatToSoles } from "@Utilities/FormatsHandler";
import { ModalAcceptCancel } from "@Molecules/index";
import { deleteMovement } from "@Adapters/saleboxes/deleteMovement.adapter";

interface DeleteMovementModalProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  movement: any;
  currentPagination: number;
  getMovements: (
    idBox: string,
    type: string,
    searParameter: string,
    concept: string,
    startDate: string,
    endDate: string,
    page: number
  ) => Promise<void>;
  initialForm: any;
  onNotification: (notification: any) => void;
  onCurrentPaginationChange: (pagination: number) => void;
  onShouldReloadTableChange: (shouldReloadTable: boolean) => void;
}

const DeleteMovementModal = ({
  isVisible,
  setIsVisible,
  movement,
  currentPagination,
  initialForm,
  getMovements,
  onNotification,
  onCurrentPaginationChange,
  onShouldReloadTableChange,
}: DeleteMovementModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteMovement = async () => {
    setIsLoading(true);
    const res = await deleteMovement(movement.id, onNotification);
    if (res.status === STATUS_OK) {
      await getMovements(
        initialForm.idBox,
        initialForm.type,
        initialForm.searParameter,
        initialForm.concept,
        initialForm.startDate,
        initialForm.endDate,
        1
      );
      if (currentPagination !== 1) {
        onShouldReloadTableChange(true);
      }
      onCurrentPaginationChange(1);
      setIsVisible(false);
    }
    setIsLoading(false);
  };

  return (
    <ModalAcceptCancel
      title={"Eliminar movimiento"}
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Eliminar",
        actionButtonTwo: handleDeleteMovement,
        isDisabled: isLoading,
      }}
      actionButtonClose={() => setIsVisible(false)}
    >
      <div className={style.container_form}>
        <div className={style.warning_container}>
          <figure>
            <img src={WarningTriangleIcon} alt="Simbolo de advertencia" />
          </figure>
          <h3 className={style.message}>
            ¿Estás seguro de que deseas eliminar este movimiento?
          </h3>
        </div>
        <section className={style.movement_info_container}>
          <div className={style.movement_info}>
            <h3>{movement.type}</h3>
            <span className={style.movement_concept}>{movement.concept}</span>
          </div>
          <div className={style.amount_info}>
            <p>{formatToSoles(movement.amount)}</p>
            <p className={style.movement_date}>{movement.dateMovement}</p>
          </div>
        </section>
      </div>
    </ModalAcceptCancel>
  );
};

export default DeleteMovementModal;
