import { GetSaleDetailsAdapter } from "@Models/interfaces/GetSaleDetailsAdapter";
import { getSalesDocumentDetail_service } from "@Services/saleDetails/getSaleDetails";

export const getSaleDetails_adapter = async (
  saleDocumentId: string,
  saleBoxId: string,
  setLoader: (loader: boolean) => void
): Promise<GetSaleDetailsAdapter> => {
  setLoader(true);
  const response = await getSalesDocumentDetail_service(
    saleDocumentId,
    saleBoxId
  );
  if (!response.isSuccess) {
    setLoader(false);
    return {
      isSuccess: response.isSuccess,
      message: "Error al obtener los detalles de la venta",
      status: response.status,
      data: {
        saleDetails: [],
        paymentMethod: [],
        change: 0,
      },
    };
  }

  const paymentMethod = response.data.paymentMethod.map((payment) => ({
    methodPayment: payment.methodPayment,
    amount: payment.amount,
  }));
  const change = response.data.change;
  const saleDetails = response.data.saleDocumentDetails.map((saleDetail) => ({
    saleDetailId: saleDetail.saleDocumentDetailId,
    quantity: saleDetail.quantity,
    priceServiceId: saleDetail.priceServiceId,
    localProductId: saleDetail.localProductId,
    price: saleDetail.price,
    discount: saleDetail.discount,
    total: saleDetail.total,
    description: saleDetail.description,
  }));
  setLoader(false);
  return {
    isSuccess: response.isSuccess,
    message: "Detalles de la venta obtenidos correctamente",
    status: response.status,
    data: {
      saleDetails: saleDetails,
      paymentMethod: paymentMethod,
      change: change,
    },
  };
};
