import React from "react";
//@ts-ignore
import { Icon } from "@viuti/recursos";
import { CoinsIcon, ClockIcon } from "@Models/icons";
import { formatToSoles } from "@Utilities/FormatsHandler";
import style from "./ServicePriceDuration.module.css"; // Asegúrate de crear este archivo CSS

export const ServicePriceDuration = ({ servicePrice, serviceDuration }) => {
  return (
    <div className={style.servicePriceDuration}>
      <div>
        <span>
          <Icon path={CoinsIcon} alt="" color={"#45348e"} size={18} />
        </span>
        <p>{formatToSoles(servicePrice)}</p>
      </div>
      <div>
        <span>
          <Icon path={ClockIcon} alt="" color={"#45348e"} size={18} />
        </span>
        <p>
          {serviceDuration} {serviceDuration > 1 ? "min" : "min"}
        </p>
      </div>
    </div>
  );
};
