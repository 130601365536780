// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KFTntVba90v_O0ogd6Ib {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tZXjdqnjzqSRUeR6KIbx {
  pointer-events: none;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.A9Ymcp0KXBBZ5xXOUjxA {
  pointer-events: none;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.u_VDXXvJq34gkkl4C0G3 {
  cursor: pointer !important;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
}

.g2pCVvLtBZJbiFSIAuQ3 {
  background: #45348e;
  border-radius: 50%;
}

.ttlPR_s_ksAE5P4d2vAX {
  background: #b4b4b4;
  border-radius: 50%;
}

.MWFrNHgIGS_rx6HEiKUf {
  cursor: pointer !important;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/InputAdditionSubtract/InputAdditionSubtract.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".containerInputAdditionSubtract {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.menosOff {\n  pointer-events: none;\n  cursor: not-allowed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.masOff {\n  pointer-events: none;\n  cursor: not-allowed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.imgL {\n  cursor: pointer !important;\n  margin-right: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n}\n\n.imgActive {\n  background: #45348e;\n  border-radius: 50%;\n}\n\n.imgDesactive {\n  background: #b4b4b4;\n  border-radius: 50%;\n}\n\n.imgR {\n  cursor: pointer !important;\n  margin-left: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInputAdditionSubtract": `KFTntVba90v_O0ogd6Ib`,
	"menosOff": `tZXjdqnjzqSRUeR6KIbx`,
	"masOff": `A9Ymcp0KXBBZ5xXOUjxA`,
	"imgL": `u_VDXXvJq34gkkl4C0G3`,
	"imgActive": `g2pCVvLtBZJbiFSIAuQ3`,
	"imgDesactive": `ttlPR_s_ksAE5P4d2vAX`,
	"imgR": `MWFrNHgIGS_rx6HEiKUf`
};
export default ___CSS_LOADER_EXPORT___;
