import React from "react";
import { iconPath } from "@Models/localPath";
import style from "./ConflictMessage.module.css";

export const ConflictMessage = ({ message }) => {
  return (
    <p className={style.messageConflicting}>
      <span>
        <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
      </span>
      {message}
    </p>
  );
};
