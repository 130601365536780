import { Service } from "@Models/interfaces/services";
import { getServicesList_service } from "@Services/services/getServices.service";

export const adapterGetServicesList_adapter = async () => {
  const response = await getServicesList_service();
  if (!response.isSuccess) {
    return {
      isSuccess: false,
      data: [],
      message: response.message,
    };
  }
  const adaptServices: Service[] = response.data.map((service: any) => {
    return {
      id: service.idService,
      name: service.serviceName,
    };
  });

  return {
    isSuccess: response.isSuccess,
    status: response.status,
    data: adaptServices,
    message: response.message,
  };
};
