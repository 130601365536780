// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qCIjURz8H_ZSIYtSKBVg {
  color: red;
  display: flex;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organism/FormReservation/components/AppointmentDetail/ConflictMessage/ConflictMessage.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,QAAQ;AACV","sourcesContent":[".messageConflicting {\n  color: red;\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageConflicting": `qCIjURz8H_ZSIYtSKBVg`
};
export default ___CSS_LOADER_EXPORT___;
