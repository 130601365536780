import { adapterGetServicesList_adapter } from "@Adapters/adapterGetServices.adapter";
import { Service } from "@Models/interfaces/services";
import { useEffect, useState } from "react";

export const useGetServices = () => {
  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    const getServices = async () => {
      const response = await adapterGetServicesList_adapter();
      if (!response.isSuccess) {
        return;
      }
      setServices(response.data);
    };
    getServices();
  }, []);

  return { services };
};
