import React from "react";
import { iconPath } from "@Models/localPath";
import style from "./OccupiedEmployeesMessage.module.css";

export const OccupiedEmployeesMessage = ({ empleadosOcupados }) => {
  return (
    <p className={style.messageConflicting}>
      <span>
        <img src={`${iconPath}/status/circleAlert.svg`} alt="alert" />
      </span>
      {empleadosOcupados.map((empleado) => empleado.mensaje).join(" ")}
    </p>
  );
};
