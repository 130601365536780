import React from "react";
import style from "./ViewMessage.module.css";
interface IViewMessageProps {
  title: string;
  message: string;
}
const ViewMessage: React.FC<IViewMessageProps> = ({ title, message }) => {
  return (
    <span className={style.container}>
      <h3 className={style.title}>{title}</h3>
      <p className={style.message}>{message}</p>
    </span>
  );
};

export default ViewMessage;
