import React, { useState, useEffect, useRef } from "react";
//@ts-ignore
import style from "./DataList.module.css";
import OptionClient from "./OptionClient/OptionClient";
import SearchWithColoredBorder from "../../atoms/inputs/searchWithColoredBorder/SearchWithColoredBorder";
import LoadingSpinner from "../../atoms/loadingSpinner/LoadingSpinner";
import OptionSupplier from "./OptionSupplier/OptionSupplier";

const DataList = ({
  data,
  value,
  handleChange,
  category,
  placeholder,
  width,
  isLoading,
  inputRef,
  onFocus,
}) => {
  const sectionRef = useRef(null);
  const inputContainerRef = useRef(null);
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus() && setIsSectionVisible(true);
    }
  };

  const handleDocumentClick = (e) => {
    if (
      sectionRef.current &&
      !sectionRef.current.contains(e.target) &&
      inputContainerRef.current &&
      !inputContainerRef.current.contains(e.target)
    ) {
      setIsSectionVisible(false);
    }
  };

  const handleInputClick = () => {
    if (value.length > 0) {
      setIsSectionVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (value.length > 0) {
      setIsSectionVisible(true);
    } else {
      setIsSectionVisible(false);
    }
  }, [value]);

  const render = () => {
    switch (category) {
      case "clients":
        return <OptionClient data={data} isLoading={isLoading} />;
      case "suppliers":
        return <OptionSupplier data={data} isLoading={isLoading} />;
      default:
        return <p>colocar correctamente category en las props</p>;
    }
  };

  return (
    <div style={{ width: width }} className={style.dataListContainer}>
      <div className={style.searchContainer} ref={inputContainerRef}>
        <SearchWithColoredBorder
          allData={[]}
          value={value}
          placeholder={placeholder}
          setValue={handleChange}
          clearInput={false}
          inputRef={inputRef}
          onClick={handleInputClick}
          onFocus={handleFocus}
        />
        {isLoading && (
          <span className={style.search__spinner}>
            <LoadingSpinner size={20} isVisible={isLoading} />
          </span>
        )}
      </div>
      {isSectionVisible && !isLoading ? (
        <section ref={sectionRef} className={style.containerResults}>
          <ul className={style.resultsList}>{render()}</ul>
        </section>
      ) : null}
    </div>
  );
};

export default DataList;
