import React, { useEffect, useState } from "react";

//@ts-ignore
import style from "./TabNavigation.module.css";

// Handlers
import ComponentErrorHandler from "./ComponentErrorHandler";
import validateModuleAccess from "../../../../utilities/jwt/validateModuleAccess";

interface ITabNavigation {
  views: {
    name: string;
    nameIcon: Element;
    component: JSX.Element;
    onChange?: () => void;
    auth: {
      uuid: string;
    };
  }[];
  initialView?: number;
}

const TabNavigation: React.FC<ITabNavigation> = ({
  views,
  initialView = 0,
}) => {
  const [selectedView, setSelectedView] = useState(views[initialView]);

  ComponentErrorHandler({ views });

  const handleViewClick = (viewName) => {
    if (!validateModuleAccess(viewName.auth?.uuid)) return;
    setSelectedView(viewName);
  };

  useEffect(() => {
    selectedView.onChange && selectedView.onChange();
  }, [selectedView]);

  return (
    <div className={style.screenContent}>
      <div className={style.navigationContainer}>
        {views.map((view, index) => {
          const uniqueKey = `${view.name}-${index}`;
          return (
            <button
              className={`${style.navigationButton} ${
                selectedView.name === view.name
                  ? style.navigationButtonActive
                  : ""
              }`}
              onClick={() => handleViewClick(view)}
              key={uniqueKey}
            >
              <p>{view.name}</p>
              {view.nameIcon && view.nameIcon}
            </button>
          );
        })}
      </div>
      <div className={style.componentContainer}>{selectedView.component}</div>
    </div>
  );
};

export default TabNavigation;
