import React, { useState, useRef, useEffect } from "react";
import {
  ModalAcceptCancel,
  DateInput,
  TertiaryButton,
  ModalLayoutWrapper,
  FormCreateNewClient,
  ButtonWithoutIcon,
  ConfirmModalV2,
  SelectInput,
  // @ts-ignore
} from "@viuti/recursos";
import { AddIcon, TimesIcon } from "@Models/icons";
import { SelectClientSearch } from "./components/SelectClientSearch";
import { NoCustomer } from "./components/Custommer/NoCustommer";
import { Customer } from "./components/Custommer/Customer";
import { AppointmentDetail } from "./components/AppointmentDetail/AppointmentDetail";
import styles from "./FormReservation.module.css";
import { postNewReserve_adapter } from "@Adapters/reserve/postNewReserve.adapter";
import { deleteDeleteAppointment_adapter } from "@Adapters/reserve/deleteDeleteAppointment.adapter";
import { useAdaptReserveDetail } from "./hooks/useAdaptReserveDetail";
import { getServices_adapter, Service } from "@Adapters/getServices.adapter";
import { STATUS, STATUS_OPTIONS } from "./data/status";
import { StatusButton } from "./components/StatusButton/StatusButton";
import { putUpdateReserveStatus_adapter } from "@Adapters/reserve/putUpdateReserveStatus.adapter";
import ConfirmModal from "../../molecules/ConfirmModal/ConfirmModal";
import { putUpdateReserveDetail_adapter } from "@Adapters/reserve/putUpdateReserveDetail.adapter";

const generateId = () => "_" + Math.random().toString(36).substr(2, 9);

const currentDate = new Date().toISOString().split("T")[0];

interface FormReservationProps {
  initialDate?: string;
  setToasterResponse: (state: { message: string; status: number }) => void;
  reserveId?: string | null | number;
  callBacks?: {
    onClose?: () => void;
    onEditOrCreateSuccess?: (data: any) => void;
    onStatusSuccess?: (data: any) => void;
    onDeleteSuccess?: (data: any) => void;
  };
  initialCustomer?: InitialCustomer;
  initialServices?: Service[];
}

const getAvailableStatuses = (currentStatus: number): number[] => {
  switch (currentStatus) {
    case STATUS.CANCELLED:
      return [STATUS.CANCELLED]; // No hay opciones cuando está cancelada
    case STATUS.PENDING:
      return [STATUS.CONFIRMED, STATUS.CANCELLED]; // Ya no incluimos RESCHEDULED
    case STATUS.CONFIRMED:
      return [STATUS.NO_SHOW, STATUS.CANCELLED]; // Ya no incluimos RESCHEDULED
    case STATUS.ATTENDED:
      return [STATUS.ATTENDED]; // No se puede cambiar cuando está atendida
    case STATUS.NO_SHOW:
      return [STATUS.NO_SHOW, STATUS.CANCELLED]; // Ya no incluimos RESCHEDULED
    case STATUS.RESCHEDULED:
      return [STATUS.CONFIRMED, STATUS.NO_SHOW, STATUS.CANCELLED];
    default:
      return [STATUS.CANCELLED];
  }
};
interface InitialCustomer {
  name: string;
  surname: string;
  birthDate: string;
  typeDocumentId: number;
  document: string;
  category: string;
  email: string;
  phone: string;
  id: number;
}

const FormReservation: React.FC<FormReservationProps> = ({
  initialDate = currentDate,
  setToasterResponse = () => {},
  reserveId = null,
  callBacks = {},
  initialCustomer = null,
  initialServices = [],
}) => {
  const { onClose, onEditOrCreateSuccess, onStatusSuccess, onDeleteSuccess } =
    callBacks;
  const inputRef = useRef(null);
  const [dateReservation, setDateReservation] = useState<string>(initialDate);
  const [customerSelected, setCustomerSelected] =
    useState<any>(initialCustomer);
  const [initialSelectCustomer, setInitialSelectCustomer] = useState<any>(null);
  const [isOpenModalNewClient, setIsOpenModalNewClient] =
    useState<boolean>(false);
  const [services, setServices] = useState<Service[]>(initialServices);
  const [initialStatusReserve, setInitialStatusReserve] =
    useState<number>(null);
  const [statusReserve, setStatusReserve] = useState<number>(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [initialSelectItems, setInitialSelectItems] = useState([]);
  const [aditionalComments, setAditionalComments] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    date: "",
  });
  const [loadingCreateReservation, setLoadingCreateReservation] =
    useState<boolean>(false);
  const [loadingCancelReservation, setLoadingDeleteReservation] =
    useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [availableHours, setAvailableHours] = useState({});
  const [loadingServices, setLoadingServices] = useState(false);
  const [messageConflicting, setMessageConflicting] = useState("");
  const [empleadosOcupados, setEmpleadosOcupados] = useState([]);
  const [idConflictingItem, setIdConflictingItem] = useState([]);
  const [incompleteItems, setIncompleteItems] = useState([]);
  const [editCustomer, setEditCustomer] = useState(false);
  const [loadingHours, setLoadingHours] = useState(false);
  const [loadingHoursId, setLoadingHoursId] = useState(null);
  const isEdit = reserveId;

  const isDisabled =
    loadingCreateReservation ||
    isLoadingDetail ||
    initialStatusReserve === STATUS.CANCELLED ||
    initialStatusReserve === STATUS.ATTENDED;
  const isDisabledButton =
    initialStatusReserve === STATUS.CANCELLED ||
    initialStatusReserve === STATUS.ATTENDED;

  const isDisabledNoShow = initialStatusReserve === STATUS.NO_SHOW;

  const [isVisibleDeleteModal, setIsVisibleDeleteModal] =
    useState<boolean>(false);

  useAdaptReserveDetail(
    reserveId,
    setToasterResponse,
    setCustomerSelected,
    setSelectedItems,
    setIsLoadingDetail,
    setInitialSelectItems,
    generateId,
    setStatusReserve,
    setInitialStatusReserve,
    setInitialSelectCustomer,
    setAvailableHours,
    setLoadingHours,
    setLoadingHoursId
  );

  const hayCambios = () => {
    const hayCambiosEnItems =
      JSON.stringify(initialSelectItems) !== JSON.stringify(selectedItems);
    const hayCambiosEnCliente =
      JSON.stringify(initialSelectCustomer) !==
      JSON.stringify(customerSelected);
    const hayCambiosEnEstado = statusReserve !== initialStatusReserve;
    return hayCambiosEnItems || hayCambiosEnCliente || hayCambiosEnEstado;
  };

  const openModalNewClient = () => {
    setIsOpenModalNewClient(true);
  };

  const handleButtonClickFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  //   {
  //     "userId": 3334162,
  //     "name": "CADENA DE BOTICAS LA SANTA CRUZ S.A.C.",
  //     "lastName": "",
  //     "mail": "",
  //     "phone": "",
  //     "documentType": 1,
  //     "documentNumber": "20603209975",
  //     "sex": 0,
  //     "dateOfBirth": null,
  //     "address": "BL. BARRIO SANTA ELENA LT. 6 MZ. A",
  //     "existeUsuario": false,
  //     "codeCountryPhone": null,
  //     "category": null
  // }
  const postNewClient = (clientResponse) => {
    const res = clientResponse;
    setCustomerSelected({
      name: res?.name,
      surname: res?.lastName,
      birthDate: res?.dateOfBirth,
      documentTypeId: res?.documentType || 2,
      document: res?.documentNumber,
      category: res?.category?.categoryName || "Sin especificar",
      email: res?.mail || "Sin especificar",
      phone: res?.phone || "Sin especificar",
      id: res?.userId,
    });
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDateReservation(selectedDate);
    if (selectedDate < currentDate) {
      setErrorMessage({ date: "No se puede colocar fechas pasadas." });
    } else {
      setErrorMessage({ date: "" });
    }
  };

  const deselectCustomer = () => {
    setCustomerSelected(null);
  };

  const isFormComplete = () => {
    const isCustomerSelected = customerSelected !== null;
    const isDateSelected = dateReservation !== "";
    const areItemsComplete = selectedItems.every(
      (item) =>
        item.service !== "" && item.employee !== "" && item.schedule !== ""
    );

    const noHayErrores =
      messageConflicting === "" &&
      empleadosOcupados.length === 0 &&
      incompleteItems.length === 0 &&
      idConflictingItem.length === 0;

    return (
      isCustomerSelected && isDateSelected && areItemsComplete && noHayErrores
    );
  };

  const orderHours = () => {
    setSelectedItems((prevItems) => {
      const updatedItems = [...prevItems];
      try {
        return updatedItems.sort((a, b) => {
          const timeA = a.schedule.split(":").map(Number);
          const timeB = b.schedule.split(":").map(Number);
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
      } catch (error) {
        return updatedItems;
      }
    });
  };

  const createOrEditReservation = async (data) => {
    if (isEdit) {
      const deletedDetails = initialSelectItems
        .filter(
          (initialItem) =>
            !selectedItems.some(
              (selectedItem) => selectedItem.id === initialItem.id
            )
        )
        .map((item) => item.id);

      const newDetails = selectedItems.filter(
        (selectedItem) =>
          !initialSelectItems.some(
            (initialItem) => initialItem.id === selectedItem.id
          )
      );

      const editedDetails = selectedItems.filter((selectedItem) => {
        const initialItem = initialSelectItems.find(
          (item) => item.id === selectedItem.id
        );
        return (
          initialItem &&
          (initialItem.service !== selectedItem.service ||
            initialItem.schedule !== selectedItem.schedule)
        );
      });

      const isStatusChanged = initialStatusReserve !== statusReserve;
      const hoursSelectedItems = selectedItems
        .map((item) => item.schedule)
        .filter((schedule) => schedule !== "");

      const hoursSelectedInitialItems = initialSelectItems
        .map((item) => item.schedule)
        .filter((schedule) => schedule !== "");

      const isHourChanged =
        hoursSelectedItems.length !== hoursSelectedInitialItems.length ||
        hoursSelectedItems.some(
          (hour, index) => hour !== hoursSelectedInitialItems[index]
        );

      const adaptStatus =
        isStatusChanged || !isHourChanged ? statusReserve : STATUS.RESCHEDULED;

      const adaptRequest = {
        reserveId: Number(reserveId),
        newStatus: adaptStatus,
        deleteReserveDetailsIds: deletedDetails,
        newReserveDetails: newDetails,
        editReserveDetails: editedDetails,
      };

      const response = await putUpdateReserveDetail_adapter(adaptRequest);
      if (response.isSuccess) {
        onEditOrCreateSuccess && onEditOrCreateSuccess(data);
        setInitialStatusReserve(statusReserve);
        setToasterResponse({
          message: "Reserva actualizada correctamente.",
          status: response.status,
        });
        setLoadingCreateReservation(false);
        setIsLoadingDetail(false);
        setInitialSelectItems(data.details);
        setStatusReserve(adaptStatus);
        setInitialStatusReserve(adaptStatus);
      } else {
        setLoadingCreateReservation(false);
        setIsLoadingDetail(false);
        setToasterResponse({
          message: response.message,
          status: response.status,
        });
      }
    } else {
      const response = await postNewReserve_adapter(data);
      if (response.isSuccess) {
        onEditOrCreateSuccess && onEditOrCreateSuccess(data);
        setToasterResponse({
          message: "Reserva creada correctamente.",
          status: response.status,
        });
        onClose && onClose();
      } else {
        setToasterResponse({
          message: response.message,
          status: response.status,
        });
        setLoadingCreateReservation(false);
      }
    }
  };

  const nextClick = async () => {
    const orderItems = [...selectedItems];
    setLoadingCreateReservation(true);

    const isSorted = (items) => {
      for (let i = 1; i < items.length; i++) {
        const timeA = items[i - 1].schedule.split(":").map(Number);
        const timeB = items[i].schedule.split(":").map(Number);
        if (
          timeA[0] > timeB[0] ||
          (timeA[0] === timeB[0] && timeA[1] > timeB[1])
        ) {
          return false; // Si encontramos un par desordenado, retornamos false
        }
      }
      return true; // Si no encontramos desorden, retornamos true
    };

    // Verificamos si las horas están ordenadas
    if (!isSorted(orderItems)) {
      orderHours();
      try {
        orderItems.sort((a, b) => {
          const timeA = a.schedule.split(":").map(Number);
          const timeB = b.schedule.split(":").map(Number);
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
      } catch (error) {
        return orderItems;
      }
      setTimeout(async () => {
        const data = {
          clientId: customerSelected.id,
          observation: aditionalComments,
          prepaid: 0,
          dateAttention: dateReservation,
          details: orderItems,
        };
        await createOrEditReservation(data); // Llamamos a la función para crear la reserva
      }, 1000);
    } else {
      const data = {
        clientId: customerSelected.id,
        observation: aditionalComments,
        prepaid: 0,
        dateAttention: dateReservation,
        details: orderItems,
      };
      await createOrEditReservation(data); // Llamamos a la función para crear la reserva
    }
  };

  const confirmDeleteReservation = async () => {
    setLoadingDeleteReservation(true);
    const response = await deleteDeleteAppointment_adapter(Number(reserveId));
    if (!response.isSuccess) {
      setLoadingDeleteReservation(false);
      setToasterResponse({
        message: response.message,
        status: response.status,
      });
      return;
    }
    setToasterResponse({
      message: "Reserva eliminada correctamente.",
      status: response.status,
    });
    onDeleteSuccess && onDeleteSuccess(dateReservation);
    setTimeout(() => {
      onClose && onClose();
    }, 1000);
    setLoadingDeleteReservation(false);
    setIsVisibleDeleteModal(false);
  };

  const handleCancelEditCustomer = () => {
    setEditCustomer(false);
    setCustomerSelected(initialSelectCustomer);
  };

  useEffect(() => {
    const getServices = async () => {
      if (services.length) return;
      setLoadingServices(true);
      if (services.length > 0) {
        setLoadingServices(false);
        return;
      } else {
        const response = await getServices_adapter();
        if (response.isSuccess) {
          setServices(response.data);
        } else {
          setToasterResponse({
            message: "Hubo un error al traer los servicios",
            status: 400,
          });
        }
      }
      setLoadingServices(false);
    };
    getServices();
  }, [customerSelected]);

  const title = () => {
    if (isEdit)
      return `Reserva ${
        STATUS_OPTIONS[initialStatusReserve]?.name.toLowerCase() || ""
      }`;
    return "Nueva reserva";
  };

  const statusOptions = STATUS_OPTIONS.filter((status) =>
    [
      initialStatusReserve,
      ...getAvailableStatuses(initialStatusReserve),
    ].includes(status.id)
  ).map((status) => ({
    value: status.id,
    name: status.name,
  }));

  const handleStatusChange = (e) => {
    setStatusReserve(Number(e.target.value));
  };

  return (
    <ModalAcceptCancel
      title={title()}
      showButtons={{
        showButtonOne: initialStatusReserve === STATUS.CANCELLED,
        showButtonTwo: !isDisabledButton,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Guardar",
        actionButtonTwo: nextClick,
        isDisabled:
          !hayCambios() || loadingCreateReservation || !isFormComplete(),
        isLoading: loadingCreateReservation,
      }}
      buttonOne={{
        textButtonOne: "Eliminar reserva",
        actionButtonOne: () => {
          setIsVisibleDeleteModal(true);
        },
        isDisabled: loadingCancelReservation || isLoadingDetail,
        isLoading: loadingCancelReservation || isLoadingDetail,
      }}
      actionButtonClose={onClose}
    >
      <div className={styles.formContainer}>
        <section className={styles.dateContainer}>
          <div className={styles.dateInputContainer}>
            <DateInput
              required={true}
              inputProps={{
                value: dateReservation,
                onChange: handleDateChange,
                min: currentDate,
              }}
              error={errorMessage.date}
              touched={errorMessage.date}
              // disabled={isLoadingDetail || !isEditDate && isEdit}
              disabled={isLoadingDetail || isEdit}
            />
            {/* {isEdit && !isEditDate && (
              <TertiaryButton
                buttonProps={{
                  onClick: () => setIsEditDate(true),
                  disabled: false,
                }}
                icon={PencilWithFrameIcon}
                value=""
              />
            )} */}
          </div>
          {isEdit && editCustomer && customerSelected === null && (
            <TertiaryButton
              buttonProps={{
                onClick: handleCancelEditCustomer,
                disabled: false,
              }}
              icon={TimesIcon}
              value="Cancelar"
            />
          )}
          {customerSelected === null && (
            <>
              {!isLoadingDetail && (
                <>
                  <TertiaryButton
                    buttonProps={{
                      onClick: openModalNewClient,
                      disabled: false,
                    }}
                    icon={AddIcon}
                    value="Nuevo cliente"
                  />
                  <SelectClientSearch
                    setStateResponse={setToasterResponse}
                    setCustomerSelected={setCustomerSelected}
                    setInitialSelectCustomer={setInitialSelectCustomer}
                    inputRef={inputRef}
                  />
                </>
              )}
            </>
          )}

          {customerSelected === null ? (
            <NoCustomer
              isLoading={isLoadingDetail}
              handleButtonClickFocusInput={handleButtonClickFocusInput}
            />
          ) : (
            <>
              <Customer
                {...customerSelected}
                deselectCustomer={deselectCustomer}
                isEdit={isEdit}
                setCustomerSelected={setCustomerSelected}
                setEditCustomer={setEditCustomer}
              />
              {isEdit && customerSelected && (
                <div className={styles.statusSection}>
                  <SelectInput
                    label="Estado de la reserva"
                    name="status"
                    value={statusReserve}
                    touched={false}
                    error={false}
                    handleChange={handleStatusChange}
                    handleBlur={() => {}}
                    items={statusOptions}
                    required={true}
                    placeholder="Seleccione un estado"
                    width="100%"
                    disabled={
                      isLoadingDetail ||
                      initialStatusReserve === STATUS.CANCELLED ||
                      initialStatusReserve === STATUS.ATTENDED
                    }
                  />
                </div>
              )}
            </>
          )}
        </section>
        <section>
          <AppointmentDetail
            date={dateReservation}
            selectedItems={selectedItems}
            generateId={generateId}
            services={services}
            setSelectedItems={setSelectedItems}
            setStateResponse={setToasterResponse}
            isEdit={isEdit}
            availableHours={availableHours}
            setAvailableHours={setAvailableHours}
            loadingServices={loadingServices}
            setMessageConflicting={setMessageConflicting}
            messageConflicting={messageConflicting}
            empleadosOcupados={empleadosOcupados}
            setEmpleadosOcupados={setEmpleadosOcupados}
            idConflictingItem={idConflictingItem}
            setIdConflictingItem={setIdConflictingItem}
            incompleteItems={incompleteItems}
            setIncompleteItems={setIncompleteItems}
            orderHours={orderHours}
            reserveId={reserveId}
            isDisabled={isDisabled}
            isDisabledNoShow={isDisabledNoShow}
            loadingHours={loadingHours}
            setLoadingHours={setLoadingHours}
            loadingHoursId={loadingHoursId}
            setLoadingHoursId={setLoadingHoursId}
          />
        </section>
      </div>
      {isOpenModalNewClient && (
        <ModalLayoutWrapper>
          <FormCreateNewClient
            isDisabled={isOpenModalNewClient}
            setIsDisabled={setIsOpenModalNewClient}
            nextClick={(client) => postNewClient(client)}
          />
        </ModalLayoutWrapper>
      )}
      <ConfirmModalV2
        title={"Eliminar reserva"}
        message={
          <p className={styles.remove__client__modal__message}>
            ¿Estás seguro que deseas eliminar la reserva? Esta acción no podrá
            deshacerse.
          </p>
        }
        buttonLbl={"Eliminar"}
        controller={{
          visible: isVisibleDeleteModal,
          setVisible: setIsVisibleDeleteModal,
          isLoading: loadingCancelReservation,
          isDisabled: loadingCancelReservation,
        }}
        confirmAction={confirmDeleteReservation}
      />
    </ModalAcceptCancel>
  );
};

export default FormReservation;
