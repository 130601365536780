import React from "react";
//@ts-ignore
import style from "./style/ButtonAddMore.module.css";
import { IButton } from "../buttonsWithoutIcons/interface/button";

const ButtonAddMore = ({ textButton, handleClick }: IButton) => {
  return (
    <button
      className={style.containerButtonAddMore}
      onClick={() => handleClick()}
    >
      {textButton}
    </button>
  );
};

export default ButtonAddMore;
