import { ModalAcceptCancel } from "@Molecules/index";
import React, { useEffect, useState } from "react";
import NewRucPage from "./NewRucPage/NewRucPage";
import useDisabledToContinue from "./NewRucPage/components/FieldsWrapper/hooks/useDisabledToContinue";
const intitRucData = {
  ruc: null,
  businessName: null,
  expirationDate: null,
  claveCertificado: null,
  file: null,
};

const FormAuthorizeRuc = ({ isVisible, setIsVisible, onClick }) => {
  const [showModalError, setShowModalError] = useState(false);
  const [modalProps, setModalProps] = useState(null);
  const [rucData, setRucData] = useState(intitRucData);
  const [file, setFile] = useState(null);
  const [logo, setLogo] = useState(null);

  const continueBttnProps = useDisabledToContinue({
    rucData,
    setRucData,
    file,
    setShowModal: setShowModalError,
    setModalProps: setModalProps,
    logo,
    onClick,
    setIsVisible,
  });
  useEffect(() => {
    if (isVisible) {
      setRucData(intitRucData);
      setFile(null);
      setLogo(null);
    }
  }, [isVisible]);

  useEffect(() => {
    if (showModalError) {
      setIsVisible(false);
    }
  }, [showModalError]);

  return (
    <>
      {showModalError && <ModalAcceptCancel {...modalProps} />}
      {isVisible && (
        <ModalAcceptCancel
          title="Autorizar RUC para facturación electrónica"
          showButtons={{
            showButtonOne: true,
            showButtonTwo: true,
            showButtonClose: true,
          }}
          buttonOne={{
            textButtonOne: "Cancelar",
            actionButtonOne: () => {
              setIsVisible(false);
            },
          }}
          buttonTwo={{
            textButtonTwo: continueBttnProps.textBttn,
            isDisabled: continueBttnProps.isDisabled,
            actionButtonTwo: async () => {
              await continueBttnProps.handleClick();
            },
          }}
          actionButtonClose={() => {
            setIsVisible(false);
          }}
          children={
            <NewRucPage
              rucData={rucData}
              setRucData={setRucData}
              file={file}
              setFile={setFile}
              logo={logo}
              setLogo={setLogo}
            />
          }
        />
      )}
    </>
  );
};

export default FormAuthorizeRuc;
