// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V_Yg_HEVXtsHCjjFYPRV {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  & p {
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.233px;
  }
}

.cPv_EhTVOQPh_yqQakMp,
.Iy9wAWIEoBd8UaGes5oC {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.233px;
  text-align: center;
}

.Iy9wAWIEoBd8UaGes5oC {
  color: #b91616;
}

.GChR9oqEQKbPDL9R_jP7 {
  border-radius: 12px;
  background: #f0edfe;
  padding: 36px;
}

.Gb6x6n8pmNpwxofti87C {
  border-radius: 12px;
  background: #ffc8c8a1;
  padding: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/noInfoToShow/noInfoToShow.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;;EAET;IACE,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;AACF;;AAEA;;EAEE,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".noInfoToShow {\n  width: 100%;\n  margin: auto;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 16px;\n\n  & p {\n    text-align: center;\n    font-family: \"Mulish\", sans-serif;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    letter-spacing: 0.233px;\n  }\n}\n\n.noInfoToShow__h3,\n.noInfoToShow__h3_error {\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  text-align: center;\n}\n\n.noInfoToShow__h3_error {\n  color: #b91616;\n}\n\n.icon {\n  border-radius: 12px;\n  background: #f0edfe;\n  padding: 36px;\n}\n\n.icon_error {\n  border-radius: 12px;\n  background: #ffc8c8a1;\n  padding: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noInfoToShow": `V_Yg_HEVXtsHCjjFYPRV`,
	"noInfoToShow__h3": `cPv_EhTVOQPh_yqQakMp`,
	"noInfoToShow__h3_error": `Iy9wAWIEoBd8UaGes5oC`,
	"icon": `GChR9oqEQKbPDL9R_jP7`,
	"icon_error": `Gb6x6n8pmNpwxofti87C`
};
export default ___CSS_LOADER_EXPORT___;
