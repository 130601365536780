// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aBScr0fVbP_tJNU0Bcni {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NEkdDL3tFWiGGLbjzNHE {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #45348e;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.233px;
  max-width: 305px;
}

.fWGek7bGWlgM6hZNP9dA {
  margin-top: 32px;
  margin-bottom: 32px;
  /*TODO*/
  width: 79px !important;
}

.uKdhtLWPSBTiSOrhg9Lp {
  color: #45348e;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  align-items: center;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ModalUnsavedChanges/ModalUnsavedChanges.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,OAAO;EACP,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".body {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.modalDescription {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  color: #45348e;\n  text-align: center;\n  font-family: Mulish;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  max-width: 305px;\n}\n\n.modalIcon {\n  margin-top: 32px;\n  margin-bottom: 32px;\n  /*TODO*/\n  width: 79px !important;\n}\n\n.title {\n  color: #45348e;\n  font-family: Mulish;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `aBScr0fVbP_tJNU0Bcni`,
	"modalDescription": `NEkdDL3tFWiGGLbjzNHE`,
	"modalIcon": `fWGek7bGWlgM6hZNP9dA`,
	"title": `uKdhtLWPSBTiSOrhg9Lp`
};
export default ___CSS_LOADER_EXPORT___;
