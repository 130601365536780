// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dq3lX4NIjZtX6VAnPpQw {
  position: relative;
  width: 101px;
  height: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 8px solid #f3f7f9;

  &:hover > .oeKI4zJjeWyi8L5_V01A {
    visibility: visible;
  }
}

.Ja_f48w4d01224Ur6BcQ {
  width: 85px;
  min-width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
}

.W84emeDPRKWPpxdi2r2v {
  background-color: #937cf4;
  width: 85px;
  min-width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  font-size: 36px;
  font-family: "Mulish";
}
.oeKI4zJjeWyi8L5_V01A {
  position: absolute;
  top: 0;
  left: 0;
  width: 85px;
  height: 85px;
  min-width: 85px;
  border-radius: 50%;
  background-color: #0000003b;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  visibility: hidden;

  &:hover {
    background-color: #0000005b;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/Avatar/Avatar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;;EAEzB;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,2BAA2B;;EAE3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iCAAiC;EACjC,kBAAkB;;EAElB;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".avatarContainer {\n  position: relative;\n  width: 101px;\n  height: 101px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  border: 8px solid #f3f7f9;\n\n  &:hover > .cameraLayout {\n    visibility: visible;\n  }\n}\n\n.pickedImage {\n  width: 85px;\n  min-width: 85px;\n  height: 85px;\n  border-radius: 50%;\n  object-fit: cover;\n}\n\n.circleAvatar {\n  background-color: #937cf4;\n  width: 85px;\n  min-width: 85px;\n  height: 85px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  color: white;\n  font-weight: 700;\n  font-size: 36px;\n  font-family: \"Mulish\";\n}\n.cameraLayout {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 85px;\n  height: 85px;\n  min-width: 85px;\n  border-radius: 50%;\n  background-color: #0000003b;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  transition: all 100ms ease-in-out;\n  visibility: hidden;\n\n  &:hover {\n    background-color: #0000005b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": `dq3lX4NIjZtX6VAnPpQw`,
	"cameraLayout": `oeKI4zJjeWyi8L5_V01A`,
	"pickedImage": `Ja_f48w4d01224Ur6BcQ`,
	"circleAvatar": `W84emeDPRKWPpxdi2r2v`
};
export default ___CSS_LOADER_EXPORT___;
