import React from "react";
import TableRow from "../TableRow/TableRow";
import {
  containerStyleDefault,
  headerStyleDefault,
  bodyStyleDefault,
  cellStyleDefault,
  rowStyleDefault,
  containerTableStyleDefault,
} from "./defaultStyles";
// @ts-ignore
import styles from "./TableWrapper.module.css";
import DualBars from "@Atoms/DualBars/DualBars";

interface TableWrapperProps {
  titles: string[];
  data: any[]; // something like this should arrive [{a:"hola", b:<div> Chau </div>, lele: 3}]
  numberOfRows?: number;
  isLoading?: boolean;
  containerStyle?: object;
  containerTableStyle?: object;
  headerStyle?: object;
  bodyStyle?: object;
  cellStyle?: object;
  rowStyle?: object;
}

const TableWrapper: React.FC<TableWrapperProps> = ({
  titles,
  data,
  isLoading = false,
  numberOfRows = 10,
  containerStyle = containerStyleDefault,
  containerTableStyle = containerTableStyleDefault,
  headerStyle = headerStyleDefault,
  bodyStyle = bodyStyleDefault,
  cellStyle = cellStyleDefault,
  rowStyle = rowStyleDefault,
}) => {
  const loadingData = isLoading
    ? new Array(titles.length).fill(<DualBars />)
    : null;
  const fixedRows = new Array(numberOfRows).fill(null);
  return (
    <div
      className={styles.table_container}
      style={{
        ...containerStyleDefault,
        ...containerStyle,
      }}
    >
      <table style={{ ...containerTableStyleDefault, ...containerTableStyle }}>
        <thead>
          <TableRow
            rowData={titles}
            cellStyle={{ ...headerStyleDefault, ...headerStyle }}
            rowStyle={{ ...rowStyleDefault, ...rowStyle }}
          />
        </thead>
        <tbody style={{ ...bodyStyleDefault, ...bodyStyle }}>
          {isLoading
            ? Array.from({ length: numberOfRows }).map((rowData, index) => (
                <TableRow
                  key={index}
                  rowData={loadingData}
                  cellStyle={{ ...cellStyleDefault, ...cellStyle }}
                  rowStyle={{ ...rowStyleDefault, ...rowStyle }}
                />
              ))
            : fixedRows.map((_, index) => (
                <TableRow
                  key={index}
                  rowData={index < data.length ? data[index] : {}}
                  cellStyle={{ ...cellStyleDefault, ...cellStyle }}
                  rowStyle={{ ...rowStyleDefault, ...rowStyle }}
                />
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableWrapper;
