// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PzfoR5XT10hRjfoDSTp1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  aspect-ratio: 1;
  padding: 3px;
  border-radius: 50%;
  border: 2px solid #937cf4;
  cursor: pointer;
}

.sYh6MZu5ZhtcEd6D0jIP {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #5d36f1;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/CircularRadioInput/CircularRadioInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: auto;\n  aspect-ratio: 1;\n  padding: 3px;\n  border-radius: 50%;\n  border: 2px solid #937cf4;\n  cursor: pointer;\n}\n\n.buttonOn {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: #5d36f1;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PzfoR5XT10hRjfoDSTp1`,
	"buttonOn": `sYh6MZu5ZhtcEd6D0jIP`
};
export default ___CSS_LOADER_EXPORT___;
