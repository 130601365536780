import { jwtDecode } from "jwt-decode";

const decodeToken = (token: string) => {
  const decoded = jwtDecode(token);
  try {
    return decoded;
  } catch (error) {
    window.location.href = "/";
    return null;
  }
};

export default decodeToken;
