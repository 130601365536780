import { getSalesDocumentDetail } from "@Adapters/saleboxes/getSalesDocumentDetail.adapter";
import { ISaleCompleteDetails } from "@Models/interfaces/salebox";
import React, { useEffect, useState } from "react";

const useDocumentDetail = (
  saleDocumentId,
  saleBoxId,
  setLoader,
  onNotification
) => {
  const [paymentMethod, setPaymentMethod] = useState<
    {
      amount: number;
      methodPayment: string;
    }[]
  >([]);
  const [saleDocumentDetails, setSaleDocumentDetails] = useState<
    {
      description: string;
      discount: number;
      localProductId: number | null;
      price: number;
      priceServiceId: number;
      quantity: number;
      saleDocumentDetailId: number;
      total: number;
    }[]
  >([]);
  const [change, setChange] = useState<number>(0);
  const [qrData, setQrData] = useState<string>("");
  const [hash, setHash] = useState<string>("");
  const [saleBoxName, setSaleBoxName] = useState<string>("");
  const [ruc, setRuc] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [typeDocumentName, setTypeDocumentName] = useState<string>("");
  const [localName, setLocalName] = useState<string>("");
  const [clientDocumentName, setClientDocumentName] = useState<string>("");

  const [saleCompleteDetails, setSaleCompleteDetails] =
    useState<ISaleCompleteDetails>(null);

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await getSalesDocumentDetail(
          saleDocumentId,
          saleBoxId,
          onNotification
        );

        setSaleCompleteDetails({
          id: response.saleDocumentId,
          selectedSaleBox: {
            id: response.saleBoxId,
            name: response.saleBoxName,
          },
          billing: {
            ruc: response.ruc,
            address: response.address,
            localName: response.localName,
            serie: response.serie,
            document: response.number,
            emissionDate: response.dateEmision,
            documentType: {
              type: response.typeDocument,
              name: response.typeDocumentName,
            },
          },
          details: {
            change: response.change,
            discount: response.discount,
            date: response.dateCreated,
            employee: {
              id: response.employeeId,
              name: response.employeeName,
            },
            qrData: response.qrData,
            hash: response.hash,
            items: response.saleDocumentDetails.map((detail) => ({
              detailId: detail.saleDocumentDetailId,
              description: detail.description,
              quantity: detail.quantity,
              price: detail.price,
              discount: detail.discount,
              igv: detail.igv,
              total: detail.total,
              localProductId: detail.localProductId,
              priceServiceId: detail.priceServiceId,
            })),
            paymentMethods: response.paymentMethod.map((payment) => ({
              amount: payment.amount,
              name: payment.methodPayment,
            })),
            summary: {
              discount: response.discount,
              change: response.change,
              icbper: response.icbper,
              igv: response.igv,
              subtotal: response.subTotal,
              total: response.total,
            },
          },
          client: {
            id: response.clientId,
            name: response.clientName,
            documentType: {
              number: response.clientDocumentNumber,
              name: response.clientDocumentName,
            },
          },
        });
        setPaymentMethod(response.paymentMethod);
        setSaleDocumentDetails(response.saleDocumentDetails);
        setChange(response.change);
        setQrData(response.qrData);
        setHash(response.hash);
        setSaleBoxName(response.saleBoxName);
        setRuc(response.ruc);
        setAddress(response.address);
        setTypeDocumentName(response.typeDocumentName);
        setLocalName(response.localName);
        setClientDocumentName(response.clientDocumentName);
        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleDocumentId]);

  return {
    paymentMethod,
    saleDocumentDetails,
    change,
    qrData,
    hash,
    saleBoxName,
    ruc,
    address,
    typeDocumentName,
    localName,
    clientDocumentName,
    saleCompleteDetails,
  };
};

export default useDocumentDetail;
