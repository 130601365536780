import React, { useEffect, useState } from "react";

interface DropdownProps {
  isOpen: boolean;
  children: React.ReactNode;
  transitionDurationInMs?: number;
  containerStyle?: React.CSSProperties;
  id?: string | number;
  childrenRef?: React.RefObject<HTMLDivElement>;
}

const Dropdown = ({
  isOpen = false,
  children,
  transitionDurationInMs = 400,
  containerStyle,
  //! DON'T USE ID AS A PROP
  id,
  //* USE REF INSTEAD PLEASE
  childrenRef,
}: DropdownProps) => {
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [effectModalOpen, setEffectModalOpen] = useState(false);
  const [close, setClose] = useState(effectModalOpen);

  useEffect(() => {
    if (!childrenRef) {
      const dropdownElement = document.getElementById(`dropdown-${id}`);
      if (dropdownElement) {
        setDropdownHeight(effectModalOpen ? dropdownElement.scrollHeight : 0);
      }
    }
    if (childrenRef) {
      const childrenElement = childrenRef?.current;
      const style = window.getComputedStyle(childrenElement);
      const margin =
        parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      const padding =
        parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
      if (childrenElement) {
        setDropdownHeight(
          effectModalOpen ? childrenElement.scrollHeight + margin + padding : 0
        );
      }
    }
  }, [effectModalOpen]);

  useEffect(() => {
    const halfTransitionDuration = transitionDurationInMs / 2;
    const doubleTransitionDuration = transitionDurationInMs * 2;
    if (isOpen) {
      setClose(true);
      setTimeout(() => {
        setEffectModalOpen(true);
      }, halfTransitionDuration);
    } else {
      setTimeout(() => {
        setEffectModalOpen(false);
      }, transitionDurationInMs);
      setTimeout(() => {
        setClose(false);
      }, doubleTransitionDuration);
    }
  }, [isOpen]);

  return (
    <div
      id={`dropdown-${id}`}
      ref={childrenRef}
      style={{
        display: close ? "block" : "none",
        width: "100%",
        opacity: effectModalOpen ? 1 : 0,
        maxHeight: effectModalOpen ? `${dropdownHeight}px` : "0px",
        transition: `max-height ${transitionDurationInMs}ms ease, opacity ${transitionDurationInMs}ms ease`,
        ...containerStyle,
      }}
    >
      {children}
    </div>
  );
};

export default Dropdown;
