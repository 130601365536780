import React from "react";
import { useAuth } from "../../../hooks/useAuth";

interface IProtectedRouteProps {
  element: JSX.Element;
  accessKeyModule: string | string[];
  validate?: "all" | "some";
}
const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  element: Element,
  accessKeyModule,
  validate = "some",
}) => {
  if (typeof accessKeyModule === "string") {
    const { hasAccess, hasPlanAccess } = useAuth(accessKeyModule);

    if (!hasAccess() || !hasPlanAccess()) {
      window.location.href = "/acceso-no-autorizado";
    }
  } else {
    if (validate === "all") {
      accessKeyModule.forEach((module) => {
        const { hasAccess, hasPlanAccess } = useAuth(module);
        if (!hasAccess() || !hasPlanAccess()) {
          window.location.href = "/acceso-no-autorizado";
        }
      });
    } else if (validate === "some") {
      const atLeastOne = accessKeyModule.some((module) => {
        const { hasAccess, hasPlanAccess } = useAuth(module);
        return hasAccess() && hasPlanAccess();
      });

      if (!atLeastOne) {
        window.location.href = "/acceso-no-autorizado";
      }
    }
  }

  return Element;
};

export default ProtectedRoute;
