import React from "react";
import Tooltip from "@Atoms/Tooltip/Tooltip";
import { truncateString } from "@Utilities/truncateString";

// This function is used to render a tooltip with a string that can be truncated
const TooltipForStrings = (content, numberOfCharacters) => (
  <Tooltip message={content} activated={content?.length > numberOfCharacters}>
    <div>{truncateString(content, numberOfCharacters)}</div>
  </Tooltip>
);

export default TooltipForStrings;
