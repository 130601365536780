import {
  ApiResponse,
  cashRegisterBaseUrl,
  headerAuthorization,
  loungesBaseUrl,
} from "@Services/constServices";
import axios from "axios";

interface ICreateMovement {
  idBox: number;
  idEmployeeMovement: number;
  idBoxMoveConcept: number;
  idMovementType: number;
  amount: number;
  observation: string;
}

export const createMovement_service = async (payload: ICreateMovement) => {
  const url = `${cashRegisterBaseUrl}/CashMovements/RecordMovement`;
  // const crea_movimiento = 59;
  // trackUserAction(crea_movimiento);
  try {
    const response: ApiResponse = await axios
      .post(url, payload, headerAuthorization)
      .then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message: error?.response?.data.message || "Error al crear el movimiento",
      data: [],
    };
  }
};

export const postConceptMovements_service = async (data) => {
  const url = `${loungesBaseUrl}/Concept/Concept`;
  // const crea_concepto_movimiento = 63;
  // trackUserAction(crea_concepto_movimiento);

  try {
    const response: ApiResponse = await axios
      .post(url, data, headerAuthorization)
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al crear el concepto de movimiento. Intentalo de nuevo.",
      data: [],
    };
  }
};

export const updateMovement_service = async (payload) => {
  const url = `${cashRegisterBaseUrl}/CashMovements/EditMovement`;
  // const edita_movimiento = 60;
  // trackUserAction(edita_movimiento);
  try {
    const response: ApiResponse = await axios
      .put(url, payload, headerAuthorization)
      .then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message: error?.response?.data.message || "Error al editar el movimiento",
      data: [],
    };
  }
};

export const getEditingHistory_service = async (id: number) => {
  const url = `${cashRegisterBaseUrl}/CashMovements/GetMovementsEditLogs?MovementId=${id}`;
  // const historial_ediciones = 64;
  // trackUserAction(historial_ediciones);
  try {
    const response: ApiResponse = await axios
      .get(url, headerAuthorization)
      .then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message ||
        "Error al obtener el historial de ediciones",
      data: [],
    };
  }
};

export const deleteMovement_service = async (
  id: number
): Promise<ApiResponse> => {
  const url = `${cashRegisterBaseUrl}/CashMovements/DeleteMove?Id=${id}`;
  // const elimina_movimiento = 61;
  // trackUserAction(elimina_movimiento);
  try {
    const response = await axios
      .delete(url, headerAuthorization)
      .then((res) => res.data);
    return {
      isSuccess: true,
      status: response.status,
      message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data.message || "Error al eliminar el movimiento",
      data: [],
    };
  }
};
