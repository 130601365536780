// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EAKEPHuIgo2MD11eFBm2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  border: 3px solid #937cf4;
  padding: 3px 3px 3px 3px;
}

.tjC8H8xZSLBamo4qVmEo {
  width: 100%;
  height: 100%;
  background-color: #937cf4;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/inputs/CircularInput/CircularInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  border-radius: 50%;\n  border: 3px solid #937cf4;\n  padding: 3px 3px 3px 3px;\n}\n\n.checked {\n  width: 100%;\n  height: 100%;\n  background-color: #937cf4;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EAKEPHuIgo2MD11eFBm2`,
	"checked": `tjC8H8xZSLBamo4qVmEo`
};
export default ___CSS_LOADER_EXPORT___;
